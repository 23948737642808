import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/common/Constants';
import { Utils } from 'src/app/common/Util';
import { ICarouselSlide } from 'src/app/models/carouselSlide';
import { CarouselSlideTypeEnum } from 'src/app/models/response/getSpotLightResponse';
import SwiperCore, { SwiperOptions, Pagination } from 'swiper';
SwiperCore.use([Pagination]);
import { SwiperComponent } from 'swiper/angular';


@Component({
  selector: 'app-carousel-label',
  templateUrl: './carousel-label.component.html',
  styleUrls: ['./carousel-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselLabelComponent implements OnInit {
  @Input() carouselSlides: Array<ICarouselSlide> = [];
  swiper_config: SwiperOptions = null;
  @ViewChild('swiper') swiper_spotLight: SwiperComponent;

  currentSlideIndex = 0;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    const width: number = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    const pictureWidth: number = 0.76 * width;
    this.swiper_config = {
      ...Constants.SPOTLIGHT_SWIPER_CONFIGURATION,
      spaceBetween: (width - pictureWidth),
      allowTouchMove: this.carouselSlides.length > 1
    };
  }

  setCurrentSlideIndex(currentSlideIndex: number) {
    this.swiper_spotLight?.swiperRef.slideTo(currentSlideIndex);
    this.currentSlideIndex = this.swiper_spotLight?.swiperRef.activeIndex % this.carouselSlides.length;
    this.centerCurrentSlide();
  }

  onSlideChange() {
    if( Utils.isNullOrUndefined(this.swiper_spotLight?.swiperRef) ) {
      return;
    }
    this.currentSlideIndex = this.swiper_spotLight?.swiperRef.activeIndex % this.carouselSlides.length;
    this.centerCurrentSlide();
    this.cd.detectChanges();
  }

  moveLeft() {
    if( this.currentSlideIndex === 0 ) {
      this.swiper_spotLight?.swiperRef.slideTo(this.carouselSlides.length - 1);
    } else {
      this.swiper_spotLight?.swiperRef.slidePrev();
    }
    this.currentSlideIndex = this.swiper_spotLight?.swiperRef.activeIndex % this.carouselSlides.length;
  }

  moveRight() {
    if( this.currentSlideIndex === this.carouselSlides.length - 1 ) {
      this.swiper_spotLight?.swiperRef.slideTo(0);
    } else {
      this.swiper_spotLight?.swiperRef.slideNext();
    }
    this.currentSlideIndex = this.swiper_spotLight?.swiperRef.activeIndex % this.carouselSlides.length;
   }

  private centerCurrentSlide() {
    const nativeElement = (<any>this.swiper_spotLight)?.elementRef?.nativeElement;
    if( Utils.isNullOrUndefined(nativeElement)) {
      return;
    }
    const swiperRef = this.swiper_spotLight?.swiperRef;
    if( Utils.isNullOrUndefined(swiperRef)) {
      return;
    }
    
    const width: number = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    const pictureWidth: number = 0.76 * width;

    if (swiperRef.isBeginning) {
      nativeElement.setAttribute('style', `padding-left: ${(width - pictureWidth) / 2}px; padding-right: 0;`);
    } else if (swiperRef.isEnd) {
      nativeElement.setAttribute('style', `padding-left: 0; padding-right: ${(width - pictureWidth) / 2}px;`);
    } else {
      nativeElement.setAttribute('style', `padding-left: 0; padding-right: 0;`);
    }
  }

  openLink(carouselSlide: ICarouselSlide) {
    if( !Utils.isNullOrUndefined(carouselSlide) ) {
      if( carouselSlide.slideType === CarouselSlideTypeEnum.Membership ) {
        // the online-order page logic relies on local storage
        localStorage.setItem(Constants.MEMBERSHIP_ID_KEY, carouselSlide.id.toString() );
        this.router.navigateByUrl('online-order/memberships');
      } else {
        this.router.navigateByUrl( 'promotion-details', { state: { carouselSlide: carouselSlide } });
      }
    }
  }
}
