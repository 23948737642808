import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IRewardUIModel } from 'src/app/models/yourReward';
import { AssetService } from 'src/app/services/asset.service';

@Component({
  selector: 'app-reward-details-card',
  templateUrl: './reward-details-card.component.html',
  styleUrls: ['./reward-details-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RewardDetailsCardComponent implements OnInit {

  @Input() reward: IRewardUIModel = null;
  constructor(public assetService: AssetService) { }

  ngOnInit() {}

}
