import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IHowToEarn } from 'src/app/models/response/getWaysToEarnResponse';
import { AssetService } from 'src/app/services/asset.service';

@Component({
  selector: 'app-birthday-offer-card',
  templateUrl: './birthday-offer-card.component.html',
  styleUrls: ['./birthday-offer-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BirthdayOfferCardComponent implements OnInit {

  @Input() howToEarn: IHowToEarn = null;

  constructor(public assetService: AssetService) { }

  ngOnInit() {}

}
