import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PunchCard } from 'src/app/models/punchCard';

@Component({
  selector: 'app-punch-cards',
  templateUrl: './punch-cards.component.html',
  styleUrls: ['./punch-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PunchCardsComponent implements OnInit {

  @Input() punchCards: Array<PunchCard>;
  @Input() hideTitle: boolean = false;

  constructor(private router: Router) { }

  ngOnInit() {}

  seeAll() {
    this.router.navigateByUrl('tabs/rewards-v4/1');
  }

}
