import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './guards/authentication.guard';
import { CloverEcomCardInfoComponent } from './components/wallet/clover-ecom-card-info/clover-ecom-card-info.component';
import { SquareCardInfoComponent } from './components/wallet/square-card-info/square-card-info.component';
import { StripeCardInfoComponent } from './components/wallet/stripe-card-info/stripe-card-info.component';
import { ToastCardInfoComponent } from './components/wallet/toast-card-info/toast-card-info.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate:[AuthenticationGuard]
  },
  {
    path: 'on-board1',
    loadChildren: () => import('./pages/on-board1/on-board1.module').then( m => m.OnBoard1PageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate:[AuthenticationGuard]
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule),
    canActivate:[AuthenticationGuard]
  },
  {
    path: 'notification-detail',
    loadChildren: () => import('./pages/notification-detail/notification-detail.module').then( m => m.NotificationDetailPageModule),
    canActivate:[AuthenticationGuard]
  },
  {
    path: 'wallet',
    loadChildren: () => import('./pages/wallet/wallet.module').then( m => m.WalletPageModule),
    canActivate:[AuthenticationGuard]
  },
  {
    path: 'load-wallet',
    loadChildren: () => import('./pages/load-wallet/load-wallet.module').then( m => m.LoadWalletPageModule),
    canActivate:[AuthenticationGuard]
  },
  {
    path: 'reward-details',
    loadChildren: () => import('./pages/reward-details/reward-details.module').then(m => m.RewardDetailsPageModule),
    canActivate:[AuthenticationGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
    canActivate:[AuthenticationGuard]
  },
  {
    path: 'profile-edit',
    loadChildren: () => import('./pages/profile-edit/profile-edit.module').then( m => m.ProfileEditPageModule),
    canActivate:[AuthenticationGuard]
  },
  {
    path: 'refer-afriend',
    loadChildren: () => import('./pages/refer-afriend/refer-afriend.module').then( m => m.ReferAFriendPageModule),
    canActivate:[AuthenticationGuard]
  },
  {
    path: 'leave-feedback',
    loadChildren: () => import('./pages/leave-feedback/leave-feedback.module').then( m => m.LeaveFeedbackPageModule),
    canActivate:[AuthenticationGuard]
  },
  {
    path: 'make-apurchase',
    loadChildren: () => import('./pages/make-apurchase/make-apurchase.module').then( m => m.MakeAPurchasePageModule),
    canActivate:[AuthenticationGuard]
  },
  {
    path: 'birthday-offer',
    loadChildren: () => import('./pages/birthday-offer/birthday-offer.module').then( m => m.BirthdayOfferPageModule),
    canActivate:[AuthenticationGuard]
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/change-password/change-password.module').then( m => m.ChangePasswordPageModule),
    canActivate:[AuthenticationGuard]
  },
  {
    path: 'qrcode',
    loadChildren: () => import('./pages/qrcode/qrcode.module').then(m => m.QRCodePageModule),
    canActivate:[AuthenticationGuard]
  },
  {
    path: 'login-email',
    loadChildren: () => import('./pages/login-email/login-email.module').then( m => m.LoginEmailPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'phone-verification',
    loadChildren: () => import('./pages/phone-verification/phone-verification.module').then( m => m.PhoneVerificationPageModule)
  },
  {
    path: 'provide-phone-number',
    loadChildren: () => import('./pages/provide-phone-number/provide-phone-number.module').then( m => m.ProvidePhoneNumberPageModule)
  },
  {
    path: 'help-v4',
    loadChildren: () => import('./pages/help-v4/help-v4.module').then( m => m.HelpV4PageModule)
  },
  {
    path: 'privacy-policy-v4',
    loadChildren: () => import('./pages/privacy-policy-v4/privacy-policy-v4.module').then( m => m.PrivacyPolicyV4PageModule)
  },
  {
    path: 'terms-v4',
    loadChildren: () => import('./pages/terms-v4/terms-v4.module').then( m => m.TermsV4PageModule)
  },
  {
    path: 'activity',
    loadChildren: () => import('./pages/activity/activity.module').then( m => m.ActivityPageModule),
    canActivate:[AuthenticationGuard]
  },
  {
    path: 'on-board2',
    loadChildren: () => import('./pages/on-board2/on-board2.module').then(m => m.OnBoard2PageModule)
  },
  {
    path: 'on-boarding-slides',
    loadChildren: () => import('./pages/on-boarding-slides/on-boarding-slides.module').then( m => m.OnBoardingSlidesPageModule)
  },
  {
    path: 'disclaimer',
    loadChildren: () => import('./pages/disclaimer/disclaimer.module').then( m => m.DisclaimerPageModule)
  },
  {
    path: 'thank-you-for-feedback',
    loadChildren: () => import('./pages/thank-you-for-feedback/thank-you-for-feedback.module').then( m => m.ThankYouForFeedbackPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'online-order',
    loadChildren: () => import('./pages/online-order/online-order.module').then(m => m.OnlineOrderPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'online-order/:subpage',
    loadChildren: () => import('./pages/online-order/online-order.module').then(m => m.OnlineOrderPageModule),
    canActivate:[AuthenticationGuard]
  },
  {
    path: 'store-info',
    loadChildren: () => import('./pages/store-info/store-info.module').then( m => m.StoreInfoPageModule)
  },
  {
    path: 'promotion-details',
    loadChildren: () => import('./pages/promotion-details/promotion-details.module').then( m => m.PromotionDetailsPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'stripe-card',
    component: StripeCardInfoComponent,
    canActivate:[AuthenticationGuard]
  },
  {
    path: 'clover-ecom-card',
    component: CloverEcomCardInfoComponent,
    canActivate:[AuthenticationGuard]
  },
  {
    path: 'toast-card',
    component: ToastCardInfoComponent,
    canActivate:[AuthenticationGuard]
  },
  {
    path: 'square-card',
    component: SquareCardInfoComponent,
    canActivate:[AuthenticationGuard]
  },
  {
    path: 'forgot-password-provide-new-password',
    loadChildren: () => import('./pages/forgot-password-provide-new-password/forgot-password-provide-new-password.module').then( m => m.ForgotPasswordProvideNewPasswordPageModule)
  },
  {
      path: '**',
      loadChildren: () => import('./pages/on-board1/on-board1.module').then( m => m.OnBoard1PageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
