import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from 'src/app/common/Util';
import { IHowToEarn } from 'src/app/models/response/getWaysToEarnResponse';

@Component({
  selector: 'app-ways-to-earn',
  templateUrl: './ways-to-earn.component.html',
  styleUrls: ['./ways-to-earn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WaysToEarnComponent implements OnInit {

  @Input() waysToEarn: Array<IHowToEarn> = [];

  constructor(private router: Router) { }

  ngOnInit() {}

  redirectTo(howToEarn: IHowToEarn) {
    if( Utils.isNullOrUndefined(howToEarn) ) {
      return;
    }
    let route: string = '';
    if (howToEarn.Type == 'Referral') {
      route = 'refer-afriend';
    }
    else if (howToEarn.Type == 'Feedback') {
      route = 'leave-feedback';
    }
    else if (howToEarn.Type == 'Birthday') {
      route = 'birthday-offer';
    }
    else if (howToEarn.Type == 'Spend') {
      route = 'make-apurchase';
    }
    
    this.router.navigateByUrl(route, { state: { howToEarn: howToEarn } });
  }
}
