import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-network-error',
  templateUrl: './network-error.component.html',
  styleUrls: ['./network-error.component.scss'],
})
export class NetworkErrorComponent implements OnInit {

  constructor(private modalController: ModalController) { }

  ngOnInit() {
   
  }

  dismiss(){
    this.modalController.dismiss();
  }
 
}
