import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { GetWalletLoadDataResponse } from '../../../models/wallet';
import { AssetService } from '../../../services/asset.service';
import { UtilService } from '../../../services/util.service';
import { WalletService } from '../../../services/wallet.service';
import { LoadingOptions } from '../../popup-model/popup-model.component';
import { PopupService } from '../../../services/popup.service';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Utils } from 'src/app/common/Util';
import { BaseComponent } from '../../base/base.component';
import { takeUntil } from 'rxjs/operators';
import { WalletV4Service } from 'src/app/services/wallet-v4.service';

@Component({
  selector: 'app-square-card-info',
  templateUrl: './square-card-info.component.html',
  styleUrls: ['./square-card-info.component.scss'],
  providers: [Keyboard]
})
export class SquareCardInfoComponent extends BaseComponent implements OnInit {

  @ViewChild('backBtn') backBtn: ElementRef<HTMLElement>;

  //@ViewChild('timePicker')
  //timePicker: DateTime;
  //public autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/yy');
  customer: any;

  //creditMask = [/\d/, /\d/, /\d/, /\d/, '\u2000', /\d/, /\d/, /\d/, /\d/, '\u2000', /\d/, /\d/, /\d/, /\d/, '\u2000', /\d/, /\d/, /\d/, /\d/];
  //dateMask = [/\d/, /\d/, '/', /\d/, /\d/];

  walletLoadData: GetWalletLoadDataResponse;
  date = "";
  cardType: string;
  cardHolderName = "";
  saveCardDetail: boolean = false;
  loadWalletAmount: any = 0.00;
  currencySymbol: string = "$"
  address = "";
  city = "";
  state = "";
  countryCode: any;

  countries: any;

  editing: boolean = false;
  readyOnly: boolean = false;
  shouldChargeWallet: boolean = false;
  amount;
  SqPaymentForm: any;
  static paymentForm: any;
  card: any;
  payments: any;
  canSaveCreditCard: boolean = false;
  square3DSEnabled: boolean = false;

  keyboardOpened: boolean = false;

  constructor(private toastCtrl: ToastController,
    private translationService: TranslateService,
    private router: Router,
    private util: UtilService,
    private walletV4Service: WalletV4Service,
    private walletService: WalletService, // COSMIN this was kept from backward compatibility reasons to avaoid changing the payment forms
    private assetService: AssetService,
    private translate: TranslateService,
    public popupService: PopupService,
    public keyboard: Keyboard) {
      super();
  }

  ngOnInit() { }

  async ionViewWillEnter() {

    this.listenKeyboardevents();

    this.currencySymbol = this.assetService.currencySymbol;
    this.loadWalletAmount = this.walletService.reloadAmount;

    await this.walletService.getWalletLoadData().then(async (result: GetWalletLoadDataResponse) => {
      if (result) {
        this.walletLoadData = result;
        this.canSaveCreditCard = result.CanSaveCreditCard;
        this.square3DSEnabled = result.Square3DSEnabled;
      }
    }, async error => {
      Utils.handleError(error, this.popupService, 'There was an error while processing this request. Please try again later.');

      await this.backDynamic();
    });
   
    this.init();
  }

  listenKeyboardevents() {
    this.keyboard.onKeyboardWillShow()
    .pipe(takeUntil(this.destroyed$))
    .subscribe(() => this.keyboardOpened = true );

    this.keyboard.onKeyboardWillHide()
    .pipe(takeUntil(this.destroyed$))
    .subscribe(() => this.keyboardOpened = false );
  }

  async ionViewWillLeave() {
    this.cardHolderName = "";
    this.saveCardDetail = false;
  }

  async backDynamic() {
    let el: HTMLElement = (<any>this.backBtn).el;
    el.click();
  }

  async requestCardNonce() {
    if (!this.cardHolderName.replace(/\s/g, '').length) {      
      let toast = await this.toastCtrl.create({
          message: this.translationService.instant("Card Holder Name is Required"),
          duration: 5000,
          position: 'bottom'
      });
      toast.present();
      return;
    }

    const result = await this.card.tokenize({
      accountHolderName: this.cardHolderName
    });

    if (result.status === 'OK') {
      
      const confirmMessage: string = this.translate.instant(`Your card will be charged`) + ` ${this.currencySymbol}${Number(this.loadWalletAmount).toFixed(2)}`;
      this.popupService.confirmV4(null, confirmMessage).then(async (confirmed: boolean) => {
        if (confirmed) {

          let loadingOptions = {
            processingMessage: 'Processing Payment...'
          } as LoadingOptions;

          let loader = await this.popupService.loading(loadingOptions);


          let verificationToken = null;
          let payWithSavedCreditCard = false;

          if (this.square3DSEnabled == true) {
            if (this.saveCardDetail) {
              let pgCardId: string = await this.saveCardAndGetId(result.token);
              if (!pgCardId || pgCardId.trim() == "") {
                await this.popupService.failV4('Saving card Failed', 'Unable to save the card. Please try again.');
                await loader.dismiss();
                return;
              }

              verificationToken = await this.verifyBuyer(pgCardId, this.loadWalletAmount);
              payWithSavedCreditCard = true;

              let vrfction = verificationToken;
              if (vrfction == null || vrfction == undefined || vrfction == "") {
                await this.popupService.failV4('Payment Failed', 'Card verification failed.');
                await loader.dismiss();
                return;
              }
            }
            else {
              verificationToken = await this.verifyBuyer(result.token, this.loadWalletAmount);

              let vrfction = verificationToken;
              if (vrfction == null || vrfction == undefined || vrfction == "") {
                await this.popupService.failV4('Payment Failed', 'Card verification failed.');
                await loader.dismiss();
                return;
              }
            }
          }


          const payloadData = {
            CardHolderName: this.cardHolderName,
            Token: result.token,
            SquareVerificationToken: verificationToken,
            CustomerId: 0,
            Amount: this.loadWalletAmount,
            //CardNumber: sessionStorage.getItem('LAST_4'),
            CardExpirationMonth: "",
            CardExpirationYear: "",
            CardCVV: "",
            SaveCreditCard: this.saveCardDetail,
            PayWithSavedCreditCard: payWithSavedCreditCard
          };
          await this.walletService.loadFundsV4(payloadData).then(async (response: any) => {

            await loader.dismiss();

            console.log(response);
            if (response.OK == false) {
              let errorMessage =
                this.translate.instant('Error verifying card. Please try again.') ;

              if (
                response.ErrorDetails &&
                response.ErrorDetails.ErrorType == 11
              ) {
                errorMessage =
                  this.translate.instant('The minimum amount to reload wallet is $')  +
                  Number(response.ErrorDetails.Amount).toFixed(2);
              }
              if (
                response.ErrorDetails &&
                response.ErrorDetails.ErrorType == 10
              ) {
                errorMessage =
                  this.translate.instant('Enter lower amount, wallet balance cannot exceed limit of $')  +
                  Number(response.ErrorDetails.Amount).toFixed(2);
              }

              await this.popupService.failV4('Payment Failed', errorMessage);

              console.error(response.Error);
            } else {
              await this.popupService.successV4('Payment Success', 'Your wallet has been reloaded.').then(async () => {
                this.walletV4Service.clearInMemoryCache();
                this.walletService.clearCache();
                this.walletService.getWalletBalance({ MerchantLocationId: this.util.merchantLocationId });
                this.router.navigateByUrl('wallet');
              });
            }
          });
        }
      });
    }

    else {
      await this.popupService.notifyV4("Ooops! Something went wrong. Please try again.");
    }
  }

  async verifyBuyer(tokenOrCardId, amount) {
    const verificationDetails = {
      amount: Number(amount).toFixed(2),
      currencyCode: this.util.currencyCode,
      intent: 'CHARGE',
      billingContact: {}
    };

    const verificationResults = await this.payments.verifyBuyer(
      tokenOrCardId,
      verificationDetails
    );
    return verificationResults.token;
  }

  async init() {
    this.initPayments(this.walletLoadData.SquareApplicationId, this.walletLoadData.SquareLocationId);
    this.card = await this.payments.card();
    await this.card.attach('#card-container');
  }

  initPayments(squareApplicationId, squareLocationId) {
    console.log('Init Square Payment Form: ApplicationId=' + squareApplicationId + ' LocationId=' + squareLocationId);
    this.payments = (<any>window).Square.payments(squareApplicationId, squareLocationId);
  }

  async saveCardAndGetId(token) {
    let cardId: string;    
    const payloadData = {
      token: token,
      cardHolderName: this.cardHolderName
    };    

    await this.walletService.saveCustomerCard(payloadData).then(async (result: any) => {
      console.log(result);
      if (result.OK) {
        cardId = result.CardId;
      } else {
        if (result.Error) {
          console.log(result.Error);          
        }
        else {
          console.log(result);
        }
      }
    }, (err) => {
      console.log(err);
    });
    return cardId;
  }
}
