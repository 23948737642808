import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-reload-amount',
  templateUrl: './reload-amount.component.html',
  styleUrls: ['./reload-amount.component.scss'],
})
export class ReloadAmountComponent implements OnInit {
  
  @Input() amounts: Array<number> = [];
  @Input() amountToLoad?: number = null;
  @Input() onAmountSelectedFunc: Function;

  constructor(public modalCtrl: ModalController) { }

  ngOnInit() {}

  dismiss(){
    this.modalCtrl.dismiss();
  }

  selectAmount(value) 
  {
    console.log(`ReloadAmountComponent.selectAmount(${value})`);
    this.amountToLoad = value;
    this.onAmountSelectedFunc(value);
    this.dismiss();
  }

}
