import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { config } from 'src/config';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Utils } from 'src/app/common/Util';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-input-popup',
  templateUrl: './input-popup.component.html',
  styleUrls: ['./input-popup.component.scss'],
})
export class InputPopupComponent extends BaseComponent implements OnInit {
  @Input() title: string = null;
  @Input() message: string = null;
  @Input() btnYesText: string = null;
  @Input() btnNoText: string = null;
  @Input() onActionProceedFunc: Function = null;

  phoneMinLength: number = Utils.maskAwarePhoneLength;
  phoneMaxLength: number = Utils.maskAwarePhoneLength;
  phonePlaceHolder: string = Utils.getPhoneMask().phonePlaceholder;

  form: FormGroup = this.formBuilder.group({
    phone: new FormControl('', [Validators.minLength(this.phoneMinLength), Validators.maxLength(this.phoneMaxLength), Validators.required])
  });

  constructor(
    public modalCtrl: ModalController,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit() {
    // format the phone number as you type
    this.form.get('phone').valueChanges
    .pipe(
      takeUntil(this.destroyed$),
      debounceTime(500),
      distinctUntilChanged()
    )
    .subscribe(value => this.form.get('phone').setValue(Utils.formatPhoneNumber(value)));
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  save() {
    if( this.form.valid ) {
      const phone: string = this.form.get('phone').value;
      this.onActionProceedFunc(phone);
      this.dismiss();
    }
  }

}
