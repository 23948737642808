import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFeedback } from 'src/app/models/feedback';
import { ISettingsFeedbackResponse } from 'src/app/models/response/getSettingsFeedbackResponse';
import { IHowToEarn } from 'src/app/models/response/getWaysToEarnResponse';
import { AssetService } from 'src/app/services/asset.service';

@Component({
  selector: 'app-leave-feedback-card',
  templateUrl: './leave-feedback-card.component.html',
  styleUrls: ['./leave-feedback-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeaveFeedbackCardComponent implements OnInit {
  @Input() howToEarn: IHowToEarn = null;
  @Input() settingsFeedback: ISettingsFeedbackResponse = null;
  @Output() save: EventEmitter<IFeedback> = new EventEmitter<IFeedback>();

  constructor(public assetService: AssetService) {}

  ngOnInit() {
  }

  saveFeedback(feedback: IFeedback) {
    console.log(`LeaveFeedbackCardComponent.saveFeedback(${feedback})`);
    this.save.emit(feedback);
  }
}
