import { Pipe, PipeTransform } from '@angular/core';
import DOMPurify from 'dompurify';

@Pipe({
  name: 'pureText'
})
export class PureTextPipe implements PipeTransform {

  transform(str: any): any {
    let res = str;
    console.log(res);
    if (str === null || str === undefined || str === '' || str === 0) {
      res = '--';
    }
    else 
    {
      const relReg = /rel="(.*?)"/gm;
      const targetReg = /target="(.*?)"/gm;
      const subst = ``;
      const externalclass = `class=\"externalLink\" `;
      res = res.replace(targetReg, externalclass);
      res = res.replace(relReg, subst); 
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(res, 'text/html');
      var body: any = htmlDoc.getElementsByTagName('body');
      console.log(body[0].innerHTML);
      return DOMPurify.sanitize(body[0].innerHTML);   
    }
    return DOMPurify.sanitize(res);
  }
}
