import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IRewardsSliderData } from 'src/app/models/rewardsSliderData';
import { TileData } from 'src/app/models/tileData';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-rewards-points',
  templateUrl: './rewards-points.component.html',
  styleUrls: ['./rewards-points.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RewardsPointsComponent implements OnInit {
  @Input() rewardsSliderData: IRewardsSliderData;
  @Input() widthPercentage: number;
  @Input() displaySeeDetails: boolean = true;
  @Input() tileData: TileData;
  @Input() showQrCodeTile: boolean;
  @Input() showWalletTile: boolean;

  constructor(private router: Router, public util: UtilService) { }

  ngOnInit() {}

  seeDetails() {
    this.router.navigateByUrl('tabs/rewards-v4/0');
  }

}
