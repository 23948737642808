import { IBaseResponse } from "./baseResponse";

export interface IAppProfileSettingsResponse extends IBaseResponse {
    Profile: IAppProfileSettings;
}

export interface IAppProfileSettings {
    NameRequired: boolean,
    EmailRequired: boolean,
    PhoneRequired: boolean,
    PasswordRequired: boolean,
    NameFullNameRequired: boolean,
    DOBCapture: boolean,
    DOBRequired: boolean,
    DOBYearCapture: boolean,
    DOBYearRequired: boolean,
    AdditionalFields: Array<ICustomerCustomField>
}
 
export interface ICustomerCustomField
{
    Id: string,
    ParentId: string,
    Name: string,
    Description: string,
    Required: boolean,
    DataType: CustomerCustomFieldTypeEnum,
    Items: Array<string>,
    ItemsWithFilter: Array<ICustomerCustomFieldFilteredItems>,

    Value: string,
}

export enum CustomerCustomFieldTypeEnum
{
    text,
    shortdate,
    select,
    childselect,
    date,
    checkbox
}

export interface ICustomerCustomFieldFilteredItems
{
    Filter: string,
    Items: Array<string>
}