import { Injectable } from '@angular/core';
import { EndPoint } from '../enums/endpoints';
import { ApiService } from './api.service';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable} from 'rxjs';
import { AuthenticationToken } from '../models/authenticationToken';
import { ILoginResponse } from '../models/response/loginResponse';
import { IEmailLogin } from '../models/emailLogin';
import { Router } from '@angular/router';
import { skip, take } from 'rxjs/operators';
import { Utils } from '../common/Util';
import { AssetService } from './asset.service';
import { NotificationService } from './notification.service';
import { Constants } from '../common/Constants';
import { ISMSSendRequest } from '../models/request/sendSMSRequest';
import { IBaseResponse } from '../models/response/baseResponse';
import { OnboardStep } from '../enums/onboard-steps';
import { ProfileV4Service } from './profile-v4.service';
import { IEmailSendRequest } from '../models/request/sendEmailRequest';
import { IVerifySMSCodeRequest } from '../models/request/verifySMSCodeRequest';
import { IOnBoard2Settings } from '../models/onBoard2Settings';
import { IAppProfileSettings, IAppProfileSettingsResponse } from '../models/response/getProfileSettingsResponse';
import { IOnboardingRequest, OnboardIdentityClaimTypeEnum } from '../models/request/onboarding-request';
import { CacheCleaningNotificationService } from './cache-cleaning-notification.service';
import { IVerifyEmailCodeRequest } from '../models/request/verifyEmailCodeRequest';
import { PopupService } from './popup.service';
import { IForgotPasswordRequest } from '../models/request/forgotPasswordRequest';
import { IForgotPasswordCodeRequest } from '../models/request/forgotPasswordCodeRequest';
import { IForgotPasswordSetNewPasswordRequest } from '../models/request/forgotPasswordSetNewPasswordRequest';
import { ISendSMSResponse } from '../models/response/sendSMSReponse';

@Injectable({
  providedIn: 'root'
})
export class AuthV4Service {

  constructor(
    private apiService: ApiService,
    public platform: Platform,
    private router: Router,    
    private notificationService: NotificationService, // COSMIN refactor the code so this can be removed from here!
    private assetService: AssetService, // COSMIN refactor the code so this can be removed from here!
    private popupService: PopupService,
    private clearCacheNotificationService: CacheCleaningNotificationService
  ) { }

  public authenticationToken$: BehaviorSubject<AuthenticationToken> = new BehaviorSubject<AuthenticationToken>(Utils.INVALID_AUTHENTICATION_TOKEN);
  get isLoggedIn(): boolean {
    return this.authenticationToken$.getValue().isLoggedIn;
  }

  loginByEmail(emailLogin: IEmailLogin) {
    console.log(`AuthV4Service.loginByEmail(${Utils.debugGetSafeJSON(emailLogin)})`);

    this.authenticationToken$
    .pipe(skip(1), take(1))
    .subscribe((result: AuthenticationToken) => {
      console.log(`AuthV4Service.loginByEmail() authenticationToken$.subscribe: result=${Utils.debugGetSafeJSON(result)}`);
      if( result.isLoggedIn ) {
        this.router.navigateByUrl('/tabs/home');
      }
    });
    
    ProfileV4Service.clearProfileCache();
    this.login(Utils.createEmailPasswordOnBoardingRequest(emailLogin.email, emailLogin.password));
  }


  login(request: IOnboardingRequest) {
    console.log(`AuthV4Service.login(${Utils.debugGetSafeJSON(request)}`);
    this.apiService.postData(EndPoint.ONBOARD, request)
    .subscribe(
      (response: ILoginResponse) => {        
        console.log(`AuthV4Service.login(${Utils.debugGetSafeJSON(response)})`);
        if(!Utils.isNullOrUndefined(response) && !response.IsError ) {        
          if( response.IsAuthenticated && !Utils.isNullOrUndefinedOrWhitespace(response.AuthToken)) {
            localStorage.setItem(Constants.AUTHENTICATION_TOKEN_KEY, response.AuthToken);
            this.authenticationToken$.next(new AuthenticationToken(response.AuthToken));            
          } else if( !response.IsAuthenticated ) {
            request = this.changeOnBoardingRequestIfNeeded(request, response);
            this.handleOnboardingResult(response, <OnboardStep>(response.NextStep), request, null, true );
          }
        }
        else if(
          Utils.isNullOrUndefined(response) ||
          response.IsError ||
          !response.IsError && !response.IsAuthenticated
        ) {
          localStorage.removeItem(Constants.AUTHENTICATION_TOKEN_KEY);
          this.authenticationToken$.next(Utils.INVALID_AUTHENTICATION_TOKEN); 
        }
      },
      (error) => {
        localStorage.removeItem(Constants.AUTHENTICATION_TOKEN_KEY);
        this.authenticationToken$.next(Utils.INVALID_AUTHENTICATION_TOKEN); 
        console.error(error);
      }
    );
  }

  onBoard(request: IOnboardingRequest, phone: string) {
    console.log(`AuthV4Service.onBoard(${Utils.debugGetSafeJSON(request)}`);
    this.apiService.postData(EndPoint.ONBOARD, request)
    .subscribe(
      (response: ILoginResponse) => {
        console.log(`AuthV4Service.onBoard(${Utils.debugGetSafeJSON(response)})`);
        if( !Utils.isNullOrUndefined(response) && !response.IsError ) {
          let nextStep: OnboardStep = OnboardStep.DONE;
          if(response.IsAuthenticated && !Utils.isNullOrUndefinedOrWhitespace(response.AuthToken)) {
            localStorage.setItem(Constants.AUTHENTICATION_TOKEN_KEY, response.AuthToken);
            this.authenticationToken$.next(new AuthenticationToken(response.AuthToken));           
          } else if( !response.IsAuthenticated ) {
            nextStep = response.NextStep;
          }          
          this.handleOnboardingResult(response, nextStep, request, phone, false);
        } else {
          localStorage.removeItem(Constants.AUTHENTICATION_TOKEN_KEY);
          this.authenticationToken$.next(Utils.INVALID_AUTHENTICATION_TOKEN);          
        }
      },
      (error) => {
        localStorage.removeItem(Constants.AUTHENTICATION_TOKEN_KEY);
        this.authenticationToken$.next(Utils.INVALID_AUTHENTICATION_TOKEN); 
        console.error(error);
      }
    );
  }

  private changeOnBoardingRequestIfNeeded(request: IOnboardingRequest, response: ILoginResponse) : IOnboardingRequest {
    if (response.NextStep != OnboardStep.DONE && request.IdentityClaimType == OnboardIdentityClaimTypeEnum.USERNAME_AND_PASSWORD) {
      console.log(`AuthV4Service.changeOnBoardingRequestIfNeeded() changing IdentityClaimType to ${OnboardIdentityClaimTypeEnum.ONBOARDING_TOKEN}`);
      request.IdentityClaimType = OnboardIdentityClaimTypeEnum.ONBOARDING_TOKEN;
      request.IdentityClaim.Username = null;
      request.IdentityClaim.Password = null;
      request.IdentityClaim.OnboardingToken = response.OnboardingToken;
    }
    return request;
  }

  logout(navigateToLogin: boolean = false, navigateToLoginByEmail: boolean = false) {
    this.authenticationToken$.next(Utils.INVALID_AUTHENTICATION_TOKEN);
    
    this.clearLocalStorage();
    this.clearCacheNotificationService.sendClearCacheNotification();

    this.notificationService.clear();  
    this.assetService.clearAll();

    // COSMIN refactor this code as soon as the asset service is refactored!
    this.assetService.getMerchantAssets().then(async (result) => {
      if(navigateToLogin) {
        this.router.navigateByUrl('on-board1', { replaceUrl: true });
      } else if(navigateToLoginByEmail) {
        this.router.navigateByUrl('login-email', { replaceUrl: true });
      }
    });
  }

  private clearLocalStorage() {
    localStorage.removeItem(Constants.AUTHENTICATION_TOKEN_KEY);
    localStorage.removeItem(Constants.NOTIFICATIONS_KEY);
    localStorage.removeItem(Constants.CUSTOMER_ID_KEY);
    localStorage.removeItem(Constants.PROFILE_DATA_KEY);
    localStorage.removeItem(Constants.STORE_INFO_KEY);
    localStorage.removeItem(Constants.LAST_FETCHED_STORE_INFO_DATA_KEY);
    localStorage.removeItem(`${this.assetService.merchantGuid}_merchant_assets`);
    localStorage.removeItem(Constants.PROMOTIONS_KEY);
    localStorage.removeItem(Constants.MEMBERSHIPS_KEY);
    localStorage.removeItem(Constants.PUNCHCARDS_KEY);
    localStorage.removeItem(Constants.REWARDS_KEY);
    localStorage.removeItem(Constants.SPOTLIGHT_KEY);
    localStorage.removeItem(Constants.WAYS_TO_EARN_KEY);
    localStorage.removeItem(Constants.WALLET_BALANCE_KEY);
    localStorage.removeItem(Constants.WALLET_LOAD_DATA_KEY);
    localStorage.removeItem(Constants.DEVICE_ID_KEY);
    localStorage.removeItem(Constants.VERIICATION_TYPE_KEY);
    localStorage.removeItem(Constants.VERIFICATION_PHONE_KEY);
    localStorage.removeItem(Constants.VERIFICATION_EMAIL_KEY);
    localStorage.removeItem(Constants.VERIFICATION_CUSTOMER_ID_KEY);
  }


  sendSMSVerificationCode(request: ISMSSendRequest): Observable<ISendSMSResponse> {
    console.log(`AuthV4Service.sendSMSVerificationCode(${Utils.debugGetSafeJSON(request)}`);
    return this.apiService.postData(EndPoint.VERIFY_PHONE_NUMBER, request) as Observable<ISendSMSResponse>;
  }

  sendEmailVerificationCode(request: IEmailSendRequest): Observable<IBaseResponse> {
    console.log(`AuthV4Service.sendSMSVerificationCode(${Utils.debugGetSafeJSON(request)}`);
    return this.apiService.postData(EndPoint.VERIFY_EMAIL, request) as Observable<IBaseResponse>;
  }


  getAppProfileSettings() : Observable<IAppProfileSettingsResponse> {
    console.log(`AuthV4Service.getAppProfileSettings()`);
    return this.apiService.getData(EndPoint.APP_PROFILE_SETTINGS) as Observable<IAppProfileSettingsResponse>;
  }


  verifySMSVerificationCode(request: IVerifySMSCodeRequest, onboardingRequest: IOnboardingRequest) {
    console.log(`AuthV4Service.verifySMSVerificationCode(${Utils.debugGetSafeJSON(request)}`);
    this.apiService.postData(EndPoint.VERIFY_CODE_PHONE, request)
    .subscribe(
      (response: IBaseResponse) => {
        console.log(`AuthV4Service.verifySMSVerificationCode(${Utils.debugGetSafeJSON(response)})`);
        if(!Utils.isNullOrUndefined(response) && !response.IsError ) {
          const newOnBoardingRequest: IOnboardingRequest = Utils.isNullOrUndefined(onboardingRequest) ? 
                                                           Utils.createPhoneOnBoardingRequest(request.Phone, null) :
                                                           onboardingRequest;
          this.onBoard(newOnBoardingRequest, request.Phone);
        } else if( Utils.isNullOrUndefined(response) || response.IsError ) {
          Utils.handleError(response, this.popupService, 'We could not verify your phone number. Please provide a valid phone number.');
        }
      },
      (error) => Utils.handleError(error, this.popupService, 'We could not verify your phone number. Please provide a valid phone number.')
    );
  }
  
  verifySMSVerificationCodeForEditProfile(request: IVerifySMSCodeRequest): Observable<IBaseResponse> {
    console.log(`AuthV4Service.verifySMSVerificationCodeForEditProfile(${Utils.debugGetSafeJSON(request)}`);
    return this.apiService.postData(EndPoint.VERIFY_CODE_PHONE, request) as Observable<IBaseResponse>;
  }

  verifyEmailVerificationCode(request: IVerifyEmailCodeRequest) {
    console.log(`AuthV4Service.verifySMSVerificationCode(${Utils.debugGetSafeJSON(request)}`);
    this.apiService.postData(EndPoint.VERIFY_CODE_EMAIL, request)
    .subscribe(
      (response: IBaseResponse) => {        
        console.log(`AuthV4Service.verifyEmailVerificationCode(${Utils.debugGetSafeJSON(response)})`);
        if(!Utils.isNullOrUndefined(response) && !response.IsError ) {
          this.onBoard(Utils.createEmailOnBoardingRequest(request.Email, request.Phone), request.Phone);
        } else if( Utils.isNullOrUndefined(response) || response.IsError ) {
          Utils.handleError(response, this.popupService, 'We could not verify your email address. Please provide a valid email address.');
        }
      },
      (error) => Utils.handleError(error, this.popupService, 'We could not verify your email address. Please provide a valid email address.')
    );
  }

  verifyEmailVerificationCodeForProfileEdit(request: IVerifyEmailCodeRequest): Observable<IBaseResponse> {
    console.log(`AuthV4Service.verifyEmailVerificationCodeForProfileEdit(${Utils.debugGetSafeJSON(request)}`);
    return this.apiService.postData(EndPoint.VERIFY_CODE_EMAIL, request) as Observable<IBaseResponse>;
  }


  async deleteProfile(data: any) {
    return await new Promise<any>((resolve, reject) => {
      this.apiService.postData(EndPoint.DELETE_PROFILE, data).subscribe(async (result: any) => {
        if(result && !result.IsError) {
          resolve(result); 
        }
        else if(result && result.IsError)  
        {      
            reject(result.Error)  
        }
      },
      (err) => {
        reject(err)   
      });
    });
  }

  forgotPassword(request: IForgotPasswordRequest): Observable<IBaseResponse> {
    console.log(`AuthV4Service.forgotPassword(${Utils.debugGetSafeJSON(request)}`);
    return this.apiService.postData(EndPoint.FORGOT_PASSWORD, request) as Observable<IBaseResponse>;
  }
  
  forgotPasswordCode(request: IForgotPasswordCodeRequest): Observable<IBaseResponse> {
    console.log(`AuthV4Service.forgotPasswordCode(${Utils.debugGetSafeJSON(request)}`);
    return this.apiService.postData(EndPoint.FORGOT_PASSWORD_CODE, request) as Observable<IBaseResponse>;
  }
  
  forgotPasswordSetNewPassword(request: IForgotPasswordSetNewPasswordRequest): Observable<IBaseResponse> {
    console.log(`AuthV4Service.forgotPasswordSetNewPassword(${Utils.debugGetSafeJSON(request)}`);
    return this.apiService.postData(EndPoint.FORGOT_PASSWORD_NEW_PASSWORD, request) as Observable<IBaseResponse>;
  }

  public verifyEmailAddress(email: string, isLogin: boolean = false) {    
    console.log(`AuthV4Service.verifyEmailAddress(${email})`);

    const request: IVerifyEmailCodeRequest = <IVerifyEmailCodeRequest>{ 
      MerchantId: null,
      MerchantLocationId: null,
      Phone: null,
      Email: email,
      CustomerId: null
    };

    console.log(`AuthV4Service.verifyEmailAddress(${email}), request=`, request);
    this.sendEmailVerificationCode(request)
    .subscribe((response: IBaseResponse) => {
      console.log(`AuthV4Service.verifyEmailAddress() subscribe: response=${Utils.debugGetSafeJSON(response)}`);
      if( Utils.isNullOrUndefined(response) || response.IsError ) {
        Utils.handleError(response, this.popupService, 'We could not verify your email address. Please provide a valid email address.');
      } else {
        this.handleOnboardingResult(response as any, OnboardStep.EMAIL_CODE, Utils.createEmailOnBoardingRequest(request.Email), null, isLogin )
      }
    },
    (error) => Utils.handleError(error, this.popupService, 'We could not verify your email address. Please provide a valid email address.')
    );
  }

  public sendVerificationCodeByEmail(email: string, phone: string = null, customerId: number = null) {    
    console.log(`AuthV4Service.sendVerificationCodeByEmail(${email})`);

    const sendEmailRequest: IEmailSendRequest = <IEmailSendRequest>{
      MerchantId: null,
      MerchantLocationId: null,
      Email: email,
      Phone: phone,
      CustomerId: customerId
    };
    console.log(`AuthV4Service.sendVerificationCodeByEmail(), sendEmailRequest=`, sendEmailRequest);

    this.sendEmailVerificationCode(sendEmailRequest)
    .pipe(take(1))
    .subscribe((response: IBaseResponse) => {
        console.log(`AuthV4Service.sendVerificationCodeByEmail() response=${Utils.debugGetSafeJSON(response)})`);
        if( Utils.isNullOrUndefined(response) || response.IsError ) {
          Utils.handleError(response, this.popupService, 'We could not verify your email address. Please provide a valid email address.');
        } else {
          this.popupService.successV4('Success', 'We have resent you a verification code.', 'OK');
          this.router.navigateByUrl('phone-verification', { state: { 
            email: sendEmailRequest.Email, 
            verificationType: Constants.UNVERIFIED_EMAIL, 
            customerId: sendEmailRequest.CustomerId,
            onboardingRequest: null
          }, replaceUrl: true });
        }
      },
      (error) => Utils.handleError(error, this.popupService, 'We could not verify your email address. Please provide a valid email address.')
    );
  }

  handleOnboardingResult(response: ILoginResponse, nextStep: OnboardStep, onboardingRequest: IOnboardingRequest = null, phone: string = null, isLogin: boolean = false) {
    console.log(`AuthV4Service.handleOnboardingResult(response=${Utils.debugGetSafeJSON(response)}, nextStep=${nextStep}, onboardingRequest=${Utils.debugGetSafeJSON(onboardingRequest)}, phone=${Utils.debugGetSafeString(phone)}, isLogin=${isLogin})`);

    if (nextStep == OnboardStep.ERROR) {
      Utils.handleError(response, this.popupService);
    } else if (nextStep == OnboardStep.PHONE_VERIFICATION) {
      this.handleNextStepPhoneVerification(response, onboardingRequest, isLogin);
    } else if (nextStep == OnboardStep.EMAIL_VERIFICATION) {
      this.handleNextStepEmailVerification(response);      
    } else if (nextStep == OnboardStep.PHONE_CODE) {
      this.handleNextStepPhoneCode(response, isLogin ? onboardingRequest: null, phone);
    } else if (nextStep == OnboardStep.EMAIL_CODE) {
      this.handleNextStepEmailCode(isLogin ? onboardingRequest: null, onboardingRequest?.IdentityClaim?.Email );
    } else if (nextStep == OnboardStep.PROFILE) {
      this.handleNextStepProfile(response, phone);
    } else if (nextStep == OnboardStep.PROVIDE_PHONE_FOR_VERIFIED_EMAIL) {
      this.handleNextStepProvidePhoneForVerifiedEmail(onboardingRequest);
    } else if (nextStep == OnboardStep.DONE) {
      this.handleNextStepDone(response, onboardingRequest);
    }
  } 

  private handleNextStepPhoneVerification(response: ILoginResponse, onboardingRequest: IOnboardingRequest, isLogin: boolean = false) {
    console.log(`AuthV4Service.handleNextStepPhoneVerification()`);
    ProfileV4Service.clearProfileCache();
    if (onboardingRequest.IdentityClaimType == OnboardIdentityClaimTypeEnum.ONBOARDING_TOKEN) {
      if (!Utils.isNullOrUndefined(response.ClaimedProfile?.Phone)) {
        onboardingRequest.Profile.Phone = response.ClaimedProfile.Phone;
      }
      else {
        this.popupService.notifyV4(`Sorry! Looks like there is no phone number associated, please verify your phone number`);
        return;
      }
    }

    const smsSendRequest: ISMSSendRequest = <ISMSSendRequest>{
      MerchantId: null,
      MerchantLocationId: null,
      Phone: response.ClaimedProfile.Phone,
      CustomerId: response.ClaimedProfile?.CustomerId
    };
    console.log(`AuthV4Service.handleNextStepPhoneVerification(), smsSendRequest=`, smsSendRequest);

    this.sendSMSVerificationCode(smsSendRequest)
    .pipe(take(1))
    .subscribe((response: ISendSMSResponse) => {
      console.log(`AuthV4Service.handleNextStepPhoneVerification() sendSMSVerificationCode() subscribe response=${Utils.debugGetSafeJSON(response)})`);

      if( Utils.isNullOrUndefined(response) || response.IsError ) {
        if( !Utils.isNullOrUndefinedOrWhitespace(response?.LimitErrorMessage ) ) {
          Utils.handleError(response, this.popupService, response.LimitErrorMessage);
        } else {
          Utils.handleError(response, this.popupService, 'We could not verify your phone number. Please provide a valid phone number.');
        }
      } else {
        this.router.navigateByUrl('phone-verification', { state: { 
          phone: smsSendRequest.Phone, 
          verificationType: Constants.UNVERIFIED_PHONE, 
          customerId: smsSendRequest.CustomerId,
          onboardingRequest: isLogin ? onboardingRequest : null
        }, replaceUrl: true });
      }      
    },
    (error) => Utils.handleError(error, this.popupService, 'We could not verify your phone number. Please provide a valid phone number.')
    );
  }


  private handleNextStepEmailVerification(response: ILoginResponse) {
    console.log(`AuthV4Service.handleNextStepEmailVerification()`);
    this.sendVerificationCodeByEmail(response.ClaimedProfile?.Email, response.ClaimedProfile?.Phone, response.ClaimedProfile?.CustomerId);
  }

  private handleNextStepPhoneCode(response: ILoginResponse, onboardingRequest: IOnboardingRequest, phone: string) {
    console.log(`AuthV4Service.handleNextStepPhoneCode()`);
    this.router.navigateByUrl('phone-verification', { state: {
      phone: phone, 
      verificationType: Constants.UNVERIFIED_PHONE, 
      customerId: response.ClaimedProfile?.CustomerId,
      onboardingRequest: onboardingRequest
    }, replaceUrl: true });
  }
 
  private handleNextStepEmailCode(onboardingRequest: IOnboardingRequest = null, email: string) {
    console.log(`AuthV4Service.handleNextStepEmailCode()`);
    this.router.navigateByUrl('phone-verification', { state: { 
      email: email, 
      verificationType: Constants.UNVERIFIED_EMAIL, 
      customerId: null,
      onboardingRequest: onboardingRequest
    }, replaceUrl: true });
  }

  private handleNextStepProfile(response: ILoginResponse, phone: string) {
    console.log(`AuthV4Service.handleNextStepProfile()`);

    if( Utils.isTrue(response.IsError) || Utils.isFirstArrayElementValid(response?.Error?.Errors )) {
      Utils.handleError(response, this.popupService);
    }

    // Check if we need additional fields
    this. getAppProfileSettings()
    .pipe(take(1))
    .subscribe( (profSettingResult: IAppProfileSettingsResponse) => {
      console.log(`AuthV4Service.handleNextStepEmailCode() profSettingResult = `, profSettingResult);
      const settings: IAppProfileSettings = profSettingResult?.Profile;

      const profileSettings: IOnBoard2Settings = <IOnBoard2Settings>{
        email: response?.ClaimedProfile?.Email,
        phone: Utils.isNullOrUndefinedOrWhitespace(response?.ClaimedProfile?.Phone) ? phone: response?.ClaimedProfile?.Phone,
        phoneIsReadOnly: response?.ClaimedProfile?.PhoneIsReadonly,
        emailIsReadOnly: response?.ClaimedProfile?.EmailIsReadonly,
        merchantName: response.MerchantName,
        merchantEmail: response.MerchantEmail,
        merchantWebsite: response.MerchantWebsite,
        additionalFields: settings?.AdditionalFields,
        nameIsRequired: settings?.NameRequired,
        emailIsRequired: settings?.EmailRequired,
        phoneIsRequired: settings?.PhoneRequired,
        passwordIsRequired: settings?.PasswordRequired,
        nameFullNameIsRequired: settings?.NameFullNameRequired,
        captureDOB: settings?.DOBCapture,
        dobIsRequired: settings?.DOBRequired,
        captureDOBYear: settings?.DOBYearCapture,
        dobYearIsRequired: settings?.DOBYearRequired,
        dobDay: response?.ClaimedProfile?.DOBDay,
        dobMonth: response?.ClaimedProfile?.DOBMonth,
        dobYear: response?.ClaimedProfile?.DOBYear,
        fullName: response?.ClaimedProfile?.Name,
        data1: response?.ClaimedProfile?.Data1,
        data2: response?.ClaimedProfile?.Data2,
        data3: response?.ClaimedProfile?.Data3,
        data4: response?.ClaimedProfile?.Data4,
        data5: response?.ClaimedProfile?.Data5,
      };
      this.router.navigateByUrl('on-board2', { state: { profileSettings: profileSettings } }); 
    },
    error => Utils.handleError(error, this.popupService, 'There was an error while processing this request. Please try again later.')
    );
  }

  private handleNextStepProvidePhoneForVerifiedEmail(onboardingRequest: IOnboardingRequest) {
    console.log(`AuthV4Service.handleNextStepProvidePhoneForVerifiedEmail()`);
    this.router.navigateByUrl('provide-phone-number', { state: { email: onboardingRequest.IdentityClaim?.Email }, replaceUrl: true });
  }

  private handleNextStepDone(response: ILoginResponse, onboardingRequest: IOnboardingRequest) {
    console.log(`AuthV4Service.handleNextStepDone()`);
    if(
      response.NextStep === OnboardStep.DONE &&
      ( onboardingRequest.IdentityClaimType === OnboardIdentityClaimTypeEnum.EMAIL || onboardingRequest.IdentityClaimType === OnboardIdentityClaimTypeEnum.PHONE )
    )
    {
      this.router.navigateByUrl('/tabs/home', { replaceUrl: true } );
    }    
  }

  getCompanyEmail() {
    return this.assetService.companyEmail;
  }

  authOnlineOrder() 
  {
    return new Promise<any>((resolve, reject) => {
      this.apiService.postData(EndPoint.AUTH_ONLINE_ORDER, {})
        .subscribe(async (result: any) => {
          if(result)
          {
            resolve(result);
          }
          else if(result && result.IsError)
          {         
            reject(result.Error)
          }
        },
        (err) => {
          reject(err)
        });
    });
  }
}
