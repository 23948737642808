import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ReferAFriendPopupComponent } from '../refer-afriend-popup/refer-afriend-popup.component';
import { UtilService } from 'src/app/services/util.service';
import { Utils } from 'src/app/common/Util';
import { IEarnable, IHowToEarn } from 'src/app/models/response/getWaysToEarnResponse';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-refer-afriend-explanation',
  templateUrl: './refer-afriend-explanation.component.html',
  styleUrls: ['./refer-afriend-explanation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReferAFriendExplanationComponent implements OnInit, OnChanges {

  @Input() howToEarn: IHowToEarn = null;
  private description: string = null;
  
  expanded: boolean = false;
  constructor(
    private modalCtrl: ModalController,
    private util: UtilService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calculateDescription();
  }

  private calculateDescription() {
    this.description = '';
    if( Utils.isFirstArrayElementValid(this.howToEarn?.FriendReferral?.Earnables) ) {
      let descriptions: Array<string> = [];
      this.howToEarn?.FriendReferral?.Earnables.forEach( (e: IEarnable) => descriptions.push(...e.Results));
      this.description = descriptions.filter( e => !Utils.isNullOrUndefinedOrWhitespace(e) ).toString();
    }
  }

  async showInfo() {
    const modal = await this.modalCtrl.create({
      component: ReferAFriendPopupComponent,
      cssClass: 'tm-decision-modal',
      componentProps: {
        btnCloseText: 'Got it, Thanks',
        title: 'How to Complete Referral',
        message: `1. Get your friends to sign up by sharing your referral link.<br/>
        2. After they sign up they receive ${this.description} towards their first purchase.<br/>
        3. Once they have made their first purchase you will receive ${this.description}<br/>`
    }});

    modal.present();
  }

  copyToClipboard() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.howToEarn?.FriendReferral?.Link;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    
    this.toastService.show('Link has been copied to clipboard.');
  }

  openExternalLink(link: string) {
    if (!Utils.isNullOrUndefinedOrWhitespace(link)) {
      this.util.openWithSystemBrowser(link);
    }
  }

}
