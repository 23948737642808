import { config } from "src/config";
import { ErrorResponse } from "../enums/error-responses";
import { AuthenticationToken } from "../models/authenticationToken";
import { IBaseResponse } from "../models/response/baseResponse";
import { IStoreLocation } from "../models/storeLocation";
import { EMPTY_USER_ACCOUNT, IUserAccount } from "../models/userAcccount";
import { UtilService } from "../services/util.service";
import { IOnboardingRequest, EMPTY_ON_BOARDING_REQUEST, OnboardSourceTypeEnum, OnboardIdentityClaimTypeEnum, IOnboardIdentityClaim } from "../models/request/onboarding-request";
import { CustomerCustomFieldTypeEnum, ICustomerCustomField } from "../models/response/getProfileSettingsResponse";
import { IOnBoard2Settings } from "../models/onBoard2Settings";
import { IOnBoardingProfile } from "../models/response/onboardingProfile";
import { IRewardsSliderData } from "../models/rewardsSliderData";
import { IPhoneMask } from "../models/phoneFormatting";
import { Constants } from "./Constants";
import { IGetProfileResponse } from "../models/response/getProfileResponse";
import { EMPTY_LOCATION } from "../models/location";
import { EMPTY_ADDRESS } from "../models/address";
import { PopupService } from "../services/popup.service";

export class Utils {
    private static phoneMask: IPhoneMask = null;
    
    public static isUndefined(value: any) : boolean
    {
        return typeof(value) === 'undefined';
    }
    
    public static isNullOrUndefined(value: any) : boolean
    {
        return value == null ||  typeof(value) === 'undefined';
    }
    
    public static isNullOrUndefinedOrWhitespace(value?: string | null) : boolean
    {
        return value == null ||  typeof(value) === 'undefined' || value.toString().trim() === '';
    }

    public static isTrue(value: boolean) : boolean
    {
        return Utils.isNullOrUndefined(value) ? false: value;
    }
    public static stringToNumber(value: string) : number
    {
        let result: number = null;
        if( !Utils.isNullOrUndefinedOrWhitespace(value)) {
            result = Number(value);
            if( isNaN(result) ) {
                result = null;
            }
        }
        return result;
    }
    public static isNumericCharacter(character: string) : boolean
    {
        return Utils.isNullOrUndefinedOrWhitespace(character) && character.length === 1 &&
               character >= '0' && character <= '9';
    }

    public static getPhoneMask(): IPhoneMask {
        if(!Utils.isNullOrUndefined(Utils.phoneMask)) {
            return Utils.phoneMask;
        }
        Utils.phoneMask = {
            mask: config.PHONE_MASK.toLowerCase().trim(),
            formattingCharacters: [],
            formattingCharactersIndices: [],
            phonePlaceholder: '4161231234'
        };
        const phoneMask: string = Utils.phoneMask.mask;
        if( Utils.isNullOrUndefinedOrWhitespace(phoneMask) ) {
            return Utils.phoneMask;
        }
        // '(xxx) xxx-xxxx'
        for( let index = 0; index < phoneMask.length; ++index ) {
            if( phoneMask[index] !== 'x' ) {
                Utils.phoneMask.formattingCharacters.push(phoneMask[index]);
                Utils.phoneMask.formattingCharactersIndices.push(index);
            }
        }
        Utils.phoneMask.phonePlaceholder = Utils.phoneMask.mask.replace(new RegExp('x', 'g'), '9');
        return Utils.phoneMask;
    }

    public static formatPhoneNumber(phoneNumber: string) : string
    {
        if( Utils.isNullOrUndefinedOrWhitespace(phoneNumber) ) {
            return '';
        }
        Utils.getPhoneMask();
        let result: string = phoneNumber.replace(/[^0-9]/g, '');        
        for( let index = 0; index < Utils.phoneMask.formattingCharacters.length; ++index ) {
            result = Utils.insertAtGivenPositionIntoString(result, Utils.phoneMask.formattingCharacters[index], Utils.phoneMask.formattingCharactersIndices[index]);
        }
        return result;
    }

    public static get maskAwarePhoneLength(): number {
        Utils.getPhoneMask();
        // const regex = /x/gi;
        // return (config.PHONE_MASK.match(regex) || []).length + Utils.phoneMask.formattingCharacters.length;
        return Utils.phoneMask.mask.length;
    }

    public static insertAtGivenPositionIntoString(target: string, stringToAdd: string, indexPosition: number): string {
        if( Utils.isNullOrUndefined(target) || Utils.isNullOrUndefined(stringToAdd) || target.length <= indexPosition ) {
            return target;
        }
         
       return `${target.substring(0, indexPosition)}${stringToAdd}${target.substring(indexPosition)}`;
    }

    
    public static isFirstArrayElementValid(array: Array<any>) : boolean
    {
        return Utils.isNullOrUndefined(array) ? false: Array.isArray(array) && !Utils.isNullOrUndefined(array[0]);
    }

    public static getStringOrNull(value: string) : string
    {
        return Utils.isNullOrUndefined(value) ? null : value;
    }
    public static convertToStringOrNull(value: number | null | undefined) : string
    {
        return Utils.isNullOrUndefined(value) ? null : value.toString();
    }
    public static debugGetSafeString(value: string | null) : string
    {
        let result: string = 'EMPTY';
        if( value == null )
        {
            result = 'NULL';
        }
        else if( Utils.isUndefined(value) )
        {
            result = 'undefined';
        }
        else if( value != '' )
        {
            result = value;
        }
        return result;
    }
    
    public static debugGetSafeJSON(value: any) : string
    {
        let result: string = '';
        if( value == null )
        {
            result = 'NULL';
        }
        else if( Utils.isUndefined(value) )
        {
            result = 'undefined';
        }
        else
        {
            result = JSON.stringify(value);
        }
        return result;
    }

    public static capitalize(value: string): string {
        return Utils.isNullOrUndefinedOrWhitespace(value) ? '' : value[0].toUpperCase() + value.slice(1);
    }

    public static getMinutesBetweenDates(startDate: Date, endDate: Date): number {
        const difference = endDate.getTime() - startDate.getTime();
        return (difference / 60000);
    }
    
    public static formatDateofBirthForDisplay(dateOfBirth: Date, removeYear = false): string {
        let result: string = '';
        if( !Utils.isNullOrUndefined(dateOfBirth) ) {
            if( removeYear ) {
                let month = dateOfBirth.toLocaleString('default', { month: 'short' });
                result = `${month} ${dateOfBirth.getDate()}`;
            } else {
                result = `${(dateOfBirth.getMonth() + 1)}/${dateOfBirth.getDate()}/${dateOfBirth.getFullYear()}`;
            }
        }

        return result;
    }
    
    public static sortbyDistance(latitude: number, longitude: number, storeList: Array<IStoreLocation> ): Array<IStoreLocation> {
        let result: Array<IStoreLocation> = null;
        if (latitude !== 0 && longitude !== 0 && !Utils.isNullOrUndefined(storeList)) {
            result = storeList.sort((n1,n2) => {
              if (n1.Distance > n2.Distance) {
                return 1;
              } else if (n1.Distance < n2.Distance) {
                  return -1;
              } else {    
              return 0;
              }
            });
        } else {
            result = storeList;
        }

        return result;
    }

    public static sortbyHomeLocation(storeList: Array<IStoreLocation> ): Array<IStoreLocation> {
        let result: Array<IStoreLocation> = null;
        if (!Utils.isNullOrUndefined(storeList)) {
            result = storeList.sort((n1, n2) => {
                return Number(n2.IsHome) - Number(n1.IsHome);
            });
        } else {
            result = storeList;
        }

        return result;
    }

    public static calculateStoreDistance(latitude: number, longitude: number, storeLocation: IStoreLocation, distanceUnit: string) {
        if( storeLocation.Lat && storeLocation.Lng && !Utils.isNullOrUndefined(latitude) && !Utils.isNullOrUndefined(longitude) && latitude !== 0 && longitude !== 0) {
            storeLocation.Distance = Number( Utils.distance(latitude, longitude, storeLocation.Lat, storeLocation.Lng, distanceUnit).toFixed(2) );                 
        } else {
            if (!storeLocation.Lat || !storeLocation.Lng) {
                storeLocation.Lat = 0;
                storeLocation.Lng = 0;
            }             
            storeLocation.Distance = 0;
        }
    }

    public static distance(latitude1: number, longitude1: number, latitude2: number, longitude2: number, unit: string): number {
        let result: number = 0;
        if (latitude1 !== latitude2 || longitude1 !== longitude2) {
            const radlat1 = (Math.PI * latitude1) / 180;
            const radlat2 = (Math.PI * latitude2) / 180;
            const theta = longitude1 - longitude2;
            const radtheta = (Math.PI * theta) / 180;
            result =
                Math.sin(radlat1) * Math.sin(radlat2) +
                Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (result > 1) {
                result = 1;
            }
            result = Math.acos(result);
            result = (result * 180) / Math.PI;
            result = result * 60 * 1.1515;
            if (unit == 'K') {
                result = result * 1.609344;
            }
            if (unit == 'N') {
                result = result * 0.8684;
            }
        }

        return result;
    }

    
    public static isMiles(element: IStoreLocation) {
        return !Utils.isNullOrUndefined(element?.Address?.Country) && element.Address.Country.toUpperCase() === 'USA';
    }

    public static getUnit(data: Array<IStoreLocation>){
        return data.some(Utils.isMiles) ? 'N' : 'K';
    }

    public static openMap(storeLocation: IStoreLocation, util: UtilService, isiOS: boolean, isAndroid: boolean) {
        const cleanUrl = storeLocation.Title.replace(/\s/g, '+');
        const destination = storeLocation.Lat + ',' + storeLocation.Lng;
        const openUrl = `https://www.google.com/maps/dir/?api=1&origin=${cleanUrl}&destination=${destination}&travelmode=driving`;

        if(isiOS){
            util.openWithSystemBrowser(`maps://?q=${destination}`);
        } else if(isAndroid){
            util.openWithSystemBrowser(openUrl);
        } 
        else {
            window.open(openUrl);
        }
    }

    public static handleError(response: IBaseResponse, popupService: PopupService, errorMessage: string = null) {

        const isExplicitError: boolean = !Utils.isNullOrUndefined(response) && response.hasOwnProperty('ExplicitErrorMessage') && !Utils.isNullOrUndefinedOrWhitespace(response['ExplicitErrorMessage']);
        const isTimeoutError: boolean = isExplicitError && !Utils.isNullOrUndefinedOrWhitespace(response['Type']) && response['Type'] === Constants.ERROR_TYPE_TIMEOUT;
        
        if( isTimeoutError ) {
            popupService.warnV4(null, response['ExplicitErrorMessage'], 'OK', null);
        } else if( isExplicitError ) {
            popupService.confirmV4(null, response['ExplicitErrorMessage'], 'OK', null);
        } else if( !Utils.isNullOrUndefinedOrWhitespace(errorMessage) ) {
            popupService.confirmV4(null, errorMessage, 'OK', null);
        } else {
            if ( !Utils.isNullOrUndefined(response?.Error?.Errors) ) {
                popupService.confirmV4('Error', Utils.createErrorList(response.Error.Errors), 'OK', null);
            } else if ( !Utils.isNullOrUndefined(response) && !Utils.isNullOrUndefined(response['Errors']) ) {
                popupService.confirmV4('Error', Utils.createErrorList(response['Errors']), 'OK', null);
            }
        }
    }

    public static createErrorList(errors: Array<any>): string
    {
      let errorString: string = '';
      if(!Utils.isNullOrUndefined(errors) && errors.length > 0) {
        errors.forEach(error => errorString += error.Message + '<br />');      
      } else {
        errorString = ErrorResponse.ERROR_ENCOUNTERED;
      }
      return errorString;
    }

    public static PrepareAdditionalProfileFieldForDataLoad(additionalProfileFields: Array<ICustomerCustomField>, userAcccount: IUserAccount): IUserAccount {
        if( Utils.isNullOrUndefined(additionalProfileFields) ) {
            return userAcccount;
        }
        let result: IUserAccount = { ... userAcccount };
        additionalProfileFields.forEach( (additionalProfileField: ICustomerCustomField) => {
            if( additionalProfileField.DataType === CustomerCustomFieldTypeEnum.date ) {
                result[additionalProfileField.Id] = Utils.ParseIsoStringFromCustomString(result[additionalProfileField.Id] as string);
            } else if( additionalProfileField.DataType === CustomerCustomFieldTypeEnum.shortdate ) {
                result[additionalProfileField.Id] = Utils.ParseIsoStringFromCustomString(result[additionalProfileField.Id] as string);
            }
        });
        return result;
    }
    
    public static PopulateUserAccountFromProfileSettings(profileSettings: IOnBoard2Settings, userAcccount: IUserAccount): IUserAccount {
        if( Utils.isNullOrUndefined(profileSettings) ) {
            return userAcccount;
        }
        let result: IUserAccount = {
            ... userAcccount,
            fullName: profileSettings.fullName,
            phone: profileSettings.phone,
            email: profileSettings.email,
            Data1: profileSettings.data1,
            Data2: profileSettings.data2,
            Data3: profileSettings.data3,
            Data4: profileSettings.data4,
            Data5: profileSettings.data5
        };
        const dobYear: number = !Utils.isNullOrUndefined(profileSettings.dobYear) ? profileSettings.dobYear : (new Date()).getFullYear();
        if( !Utils.isNullOrUndefined(dobYear) && !Utils.isNullOrUndefined(profileSettings.dobMonth) && !Utils.isNullOrUndefined(profileSettings.dobDay) ) {
            result.dateOfBirth = new Date(dobYear, profileSettings.dobMonth - 1, profileSettings.dobDay);
        }
        result.formattedDateOfBirth = Utils.formatDateofBirthForDisplay(result.dateOfBirth);
        result = Utils.PrepareAdditionalProfileFieldForDataLoad(profileSettings.additionalFields, result);

        return result;
    }

    public static createUserAccountFromProfileResponse(profileResponse: IGetProfileResponse, merchantLocations: any ): IUserAccount {
        console.log('Utils.createUserAccountFromProfileResponse()');
        let result: IUserAccount = null;
        if( Utils.isNullOrUndefined(profileResponse?.Profile) ) {
            return result;
        }

        result = EMPTY_USER_ACCOUNT;
        result.id = profileResponse.CustomerId.toString();
        result.fullName = profileResponse.Profile.Name;
        result.phone = Utils.formatPhoneNumber(profileResponse.Profile.Phone);
        result.email = profileResponse.Profile.Email;  
        result.enabledPushNotifications = profileResponse.Profile.IsPushEnabled;
  
        if ( !Utils.isNullOrUndefined(profileResponse.Profile.DOBDay) &&  !Utils.isNullOrUndefined(profileResponse.Profile.DOBMonth) ) {
            if( !Utils.isNullOrUndefined( profileResponse.Profile.DOBYear ) ) {
                result.dateOfBirth = new Date(profileResponse.Profile.DOBYear, profileResponse.Profile.DOBMonth - 1, profileResponse.Profile.DOBDay);
            } else {
                const now: Date = new Date();
                result.dateOfBirth = new Date(now.getFullYear(), profileResponse.Profile.DOBMonth - 1, profileResponse.Profile.DOBDay);
            }
        }
        result.formattedDOBFromServerSide = profileResponse.Profile.FormattedDOB;
        result.preferredLocation = EMPTY_LOCATION;
        if (!Utils.isNullOrUndefined(profileResponse.HomeLocation?.MerchantLocationId)) {
            result.preferredLocation.Id = profileResponse.HomeLocation.MerchantLocationId;
            
            if (Utils.isNullOrUndefined(result.preferredLocation.Title) && !Utils.isNullOrUndefined(profileResponse.HomeLocation?.MerchantLocationName)) {
                result.preferredLocation.Title = profileResponse.HomeLocation.MerchantLocationName;
            }
            Utils.FineTuneHomeLocation(merchantLocations, result);
        }
  
        result.Data1 = profileResponse.Profile.Data1; 
        result.Data2 = profileResponse.Profile.Data2; 
        result.Data3 = profileResponse.Profile.Data3; 
        result.Data4 = profileResponse.Profile.Data4; 
        result.Data5 = profileResponse.Profile.Data5; 
        result.formattedDateOfBirth = Utils.formatDateofBirthForDisplay(result.dateOfBirth);

        return result;
    }


    private static FineTuneHomeLocation(merchantLocations: any, userAccount: IUserAccount) {
        console.log('Utils.FineTuneHomeLocation()');
        if (merchantLocations && merchantLocations.length > 0) {
            const preferredStore = merchantLocations.find(x => x.Id == userAccount.preferredLocation.Id);

            if (!Utils.isNullOrUndefined(preferredStore?.Title)) {
                userAccount.preferredLocation.Title = preferredStore.Title;
            }
            if (!Utils.isNullOrUndefined(preferredStore?.Address)) {
                userAccount.preferredLocation.Address = EMPTY_ADDRESS;
                userAccount.preferredLocation.Address.Country = preferredStore.Address.Country;
                userAccount.preferredLocation.Address.State = preferredStore.Address.State;
                userAccount.preferredLocation.Address.City = preferredStore.Address.City;
                userAccount.preferredLocation.Address.Street = preferredStore.Address.Street;
                userAccount.preferredLocation.Address.Zip = preferredStore.Address.Zip;
            }
            if (!Utils.isNullOrUndefined(preferredStore?.Distance)) {
                userAccount.preferredLocation.distance = preferredStore.Distance;
            }
        }
    }

    private static ParseIsoStringFromCustomString(value: string /* '01-20-2020' MM-DD-YYYY*/ ): string {
        let result: string = null;
        if( !Utils.isNullOrUndefinedOrWhitespace(value) ) {
            const now = new Date();
            const segments = value.split('-');
            const year = Utils.isNullOrUndefinedOrWhitespace(segments[2]) ? now.getFullYear() : Number(segments[2]);
            const months = Utils.isNullOrUndefinedOrWhitespace(segments[0]) ? now.getMonth() + 1  : Number(segments[0]);
            const days = Utils.isNullOrUndefinedOrWhitespace(segments[1]) ? now.getDay() : Number(segments[1]);

            result = new Date(year, months, days).toISOString();
        } 
        return result;
    }
    
    private static PrepareProfileDateForSave(dateAsIsoString: string, isShortDate: boolean ): string {
        let result: string = null;
        if( !Utils.isNullOrUndefinedOrWhitespace(dateAsIsoString) ) {
            const date: Date = new Date(dateAsIsoString);
            const month: string = date.getMonth() + 1 < 10 ? `0${(date.getMonth() + 1)}` : `${(date.getMonth() + 1)}`;
            const day: string = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;

            result = isShortDate ? `${month}-${day}` : `${month}-${day}-${date.getFullYear()}`;
        } 
        return result;
    }

    public static PrepareAdditionalProfileFieldForDataSave(additionalProfileFields: Array<ICustomerCustomField>, userAcccount: IUserAccount): IUserAccount {
        if( Utils.isNullOrUndefined(additionalProfileFields) ) {
            return userAcccount;
        }
        let result: IUserAccount = { ... userAcccount };
        additionalProfileFields.forEach( (additionalProfileField: ICustomerCustomField) => {
            if( !Utils.isNullOrUndefined(result[additionalProfileField.Id]) ) {
                if( additionalProfileField.DataType === CustomerCustomFieldTypeEnum.date ) {
                    result[additionalProfileField.Id] = Utils.PrepareProfileDateForSave(result[additionalProfileField.Id] as string, false);
                } else if( additionalProfileField.DataType === CustomerCustomFieldTypeEnum.shortdate ) {
                    result[additionalProfileField.Id] = Utils.PrepareProfileDateForSave(result[additionalProfileField.Id] as string, true);
                }
            }
        });
        return result;
    }
    

    public static createRewardsSliderData( currentNumberOfPoints: number, nextRewardLevel: number, nextRewardLevels: Array<number> ): IRewardsSliderData {
        nextRewardLevel = Utils.isNullOrUndefined(nextRewardLevel) ? 0 : nextRewardLevel;
        nextRewardLevels = Utils.isNullOrUndefined(nextRewardLevels) ? [] : nextRewardLevels;
        if( Utils.isNullOrUndefined(currentNumberOfPoints) ) {
            return null;
        }
        
        const nextLevels: Array<number> = nextRewardLevels.filter(function(item, pos) {
            return nextRewardLevels.indexOf(item) == pos;
        }).slice(0, 4);

        return <IRewardsSliderData>{
            currentNumberOfPoints: currentNumberOfPoints,
            nextRewardLevels: nextLevels
        };
    }

    public static get INVALID_AUTHENTICATION_TOKEN(): AuthenticationToken {
        return new AuthenticationToken(null);
    }


    public static storeToLocalStorage(key: string, object: any) {
        if(Utils.isNullOrUndefined(object)) {
            localStorage.removeItem(key);
        } else {
            const value: string = JSON.stringify(object);
            localStorage.setItem(key, value);
        }
    }

    public static readFromLocalStorage(key: string): any {
        let result = null;
        const value: string = localStorage.getItem(key);
        if( !Utils.isNullOrUndefinedOrWhitespace(value) ) {
            result = JSON.parse(value);
        }
        return result;
    }

    public static readStringFromLocalStorageWithDefaultValue(key: string, defaultValue: string = '' ): any {
        let result = defaultValue;
        const value: string = localStorage.getItem(key);
        if( !Utils.isNullOrUndefinedOrWhitespace(value) ) {
            result = value;
        }
        return result;
    }

    public static minWidthForRewardlevel( rewardlevel: number ): number {
        const MIN_WIDTH_PER_NO_OF_DIGITS = { 1: 1, 2: 1.5, 3: 2.4, 4: 3, 5: 3.2 };
        let noOfDigits: number = rewardlevel.toString().length;
        noOfDigits =  noOfDigits > 5 ? 5 : noOfDigits < 1 ? 1 : noOfDigits;
        return MIN_WIDTH_PER_NO_OF_DIGITS[noOfDigits];
    }
    
    public static get mobileAppAllowsRegistrationByEmail(): boolean {
        return config.REGISTER_WITH_EMAIL != '0';
    }

    public static createPhoneOnBoardingRequest(phone: string, email: string): IOnboardingRequest {
        return <IOnboardingRequest>{
            ... EMPTY_ON_BOARDING_REQUEST,
            Source: OnboardSourceTypeEnum.MOBILE_APP,
            IdentityClaimType: Utils.mobileAppAllowsRegistrationByEmail ? OnboardIdentityClaimTypeEnum.EMAIL : OnboardIdentityClaimTypeEnum.PHONE,
            IdentityClaim: <IOnboardIdentityClaim>{
              ...EMPTY_ON_BOARDING_REQUEST.IdentityClaim,
              Email: email,
              Phone: phone
            }
        };
    }

    public static createEmailOnBoardingRequest(email: string, phone: string = null ): IOnboardingRequest {
        return <IOnboardingRequest>{
            ... EMPTY_ON_BOARDING_REQUEST,
            Source: OnboardSourceTypeEnum.MOBILE_APP,
            IdentityClaimType: OnboardIdentityClaimTypeEnum.EMAIL,
            IdentityClaim: <IOnboardIdentityClaim>{
              ...EMPTY_ON_BOARDING_REQUEST.IdentityClaim,
              Phone: phone,
              Email: email
            }
          };
    }
    
    public static createEmailPasswordOnBoardingRequest(email: string, password: string): IOnboardingRequest {
        return <IOnboardingRequest>{
            ... EMPTY_ON_BOARDING_REQUEST,
            Source: OnboardSourceTypeEnum.MOBILE_APP,
            IdentityClaimType: OnboardIdentityClaimTypeEnum.USERNAME_AND_PASSWORD,
            IdentityClaim: <IOnboardIdentityClaim>{
                ...EMPTY_ON_BOARDING_REQUEST.IdentityClaim,
                Username: email,
                Password: password
            }
          };
    }

    public static createBoardingsRequest(userAccount: IUserAccount, captureDOB: boolean | null, captureDOBYear: boolean | null ): IOnboardingRequest {
        const result: IOnboardingRequest = Utils.createPhoneOnBoardingRequest(userAccount.phone, userAccount.email);
        result.Profile = <IOnBoardingProfile>{
            Name:              userAccount.fullName,
            Email:             userAccount.email,
            Phone:             userAccount.phone,
            DOBDay:            Utils.isTrue(captureDOB) && !Utils.isNullOrUndefined(userAccount.dateOfBirth) ? userAccount.dateOfBirth.getDate() : null, 
            DOBMonth:          Utils.isTrue(captureDOB) && !Utils.isNullOrUndefined(userAccount.dateOfBirth) ? userAccount.dateOfBirth.getMonth() + 1 : null, // this is done for backward compatibility with V3
            DOBYear:           Utils.isTrue(captureDOBYear) && !Utils.isNullOrUndefined(userAccount.dateOfBirth) ?  userAccount.dateOfBirth.getFullYear() : null,
            Password:          userAccount.password,
            Data1:             userAccount.Data1,
            Data2:             userAccount.Data2,
            Data3:             userAccount.Data3,
            Data4:             userAccount.Data4,
            Data5:             userAccount.Data5,
            TermsAccepted:     true,
            CASLAndSMSConsent: true,
            MerchantLocationId: userAccount?.preferredLocation?.Id
        };
        return result;
    }

    public static getRewardIconUrl(iconType: string): string {
        let result: string = Constants.GENERIC_REWARD_ICON_NAME;
        const index = Constants.DISCOUNT_TYPES.findIndex(e => e === iconType);
        if( -1 !== index && index <  Constants.DISCOUNT_TYPE_ICON_NAMES.length ) {
          result = `${Constants.DISCOUNT_TYPE_ICON_NAMES[index]}.svg`;
        }
        return result;
    }
    
    public static getRewardIconFullUrl(iconType: string): string {
        let result: string = Utils.getRewardIconUrl(iconType);
        result = `/assets/icons/${result}`;
        return result;
    }

    public static getPaymentFromUrl(paymentSystemProvider: string): string {
        let result: string = null;
        if( !Utils.isNullOrUndefinedOrWhitespace(paymentSystemProvider) && Constants.PAYMENT_FORM_URL_MAP.has(paymentSystemProvider) ) {
            result = Constants.PAYMENT_FORM_URL_MAP.get(paymentSystemProvider);
        }
        return result;
    }

    public static localStorageValueAsNumber(localStorageKey: string): number | null {
        let result: number = null;
        if( !Utils.isNullOrUndefinedOrWhitespace(localStorageKey)) {
            result = Utils.stringToNumber(localStorage.getItem(localStorageKey))
        }
        return result;
    }

    // a@tapmango.Com == A@tapmaNgo.coM
    public static areEmailAddressesEquivalent(emailAddress1: string, emailAddress2: string) : boolean
    {
        let result: boolean = false;
        if( Utils.isNullOrUndefined(emailAddress1) ) {
            result = Utils.isNullOrUndefined(emailAddress2);
        } else {
            // emailAddress1 is not null or undefined
            if(!Utils.isNullOrUndefined(emailAddress2)) {
                emailAddress1 = emailAddress1.trim().toLowerCase();
                emailAddress2 = emailAddress2.trim().toLowerCase();
                result = emailAddress1 === emailAddress2;
            }
        }
        return result;
    }
}

