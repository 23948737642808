import { Injectable } from '@angular/core';
import { EndPoint } from '../enums/endpoints';
import { Observable, Subject, of } from 'rxjs';
import { take, catchError, map } from 'rxjs/operators';
import { Constants } from '../common/Constants';
import { Utils } from '../common/Util';
import { IClearCacheNotificationClient } from '../models/ClearCacheNotificationClient';
import { IGetWalletBalanceRequest } from '../models/request/getWalletBalanceRequest';
import { IGetWalletBalanceResponse } from '../models/response/getWalletBalanceResponse';
import { ApiService } from './api.service';
import { CacheCleaningNotificationService } from './cache-cleaning-notification.service';
import { IGetWalletLoadDataResponse } from '../models/response/getWalletLoadDataResponse';
import { ICardInfoRequest } from '../models/request/deleteCardRequest';
import { IDeleteCardResponse } from '../models/response/deleteCardResponse';
import { ISetCustomerPhoneResponse } from '../models/response/setCustomerPhoneResponse';
import { ISetCustomerPhoneRequest } from '../models/request/setCustomerPhoneRequest';
import { ILoadFundsV4Response } from '../models/response/loadFundsV4Response';
import { ILoadFundsV4Request } from '../models/request/loadFundsV4Request';
import { PopupService } from './popup.service';

@Injectable({
  providedIn: 'root'
})
export class WalletV4Service implements IClearCacheNotificationClient {
  private getWalletBalanceResponse: IGetWalletBalanceResponse = null;
  private getWalletLoadDataResponse: IGetWalletLoadDataResponse = null;

  public balance$: Subject<number> = new Subject<number>();

  constructor(
    private apiService: ApiService,
    private popupService: PopupService,
    private clearCacheNotificationService: CacheCleaningNotificationService
  ) {
    this.handleClearCacheNotification();
    this.handleClearMemoryCacheNotification();
  }

  getWalletBalance(request: IGetWalletBalanceRequest): Observable<IGetWalletBalanceResponse> {
    console.log(`WalletV4Service.getWalletBalance(${Utils.debugGetSafeJSON(request)}`);
    if( !Utils.isNullOrUndefined(this.getWalletBalanceResponse)) {
      this.setWalletBalance(this.getWalletBalanceResponse);
      return of(this.getWalletBalanceResponse);
    }
    else {
      return this.apiService.postData(EndPoint.USER_WALLET_BALANCE, request)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(error);
          // could not load the most recent getWalletBalanceResponse. We need to display the last loaded ones from the local storage.
          this.setWalletBalance(<IGetWalletBalanceResponse>Utils.readFromLocalStorage(Constants.WALLET_BALANCE_KEY));
  
          Utils.handleError(error, this.popupService, 'There was an error while processing this request. Please try again later.');
          return of(this.getWalletBalanceResponse);
        }),
        map( (response: IGetWalletBalanceResponse) => {
          console.log(`WalletV4Service.getWalletBalance() response=${Utils.debugGetSafeJSON(response)}`);
          if(!Utils.isNullOrUndefined(response)) 
          {
            if(response.IsError) {
              console.error(response.Error);
              // could not load the most recent getWalletBalanceResponse. We need to display the last loaded one from the local storage.
              this.setWalletBalance(<IGetWalletBalanceResponse>Utils.readFromLocalStorage(Constants.WALLET_BALANCE_KEY));
            } else {
              this.setWalletBalance(response);
              Utils.storeToLocalStorage(Constants.WALLET_BALANCE_KEY, response);
            }
          }
          return this.getWalletBalanceResponse;
        })
      )
    }
  }

  forceGetWalletBalance(request: IGetWalletBalanceRequest): Observable<IGetWalletBalanceResponse> {
    console.log('WalletV4Service.forceGetWalletBalance()');
    this.getWalletBalanceResponse = null;
    return this.getWalletBalance(request);
  }

  private setWalletBalance(getWalletBalanceResponse: IGetWalletBalanceResponse) {
    this.getWalletBalanceResponse = getWalletBalanceResponse;
    if( !Utils.isNullOrUndefined(getWalletBalanceResponse?.Balance) ) {
      this.balance$.next(getWalletBalanceResponse?.Balance);
    }
  }

  

  getWalletLoadData(): Observable<IGetWalletLoadDataResponse> {
    console.log(`WalletV4Service.getWalletLoadData()`);
    if( !Utils.isNullOrUndefined(this.getWalletLoadDataResponse)) {
      this.setWalletLoadData(this.getWalletLoadDataResponse);
      return of(this.getWalletLoadDataResponse);
    }
    else {
      return this.apiService.getData(EndPoint.USER_WALLET_DATA)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(error);
          // could not load the most recent getWalletLoadDataResponse. We need to display the last loaded ones from the local storage.
          this.setWalletLoadData(<IGetWalletLoadDataResponse>Utils.readFromLocalStorage(Constants.WALLET_LOAD_DATA_KEY));
  
          Utils.handleError(error, this.popupService, 'There was an error while processing this request. Please try again later.');
          return of(this.getWalletLoadDataResponse);
        }),
        map( (response: IGetWalletLoadDataResponse) => {
          console.log(`WalletV4Service.getWalletLoadData() response=${Utils.debugGetSafeJSON(response)}`);
          if(!Utils.isNullOrUndefined(response)) 
          {
            if(response.IsError) {
              console.error(response.Error);
              // could not load the most recent getWalletLoadDataResponse. We need to display the last loaded one from the local storage.
              this.setWalletLoadData(<IGetWalletLoadDataResponse>Utils.readFromLocalStorage(Constants.WALLET_LOAD_DATA_KEY));
            } else {
              this.setWalletLoadData(response);
              Utils.storeToLocalStorage(Constants.WALLET_LOAD_DATA_KEY, response);
            }
          }
          return this.getWalletLoadDataResponse;
        })
      );
    }
  }

  forceGetWalletLoadData(): Observable<IGetWalletLoadDataResponse> {
    console.log('WalletV4Service.forceGetWalletLoadData()');
    this.getWalletLoadDataResponse = null;
    return this.getWalletLoadData();
  }

  private setWalletLoadData(getWalletLoadDataResponse: IGetWalletLoadDataResponse) {
    this.getWalletLoadDataResponse = getWalletLoadDataResponse;
  }

  handleClearCacheNotification(): void {
    this.clearCacheNotificationService.cacheCleaningNotification$
    .subscribe( () => {
      this.clearInMemoryCache();
      localStorage.removeItem(Constants.WALLET_BALANCE_KEY);
      localStorage.removeItem(Constants.WALLET_LOAD_DATA_KEY);
    });
  }

  handleClearMemoryCacheNotification(): void {
    this.clearCacheNotificationService.memoryCacheCleaningNotification$
    .subscribe( () => this.clearInMemoryCache() );
  }

  public clearInMemoryCache(): void {
    this.setWalletBalance(null);
    this.setWalletLoadData(null);
  }

  deleteCard(request: ICardInfoRequest): Observable<boolean> {
    console.log(`WalletV4Service.deleteCard(${Utils.debugGetSafeJSON(request)}`);

    return this.apiService.postData(EndPoint.USER_DELETE_CARD, request)
    .pipe(
      take(1),
      catchError((error) => {
        console.error(error);
        Utils.handleError(error, this.popupService, 'Something went wrong. Unable to delete the card.');
        return of(false);
      }),
      map( (response: IDeleteCardResponse) => {
        console.log(`WalletV4Service.deleteCard() response=${Utils.debugGetSafeJSON(response)}`);
        if(!Utils.isNullOrUndefined(response)) 
        {
          if(response.IsError || response.Success === false ) {
            console.error(response.Error);
            Utils.handleError(response, this.popupService, 'Something went wrong. Unable to delete the card.');
          } else {
            return true;
          }
        }
        return false;
      })
    );
  }

  

  setCustomerPhone(request: ISetCustomerPhoneRequest): Observable<boolean> {
    console.log(`WalletV4Service.setCustomerPhone(${Utils.debugGetSafeJSON(request)}`);

    return this.apiService.postData(EndPoint.USER_SET_PHONE, request)
    .pipe(
      take(1),
      catchError((error) => {
        console.error(error);
        Utils.handleError(error, this.popupService, 'Phone update has failed.');
        return of(false);
      }),
      map( (response: ISetCustomerPhoneResponse) => {
        console.log(`WalletV4Service.setCustomerPhone() response=${Utils.debugGetSafeJSON(response)}`);
        if(!Utils.isNullOrUndefined(response)) 
        {
          if(response.IsError || response.OK === false ) {
            console.error(response.Error);
            Utils.handleError(response, this.popupService, 'Phone update has failed.');
          } else {
            return true;
          }
        }
        return false;
      })
    );
  }

  loadFundsV4(request: ILoadFundsV4Request): Observable<ILoadFundsV4Response> {
    console.log(`WalletV4Service.loadFundsV4(${Utils.debugGetSafeJSON(request)}`);

    return this.apiService.postData(EndPoint.USER_LOAD_WALLET, request)
    .pipe(
      take(1),
      catchError((error) => {
        console.error(error);
        Utils.handleError(error, this.popupService, 'Error verifying card. Please try again.' );
        return of(error);
      }),
      map( (response: ILoadFundsV4Response) => {
        console.log(`WalletV4Service.loadFundsV4() response=${Utils.debugGetSafeJSON(response)}`);
        if(!Utils.isNullOrUndefined(response)) 
        {
          if(response.IsError ) {
            console.error(response.Error);
            Utils.handleError(response, this.popupService, 'Error verifying card. Please try again.');
          }
        }
        return response;
      })
    );
  }

  clearWalletLoadBalanceResponse() {
    this.getWalletBalanceResponse = null;
    this.getWalletLoadDataResponse = null;
  }
}
