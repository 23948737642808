import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IActivity } from 'src/app/models/response/getActivitiesResponse';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityComponent implements OnInit {

  @Input() activities: Array<IActivity> = [];
  
  constructor() { }

  ngOnInit() {}
}
