import { Injectable } from '@angular/core';
import { EndPoint } from '../enums/endpoints';
import { ApiService } from './api.service';
import { config } from 'src/config';
import { Observable, timer } from 'rxjs';
import { MenuController, Platform } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Utils } from '../common/Util';
import { Constants } from '../common/Constants';

@Injectable({
  providedIn: 'root'
})
export class AssetService {
  merchantLocations: any;
  merchantAsset$: Observable<any>;

  constructor(private apiService: ApiService, private statusBar : StatusBar, private platForm: Platform, private menuCtrl: MenuController) { }

  async getMerchantAssets() : Promise<any> {

    return await new Promise<any>((resolve, reject) => {

      let merchantAssetsDataKey = config.MERCHANT_GUID + '_' + 'merchant_assets';
      let merchant_asset_list = localStorage.getItem(merchantAssetsDataKey);
      let merchant_asset_list_time = localStorage.getItem(Constants.LAST_FETCHED_MERCHANT_ASSET_DATA_KEY);

      if(merchant_asset_list && merchant_asset_list_time) {
        let startDate = new Date(merchant_asset_list_time);
        let endDate = new Date();
        let merchant_asset_list_Data = JSON.parse(merchant_asset_list);

        let timeDiff = this.getMinutesBetweenDates(startDate, endDate);

        if (timeDiff > 15) {
          this.apiService.getData(EndPoint.MERCHANT_ASSETS).subscribe((res: any) => {
            if (res) {
              let lastFetchedData = new Date();              
              localStorage.setItem(merchantAssetsDataKey, JSON.stringify(res));
              localStorage.setItem(Constants.LAST_FETCHED_MERCHANT_ASSET_DATA_KEY, lastFetchedData.toString());
              this.merchantAsset$ = res;
              this.setValues(res);
              resolve(this.merchantAsset$);
            }
          });              
        }
        else {
          this.merchantAsset$ = merchant_asset_list_Data;
          this.setValues(merchant_asset_list_Data);
          resolve(this.merchantAsset$);
        }
      }
      else {
        this.apiService.getData(EndPoint.MERCHANT_ASSETS).subscribe(async (result: any) => {
          if(result && !result.IsError) 
          {      
            let lastFetchedData = new Date();              
            localStorage.setItem(merchantAssetsDataKey, JSON.stringify(result));
            localStorage.setItem(Constants.LAST_FETCHED_MERCHANT_ASSET_DATA_KEY, lastFetchedData.toString());
            this.merchantAsset$ = result;
            this.setValues(result);
            resolve(result);      
          }
          else if(result && result.IsError)  
          {         
            reject(result.Error)  
          }
        },
        (err) => {
          reject(err)   
        });
            
      }
    });
    

  }

  setValues(result: any) {
    localStorage.removeItem('tabs');
    this.merchantId = result.MerchantId;     
    this.merchantGuid = result.MerchantGuid;     
    this.logoUrl = result.LogoUrl;
    this.appIconUrl = result.AppIconUrl;
    this.logoDarkUrl = result.LogoDarkUrl;
    this.translationJSON = result.TranslationJSON;
    this.currencyCode = result.CurrencyCode; 
    this.currencySymbol = result.CurrencySymbol; 
    this.tabs = result.Tabs,
    this.mobileOrderingEnabled = result.IsMobileOrderingEnabled;
    this.companyEmail = result.Email;
    this.companyWebsite = result.CompanyWebsite;
    this.companyName = result.CompanyName;
    this.loginBgUrl = result.LoginBgUrl;
    if (result.DefaultPromoUrl != null) {
      this.defaultPromoUrl = result.DefaultPromoUrl;
    }
    this.isDarkModeDefault = result.IsDarkModeDefault;

    this.qRCodeIconUrl = result.QRCodeIconUrl;
    this.pointsIconUrl = result.PointsIconUrl;
    this.walletIconUrl = result.WalletIconUrl;
    this.pointsEarnedIconUrl = result.PointsEarnedIconUrl;
    this.referFriendIconUrl = result.ReferFriendIconUrl;
    this.leaveUsFeedbackIconUrl = result.LeaveUsFeedbackIconUrl;
    this.birthdayIconUrl = result.BirthdayIconUrl;
    this.spendIconUrl = result.SpendIconUrl;
    this.mapPinIconUrl = result.MapPinIconUrl;
    this.facebookIconUrl = result.FacebookIconUrl;
    this.yelpIconUrl = result.YelpIconUrl;
    this.instagramIconUrl = result.InstagramIconUrl;
    this.twitterIconUrl = result.TwitterIconUrl;
    this.homeTabIconUrl = result.HomeTabIconUrl;
    this.rewardTabIconUrl = result.RewardTabIconUrl;
    this.orderTabIconUrl = result.OrderTabIconUrl;
    this.earnTabIconUrl = result.EarnTabIconUrl;
    this.storeTabIconUrl = result.StoreTabIconUrl;
    this.notificationIconUrl = result.NotificationIconUrl;
    this.customTabIconUrl = result.CustomTabIconUrl;
    this.mobileAppAllowRegistrtaionByEmail = result.MobileAppAllowRegistrtaionByEmail;
    this.mobileAppRewardsRedeemMessage = result.MobileAppRewardsRedeemMessage;
    this.mobileAppWalletHomeMessage = result.MobileAppWalletHomeMessage;
    this.replyTo = result.ReplyTo;
    this.walletEnabled = result.WalletEnabled;
    this.membershipsEnabled = result.IsMembershipsEnabled;
    this.hasMobileFriendlyMemberships = result.HasMobileFriendlyMemberships;
    this.onboardingSlide_1 = result.OnboardingSlide_1;
    this.onboardingSlide_2 = result.OnboardingSlide_2;
    this.onboardingSlide_3 = result.OnboardingSlide_3;
    this.onboardingSlide_4 = result.OnboardingSlide_4;
    this.onboardingSlide_5 = result.OnboardingSlide_5;
    this.onboardingSlide_6 = result.OnboardingSlide_6;
    this.onboardingSlide_7 = result.OnboardingSlide_7;
    this.onboardingSlide_8 = result.OnboardingSlide_8;
    this.phoneMinLength = result.PhoneMinLength;
    this.phoneMaxLength = result.PhoneMaxLength;
    this.isHighRiskMerchant = result.IsHighRiskMerchant;
    this.restrictedCountry = result.RestrictedCountry;
    this.restrictedState = result.RestrictedState;
    this.customDefaultPromotionImageURL = result.CustomDefaultPromotionImageURL;
    //this.isApplePayAvailable = result.IsApplePayEnabled;
 
    this.mapBoundry = result.MapBoundry;

   //this.setDefaultColors();     
  }

  async checkIfAppNeedsUpdate(appInfo: any): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      this.apiService.postData(EndPoint.CHECK_APP_VERSION, appInfo).subscribe((result: any) => {
        if (result) {         
          resolve(result);
        }
        else if(result && result.IsError)  
        {         
            reject(result.Error)  
        }
      }, 
      (err) => {
        reject(err)   
      });              
    });
  }

  /* Getters */
  get merchantId(): number { return Utils.localStorageValueAsNumber('merchantId') }
  get merchantGuid() { return  localStorage.getItem('merchantGuid') ? localStorage.getItem('merchantGuid') : null }
  get logoUrl() { return  localStorage.getItem('logoUrl') ? localStorage.getItem('logoUrl') : null }
  get appIconUrl() { return  localStorage.getItem('appIconUrl') ? localStorage.getItem('appIconUrl') : null }
  
  get appIconUrlOrDefault(): string {
    let appIconUrl: string = localStorage.getItem('appIconUrl');
    return Utils.isNullOrUndefinedOrWhitespace(appIconUrl) ? '../../../assets/custom-icons/brand-icon.png' : appIconUrl;
  }

  get logoDarkUrl() { return  localStorage.getItem('logoDarkUrl') ? localStorage.getItem('logoDarkUrl') : null }
  get loginBgUrl() { return  localStorage.getItem('loginBgUrl') ? localStorage.getItem('loginBgUrl') : null }
  get defaultPromoUrl() { return  localStorage.getItem('defaultPromoUrl') ? localStorage.getItem('defaultPromoUrl') : null }
  get primaryColor() { return  localStorage.getItem('primaryColor') ? localStorage.getItem('primaryColor') : null }
  get translationJSON() { return  localStorage.getItem('translationJSON') ? localStorage.getItem('translationJSON') : null }
  get currencyCode() { return  localStorage.getItem('currencyCode') ? localStorage.getItem('currencyCode') : null }
  get currencySymbol() { return  localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') : null }
  get tabs() { return  localStorage.getItem('tabs') ? localStorage.getItem('tabs') : null }
  get progressbarBackground() { return  localStorage.getItem('progressbarBackgroundColor') ? localStorage.getItem('progressbarBackgroundColor') : null }
  get customerHomeBackground() { return  localStorage.getItem('customerHomeBackground') ? localStorage.getItem('customerHomeBackground') : null }
  get mobileOrderingEnabled() { return  localStorage.getItem('mobile_ordering') ? localStorage.getItem('mobile_ordering') : null }
  get companyWebsite() { return  localStorage.getItem('company_website') ? localStorage.getItem('company_website') : null }
  get companyEmail() { return  localStorage.getItem('company_email') ? localStorage.getItem('company_email') : null }
  get companyName() { return  localStorage.getItem('company_name') ? localStorage.getItem('company_name') : null }
  get isDarkModeDefault() { return  localStorage.getItem('isDarkModeDefault') ? localStorage.getItem('isDarkModeDefault') : null }
  get qRCodeIconUrl() { return  localStorage.getItem('qRCodeIconUrl') ? localStorage.getItem('qRCodeIconUrl') : null }
  get pointsIconUrl() { return  localStorage.getItem('pointsIconUrl') ? localStorage.getItem('pointsIconUrl') : null }
  get walletIconUrl() { return  localStorage.getItem('walletIconUrl') ? localStorage.getItem('walletIconUrl') : null }
  get pointsEarnedIconUrl() { return  localStorage.getItem('pointsEarnedIconUrl') ? localStorage.getItem('pointsEarnedIconUrl') : null }
  get referFriendIconUrl() { return  localStorage.getItem('referFriendIconUrl') ? localStorage.getItem('referFriendIconUrl') : null }
  get leaveUsFeedbackIconUrl() { return  localStorage.getItem('leaveUsFeedbackIconUrl') ? localStorage.getItem('leaveUsFeedbackIconUrl') : null }
  get birthdayIconUrl() { return  localStorage.getItem('birthdayIconUrl') ? localStorage.getItem('birthdayIconUrl') : null }
  get spendIconUrl() { return  localStorage.getItem('spendIconUrl') ? localStorage.getItem('spendIconUrl') : null }
  get mapPinIconUrl() { return  localStorage.getItem('mapPinIconUrl') ? localStorage.getItem('mapPinIconUrl') : null }
  get facebookIconUrl() { return  localStorage.getItem('facebookIconUrl') ? localStorage.getItem('facebookIconUrl') : null }
  get yelpIconUrl() { return  localStorage.getItem('yelpIconUrl') ? localStorage.getItem('yelpIconUrl') : null }
  get instagramIconUrl() { return  localStorage.getItem('instagramIconUrl') ? localStorage.getItem('instagramIconUrl') : null }
  get twitterIconUrl() { return  localStorage.getItem('twitterIconUrl') ? localStorage.getItem('twitterIconUrl') : null }
  get homeTabIconUrl() { return  localStorage.getItem('homeTabIconUrl') ? localStorage.getItem('homeTabIconUrl') : null }
  get rewardTabIconUrl() { return  localStorage.getItem('rewardTabIconUrl') ? localStorage.getItem('rewardTabIconUrl') : null }
  get orderTabIconUrl() { return  localStorage.getItem('orderTabIconUrl') ? localStorage.getItem('orderTabIconUrl') : null }
  get earnTabIconUrl() { return  localStorage.getItem('earnTabIconUrl') ? localStorage.getItem('earnTabIconUrl') : null }
  get customTabIconUrl() { return  localStorage.getItem('customTabIconUrl') ? localStorage.getItem('customTabIconUrl') : null }
  get storeTabIconUrl() { return  localStorage.getItem('storeTabIconUrl') ? localStorage.getItem('storeTabIconUrl') : null }
  get notificationIconUrl() { return  localStorage.getItem('notificationIconUrl') ? localStorage.getItem('notificationIconUrl') : null }
  get preferredDarkModeSelected() { 
    if (this.isNullUndefinedOrEmpty('slected-app-theme')) 
    { 
      return false; 
    } 
    else 
    {
      return this.convertStringToBoolean('slected-app-theme'); 
    }
  }
  get mobileAppAllowRegistrtaionByEmail() { 
    if (this.isNullUndefinedOrEmpty('mobileAppAllowRegistrtaionByEmail')) 
    { 
      return false; 
    } 
    else 
    {
      return this.convertStringToBoolean('mobileAppAllowRegistrtaionByEmail'); 
    }
  }
  get mobileAppRewardsRedeemMessage()  { return  localStorage.getItem('mobileAppRewardsRedeemMessage') ? localStorage.getItem('mobileAppRewardsRedeemMessage') : null }
  get mobileAppWalletHomeMessage()  { return  localStorage.getItem('mobileAppWalletHomeMessage') ? localStorage.getItem('mobileAppWalletHomeMessage') : null }
  get replyTo() { return  localStorage.getItem('replyTo') ? localStorage.getItem('replyTo') : null }
  get walletEnabled() { return  localStorage.getItem('walletEnabled') ? localStorage.getItem('walletEnabled') : null }
  get membershipsEnabled() { return  localStorage.getItem('membershipsEnabled') ? localStorage.getItem('membershipsEnabled') : null }
  get hasMobileFriendlyMemberships() { return localStorage.getItem('hasMobileFriendlyMemberships') ? localStorage.getItem('hasMobileFriendlyMemberships') : null}
  get onboardingSlide_1() { return  localStorage.getItem('onboardingSlide_1') ? localStorage.getItem('onboardingSlide_1') : null }
  get onboardingSlide_2() { return  localStorage.getItem('onboardingSlide_2') ? localStorage.getItem('onboardingSlide_2') : null }
  get onboardingSlide_3() { return  localStorage.getItem('onboardingSlide_3') ? localStorage.getItem('onboardingSlide_3') : null }
  get onboardingSlide_4() { return  localStorage.getItem('onboardingSlide_4') ? localStorage.getItem('onboardingSlide_4') : null }
  get onboardingSlide_5() { return  localStorage.getItem('onboardingSlide_5') ? localStorage.getItem('onboardingSlide_5') : null }
  get onboardingSlide_6() { return  localStorage.getItem('onboardingSlide_6') ? localStorage.getItem('onboardingSlide_6') : null }
  get onboardingSlide_7() { return  localStorage.getItem('onboardingSlide_7') ? localStorage.getItem('onboardingSlide_7') : null }
  get onboardingSlide_8() { return  localStorage.getItem('onboardingSlide_8') ? localStorage.getItem('onboardingSlide_8') : null }
  get phoneMaxLength() { return  localStorage.getItem('phoneMaxLength') ? localStorage.getItem('phoneMaxLength') : null }
  get phoneMinLength() { return  localStorage.getItem('phoneMinLength') ? localStorage.getItem('phoneMinLength') : null }
  get isHighRiskMerchant() { return localStorage.getItem('isHighRiskMerchant') ? localStorage.getItem('isHighRiskMerchant') : null}
  get restrictedCountry() { return localStorage.getItem('restrictedCountry') ? localStorage.getItem('restrictedCountry') : null}
  get restrictedState() { return localStorage.getItem('restrictedState') ? localStorage.getItem('restrictedState') : null}
  get customDefaultPromotionImageURL() { return localStorage.getItem(Constants.CUSTOM_DEFAULT_PROMOTION_IMAGE_URL_KEY) ? localStorage.getItem(Constants.CUSTOM_DEFAULT_PROMOTION_IMAGE_URL_KEY) : null}
  //get isApplePayAvailable() { return localStorage.getItem('isApplePayAvailable') ? localStorage.getItem('isApplePayAvailable') : null}
  get mapBoundry() { return localStorage.getItem('mapBoundry') ? localStorage.getItem('mapBoundry') : null}
  
  /* Setters */
  set merchantId(id: number) { localStorage.setItem('merchantId', id.toString())}
  set merchantGuid(guid: string) { localStorage.setItem('merchantGuid', guid)}
  set logoUrl(url: string) { localStorage.setItem('logoUrl', url)}
  set appIconUrl(url: string) { localStorage.setItem('appIconUrl', url)}  
  set logoDarkUrl(url: string) { localStorage.setItem('logoDarkUrl', url)}
  set loginBgUrl(url: string) { localStorage.setItem('loginBgUrl', url)}
  set defaultPromoUrl(url: string) { localStorage.setItem('defaultPromoUrl', url)}
  set primaryColor(color: string) { localStorage.setItem('primaryColor', color)}
  set translationJSON(translation: string) { localStorage.setItem('translationJSON', translation)}
  set currencyCode(currencyCode: string) { localStorage.setItem('currencyCode', currencyCode)}
  set currencySymbol(currencySymbol: string) { localStorage.setItem('currencySymbol', currencySymbol)} 
  set progressbarBackground(prBgColor: string) { localStorage.setItem('progressbarBackgroundColor', prBgColor)}
  set customerHomeBackground(customBgcolor: string) { localStorage.setItem('customerHomeBackground', customBgcolor)}
  set mobileOrderingEnabled(mobile_ordering: string) { localStorage.setItem('mobile_ordering', mobile_ordering ? mobile_ordering.toString() : "")}
  set companyWebsite(company_website: string) { localStorage.setItem('company_website', company_website ? company_website.toString() : "")}
  set companyEmail(company_email: string) { localStorage.setItem('company_email', company_email ? company_email.toString() : "")}
  set companyName(company_name: string) { localStorage.setItem('company_name', company_name ? company_name.toString() : "")}
  set isDarkModeDefault(isDarkMode_Default: string) { localStorage.setItem('isDarkModeDefault', isDarkMode_Default ? isDarkMode_Default.toString() : "")}
  set preferredDarkModeSelected(key: boolean) { localStorage.setItem('slected-app-theme', key.toString())}
  set qRCodeIconUrl(url: string) { localStorage.setItem('qRCodeIconUrl', url)}
  set pointsIconUrl(url: string) { localStorage.setItem('pointsIconUrl', url)}
  set walletIconUrl(url: string) { localStorage.setItem('walletIconUrl', url)}
  set pointsEarnedIconUrl(url: string) { localStorage.setItem('pointsEarnedIconUrl', url)}
  set referFriendIconUrl(url: string) { localStorage.setItem('referFriendIconUrl', url)}
  set leaveUsFeedbackIconUrl(url: string) { localStorage.setItem('leaveUsFeedbackIconUrl', url)}
  set birthdayIconUrl(url: string) { localStorage.setItem('birthdayIconUrl', url)}
  set spendIconUrl(url: string) { localStorage.setItem('spendIconUrl', url)}
  set mapPinIconUrl(url: string) { localStorage.setItem('mapPinIconUrl', url)}
  set facebookIconUrl(url: string) { localStorage.setItem('facebookIconUrl', url)}
  set yelpIconUrl(url: string) { localStorage.setItem('yelpIconUrl', url)}
  set instagramIconUrl(url: string) { localStorage.setItem('instagramIconUrl', url)}
  set twitterIconUrl(url: string) { localStorage.setItem('twitterIconUrl', url)}
  set homeTabIconUrl(url: string) { localStorage.setItem('homeTabIconUrl', url)}
  set rewardTabIconUrl(url: string) { localStorage.setItem('rewardTabIconUrl', url)}
  set orderTabIconUrl(url: string) { localStorage.setItem('orderTabIconUrl', url)}
  set earnTabIconUrl(url: string) { localStorage.setItem('earnTabIconUrl', url)}
  set customTabIconUrl(url: string) { localStorage.setItem('customTabIconUrl', url)}
  set storeTabIconUrl(url: string) { localStorage.setItem('storeTabIconUrl', url)}
  set notificationIconUrl(url: string) { localStorage.setItem('notificationIconUrl', url)}
  set tabs(tabsData: any) { 
    if (tabsData) {
      localStorage.setItem('tabs', JSON.stringify(tabsData))
    }    
  }
  set mobileAppAllowRegistrtaionByEmail(isAllowedToRegisterbyemail: boolean) { localStorage.setItem('mobileAppAllowRegistrtaionByEmail', isAllowedToRegisterbyemail.toString())}
  set mobileAppRewardsRedeemMessage(message: string)  { localStorage.setItem('mobileAppRewardsRedeemMessage', message)}
  set mobileAppWalletHomeMessage(message: string)  { localStorage.setItem('mobileAppWalletHomeMessage', message)}
  set replyTo(email: string)  { localStorage.setItem('replyTo', email)}
  set walletEnabled(enabled: string) { localStorage.setItem('walletEnabled', enabled ? enabled.toString() : "")}
  set membershipsEnabled(enabled: string) { localStorage.setItem('membershipsEnabled', enabled ? enabled.toString() : "")}
  set hasMobileFriendlyMemberships(value: string) {localStorage.setItem('hasMobileFriendlyMemberships', value ? value.toString() : "")}

  set onboardingSlide_1(url: string) { localStorage.setItem('onboardingSlide_1', url)}
  set onboardingSlide_2(url: string) { localStorage.setItem('onboardingSlide_2', url)}
  set onboardingSlide_3(url: string) { localStorage.setItem('onboardingSlide_3', url)}
  set onboardingSlide_4(url: string) { localStorage.setItem('onboardingSlide_4', url)}
  set onboardingSlide_5(url: string) { localStorage.setItem('onboardingSlide_5', url)}
  set onboardingSlide_6(url: string) { localStorage.setItem('onboardingSlide_6', url)}
  set onboardingSlide_7(url: string) { localStorage.setItem('onboardingSlide_7', url)}
  set onboardingSlide_8(url: string) { localStorage.setItem('onboardingSlide_8', url)}

  set phoneMaxLength(length: string) { localStorage.setItem('phoneMaxLength', length)}
  set phoneMinLength(length: string) { localStorage.setItem('phoneMinLength', length)}

  set isHighRiskMerchant(value: string) {localStorage.setItem('isHighRiskMerchant', value.toString())}
  set restrictedCountry(value: string) {localStorage.setItem('restrictedCountry', value ? value.toString() : "")}
  set restrictedState(value: string) {localStorage.setItem('restrictedState', value ? value.toString() : "")}
  set customDefaultPromotionImageURL(value: string) {localStorage.setItem(Constants.CUSTOM_DEFAULT_PROMOTION_IMAGE_URL_KEY, value ? value.toString() : "")}
 // set isApplePayAvailable(value: string) {localStorage.setItem('isApplePayAvailable', value ? value.toString() : "")}
  set mapBoundry(value: string) {localStorage.setItem('mapBoundry', value ? value.toString() : "")}
  
  setDefaultColors() {
    
    let _primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary');
    if (_primaryColor == "" || _primaryColor == undefined) {
      _primaryColor = null;
    }
    let _progressbarBackgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--ion-color-progressbar');
    if (_progressbarBackgroundColor == "" || _progressbarBackgroundColor == undefined) {
      _progressbarBackgroundColor = null;
    }
    let _customerHomeBackground = getComputedStyle(document.documentElement).getPropertyValue('--ion-color-background');

    if (_customerHomeBackground == "" || _customerHomeBackground == undefined) {
      _customerHomeBackground = null;
    }    

    let html = document.querySelector('html');

    if (config.COLORS.primary) {
      this.primaryColor = config.COLORS.primary;
    }
    else {
      this.primaryColor = _primaryColor;
    }

    if (config.COLORS.progressbarBackground) {
      this.progressbarBackground = config.COLORS.progressbarBackground;
    }
    else {
      this.progressbarBackground = _progressbarBackgroundColor;
    }

    if (config.COLORS.customerHomeBackground) {
      this.customerHomeBackground = config.COLORS.customerHomeBackground;
    }
    else {
      this.customerHomeBackground = _customerHomeBackground;
    }


    console.log(`Primary Color => ${this.primaryColor}`);
    console.log(`Progress BG Color => ${this.progressbarBackground}`);
    console.log(`Home BG Color => ${this.customerHomeBackground}`);

    if (this.platForm.is('cordova')) {
      if (this.platForm.is('ios')) {
        this.statusBar.backgroundColorByHexString(this.primaryColor);
      }
    }    

    html.style.setProperty('--ion-color-primary', this.primaryColor); 
    html.style.setProperty('--ion-color-progressbar', this.progressbarBackground);
    html.style.setProperty('--customer-home-background', this.customerHomeBackground);  

    let body = document.querySelector('body');
    body.style.setProperty('--ion-color-primary', this.primaryColor);   
  }


  clearAll() {
    localStorage.removeItem('merchantId');
    localStorage.removeItem('merchantGuid');
    localStorage.removeItem('logoUrl');
    localStorage.removeItem('primaryColor');
    localStorage.removeItem('progressbarBackgroundColor');
    localStorage.removeItem('tabs');
    localStorage.removeItem('customerHomeBackground');
    localStorage.removeItem('currencyCode');
    localStorage.removeItem('currencySymbol');
    localStorage.removeItem('company_email');
    localStorage.removeItem('company_name');
    localStorage.removeItem('company_website');
    localStorage.removeItem('mobile_ordering');
    localStorage.removeItem('translationJSON');    
    localStorage.removeItem('mobileAppAllowRegistrtaionByEmail');   
    localStorage.removeItem('phoneMaxLength');
    localStorage.removeItem('phoneMinLength');
    localStorage.removeItem(Constants.CUSTOM_DEFAULT_PROMOTION_IMAGE_URL_KEY);

  }

  getMinutesBetweenDates(startDate, endDate) {
    var diff = endDate.getTime() - startDate.getTime();
    return (diff / 60000);
  }

  isNullUndefinedOrEmpty(key: string) : boolean {
     
    if(localStorage.getItem(key) == null || localStorage.getItem(key) == undefined || localStorage.getItem(key) == '') {
      return true;
    } 
    return false;
  }

  convertStringToBoolean(key: string) 
  { 
    if (localStorage.getItem(key) == 'true') 
    { 
      return true; 
    } 
    return false 
  }

  async menu() {
    await this.menuCtrl.open();     
  }
}
