import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { ModalController, Platform } from '@ionic/angular';
import { Subscription, timer } from 'rxjs';
import { NetworkErrorComponent } from '../components/network-error/network-error.component';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  public disconnect: boolean = false;
  disconnectSubscription: Subscription;
  connectSubscription: Subscription;

  constructor(private network:Network, public modalCtrl: ModalController, private util: UtilService, private platForm: Platform) { }

  startWatchingDisconnection() {
    this.disconnectSubscription  = this.network.onDisconnect().subscribe(() => {
      console.log('network was disconnected :-(');     
      this.util.closeAllModals();
      timer(500).subscribe(r => {
        this.loadModal();
      });      
    });
  }

  startWatchingForConnection() {
    this.connectSubscription = this.network.onConnect().subscribe(async () => {
      console.log('network connected!');
      console.log('checking app version!');    

      setTimeout(async () => {
        try {
          if (this.network.type === 'wifi') {
            console.log('we got a wifi connection, woohoo!');
          }
          await this.util.closeAllModals();
                
        } catch (error) {
          console.log('Handled modal dismiss error');
        }
        
      }, 3000);
      
    });
    
  }

  stopWatching() {
    this.connectSubscription.unsubscribe();
  }

  async loadModal() {    

    await this.util.closeAllModals();

    const modal = await this.modalCtrl.create({
      component: NetworkErrorComponent,
      animated: true,
      mode: 'ios',
      cssClass: 'tm-custom-network-modal-css',
      backdropDismiss: true,
      componentProps: {}
    });

    this.util.storeModal(modal);

    return await modal.present(); 
   
  }


  

}
