import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IPunchCardsUIModel } from 'src/app/models/punchCard';

@Component({
  selector: 'app-your-punch-cards-tab',
  templateUrl: './your-punch-cards-tab.component.html',
  styleUrls: ['./your-punch-cards-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YourPunchCardsTabComponent implements OnInit {

  @Input() punchCardsUIModel: IPunchCardsUIModel = null;

  constructor() { }

  ngOnInit() {}

}
