import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { ToastService } from 'src/app/services/toast.service';
import { Constants } from 'src/app/common/Constants';
import { Utils } from 'src/app/common/Util';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-preferred-location-list',
  templateUrl: './preferred-location-list.component.html',
  styleUrls: ['./preferred-location-list.component.scss'],
})
export class PreferredLocationListComponent implements OnInit, AfterViewInit {
  @Input() onLocationSelectedFunc: Function;
  @Input() distanceUnit: string;
  @Input() locations: any = [];
  @Input() selectedLocation: any;
  @Input() restriction;


  data: any = [];
  err: any;
  lat: number;
  lng: number;
  locationPermissionDenied: boolean = false;  

  // Default Map restricted to USA
  styles = Constants.DEFAULT_MAP_STYLE;
  zoom: number = Constants.DEFAULT_MAP_ZOOM;
  previousIW;

  constructor(public modalCtrl: ModalController,  private geolocation: Geolocation, private toastService: ToastService, private locationService: LocationService) 
  { 
    this.locationService.populateStores();
	  if (!this.restriction) {
      console.log('MAP RESTRICTED TO');
      this.restriction = this.locationService.restriction;
      console.log(this.restriction);
	  }
	  console.log(this.selectedLocation);
      this.geolocation
      .getCurrentPosition()
      .then((resp) => {
		console.log("GEOLOCATION RESPONSE");
		console.log(resp)
        this.locations.forEach(async (element) =>  {
           this.lat = resp.coords.latitude; 
           this.lng = resp.coords.longitude;
           (<any>element).distance = Utils.distance(this.lat, this.lng,element.Lat, element.Lng, this.distanceUnit).toFixed(2);	
           if (this.locationService && this.locationService.currentCenter && this.locationService.currentCenter.lat && this.locationService.currentCenter.lng) {      
            this.lat = this.locationService.currentCenter.lat;
            this.lng = this.locationService.currentCenter.lng;
          }          	  
        });
        console.log(this.locations);
		this.locationPermissionDenied = false;
      })
      .catch((error) => {
		this.locationPermissionDenied = true;
		this.locations.forEach(async (element) =>  {			        
			(<any>element).distance = this.distance(this.lat, this.lng,element.Lat, element.Lng, this.distanceUnit).toFixed(2);
			(<any>element).imageUrl = null;
		});
		this.toastService.show('We cannot determine your current location. Please enable loation permission for smoother experience.', 4000);
        console.log("Error getting location", error);
    });    
    
  }

  ngOnInit() {}

  ngAfterViewInit() {
    
  }

  dismiss(){
    this.modalCtrl.dismiss();
  }
  
  distance(lat1, lon1, lat2, lon2, unit) {
    if (lat1 == lat2 && lon1 == lon2) {
      return 0;
    } else {
      let radlat1 = (Math.PI * lat1) / 180;
      let radlat2 = (Math.PI * lat2) / 180;
      let theta = lon1 - lon2;
      let radtheta = (Math.PI * theta) / 180;
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") {
        dist = dist * 1.609344;
      }
      if (unit == "N") {
        dist = dist * 0.8684;
      }
      return dist;
    }
  }

  async clickedMarker(label: string, index: number, infowindow: any, id: number) {
    if (this.previousIW) {
		await this.previousIW.close();
	}
	this.previousIW = infowindow;
    console.log(`clicked the marker: ${label || index}`);
	console.log(`Id: ${id}`);
	var myElement = document.getElementById(`location_id_${id}`);
	var topPos = myElement.offsetLeft;
	document.getElementById('tm_locations_section').scrollLeft = topPos;

  }

  selectPreferredLocation(item) {
	this.selectedLocation = item;
	console.log(this.selectedLocation);
  }

}
