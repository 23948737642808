import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';
import { ToastService } from './toast.service';
import { Platform } from '@ionic/angular';
import { config } from 'src/config';
import { ApiService } from './api.service';
import { Storage } from "@ionic/storage";
import { EndPoint } from '../enums/endpoints';
import { Router } from '@angular/router';
declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private _pushEnabled: boolean = null;
  private _pushRegistrationId: string = null;
  newMessage$ = new BehaviorSubject<any>(undefined);

  constructor(private push: Push,
    private platform: Platform, 
    public storage: Storage, 
    private router: Router,
    private apiService: ApiService
  ) 
  {
    
  }

  /* Getters */
  get pushEnabled():boolean{    
    return this._pushEnabled;
  }
  
  set pushEnabled(isEnabled:boolean){
      this._pushEnabled = isEnabled;
      this.storage.set("pushEnabled", isEnabled);
  }

  get pushRegistrationId():string{
      return this._pushRegistrationId;
  }
  
  set pushRegistrationId(regId:string){
      this._pushRegistrationId = regId;
      this.storage.set("pushRegistrationId", regId);
  }

  clear(){
    this._pushEnabled = null;
    this._pushRegistrationId = null;
  }

  registerForNotifications() {
    if (this.platform.is("cordova") == false || this.pushEnabled == false) return;
    const options: PushOptions = {
                      android: {
                        sound: true,
                        vibrate: true,
                        icon: "notify",
                        iconColor: config.COLORS.androidPushNotificationColor || "red"
                      },
                      ios: {
                          alert: 'true',
                          badge: true,
                          sound: 'false'
                      },
                      windows: {},
                      browser: {
                          pushServiceURL: 'http://push.api.phonegap.com/v1/push'
                      }
    };

    const pushObject: PushObject = this.push.init(options);

    pushObject.on('notification').subscribe((notification: any) => {
        console.log('Received a notification', notification);
        this.newMessage$.next(notification);
        
        if (notification.additionalData.foreground == false) {
          this.router.navigate(['/notifications']);
        }        
    });

    pushObject.on('registration').subscribe(async (registration: any) => {
      console.log('Device registered for notifications', registration);  
      if(registration && registration.registrationId) {        
        if (this.pushRegistrationId != registration.registrationId) {
          this.pushRegistrationId = registration.registrationId;  
          const pushData = { 
            RegId: this.pushRegistrationId, 
            Platform: this.determinePlatform(), 
            EnablePushNotifications: this.pushEnabled
          }  
               
          if (pushData.Platform == "ios") {
            this.pushEnabled = true;
            pushData.EnablePushNotifications = this.pushEnabled;
          }

          if (pushData.Platform == "android") {
            if (registration.androidAPILevel && registration.androidAPILevel == ">=33") {
              if (registration.onPushAccepted && registration.onPushAccepted == "PERMISSION_GRANTED") {
                pushData.EnablePushNotifications = true;              
              }              
            }            
            else {
              pushData.EnablePushNotifications = await this.deviceHasPermission()
            } 
          }

          this.pushEnabled = pushData.EnablePushNotifications;
          const pushPreferenceResult =  new Promise<any>((resolve, reject) => {
            this.apiService.postData(EndPoint.USER_NOTIFICATION_PREFERENCE, pushData)
            .subscribe((result) => {
              resolve(result)
            }, (error) => {
              reject(error)
            });
          }); 
          console.log(pushPreferenceResult);    
        }                                 
      } 
    });

    pushObject.on('error').subscribe(error => console.error('Error with Push plugin', error));
  
  
  }

  updateNotificationData(checkLocalLevel: boolean = true) {
    //check local os level then update notification
    let osNotificationPermission = true;
    
    this.push.hasPermission().then(data => {
      console.log('osNotificationPush: ' + data.isEnabled);
      osNotificationPermission = data.isEnabled;      

      const pushData = { 
        RegId: this.pushRegistrationId, 
        Platform: this.determinePlatform(), 
        EnablePushNotifications: osNotificationPermission && checkLocalLevel
      }

      this.pushEnabled = pushData.EnablePushNotifications;

      const pushPreferenceResult =  new Promise<any>((resolve, reject) => {
        this.apiService.postData(EndPoint.USER_NOTIFICATION_PREFERENCE, pushData)
        .subscribe((result) => {
          resolve(result)
        }, (error) => {
          reject(error)
        });
      }); 
      console.log(pushPreferenceResult);

    });    
  }

  async deviceHasPermission() {
    let hasPermission : boolean = true;

    if(this.platform.is("cordova")){     
      hasPermission = (await this.push.hasPermission()).isEnabled;      
    }
    else {
      hasPermission = false;
    }
    if (!hasPermission) {
      this.clear();
    }
    return hasPermission;
  }

  public determinePlatform() {
    if (this.platform.is("ios"))
      return "ios";
    if (this.platform.is("android"))
      return "android";
    if (this.platform.is("desktop"))
      return "android";
  }

  async getPushEnabledFromStorage() {
    var isEnabled = false;
    await this.storage.get("pushEnabled").then((result) => {
      console.log(`STORAGE VALUE FOR PUSH ENABLED : ${result}`);
      isEnabled = result;
    });
    return isEnabled;
  }

  async setPushPopUpPreference(val) {
    let result = await this.storage.set("ASK_FOR_PUSH", val);
    return result;
  }

  async getPushPopUpPreference() {
    return await this.storage.get("ASK_FOR_PUSH");
  }

  async removePushPopUpPreference() {
    await this.storage.remove("ASK_FOR_PUSH");
  }
  async setPushPopUpPreferenceOnResume(val) {
    let result = await this.storage.set("ASK_FOR_PUSH_RESUME", val);
    return result;
  }

  async getPushPopUpPreferenceOnResume() {
    return await this.storage.get("ASK_FOR_PUSH_RESUME");
  }

  async checkAndRegister() {
   await this.push.hasPermission().then(data => {    
      if (data.isEnabled) {        
        this.registerForNotifications();
      }
    });    
  }

}