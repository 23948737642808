import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EndPoint } from 'src/app/enums/endpoints';
import { ApiService } from 'src/app/services/api.service';
import { UtilService } from 'src/app/services/util.service';


@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
})
export class QrCodeComponent implements OnInit {
  
  @Input() tagId: string;
  @Input() _util: UtilService;

  constructor(public modalController: ModalController, private api: ApiService) { }

  ngOnInit() {
    console.log(`QR TAG ID => ${this.tagId}`);
    if (this.tagId == null || this.tagId == undefined) {
      var GenerateTagRequest = { "CustomerId": this._util.customerId }
      this.api.postData(EndPoint.GENERATE_TAG_ID, GenerateTagRequest).subscribe((res : any) => {
          if (res && res.success && res.tagId) {
            this.tagId = res.tagId;
          }
      });
    }
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
