import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {Router} from '@angular/router';
import { DecisionPopupComponent } from '../../decision-popup/decision-popup.component';
import { SuccessPopupComponent } from '../../success-popup/success-popup.component';
import { ReloadAmountComponent } from '../../reload-amount/reload-amount.component';
import { IGetWalletLoadDataResponse, IPaymentCard } from 'src/app/models/response/getWalletLoadDataResponse';
import { ICardInfoRequest } from 'src/app/models/request/deleteCardRequest';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../base/base.component';
import { WalletV4Service } from 'src/app/services/wallet-v4.service';
import { InputPopupComponent } from '../../input-popup/input-popup.component';
import { Utils } from 'src/app/common/Util';
import { ISetCustomerPhoneRequest } from 'src/app/models/request/setCustomerPhoneRequest';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alert.service';
import { SquareCardInfoComponent } from '../../wallet/square-card-info/square-card-info.component';
import { ILoadFundsV4Request } from 'src/app/models/request/loadFundsV4Request';
import { ILoadFundsV4Response } from 'src/app/models/response/loadFundsV4Response';
import { WalletService } from 'src/app/services/wallet.service';
import { ApplePayService } from 'src/app/services/apple-pay.service';
import { ProfileV4Service } from 'src/app/services/profile-v4.service';
import { DecimalPipe } from '@angular/common';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { environment } from 'src/environments/environment';
import { ConfirmOptions, FailOptions, PopupResponse, SuccessOptions, LoadingOptions } from '../../popup-model/popup-model.component';
import { PopupService } from 'src/app/services/popup.service';
import { BehaviorSubject, timer } from 'rxjs';

@Component({
  selector: 'app-load-wallet',
  templateUrl: './load-wallet.component.html',
  styleUrls: ['./load-wallet.component.scss'],
  providers: [SquareCardInfoComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadWalletComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() currencySymbol: string = null;
  @Input() balance?: number = null;
  @Input() walletLoadData: IGetWalletLoadDataResponse = null;

  maxWalletBalanceAllowed: number = null;
  amounts: Array<number> = [];
  amountToLoad?: number = null;
  askForPhoneNumberForWalletOrder: boolean = false;
  card: IPaymentCard = null;
  hasCard: boolean = false;
  isApplePayAvailable: boolean = false;
  paymentSystemProvider: string = null;
  applePayloadData$ = new BehaviorSubject<ILoadFundsV4Request>(null);
  
  constructor(
    private modalCtrl: ModalController,
    private walletV4Service: WalletV4Service,
    private walletService: WalletService,
    private translate: TranslateService,
    private alertService: AlertService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private squareCardInfoComponent: SquareCardInfoComponent,
    private applePay: ApplePayService,
    private profileV4Service: ProfileV4Service,
    private decimalPipe: DecimalPipe,
    private iab: InAppBrowser,
    public popupService: PopupService
  ) {
    super();
  }

  ngOnInit() {}

  async ionViewWillEnter() {
    await this.applePay.checkIfApplepayAvailable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if( !Utils.isNullOrUndefined(changes['walletLoadData'] ) ) {
      if( !Utils.isNullOrUndefined(this.walletLoadData) ) {
        this.paymentSystemProvider = this.walletLoadData.PaymentSystemProvider;
        this.askForPhoneNumberForWalletOrder = this.walletLoadData.AskForPhoneNumberForWalletOrder;
        this.maxWalletBalanceAllowed = this.walletLoadData.MaxWalletBalanceAllowed;
        this.hasCard = !Utils.isNullOrUndefined(this.walletLoadData.CustomerStoredCard) ? this.walletLoadData.CustomerStoredCard.HasCard : false;
        this.isApplePayAvailable = this.walletLoadData.IsApplePayEnabled;          
                  
        this.card = this.hasCard ? this.walletLoadData.CustomerStoredCard.Card : null;

        this.amountToLoad = 0;
        this.amounts = [];
        if (this.walletLoadData.WalletLoadAmounts && this.walletLoadData.WalletLoadAmounts.length > 0) {
          this.amounts = [...this.walletLoadData.WalletLoadAmounts];
          if (this.amounts.length > 1) {
            this.amountToLoad = this.amounts[1];
          }
        }
      }
    }
  }

  get goToCardDeleteSuccessFunc() {
    return this.cardDeleteSuccess.bind(this);
  }

  get goToUpdateViewFunc() {
    return this.updateView.bind(this);
  }
  
  get goToupdateReloadAmountFunc() {
    return this.updateReloadAmount.bind(this);
  }
  
  get goToSavePhoneNumberFunc() {
    return this.savePhoneNumber.bind(this);
  }

  get goToYourCardWillBeChargedFunc() {
    return this.cardWillBeChargedConfirmed.bind(this);
  }

  get goToApplePaydWillBeChargedFunc() {
    return this.applePayWillBeChargedConfirmed.bind(this);
  }
  
  updateView() {
    this.hasCard = false;
    this.modalCtrl.dismiss();    
    this.cd.detectChanges();
  }

  updateReloadAmount(value: number) {
    console.log(`LoadWalletComponent.updateReloadAmount(${value})`);
    this.amountToLoad = value;
    this.modalCtrl.dismiss();
    this.cd.detectChanges();
  }

  savePhoneNumber(phoneNumber: string) {
    console.log(`LoadWalletComponent.savePhoneNumber(${phoneNumber})`);
    if( Utils.isNullOrUndefinedOrWhitespace(phoneNumber)) {
      return;
    }

    this.walletV4Service.setCustomerPhone(<ISetCustomerPhoneRequest>{ Phone: phoneNumber })
    .pipe(takeUntil(this.destroyed$))
    .subscribe(async (response: boolean) => {
      this.askForPhoneNumberForWalletOrder = false;
      this.addToWallet();
    });
  }

  async removePaymentMethod() {
    const modal = await this.modalCtrl.create({
      component: DecisionPopupComponent,
      cssClass: 'tm-decision-modal',
      componentProps: {
        btnYesText: 'Yes, Delete',
        btnNoText: 'Cancel',
        title: 'Are you sure you want to remove this payment method?',
        message: `You can always reconnect it later, we just want to make sure.`,
        onActionProceedFunc: this.goToCardDeleteSuccessFunc
    }});
    modal.present(); 
  }
  
  async displayErrorPopup(title: string, message: string ) {
    const modal = await this.modalCtrl.create({
      component: DecisionPopupComponent,
      cssClass: 'tm-decision-modal',
      componentProps: {
        btnYesText: 'OK',
        title: title,
        message: message,
        onActionProceedFunc: this.goToCardDeleteSuccessFunc
    }});
    modal.present(); 
  }
  
  async cardDeleteSuccess() {
    this.modalCtrl.dismiss();

    this.walletV4Service.deleteCard(<ICardInfoRequest>{CustomerId: null})
    .pipe(takeUntil(this.destroyed$))
    .subscribe(async (response: boolean) => {
      const modal = await this.modalCtrl.create({
        component: SuccessPopupComponent,
        cssClass: 'tm-decision-modal',
        componentProps: {
          btnCloseText: 'Got it, Thanks',         
          title: 'Payment Method Removed',
          message: `Payment method endig with •••• ${this.card.Last4} has been removed from your account.`,
          onActionCloseFunc: this.goToUpdateViewFunc         
      }});
      modal.present();
    });
  }

  async toggleWalletAmountOption() {
    const modal = await this.modalCtrl.create({
      component: ReloadAmountComponent,
      componentProps: {
        onAmountSelectedFunc: this.goToupdateReloadAmountFunc,
        amounts: this.amounts,
        amountToLoad: this.amountToLoad
    }});
    modal.present();
  }


  async cardWillBeChargedConfirmed() {
    this.modalCtrl.dismiss();

    let loadingOptions = {
      processingMessage: 'Processing Payment...'
    } as LoadingOptions;

    // COSMIN let loader = await this.popupService.loading(loadingOptions);

    let squareVerificationToken:string = null;
    squareVerificationToken = await this.getVerificationTokenIfNeeded(this.amountToLoad);
    if (squareVerificationToken == 'failed') {
      this.displayErrorPopup('Payment Failed', 'Card verification failed.');
      return;
    }

    /* Create Payload for V4 End-Point */
    const request: ILoadFundsV4Request = <ILoadFundsV4Request>{
      CardHolderName: this.card.CardHolderName,
      Token: null,
      Amount: this.amountToLoad,
      CardNumber: this.card.Last4,
      CardExpirationMonth: this.card.ExpirationMonth,
      CardExpirationYear: this.card.ExpirationYear,
      CardCVV: '',
      SaveCreditCard: true,
      PayWithSavedCreditCard: true,
      SquareVerificationToken: squareVerificationToken
    };

    this.walletV4Service.loadFundsV4(request)
    .pipe(takeUntil(this.destroyed$))
    .subscribe((response: ILoadFundsV4Response) => this.parseLoadFundsResponse(response));
  }


  async applePayWillBeChargedConfirmed() {
    this.modalCtrl.dismiss();

    let loadingOptions = {
      processingMessage: 'Processing'
    } as LoadingOptions;
    let loader = await this.popupService.loading(loadingOptions);    
    this.walletV4Service.loadFundsV4(this.applePayloadData$.getValue())
    .pipe(takeUntil(this.destroyed$))
    .subscribe(async (response: ILoadFundsV4Response) => {
        timer(1000).subscribe(async x => {
          await loader.dismiss();
          this.parseLoadFundsResponse(response)
        });        
    });    
  }

  private async parseLoadFundsResponse(response: ILoadFundsV4Response) {
    // await loader.dismiss();

    console.log('LoadWalletComponent.parseLoadFundsResponse()');
    if (response.OK == false) {
      let errorMessage = this.translate.instant('Error verifying card. Please try again.') ;

      if (!Utils.isNullOrUndefined(response.ErrorDetails?.ErrorType) ) {
        if( response.ErrorDetails.ErrorType == 11) {
          errorMessage = this.translate.instant('The minimum amount to reload wallet is $')  + Number(response.ErrorDetails.Amount).toFixed(2);
        } else if (response.ErrorDetails.ErrorType == 10) {
          errorMessage = this.translate.instant('Enter lower amount, wallet balance cannot exceed limit of $') + Number(response.ErrorDetails.Amount).toFixed(2);
        }
      }

      await this.displayErrorPopup('Payment Failed', errorMessage);

      console.log('LoadWalletComponent.parseLoadFundsResponse()');
      console.error(response.Error)
    } else {
      const modal = await this.modalCtrl.create({
        component: SuccessPopupComponent,
        cssClass: 'tm-decision-modal',
        componentProps: {
          btnCloseText: 'OK',
          title: 'Payment Success',
          message: 'Your wallet has been reloaded.'
      }});
      modal.present();

      this.walletV4Service.clearInMemoryCache();
      this.walletService.clearCache();
      this.router.navigateByUrl('wallet');
    }
  }

  async displayYourCardWillBeChargedConfirmation() {
    const modal = await this.modalCtrl.create({
      component: DecisionPopupComponent,
      cssClass: 'tm-decision-modal',
      componentProps: {
        btnYesText: 'Confirm',
        btnNoText: 'Cancel',
        message: `Your card ending in ${this.card.Last4} will be charged ${this.currencySymbol}${Number(this.amountToLoad).toFixed(2)}`,
        onActionProceedFunc: this.goToYourCardWillBeChargedFunc
    }});
    modal.present(); 
  }

  async displayApplePayCardWillBeChargedConfirmation() {
    const modal = await this.modalCtrl.create({
      component: DecisionPopupComponent,
      cssClass: 'tm-decision-modal',
      componentProps: {
        btnYesText: 'Confirm',
        btnNoText: 'Cancel',
        message: `Your card will be charged ${this.currencySymbol}${Number(this.amountToLoad).toFixed(2)}`,
        onActionProceedFunc: this.goToApplePaydWillBeChargedFunc
    }});
    modal.present(); 
  }

  addToWallet() {
    //check if extra details required
    if (this.askForPhoneNumberForWalletOrder ) {
      this.checkForExtraDetails();
      return;
    }

    if (!this.isValidAmountValue) {
      return;
    }

    if (this.hasCard == false) {
      this.navigateToCardInfoPage();
    } else {
      this.displayYourCardWillBeChargedConfirmation();
    }
  }

  async checkForExtraDetails() {
    const modal = await this.modalCtrl.create({
      component: InputPopupComponent,
      componentProps: {
        title: 'Phone Number',
        message: 'Please provide Phone number to proceed',
        btnYesText: 'Confirm',
        btnNoText: 'Cancel',
        onActionProceedFunc: this.goToSavePhoneNumberFunc
    }});
    modal.present();
  }


  private get isValidAmountValue(): boolean {
    let result: boolean = true;
    if (Utils.isNullOrUndefined(this.amountToLoad) || this.amountToLoad == 0) {
      this.alertService.present(this.translate.instant('Please select amount to load.'));
      result = false;
    } else if (!Utils.isNullOrUndefined(this.balance) && this.balance != 0 && this.maxWalletBalanceAllowed) {
      const newBalance = this.balance + this.amountToLoad;
      if (newBalance > this.maxWalletBalanceAllowed) {
        this.alertService.present(this.translate.instant('Selected amount will exceed the maximum allowed wallet balance ' + this.currencySymbol + this.maxWalletBalanceAllowed));
        result = false;
      }
    }

    return result;
  }


  navigateToCardInfoPage() {
    this.walletService.reloadAmount = this.amountToLoad;
    this.walletService.stripePublicKey = this.walletLoadData.StripePublicKey;
    const url: string = Utils.getPaymentFromUrl(this.paymentSystemProvider);
    if( !Utils.isNullOrUndefinedOrWhitespace(url) ) {
      console.log(`Navigate to the ${url} payment page because you don't have any credit card on file.`);
      this.router.navigateByUrl(url);
    } else {
      console.error('Could not find any payment form for paymentSystemProvider=', Utils.debugGetSafeString(this.paymentSystemProvider));
    }
  }

  async loadWithApplePay() {
    this.applePayloadData$.next(null);
    var merchantId = this.profileV4Service.MerchantId;
    var merchantLocationId = this.profileV4Service.getMerchantlocationId() ? this.profileV4Service.getMerchantlocationId() : 0;
    var currcyCode = this.profileV4Service.getCurrencycode();
    var customerId = this.profileV4Service.getCustomeId();

    console.log(`customerId: ${customerId} MerchantId: ${merchantId} MerchantLocationId: ${merchantLocationId} CurrencyCode: ${currcyCode}`);

    if (this.askForPhoneNumberForWalletOrder ) {
      this.checkForExtraDetails();
      return;
    }
    if (!this.isValidAmountValue) {
      return;
    }
    var puid = { "puid": this.applePay.generateUUID() };
    let objJsonStr = JSON.stringify(puid);     
    let objJsonB64 =  (<any>window).global.Buffer.from(objJsonStr).toString("base64");
    let logo_url = this.profileV4Service.getMerchantLogoUrl();
    let lineItems = [];  		
    const request = {
      "lineItems": lineItems,
      "countryCode": "US",
      "currencyCode": currcyCode,
      "merchantCapabilities": [
          "supports3DS"
      ],
      "supportedNetworks": [
          "visa",
          "masterCard",
          "amex",
          "discover"
      ],
      "total": {
          "label": "Wallet Load Amount",
          "type": "final",
          "amount": this.decimalPipe.transform(this.amountToLoad, '1.2-2') 
      },
      "requiredBillingContactFields": ['postalAddress', 'name', 'phone', 'email'],   
      "applicationData": objJsonB64, 
      "currencySymbol": this.currencySymbol,
      "logoUrl": logo_url,
      "postalCode": "",
      "merchantId": merchantId,
      "merchantLocationId": merchantLocationId,
      "puid": puid,
      "customerId": customerId,
      "appVersion": "V4"         
    };

    var ref = this.iab.create(environment.APPLE_PAY_WALLET_URL, this.applePay.target, this.applePay.external_browser_options);

    ref.on('loadstop').subscribe(event => {
      var payOBJ = JSON.stringify(request);
      console.log(payOBJ);
      ref.executeScript({
        code: "(function() {localStorage.setItem('payOBJ', JSON.stringify(" + JSON.stringify(request) + ")) })()"
      }).then(() => {
        console.log("in executeScript then()");
        ref.executeScript({
          code: "(function() {callLoadData();})()"
        }).then(() => {
          console.log("in executeScript then()");
        }); 
      });      
    });    

    ref.on('message').subscribe(async (event) => {
      const postObject:any = event
      if(event.data.action == "received_apple_pay_token") {
       console.log(postObject.data.action)
       console.log(JSON.parse(postObject.data.paymentData));     
       let paymentData = JSON.parse(postObject.data.paymentData);

        if (paymentData.billingContact) {
          request.countryCode = paymentData.billingContact.countryCode;  
          request.postalCode = paymentData.billingContact.postalCode;         
        }        
        const payloadData: ILoadFundsV4Request = <ILoadFundsV4Request>{
          CustomerId: customerId,
          CardHolderName: null,
          Token: null,
          Amount: this.amountToLoad,
          CardNumber: null,
          CardExpirationMonth: null,
          CardExpirationYear: null,
          CardCVV: '',
          SaveCreditCard: false,
          PayWithSavedCreditCard: false,
          IsApplePay: true,
          ApplePayToken:  (<any>window).global.Buffer.from(JSON.stringify(paymentData)).toString("base64"),
          ApplePayPaymentId: puid.puid,
          ApplePayApplicationData: request.applicationData,
          CountryCode: request.countryCode,
          PostalCode: request.postalCode
        };
       
        ref.executeScript({
          code: "(function() {callMethod();})()"
        }).then(() => {
          console.log("in executeScript then()");
        });         
                
        this.applePayloadData$.next(payloadData);
        console.log(this.applePayloadData$.getValue());
        await this.displayApplePayCardWillBeChargedConfirmation();       
      }        
     if(event.data.action == "close_apple_pay_external") {
       console.log(postObject.data.action)
       ref.close();
     }     
   })

  }


  async getVerificationTokenIfNeeded(amount: number) {    
    let verificationToken: string = null;

    if (
        !Utils.isNullOrUndefined(this.walletLoadData) && !Utils.isNullOrUndefinedOrWhitespace(this.paymentSystemProvider)
        && (this.paymentSystemProvider == 'Square' || this.paymentSystemProvider == 'SQUAREPAYMENTS')
        && this.walletLoadData.Square3DSEnabled === true
        && !Utils.isNullOrUndefined(this.card?.CardId)
      ) {
        this.squareCardInfoComponent.initPayments(this.walletLoadData.SquareApplicationId, this.walletLoadData.SquareLocationId);
        verificationToken = await this.squareCardInfoComponent.verifyBuyer(this.card.CardId, amount);
        if (Utils.isNullOrUndefinedOrWhitespace(verificationToken)) {
          verificationToken = 'failed';
        }
    }

    return verificationToken;
  }
}
