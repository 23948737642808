import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-version-update',
  templateUrl: './version-update.component.html',
  styleUrls: ['./version-update.component.scss'],
})
export class VersionUpdateComponent implements OnInit {

  @Input() appInfo: any;
  @Input() _util: UtilService;

  constructor() { }

  ngOnInit() {}

  updateApp() {
    let url = null;
    if (this.appInfo.platform == 'ios') {
      //url = `itms-apps://itunes.apple.com/app/${1462102559}`
      url = `${this.appInfo.updateURL}`
    }
    if (this.appInfo.platform == 'android') {
      //url = `https://play.google.com/store/apps/details?id=${this.appInfo.appPackageName}`;
      url = `${this.appInfo.updateURL}`
    }
    if (url) {
      this._util.openWithSystemBrowser(url);
    }
   
  }

}
