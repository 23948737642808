import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
import { Utils } from 'src/app/common/Util';
import { ProfileV4Service } from 'src/app/services/profile-v4.service';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WalletComponent implements OnInit, AfterViewInit {
  @Input() balance?: number = null;
  @Input() currencySymbol: string = null;
  @Input() showWalletLoadButton: boolean = false;

  constructor(private router: Router) { }

  ngOnInit() {
    console.log('showWalletLoadButton => ' + this.showWalletLoadButton);
  }

  ngAfterViewInit() {
      timer(500).subscribe(x => {
        if (this.showWalletLoadButton) {
          let el = document.getElementById('loadWalletButton');
          el.addEventListener('click', this.goToLoadWallet.bind(this));
        }           
      });      
  }

  goToLoadWallet() {
    console.log('showWalletLoadButton => ' + this.showWalletLoadButton);
    if (this.showWalletLoadButton) {
      this.router.navigateByUrl('/load-wallet');
    }    
  }

}
