import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GetWalletLoadDataResponse } from '../../../models/wallet';
import { AssetService } from '../../../services/asset.service';
import { UtilService } from '../../../services/util.service';
import { WalletService } from '../../../services/wallet.service';
import { LoadingOptions } from '../../popup-model/popup-model.component';
import { PopupService } from '../../../services/popup.service';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Utils } from 'src/app/common/Util';
import { BaseComponent } from '../../base/base.component';
import { takeUntil } from 'rxjs/operators';
import { WalletV4Service } from 'src/app/services/wallet-v4.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-clover-ecom-card-info',
  templateUrl: './clover-ecom-card-info.component.html',
  styleUrls: ['./clover-ecom-card-info.component.scss'],
  providers: [Keyboard]
})
export class CloverEcomCardInfoComponent extends BaseComponent implements OnInit {

  @ViewChild('backBtn') backBtn: ElementRef<HTMLElement>;


  clover: any;
  cardHolderName: string;
  cloverMerchantKey = '';
  customerValidated: boolean = false;
  saveCreditCard: boolean = false;

  customer: any;
    
  walletLoadData: GetWalletLoadDataResponse;
  date = "";
  creditForm: any = {};
  card: any = {};
  cardType: string;  
  saveCardDetail: boolean = false;
  loadWalletAmount: any = 0.00;
  currencySymbol: string = "$"
  address = "";
  city = "";
  state = "";
  countryCode: any;

  countries: any;

  editing: boolean = false;
  readyOnly: boolean = false;
  shouldChargeWallet: boolean = false;
  amount;
  clvPaymentForm: any;
  static paymentForm: any;
  canSaveCreditCard: boolean = false;

  keyboardOpened: boolean = false;

  constructor(
    private router: Router,
    private util: UtilService,
    private walletV4Service: WalletV4Service,
    private walletService: WalletService, // COSMIN this was kept from backward compatibility reasons to avaoid changing the payment forms
    private assetService: AssetService,
    public popupService: PopupService,
    public keyboard: Keyboard,
    private translate: TranslateService
    )
  {    
    super();
  }

  ngOnInit() { }

  async ionViewWillEnter() {

    this.listenKeyboardevents();

    this.currencySymbol = this.assetService.currencySymbol;
    this.loadWalletAmount = this.walletService.reloadAmount;

    await this.walletService.getWalletLoadData().then(async (result: GetWalletLoadDataResponse) => {
      if (result) {
        this.walletLoadData = result;
        this.canSaveCreditCard = result.CanSaveCreditCard;
        this.cloverMerchantKey = result.CloverMerchantKey;
      }
    }, async error => {
      Utils.handleError(error, this.popupService, 'There was an error while processing this request. Please try again later.');
      await this.backDynamic();

    });

    this.setupClover();
  }

  listenKeyboardevents() {
    this.keyboard.onKeyboardWillShow()
    .pipe(takeUntil(this.destroyed$))
    .subscribe(() => this.keyboardOpened = true );

    this.keyboard.onKeyboardWillHide()
    .pipe(takeUntil(this.destroyed$))
    .subscribe(() => this.keyboardOpened = false );
  }

  async ionViewWillLeave() {
    this.cardHolderName = "";
    this.saveCardDetail = false;
    //destroy paymentForm();
  }

  async backDynamic() {
    let el: HTMLElement = (<any>this.backBtn).el;
    el.click();
  }

  public static recalculatePaymentFormSize() {    
    this.paymentForm.recalculateSize();
  }

  async getClvrToken() {
    let loadingOptions = {
      processingMessage: 'Processing Payment...'
    } as LoadingOptions;


    let loader = await this.popupService.loading(loadingOptions);

    this.clover.createToken()
      .then(async (result) => {
        if (result.errors) {
          await loader.dismiss();

          Object.values(result.errors).forEach(function (value) {
            alert(value);
          });
        } else {
          await loader.dismiss();

          this.processWalletPayment(result.token, result.card);
        }
      });
  }

  processWalletPayment(token: string, card: any) {
    const payloadData = {
      Token: token,
      Amount: this.loadWalletAmount,
      SaveCreditCard: this.saveCardDetail,
      PayWithSavedCreditCard: false
    };

    const confirmMessage: string = `Your card ending in ${card.last4} will be charged ${this.currencySymbol}${Number(payloadData.Amount).toFixed(2)}`;
    this.popupService.confirmV4(null, confirmMessage).then(async (confirmed: boolean) => {
      if (confirmed) {

        let loadingOptions = {
          processingMessage: 'Processing Payment...'
        } as LoadingOptions;

        let loader = await this.popupService.loading(loadingOptions);

        await this.walletService.loadFundsV4(payloadData).then(async (response: any) => {

          await loader.dismiss();

          console.log(response);
          if (response.OK == false) {
            let errorMessage =
              this.translate.instant('Error verifying card. Please try again.') ;

            if (
              response.ErrorDetails &&
              response.ErrorDetails.ErrorType == 3
            ) {
              errorMessage = response.Error;
            }

            if (
              response.ErrorDetails &&
              response.ErrorDetails.ErrorType == 11
            ) {
              errorMessage =
                this.translate.instant('The minimum amount to reload wallet is $')  +
                Number(response.ErrorDetails.Amount).toFixed(2);
            }
            if (
              response.ErrorDetails &&
              response.ErrorDetails.ErrorType == 10
            ) {
              errorMessage =
                this.translate.instant('Enter lower amount, wallet balance cannot exceed limit of $')  +
                Number(response.ErrorDetails.Amount).toFixed(2);
            }

            await this.popupService.failV4('Payment Failed', errorMessage);

            console.error(response.Error);
          } else {
            await this.popupService.successV4('Payment Success', 'Your wallet has been reloaded.').then(async () => {
              this.walletV4Service.clearInMemoryCache();
              this.walletService.clearCache();
              this.walletService.getWalletBalance({ MerchantLocationId: this.util.merchantLocationId });
              this.router.navigateByUrl('wallet');
            });
          }
        });
      }
    });    

  }

  setupClover() {
    this.clover = new (<any>window).Clover(this.cloverMerchantKey);
    const elements = this.clover.elements();

    const elementStyle = {
      'box-sizing': 'border-box',
      'border-bottom': '0.5px solid rgba(196, 196, 196, 0.9)',
      'height': '50px',
      'margin-bottom': '10px',
      'font-size': '18px',
      'width': '100%',
      'padding-left': '5px'
    };

    const styles = {
      'card-number input': elementStyle,
      'card-date input': elementStyle,
      'card-cvv input': elementStyle,
      'card-postal-code input': elementStyle
    };

    const cardNumber = elements.create('CARD_NUMBER', styles);
    const cardDate = elements.create('CARD_DATE', styles);
    const cardCvv = elements.create('CARD_CVV', styles);
    const cardPostalCode = elements.create('CARD_POSTAL_CODE', styles);

    cardNumber.mount('#clv-card-number');
    cardDate.mount('#clv-expiration-date');
    cardCvv.mount('#clv-cvv');
    cardPostalCode.mount('#clv-postal-code');
        
  }
    
  isReadOnly() {
    return this.readyOnly;
  }

  saveCreditCardChecked(event) {
    if (event.detail.checked) {
      this.saveCreditCard = true;
    } else {
      this.saveCreditCard = false;
    }
  }

}
