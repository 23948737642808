import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Utils } from 'src/app/common/Util';
import { AssetService } from 'src/app/services/asset.service';

@Component({
  selector: 'app-provide-phone-number-content',
  templateUrl: './provide-phone-number-content.component.html',
  styleUrls: ['./provide-phone-number-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProvidePhoneNumberContentComponent implements OnInit {
  @Input() email: string = null;
  @Output() confirm: EventEmitter<string> = new EventEmitter<string>();

  constructor(public assetService: AssetService) {}

  ngOnInit() {
    console.log(`ProvidePhoneNumberPage.ngOnInit() email=${Utils.debugGetSafeString(this.email)}`);
  }

  onConfim(phone: string) {
    console.log(`ProvidePhoneNumberContentComponent.onVerify(${phone})`);
    this.confirm.emit(phone);
  }
}
