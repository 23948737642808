import { Injectable } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { config } from 'src/config';
import { Platform } from '@ionic/angular';
import { Constants } from '../common/Constants';
import { EMPTY_REQUEST_HEADER_DATA, IRequestHeaderData } from '../models/requestHeaderData';
import { Utils } from '../common/Util';

@Injectable({
  providedIn: 'root'
})
export class HeadersService {
  private requestHeaderData: IRequestHeaderData = null;

  constructor(
    private platform: Platform,
    private device: Device, 
    private appVersion: AppVersion
  ) { }

  get RequestHeaderData(): IRequestHeaderData {
    this.requestHeaderData = this.createRequestHeaderData();
    return this.requestHeaderData;
  }

  private createRequestHeaderData(): IRequestHeaderData {
    return Utils.isNullOrUndefined(this.requestHeaderData) ?    
        <IRequestHeaderData>{
          ...EMPTY_REQUEST_HEADER_DATA,
          MERCHANT_GUID: config.MERCHANT_GUID,
          MERCHANT_APP_ID: config.MERCHANT_APP_ID,
          APP_LANG: Utils.readStringFromLocalStorageWithDefaultValue(Constants.APP_LANGUAGE_KEY, config.DEFAULT_LANGUAGE),
          DEVICE_ID: '0'
        } :
        <IRequestHeaderData>{
          ...this.requestHeaderData,
          APP_LANG: Utils.readStringFromLocalStorageWithDefaultValue(Constants.APP_LANGUAGE_KEY, config.DEFAULT_LANGUAGE)
        };        
  }

  async initialize(): Promise<IRequestHeaderData> {
    return await new Promise<any>(async (resolve, reject) => {
      const result: IRequestHeaderData = this.createRequestHeaderData();

      this.platform.ready().then(async () => {
        if (this.platform.is("cordova")) {
          result.DEVICE_ID = this.device.uuid;
          if (Utils.isNullOrUndefinedOrWhitespace(result.DEVICE_ID) ) {
            result.DEVICE_ID = '0';
          }
          localStorage.setItem(Constants.DEVICE_ID_KEY, result.DEVICE_ID);
        
          await this.appVersion.getPackageName().then((name: any) => {
            if (name) {
              result.APP_BUNDLE_ID = name;
            }
          })
          .catch((error: any) => {
            console.log(error)
          });
        
          await this.appVersion.getVersionNumber().then((number: any) => {
            if (number) {
              result.APP_VERSION = number;
            }
          })
          .catch((error: any) => {
            console.log(error)
          });       
        }

        this.requestHeaderData = result;
        resolve(result);
      });
    });
  }


  // async getHeadersObolete() : Promise<any> {

  //   return await new Promise<any>(async (resolve, reject) =>  {

     

  //     var data = {
  //       "Accept": "application/json",
  //       "MERCHANT_GUID": config.MERCHANT_GUID,
  //       "MERCHANT_APP_ID": config.MERCHANT_APP_ID,
  //       "APP_LANG": config.DEFAULT_LANGUAGE,          
  //       "APP_VERSION": "5.0.1",
  //       "APP_BUNDLE_ID": "com.tapmango.mobile",
  //       "DEVICE_ID": this.deviceId           
  //     };

      
  //     const language: string = localStorage.getItem(Constants.APP_LANGUAGE_KEY);
  //     if (!Utils.isNullOrUndefinedOrWhitespace(language)) {
  //       data.APP_LANG = language;
  //     }

  //     if (this.platform.is("cordova")) {

  //       this.deviceId = this.device.uuid;

  //       console.log(`Device Id : => ${this.deviceId}`); 

  //       if (!this.deviceId) {
  //         this.deviceId = "0";
  //       }  

  //       data.DEVICE_ID = this.deviceId;
      
      
  //       await this.appVersion.getPackageName().then((name: any) => {
  //         if (name) {
  //           data.APP_BUNDLE_ID = name;
  //         }
  //       })
  //       .catch((error: any) => {
  //         console.log(error)
  //       });
      
  //       await this.appVersion.getVersionNumber().then((number: any) => {
  //         if (number) {
  //           data.APP_VERSION = number;
  //         }
  //       })
  //       .catch((error: any) => {
  //         console.log(error)
  //       });       
  //     }

  //     localStorage.setItem(Constants.DEVICE_ID_KEY, this.deviceId);     
  //     resolve(data);
  //   });    
  // }
}
