import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn } from '@angular/forms';
import { IonDatetime, ModalController } from '@ionic/angular';
import { IUserAccount } from 'src/app/models/userAcccount';
import { PreferredLocationListComponent } from '../../preferred-location-list/preferred-location-list.component';
import { Utils } from 'src/app/common/Util';
import { TermsComponent } from '../../terms/terms.component';
import { PrivacyComponent } from '../../privacy/privacy.component';
import { LocationService } from 'src/app/services/location.service';
import { ValidatorService } from 'src/app/services/validator.service';
import { Constants } from 'src/app/common/Constants';
import { IOnBoard2Settings } from 'src/app/models/onBoard2Settings';
import { CustomerCustomFieldTypeEnum, ICustomerCustomField } from 'src/app/models/response/getProfileSettingsResponse';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BaseComponent } from '../../base/base.component';
import { IStoreLocation } from 'src/app/models/storeLocation';
import { Router } from '@angular/router';

@Component({
  selector: 'app-on-board2-form',
  templateUrl: './on-board2-form.component.html',
  styleUrls: ['./on-board2-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnBoard2FormComponent extends BaseComponent implements OnInit {
  @Input() isAccountCreation: boolean = true;
  @Input() userAccount: IUserAccount = null;
  @Input() profileSettings: IOnBoard2Settings = null;
  @Output() saveChanges: EventEmitter<IUserAccount> = new EventEmitter<IUserAccount>();

  showPassword: boolean = false;
  @ViewChild('dateTimeElement') dateTimeElement: IonDatetime;
  minDate: string = (new Date((new Date()).getFullYear() - 100, (new Date()).getMonth(), (new Date()).getDate())).toISOString();
  maxDate: string = (new Date()).toISOString();

  locationIsTouchedOrDirty: boolean = false;
  distanceUnit: any = LocationService.distanceUnit;
  dateofBirthIsDisplayed: boolean = false;
  dateofBirthIsDisabled: boolean = true;
  phoneMinLength: number = Utils.maskAwarePhoneLength;
  phoneMaxLength: number = Utils.maskAwarePhoneLength;
  phonePlaceHolder: string = Utils.getPhoneMask().phonePlaceholder;
  passwordPlaceholder: string = 'Password';

  form: FormGroup = null;

  
  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private cd: ChangeDetectorRef,
    private validatorService: ValidatorService,
    private locationService: LocationService,
    private router: Router
  ) 
  {
    super();
  }

  
  ngOnInit() {
    console.log('OnBoard2FormComponent.ngOnInit(): userAccount=', this.userAccount);
    console.log('OnBoard2FormComponent.ngOnInit(): profileSettings=', this.profileSettings);
    this.distanceUnit = LocationService.distanceUnit;
    this.passwordPlaceholder = this.isAccountCreation ? 'Password' : 'Enter password to save changes';
    
    this.createForm();

    this.form.patchValue(this.userAccount);
   
    if( !Utils.isTrue(this.profileSettings.phoneIsReadOnly) ) {
      this.form.get('phone').valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(value => this.form.get('phone').setValue(Utils.formatPhoneNumber(value)));
    }
  }

  createForm() {
    if( Utils.isNullOrUndefined(this.form) ) {
      const passwordValidators = [Validators.required, Validators.maxLength(50), Validators.minLength(8), this.validatorService.LowerCase(), this.validatorService.UpperCase(), this.validatorService.Digits(), this.validatorService.SpecialChar(), this.validatorService.NoSpace() ];
      this.form = this.isAccountCreation ?

        this.formBuilder.group({
          fullName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50), this.validatorService.FullName()]),
          email: new FormControl({ value: null, disabled: this.profileSettings.emailIsReadOnly }, [Validators.email, Validators.pattern(Constants.EMAIL_REGEX), Validators.required]),
          phone: new FormControl({ value: null, disabled: this.profileSettings.phoneIsReadOnly }, [Validators.required, Validators.minLength(this.phoneMinLength), Validators.maxLength(this.phoneMaxLength)] ),
          password: new FormControl('', passwordValidators),
      
          smsConsent: new FormControl('false', [Validators.requiredTrue]),
          termsConsent: new FormControl('false', [Validators.requiredTrue])
        })
        
        :

        this.formBuilder.group({
          fullName: new FormControl(this.userAccount.fullName, [Validators.required, Validators.minLength(3), Validators.maxLength(50), this.validatorService.FullName()]),
          phone: new FormControl({ value: null }, [Validators.required, Validators.minLength(this.phoneMinLength), Validators.maxLength(this.phoneMaxLength)] ),
          email: new FormControl({ value: null }, [Validators.email, Validators.pattern(Constants.EMAIL_REGEX), Validators.required]),
          password: new FormControl('', [Validators.required]),
          preferredstoreid: new FormControl(this.userAccount.preferredLocation.Id, [Validators.required])
        });

        this.addDateOfBirthFormControlIfNeeded();

        if( this.isAccountCreation && !Utils.isNullOrUndefined(this.profileSettings.additionalFields) ) {
          this.profileSettings.additionalFields.forEach( (additionalProfileField: ICustomerCustomField, index ) => {
            if( !Utils.isNullOrUndefined(additionalProfileField) ) {
              const validators = additionalProfileField.Required ? 
                                additionalProfileField.DataType == CustomerCustomFieldTypeEnum.checkbox ? [Validators.requiredTrue] : [Validators.required] :
                                [];
              this.form.addControl(`Data${(index + 1)}`, new FormControl('', validators));
            }
          });
        }
    }
  }

  private addDateOfBirthFormControlIfNeeded() {
    this.dateofBirthIsDisplayed = this.isAccountCreation && this.profileSettings.captureDOB;
    if( !this.dateofBirthIsDisplayed ) {
      return;
    }

    this.dateofBirthIsDisabled = !Utils.isNullOrUndefinedOrWhitespace(this.userAccount.formattedDateOfBirth);
    const dobMinLength: number = this.profileSettings.captureDOBYear ? 8 : 5;
    
    console.log('OnBord2FormComponent.addDateOfBirthFormControlIfNeeded() captureDOBYear=', this.profileSettings.captureDOBYear, ' dobMinLength=', dobMinLength)
    if(this.profileSettings.dobIsRequired){      
      this.form.addControl('formattedDateOfBirth', new FormControl({ value: this.userAccount.formattedDateOfBirth, disabled: this.dateofBirthIsDisabled }, [Validators.minLength(dobMinLength), Validators.required] ));
    } else {
      this.form.addControl('formattedDateOfBirth', new FormControl({ value: this.userAccount.formattedDateOfBirth, disabled: this.dateofBirthIsDisabled }, [Validators.minLength(dobMinLength)] ));
    }
  }

  getDataValue(index: number): string | Date | boolean {
    let result = null;
    if( !Utils.isNullOrUndefined(this.profileSettings?.additionalFields) && !Utils.isNullOrUndefined(this.userAccount) ) {
      result = this.userAccount[`Data${(index + 1)}`];
    }
    return result;
  }

  toggleShow() {
    this.showPassword = !this.showPassword;
  }

  openDateTime(event) {
    if( this.isAccountCreation ) {
      event.preventDefault();
      this.dateTimeElement.open();
    }
  }
  
  selectDate(event) {
    console.log('OnBord2FormComponent.selectDate() event=', event)
    const selectedDate: Date = new Date(event?.detail?.value);
    this.userAccount.dateOfBirth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
  }

  async togglePreferredStoreOption() {
    let locations: Array<IStoreLocation> = JSON.parse(localStorage.getItem(Constants.STORE_INFO_KEY));
    if( Utils.isNullOrUndefined(locations)) {
      await this.locationService.populateStores().then( (storeList: Array<IStoreLocation>) => {
        locations = storeList;
      });
    }
    const selectedLocation = Utils.isNullOrUndefined(this.userAccount.preferredLocation?.Id) ? null : locations.find(x => x.Id == this.userAccount.preferredLocation.Id);
    this.modalController.create({
      component: PreferredLocationListComponent,
      componentProps: {
        selectedLocation: selectedLocation,
        distanceUnit: this.distanceUnit,
        onLocationSelectedFunc: this.goToUpdatePreferredLocationListFunc,
        locations: locations,
        restriction: this.locationService.restriction
      },
      cssClass: 'preferred-location-list'
    })
    .then((modalElement) =>
    {
      this.locationIsTouchedOrDirty = true;
      modalElement.present().finally(() => this.cd.detectChanges() );
    });
  }

  get goToUpdatePreferredLocationListFunc() {
    return this.updatePreferredLocationList.bind(this);
  }

  updatePreferredLocationList(selectedLocation: any) {
    this.userAccount.preferredLocation = selectedLocation;
    this.modalController.dismiss();
    this.cd.detectChanges();
  }

  get isFormValid(): boolean {
    return this.form.valid && !Utils.isNullOrUndefined(this.userAccount?.preferredLocation?.Title) && !Utils.isNullOrUndefined(this.userAccount?.preferredLocation?.Address);
  }

  save() {
    if( this.form.valid ) {
      this.userAccount = {
        ...this.userAccount,
        ...this.form.value,
        formattedDateOfBirth: Utils.formatDateofBirthForDisplay(this.userAccount.dateOfBirth)
      };
      this.saveChanges.emit(this.userAccount);
    }
  }

  async showTerms() {
    const modal = await this.modalController.create({
      component: TermsComponent   
    });
    return await modal.present();
  }

  async showPrivacy() {
    const modal = await this.modalController.create({
      component: PrivacyComponent   
    });
    return await modal.present();
  }

  forgotPassword() {
    const pageToGoBackTo: string = this.isAccountCreation ? 'on-board2' : 'profile-edit';
    this.router.navigateByUrl('forgot-password', { state: { pageToGoBackTo: pageToGoBackTo }, replaceUrl: true });
  }
}
