import { Component, Input, OnInit } from '@angular/core';
import { PunchCard } from 'src/app/models/punchCard';

@Component({
  selector: 'app-punch-card',
  templateUrl: './punch-card.component.html',
  styleUrls: ['./punch-card.component.scss'],
})
export class PunchCardComponent implements OnInit {
  
  @Input() punchCard: PunchCard;
  constructor() { }

  ngOnInit() {}

}
