import { Injectable } from '@angular/core';
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { BehaviorSubject, Observable, Subject, Subscription, of, timer } from 'rxjs';
import { EndPoint } from '../enums/endpoints';
import { GetBalanceResponse } from '../models/wallet';
import { ApiService } from './api.service';
import { NotificationService } from './notification.service';
import { ToastService } from './toast.service';
import { Router } from '@angular/router';
import { NotificationV4Service } from './notification-v4.service';
import { INotification } from '../models/response/notification';
import { takeUntil, map } from 'rxjs/operators';
declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  walletLoadData$: Observable<GetBalanceResponse>;

  homeLocationDistance: number = null;

  landinPageLoadComplete = new BehaviorSubject<boolean>(false);

  notifications: Array<INotification> = null;

  private subscription: Subscription;

  constructor( private geolocation: Geolocation, 
    private apiService: ApiService, 
    private notificationService: NotificationService,
    private notificationV4Service: NotificationV4Service,
    private router: Router,
    private toastService: ToastService) { }

  async getDistanceForHomeLocation(Lat: number, Lng: number) {

    return await new Promise<number>(async (resolve, reject) => {

      var isCordovaApp = !!window.cordova;
      
      let homeLocationDistance: number = 0;

      if (isCordovaApp) {

        this.geolocation.getCurrentPosition({enableHighAccuracy: true, timeout: 5000, maximumAge: 0}).then((resp) => {

          console.log(resp); 

          homeLocationDistance = Number(this.distance(resp.coords.latitude, resp.coords.longitude, Lat, Lng, "K").toFixed(2));

          resolve(homeLocationDistance);
        }, error => {          
          resolve(homeLocationDistance);
        });
      }
      else {
        await navigator.permissions.query({name:'geolocation'}).then(async (result) => {
          if (result.state == 'granted') {
            await this.getCurrentPositionFromBrowser().then((result) => {
              if(result !== null) {
                homeLocationDistance = Number(this.distance(result.latitude, result.longitude, Lat, Lng, "K").toFixed(2));
                resolve(homeLocationDistance);
              }
            }, error => {
              console.log(error);
              resolve(homeLocationDistance);
            });
          }
          else if (result.state == 'prompt') {
            await this.getCurrentPositionFromBrowser().then((result) => {
              if(result !== null) {
                homeLocationDistance = Number(this.distance(result.latitude, result.longitude, Lat, Lng, "K").toFixed(2));
                resolve(homeLocationDistance);
              }
            }, error => {
              console.log(error)
              resolve(homeLocationDistance);
            });      
          }
          else if (result.state == 'denied') {
            resolve(homeLocationDistance);
          }        
        }, error => {
          console.log(error)
          resolve(homeLocationDistance);
        })
      }
    });
    
  }

  async getCurrentPositionFromBrowser() {
    return await new Promise<any>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(async (resp) => {
        if(resp.coords.latitude &&  Number(resp.coords.latitude) && resp.coords.longitude &&  Number(resp.coords.longitude)) {
          resolve(resp.coords);
        }
      }, error => {
        reject(error);
      }, {enableHighAccuracy: true, timeout: 3000, maximumAge: 0});
    });    
  }

  async getWalletLoadData() {  

    return await new Promise<any>((resolve, reject) => {
      if (!this.walletLoadData$) {
        this.apiService.getData(EndPoint.USER_WALLET_DATA)
          .subscribe(
            async (result: any) => {             
              if (result) {
                this.walletLoadData$ = result;
                resolve(result)
              }
              else if (result && result.IsError) {
                reject(result.Error)
              }
            },
            async (err) => {             
              reject(err)
            });
      }
      else {
        resolve(this.walletLoadData$);
      }
    });
  }

  distance(lat1, lon1, lat2, lon2, unit) {
    if (lat1 == lat2 && lon1 == lon2) {
      return 0;
    } else {
      let radlat1 = (Math.PI * lat1) / 180;
      let radlat2 = (Math.PI * lat2) / 180;
      let theta = lon1 - lon2;
      let radtheta = (Math.PI * theta) / 180;
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") {
        dist = dist * 1.609344;
      }
      if (unit == "N") {
        dist = dist * 0.8684;
      }
      return dist;
    }
  }

  updateNotificationPreference() {
    this.notificationService.updateNotificationData();
  }

  setPushEnabled(val) {
    this.notificationService.pushEnabled = val;
  }

  registerForNotifications() {
    this.notificationService.registerForNotifications();
  }

  async setPushPopUpPreference(val) {    
    return await this.notificationService.setPushPopUpPreference(val);
  }

  async getPushPopUpPreference() {
    return await this.notificationService.getPushPopUpPreference();
  }

  async removePushPopUpPreference() {
    return await this.notificationService.removePushPopUpPreference();
  }

  determinePlatform() {
    return this.notificationService.determinePlatform();
  }

  async setPushPopUpPreferenceOnResume(val) {
    return await this.notificationService.setPushPopUpPreferenceOnResume(val);
  }

  async getPushPopUpPreferenceOnResume() {
    return await this.notificationService.getPushPopUpPreferenceOnResume();
  }
  
  clearNotificationPreference() {
    this.notificationService.clear();
  }

  async checkAndRegister() {
    return await this.notificationService.deviceHasPermission();
  }

  setupNotificationHandler() {
    this.notificationService.newMessage$.subscribe(async (notification) => {
      if (notification) {        
        let value = notification.additionalData;
        console.log(`Notification Message Received => `, value);
        if(value) {          
          console.log(`Notification Message for Id => ${value.id}`);
          this.updateNotifications().subscribe(async notifications => {
            console.log(`New Notifications => `, notifications);
            this.notifications = notifications;
            if (value.foreground == false && value.type == "PROMO") {   
              if (this.notifications && this.notifications.length > 0) {              
                let newNotification = this.notifications.find(x => x.Id == value.id);
                console.log(`New Notification => `, newNotification);
                if (newNotification) {
                  timer(1000).subscribe(y => {
                    console.log(`Timer subscribe => `, newNotification);
                    this.router.navigateByUrl('notification-detail', { state: { notification: newNotification } });
                    console.log("New Notification found");                   
                  });                 
                }         
              }     
            } 
            else {            
              try {
                await this.toastService.showTop(notification.message).then(() => {
                  console.log("Notification Popup displayed");                  
                });              
              } catch (error) {
                console.log("Could not fetch latest profile data");             
              }
            }       
          });               
        }
        this.notificationService.newMessage$.next(undefined);
      }
    });   
  }

  updateNotifications() : Observable<INotification[]> {
    this.notificationV4Service.handleClearCacheNotification();
    this.notificationV4Service.handleClearMemoryCacheNotification();
    return this.notificationV4Service.forceGetNotifications();      
  }

  unsubscribe(): void {
    if (this.subscription) {
      console.log("Unsubscribing Notification Detail"); 
      this.subscription.unsubscribe();
    }
  }
 
}
