import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IHowToEarn } from 'src/app/models/response/getWaysToEarnResponse';

@Component({
  selector: 'app-make-apurchase-explanation',
  templateUrl: './make-apurchase-explanation.component.html',
  styleUrls: ['./make-apurchase-explanation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MakeAPurchaseExplanationComponent implements OnInit {

  @Input() howToEarn: IHowToEarn = null;

  constructor() { }

  ngOnInit() {}

}
