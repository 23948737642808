import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { INotification } from 'src/app/models/response/notification';

@Component({
  selector: 'app-notification-detail',
  templateUrl: './notification-detail.component.html',
  styleUrls: ['./notification-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationDetailComponent implements OnInit {

  @Input() notification: INotification = null;
  constructor() { }

  ngOnInit() {}

}
