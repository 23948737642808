import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-how-to-redeem',
  templateUrl: './how-to-redeem.component.html',
  styleUrls: ['./how-to-redeem.component.scss'],
})
export class HowToRedeemComponent implements OnInit {

  constructor(public modalController: ModalController) { }

  ngOnInit() {}

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });

  }
}
