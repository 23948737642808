import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AdditionalFieldCheckboxComponent } from '../../additional-field-checkbox/additional-field-checkbox.component';
import { AbstractControl, FormGroup } from '@angular/forms';
import { CustomerCustomFieldTypeEnum, ICustomerCustomField } from 'src/app/models/response/getProfileSettingsResponse';


@Component({
  selector: 'app-additional-profile-data',
  templateUrl: './additional-profile-data.component.html',
  styleUrls: ['./additional-profile-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalProfileDataComponent implements OnInit {
  @Input() form: FormGroup = null;
  @Input() additionalProfileField: ICustomerCustomField = null;
  @Input() name: string = null;
  @Input() value: string | Date | boolean;

  formControl: AbstractControl = null;
  customerCustomFieldTypeEnum = CustomerCustomFieldTypeEnum;

  constructor( private modalCtrl: ModalController ) { }

  ngOnInit() {
    this.formControl = this.form.get(this.name);
    console.log('AdditionalProfileDataComponent.ngOnInit(): additionalProfileField=', this.additionalProfileField); 
  }

  async showDisclosure() {
    const modal = await this.modalCtrl.create({
      component: AdditionalFieldCheckboxComponent,
      id: 'checkbox-modal',
      animated: true,
      mode: 'ios',
      cssClass: 'tm-additional-field-checkbox-modal-css',
      backdropDismiss: true,
      componentProps: {
        _id: this.additionalProfileField.Id, 
        _description: this.additionalProfileField.Description, 
        _name: this.additionalProfileField.Name, 
        _modalCtrl: this.modalCtrl, 
        _modalId: 'checkbox-modal',
        updateCheckBoxValue: this.updateCheckBoxValueFunc
      }
    });  
    return await modal.present(); 
  }

  async updateCheckBoxValue(data: any) : Promise<any>
  {
    return await new Promise<any>((resolve, reject) => {
      this.value = data.val;
      this.formControl.setValue(this.value);
      resolve(true)
    });
    
  }
  
  get updateCheckBoxValueFunc() {
    return this.updateCheckBoxValue.bind(this);
  }
}
