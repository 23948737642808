import { Constants } from "../common/Constants";
import { Utils } from "../common/Util";

export class AuthenticationToken {
  constructor(private authenticationToken: string) 
  {
  }

  get isLoggedIn(): boolean {
    this.authenticationToken = localStorage.getItem(Constants.AUTHENTICATION_TOKEN_KEY);
    return !Utils.isNullOrUndefinedOrWhitespace(this.authenticationToken);
  }
}
