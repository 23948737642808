import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.PRODUCTION) {
  enableProdMode();
}

let src: string;
if (environment.PRODUCTION) {
  src='https://checkout.clover.com/sdk.js';
} else {
  src='https://checkout.sandbox.dev.clover.com/sdk.js';
}

let script = document.createElement('script');
script.type = 'text/javascript';
script.src = src;
document.getElementsByTagName('head')[0].appendChild(script);


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
