import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IPunchCardsUIModel } from 'src/app/models/punchCard';
import { IRewardsSliderData } from 'src/app/models/rewardsSliderData';
import { IRewardUIModel } from 'src/app/models/yourReward';

@Component({
  selector: 'app-your-rewards-tab',
  templateUrl: './your-rewards-tab.component.html',
  styleUrls: ['./your-rewards-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YourRewardsTabComponent implements OnInit {

  @Input() rewardsSliderData: IRewardsSliderData = null;
  @Input() yourRewards: Array<IRewardUIModel> = null;
  @Input() punchCardsUIModel: IPunchCardsUIModel = null;
  
  constructor() { }

  ngOnInit() {}

}
