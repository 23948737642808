import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-decision-popup',
  templateUrl: './decision-popup.component.html',
  styleUrls: ['./decision-popup.component.scss'],
})
export class DecisionPopupComponent implements OnInit {
  @Input() title: string = null;
  @Input() message: string = null;
  @Input() btnYesText: string = null;
  @Input() btnNoText: string = null;
  @Input() onActionProceedFunc: Function = null;

  constructor(public modalCtrl: ModalController) { }

  ngOnInit() {}

  dismiss() {
    this.modalCtrl.dismiss();
  }

}
