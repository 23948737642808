export interface IAddress {
    Street: string;
    City: string;
    State: string;
    Country: string;
    Zip: string;
}

export const EMPTY_ADDRESS: IAddress = {
    Street: null,
    City: null,
    State: null,
    Country: null,
    Zip: null
}