import { Injectable } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { config } from 'src/config';
import { Utils } from '../common/Util';

@Injectable({
  providedIn: 'root'
})
export class CacheCleaningNotificationService {

  cacheCleaningNotification$ = new Subject();
  memoryCacheCleaningNotification$ = new Subject();

  constructor() {
    this.setUpSchedule();
  }

  sendClearCacheNotification() {
    this.cacheCleaningNotification$.next(new Date());
  }

  sendClearMemoryCacheNotification() {
    this.memoryCacheCleaningNotification$.next(new Date());
  }

  private setUpSchedule() {
    const timeInterval: number = Utils.isNullOrUndefinedOrWhitespace(config.CACHE_CLEANUP_INTERVAL_IN_SECONDS) ?
                                2 * 60 * 1000/* 2 minutes */ :
                                Number(config.CACHE_CLEANUP_INTERVAL_IN_SECONDS) * 1000;
    timer(timeInterval, timeInterval)
    .subscribe( () => this.sendClearMemoryCacheNotification() );
  }
}
