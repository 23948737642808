import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, MenuController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/common/Constants';
import { Utils } from 'src/app/common/Util';
import { MenuData } from 'src/app/models/menuData';
import { TileData } from 'src/app/models/tileData';
import { AssetService } from 'src/app/services/asset.service';
import { AuthV4Service } from 'src/app/services/auth-v4.service';
import { ProfileV4Service } from 'src/app/services/profile-v4.service';
import { ThemeService } from 'src/app/services/theme.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UtilService } from 'src/app/services/util.service';
import { config } from 'src/config';
import { BaseComponent } from '../base/base.component';
import { takeUntil } from 'rxjs/operators';
import { IGetProfileRequest } from 'src/app/models/request/getProfileRequest';
import { IGetProfileResponse } from 'src/app/models/response/getProfileResponse';

@Component({
  selector: 'app-slide-menu',
  templateUrl: './slide-menu.component.html',
  styleUrls: ['./slide-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlideMenuComponent extends BaseComponent implements OnInit {
  menuData: MenuData;
  tileData: TileData;
  enableDarkMode: boolean = null;
  systemLanguage:any;
  languages = Constants.ALL_SUPPORTED_LANGUAGES;
  selectedLanguage: string;
  displayVisitOurWebSite: boolean = false;
  showWalletTile: boolean;
  showModeSwitch: boolean = true;
  
  fix_bug: string = '0px';

  constructor(
    private themeService: ThemeService, 
    private menuCtrl: MenuController, 
    public actionSheetController: ActionSheetController,
    private translate: TranslateService,
    private translationService: TranslationService,  
    private router: Router,
    public authService: AuthV4Service,
    private profileV4Service: ProfileV4Service,
    public util: UtilService,
    private assetService: AssetService
  )
  {
    super();
  }

  async ngOnInit() {
    console.log(`SlideMenuComponent.ngOnInit()`);
    await this.initialize();
  }

  async ionViewWillEnter() {
    console.log(`SlideMenuComponent.ionViewWillEnter()`);
  }

  private async initialize() {
    
    this.profileV4Service.profile$
    .pipe(takeUntil(this.destroyed$))
    .subscribe((response: IGetProfileResponse) => this.getTileAndMenuData(response));

    this.readProfileData();
    await this.themeService.isDarkThemeSelected().then(() => {
      this.enableDarkMode = this.themeService.darkModeSelected$.value;
      console.log(this.enableDarkMode);     
    });  
    this.systemLanguage = localStorage.getItem(Constants.APP_LANGUAGE_KEY);  
    console.log(`System Language => ${this.systemLanguage}`);
    if (!this.systemLanguage) {
      localStorage.setItem(Constants.APP_LANGUAGE_KEY, "en");
      this.systemLanguage = localStorage.getItem(Constants.APP_LANGUAGE_KEY);      
    }
    this.selectedLanguage = this.languages.filter(x => x.key == this.systemLanguage)[0].value;

    this.displayVisitOurWebSite = !Utils.isNullOrUndefinedOrWhitespace(this.assetService.companyWebsite);
    this.showWalletTile = this.profileV4Service.isWalletEnabled;

    if(this.util.forceMode == "light" || this.util.forceMode == "dark") {
       this.showModeSwitch = false;      
    }
  }

  selectMode() {
    console.log('Mode Changes');
    this.enableDarkMode = !this.enableDarkMode;
    this.themeService.setAppTheme(this.enableDarkMode);    
  }

  async changeLanguage() 
  {    
    const actionSheet = await this.actionSheetController.create({
      header: this.translate.instant("Choose your language"),
      buttons: [
        {
          text: "🇺🇸  English",
          handler: () => {
            localStorage.setItem(Constants.APP_LANGUAGE_KEY, "en");
            this.updateAppLanguage();
          },
          cssClass:  this.systemLanguage == "en" ?  "tm-actionsheet-class-language" : ""
        },
        {
          text: "🇪🇸  Spanish",
          handler: () => {
            localStorage.setItem(Constants.APP_LANGUAGE_KEY, "es");
            this.updateAppLanguage();            
          },
          cssClass:  this.systemLanguage == "es" ?  "tm-actionsheet-class-language" : ""
        },
        {
          text: "🇫🇷  French",
          handler: () => {
            localStorage.setItem(Constants.APP_LANGUAGE_KEY, "fr");
            this.updateAppLanguage();            
          },
          cssClass: this.systemLanguage == "fr" ?  "tm-actionsheet-class-language" : ""
        }
      ],
    });
    
    this.closeMenu();
    await actionSheet.present();
  }

  updateAppLanguage(){
    if(!localStorage.getItem(Constants.APP_LANGUAGE_KEY)){
      localStorage.setItem(Constants.APP_LANGUAGE_KEY, config.DEFAULT_LANGUAGE);
      this.systemLanguage = config.DEFAULT_LANGUAGE;
    }
    else {
     this.systemLanguage = localStorage.getItem(Constants.APP_LANGUAGE_KEY);
    }
    this.translationService.setLanguage(this.systemLanguage);
    this.selectedLanguage = this.languages.filter(x => x.key == this.systemLanguage)[0].value;
    console.log(`Selected Language => ${this.selectedLanguage}`)
  }
  
  closeMenu() {
    this.menuCtrl.close();
  }

  goToProfile() {  
    this.closeMenu();
    this.router.navigateByUrl('/profile');
  }

  goToNotifications() {
    this.closeMenu();
    this.router.navigateByUrl('/notifications');
  }

  openWallet() {
    this.closeMenu();    
    this.router.navigateByUrl('/wallet');
  }

  goToRewards() {
    this.closeMenu();
    this.router.navigateByUrl('tabs/rewards-v4');
  }

  logout() {
    this.authService.logout(true);
  }

  menuWillOpen() {
    const ionContent = document.querySelector('ion-content');
    if (ionContent) {
      ionContent.style.pointerEvents = 'auto';
    }
    this.readProfileData();
    this.enableDarkMode = this.themeService.darkModeSelected$.value;
    console.log(this.enableDarkMode);       
  }

  private readProfileData() {
    this.profileV4Service.getProfile(<IGetProfileRequest>{ MerchantId: this.profileV4Service.MerchantId, LocationId: null, Channel: null }, true, true);
  }

  getTileAndMenuData(response: IGetProfileResponse) {
    if( Utils.isNullOrUndefined(response) ) {
    return;
  } 
    let data = this.profileV4Service.getTileAndMenuData();   
    this.menuData = data.menuData;
    this.tileData = data.tileData;  
  }
  
  visitOurWebsite() {
    this.util.openWithSystemBrowser(this.assetService.companyWebsite);
  }

  ion_menu_scroll_bug() {
    this.fix_bug = '1px';
    setTimeout(() => {
      const ionContent = document.querySelector('ion-content');
      ionContent.scrollToBottom();  // or any other scroll method
      console.log('scrolling');
    }, 300);
  }

  ion_menu_scroll_bug_fix() {
    this.fix_bug = '0px';
    
  }

  menuDidClose() {
    const content = document.querySelector('ion-content');
    if (content) {
      content.style.pointerEvents = 'auto';  // Re-enable mouse interaction
    }
  }
  
}
