import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IRewardUIModel } from 'src/app/models/yourReward';

@Component({
  selector: 'app-reward-details-explanation',
  templateUrl: './reward-details-explanation.component.html',
  styleUrls: ['./reward-details-explanation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RewardDetailsExplanationComponent implements OnInit {
  @Input() reward: IRewardUIModel = null;
  expanded: boolean = false;
  constructor() { }

  ngOnInit() {}

  seeMore() {
    this.expanded = true;
  }
  
  seeLess() {
    this.expanded = false;
  }
}
