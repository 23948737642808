import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { IEmailLogin } from 'src/app/models/emailLogin';
import { DecisionPopupComponent } from '../../decision-popup/decision-popup.component';
import { Utils } from 'src/app/common/Util';
import { Constants } from 'src/app/common/Constants';

@Component({
  selector: 'app-login-email-form',
  templateUrl: './login-email-form.component.html',
  styleUrls: ['./login-email-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginEmailFormComponent implements OnInit {

  @Output() verifyPassword: EventEmitter<IEmailLogin> = new EventEmitter<IEmailLogin>();
  showPassword: boolean = false;

  private _emailLogin: IEmailLogin = null;
  @Input()
  set emailLogin(value: IEmailLogin) {
    const needToNotifyLoginHasFailed: boolean = Utils.isTrue(value?.loginHasFailed);
    this._emailLogin = value;
    if( needToNotifyLoginHasFailed ) {
      this.showInvalidPassword();
    }
  }
  get emailLogin(): IEmailLogin {
    return this._emailLogin;
  }

  readonly mobileAppAllowsRegistrationByEmail: boolean = Utils.mobileAppAllowsRegistrationByEmail;

  form: FormGroup = this.formBuilder.group({
    email: new FormControl('', [Validators.email, Validators.pattern(Constants.EMAIL_REGEX), Validators.required]),
    password: new FormControl('', [Validators.required, Validators.maxLength(20)])
  });
  
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private modalCtrl: ModalController
  )
  { }

  ngOnInit() {
    this.form.patchValue(this.emailLogin);
  }

  toggleShow() {
    this.showPassword = !this.showPassword;
  }

  login() {
    if( this.form.valid ) {
      this.emailLogin = {
        ...this.form.value,
        loginHasFailed: false
      };
      this.verifyPassword.emit(this.emailLogin);
      this.form.reset();
    }
  }

  forgotPassword() {
    this.router.navigateByUrl('forgot-password', { state: { pageToGoBackTo: 'login-email'}, replaceUrl: true });
  }

  signUp() {
    this.router.navigateByUrl('on-board1', { replaceUrl: true })
  }
  
  loginByPhoneOnlyOrByEmailOnly() {
    this.router.navigateByUrl('on-board1', { replaceUrl: true })
  }

  async showInvalidPassword() {
    console.log(`LoginEmailFormComponent.showInvalidPassword() emailLogin=`, this.emailLogin)
    this.forceCloseModal();

    this.form.patchValue(this.emailLogin);
    const modal = await this.modalCtrl.create({
      component: DecisionPopupComponent,
      cssClass: 'tm-decision-modal',
      componentProps: {
        btnYesText: 'Got it, Thanks',
        title: 'Please try again',
        message: `Invalid email and password combination!<br/>Please try again.`
    }});

    modal.present();
  }

  private forceCloseModal() {
    console.log(`LoginEmailFormComponent.forceCloseModal()`)
    if( !Utils.isNullOrUndefined(this.modalCtrl ) ) {
      console.log(`LoginEmailFormComponent.forceCloseModal() will dismiss the modalCtrl`)
      this.modalCtrl.dismiss();
    }
  }
}

