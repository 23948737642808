import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonToggle, Platform } from '@ionic/angular';
import { IUserAccount } from 'src/app/models/userAcccount';
import { NotificationService } from 'src/app/services/notification.service';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent implements OnInit {
  
  @Input() userAccount: IUserAccount = null;
  @Input() displayBirthday: boolean = false;
  @Output() deleteAccount: EventEmitter<void> = new EventEmitter<void>();

  enabledPushNotifications: boolean = false;
  errorMessageDisplayed: boolean = false;
  public toggle = false;
  isDataLoaded: boolean = false;
  @ViewChild('pushSelect', { static: false }) pushSelect: IonToggle;

  constructor(
      private router: Router,
      private notificationService: NotificationService,
      private platForm: Platform,
      private popupService: PopupService,
      private cd: ChangeDetectorRef
    ) 
  {
    console.log('ProfileComponent.constructor()');
  }

  async ngOnInit() {
    console.log('ProfileComponent.ngOnInit()');
    if (this.platForm.is('cordova')) {
      console.log('CHECKING PUSH PERMISSIONS');
      this.enabledPushNotifications = this.notificationService.pushEnabled;      
    }
  }

  async ionViewWillEnter() {
    console.log('ProfileComponent.ionViewWillEnter() userAccount=', this.userAccount);
  }


  async updateNotification($event) {
    console.log(`Updating Push Notification Preference`);      
    this.notificationService.pushEnabled = $event.detail.checked;    
    if ($event.detail.checked == this.toggle) {
      this.notificationService.updateNotificationData($event.detail.checked);
      console.log("Prevent Executing Twice");
      return; 
    }
    else {
      this.toggle = $event.detail.checked;
      if (this.toggle) {
        this.errorMessageDisplayed = false;
        await this.notificationService.deviceHasPermission().then((permission) => { 
          console.log(`Device has Permission to receive Notification: ${permission}`);
          this.notificationService.pushEnabled = $event.detail.checked && permission;
          if(this.notificationService.pushEnabled){
            this.notificationService.registerForNotifications();
          }
          if(permission == false){
            if (!this.errorMessageDisplayed) {
              this.showPermissionError();       
            }    
          }             
        });          
      }       
      this.enabledPushNotifications = this.notificationService.pushEnabled; 
      if (!this.errorMessageDisplayed) {
        this.notificationService.updateNotificationData(this.enabledPushNotifications);  
      }     
      if (this.errorMessageDisplayed) {
        this.pushSelect.checked = false; 
      }       
    }    
  }

  public deleteUserAccount() {
    this.deleteAccount.emit();
  }

  toggleNotifications(val: boolean) {
    this.userAccount.enabledPushNotifications = val;
  }
  
  showPermissionError(){ 
    this.errorMessageDisplayed = true;
    this.popupService.confirmV4('Enable notification permissions for this application in your device settings to receive push notifications.', 'Permissions required', 'Ok', null);
  }

  goToEditProfile() {
    this.router.navigateByUrl('/profile-edit');
  }

  goToChangePassword() {
    this.router.navigateByUrl('/change-password');
  }
}
