import { Injectable } from '@angular/core';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { AlertService } from './alert.service';
import { ApplePay } from '@ionic-native/apple-pay/ngx';
import { EndPoint } from '../enums/endpoints';

@Injectable({
  providedIn: 'root'
})
export class ApplePayService {
  canMakeApplePaypayment$ = new BehaviorSubject<boolean>(false);
  canMakeApplePaypaymentOOViaV3$ = new BehaviorSubject<boolean>(false);
  applePayToken$ = new BehaviorSubject<any>(null);
  browser: InAppBrowser;
  external_browser_options: InAppBrowserOptions = {
    location : 'no',
    hidden : 'no',
    clearcache : 'yes',
    clearsessioncache : 'yes',
    zoom : 'yes',
    hardwareback : 'yes',
    mediaPlaybackRequiresUserAction : 'no',
    shouldPauseOnSuspend : 'no', 
    closebuttoncaption : 'Back', 
    disallowoverscroll : 'no', 
    toolbar : 'no',
    enableViewportScale : 'yes',
    allowInlineMediaPlayback : 'no', 
    presentationstyle : 'fullscreen',
    fullscreen : 'yes', 
    usewkwebview: 'no'
  }; 
  target = "_blank"; 
  
  constructor(private applePay: ApplePay, private apiService: ApiService, public alertService: AlertService, private iab: InAppBrowser) { }

  async checkIfApplepayAvailable() {        
    return await new Promise<any>((resolve, reject) => {
      this.applePay.canMakePayments().then((message) => {
        console.log(message);
        if (message) {    
          this.canMakeApplePaypayment$.next(true);      
          resolve(true);  
        }
        else {  
          console.log('Cannot make apple pay payments. No errors were generated.');      
          this.canMakeApplePaypayment$.next(false);    
          resolve(false);  
        }    
      },
      (error) => {
        console.log(error);    
        this.canMakeApplePaypayment$.next(false);        
        resolve(false)   
      });
    });
  }

  completeLastTransaction(message) {
    this.applePay.completeLastTransaction(message);            
  }

  public postApplePayTokenToV3(paymentObj: any, orderUrl: any, puid: any, applicationData: any): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      let iframe = document.getElementById('orderFrame') as HTMLIFrameElement   
      console.log("mobile app - posting message", { applepayToken: paymentObj, puid: puid, applicationData: applicationData});
      iframe.contentWindow.postMessage( { applepayToken: paymentObj, puid: puid, applicationData: applicationData }, orderUrl);
      resolve(true);
    });
  }
  
  public postApplePayTokenToOO(paymentObj: any, orderUrl: any, puid: any, applicationData: any): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      let iframe = document.getElementById('orderFrame') as HTMLIFrameElement   
      console.log("mobile app - posting message", { applepayWalletToken: paymentObj, puid: puid, applicationData: applicationData});
      iframe.contentWindow.postMessage( { applepayWalletToken: paymentObj, puid: puid, applicationData: applicationData }, orderUrl);
      resolve(true);
    });
  }

  public generateUUID() {
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }
  

}
