export enum EndPoint {
    USER_NOTIFICATION = '/api/CustomerV4/GetNotifications',
    USER_NOTIFICATION_UPDATE = '/api/CustomerV4/UpdateNotification',
    USER_NOTIFICATION_PREFERENCE = '/api/CustomerV4/UpdateNotificationPreference',
    USER_ACTIVITY = '/api/CustomerV4/GetActivity',
    USER_REWARDS = '/api/CustomerV4/GetRedeemablesV2',
    USER_SPOTLIGHT = '/api/CustomerV4/GetSpotlightData',
    USER_PROMOTIONS = '/api/CustomerV4/GetPromotions',
    USER_MEMBERSHIPS = '/api/CustomerV4/GetMemberships',
    USER_PUNCHCARD = '/api/CustomerV4/GetPunchCards',
    USER_WALLET_BALANCE = '/api/CustomerV4/GetWalletBalance',
    USER_WALLET_DATA = '/api/CustomerV4/GetWalletLoadData',
    USER_LOAD_WALLET = '/api/CustomerV4/loadFundsV4',
    USER_SAVE_CUSTOMER_CARD = '/api/CustomerV4/SaveCustomerCard',
    USER_DELETE_CARD = '/api/CustomerV4/DeleteCard',
    USER_SET_PHONE = '/api/CustomerV4/SetCustomerPhone',
    GET_COUNTRIES_ALPHA3 = '/api/CustomerV4/GetCountriesAlpha3',
    WAYS_TO_EARN = '/api/CustomerV4/GetWaysToEarn',
    FEEDBACK_SETTINGS = '/api/CustomerV4/GetFeedbackSettings',
    FEEDBACK_SAVE = '/api/CustomerV4/SaveFeedback',
    VERIFY_CODE_EMAIL = '/api/CustomerV4/EmailVerificationCode',
    VERIFY_CODE_PHONE = '/api/CustomerV4/PhoneVerificationCode',
    VERIFY_PHONE_NUMBER = '/api/CustomerV4/PhoneVerificationSend',
    VERIFY_EMAIL = '/api/CustomerV4/EmailVerificationSend',
    ONBOARD = '/api/CustomerV4/Onboard',
    LOGOUT = '/api/CustomerV4/Logout',
    REGISTER = '/api/CustomerV4/Register',
    FORGOT_PASSWORD = '/api/CustomerV4/ResetPasswordEmailSend',
    FORGOT_PASSWORD_CODE = '/api/CustomerV4/ResetPasswordCode',
    FORGOT_PASSWORD_NEW_PASSWORD = '/api/CustomerV4/ResetPasswordWithNewPassword',
    AUTH = '/api/CustomerV4/Auth',
    PROFILE = '/api/CustomerV4/GetProfile',
    EDIT_PROFILE = '/api/CustomerV4/UpdateProfile',
    DELETE_PROFILE = '/api/CustomerV4/DeleteCustomerProfile',
    UPDATE_PASSWORD = '/api/CustomerV4/UpdatePassword',
    AUTH_ONLINE_ORDER = '/api/CustomerV4/AuthOnlineOrder',
    STORE_INFO = '/api/CustomerV4/GetStoreInfo',
    STORE_LOCATIONS = '/api/CustomerV4/GetStoreLocations',
    MERCHANT_ASSETS = '/api/CustomerV4/GetMerchantAssets',
    CHECK_APP_VERSION = '/api/CustomerV4/CheckAppVersion',
    APP_PROFILE_SETTINGS = '/api/CustomerV4/GetAppSettings',
    GENERATE_TAG_ID = '/api/CustomerV4/GenerateTag',
    PRIVACY_POLICY_AMENDMENTS = '/api/CustomerV4/GetMerchantPrivacyPolicyAmendments', 
    GET_MERCHANT_LOCATIONS = '/api/CustomerV4/GetStoreLocations', 
}
