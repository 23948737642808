export class TileData {
    walletAmount: number;
    currencySymbol: string;
    pointsBalance?: string;

    constructor(walletAmount, currencySymbol, pointsBalance) 
    {
        this.currencySymbol = currencySymbol;
        this.walletAmount = walletAmount;
        this.pointsBalance = pointsBalance;
    }
}