import { Injectable } from '@angular/core';
import {ToastController} from "@ionic/angular";
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastCtrl: ToastController, private translate: TranslateService) { }

  async show(msg: string, duration: number = 2000) {
        const toast = await this.toastCtrl.create({
            message: this.translate.instant(msg),
            duration: duration,
            position: 'bottom'
        });
        return await toast.present();
  }


  async showTop(msg: string) {
    const toast = await this.toastCtrl.create({
        message: this.translate.instant(msg),
        duration: 2000,
        position: 'top'
    });
    return await toast.present();
}

  async showMiddle(msg: string) {
        const toast = await this.toastCtrl.create({
            message: this.translate.instant(msg),
            duration: 2000,
            position: 'middle'
        });
        return await toast.present();
  }

  async showCloseButton(msg: string) {
        const toast = await this.toastCtrl.create({
            message: this.translate.instant(msg)
        });
        return await toast.present();
  }
}
