import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Constants } from 'src/app/common/Constants';
import { Utils } from 'src/app/common/Util';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'app-on-boarding-carousel',
  templateUrl: './on-boarding-carousel.component.html',
  styleUrls: ['./on-boarding-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnBoardingCarouselComponent implements OnInit, AfterViewInit {

  @Input() pageIndex: number = 0;
  pageIndices: Array<number> = [0, 1, 2, 3];
  @ViewChild('swiper') swiper: SwiperComponent;

  static readonly SLIDE_CONTAINER_PERCENTAGE: number = 0.9; // this number should match the css .slide-image width: 90vw;
  swiper_config: SwiperOptions = Constants.ONBOARDING_SLIDES_SWIPER_CONFIGURATION;


  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {
    const width: number = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    const pictureWidth: number = OnBoardingCarouselComponent.SLIDE_CONTAINER_PERCENTAGE * width;
    this.swiper_config = {
      ...Constants.ONBOARDING_SLIDES_SWIPER_CONFIGURATION,
      spaceBetween: (width - pictureWidth)
    };
  }

  ngAfterViewInit() {
    // console.log(`OnBoardingCarouselComponent.ngAfterViewInit()`, ', pageIndex=', this.pageIndex);
    // this.cd.detectChanges();
  }

  onSlideChange() {
    //console.log(`OnBoardingCarouselComponent.onSlideChange() beginning`, ', pageIndex=', this.pageIndex);
    if( Utils.isNullOrUndefined(this.swiper) ) {
      return;
    }
    if( Utils.isNullOrUndefined(this.swiper?.swiperRef) ) {
      return;
    }
    this.pageIndex = this.swiper?.swiperRef.activeIndex % this.pageIndices.length;
    
    //console.log(`OnBoardingCarouselComponent.onSlideChange() 2 pageIndex=`, this.pageIndex, ` activeIndex=`, this.swiper?.swiperRef.activeIndex, ', realIndex=', this.swiper?.swiperRef.realIndex);
    this.cd.detectChanges();
  }

  selectSlide(pageIndex: number) {
    //console.log(`OnBoardingCarouselComponent.selectSlide() beginning pageIndex=`, pageIndex);
    this.swiper?.swiperRef.slideTo(pageIndex);
    //console.log(`OnBoardingCarouselComponent.selectSlide() 2 activeIndex=`, this.swiper?.swiperRef.activeIndex, ', realIndex=', this.swiper?.swiperRef.realIndex);
    this.pageIndex = this.swiper?.swiperRef.activeIndex % this.pageIndices.length;
  }
  
}
