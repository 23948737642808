import { Injectable } from '@angular/core';
import {LoadingController} from "@ionic/angular";
import {SpinnerTypes} from '@ionic/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { EndPoint } from '../enums/endpoints';
import { Utils } from '../common/Util';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  loaderInst = [];
  loader_num = 0;
  loadingSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingMap: Map<string, boolean> = new Map<string, boolean>();

  constructor(private loadingCtrl: LoadingController, private spinner: NgxSpinnerService) { }

  /**
   * Sets the loadingSub property value based on the following:
   * - If loading is true, add the provided url to the loadingMap with a true value, set loadingSub value to true
   * - If loading is false, remove the loadingMap entry and only when the map is empty will we set loadingSub to false
   * This pattern ensures if there are multiple requests awaiting completion, we don't set loading to false before
   * other requests have completed. At the moment, this function is only called from the @link{HttpRequestInterceptor}
   * @param loading {boolean}
   * @param url {string}
   */
  setLoading(loading: boolean, url: string): void {
    if (Utils.isNullOrUndefinedOrWhitespace(url)) {
      throw new Error('Invalid request URL');
    }

    if (
      url.includes(EndPoint.USER_LOAD_WALLET) || 
      url.includes(EndPoint.USER_NOTIFICATION_PREFERENCE) ||
      url.includes(EndPoint.CHECK_APP_VERSION)
    ) {
      return;
    }

    if (loading) {
      if( this.loadingMap.size === 0 || this.loadingSub.value === false ) {
        this.spinner.show();
      }
      this.loadingMap.set(url, loading);
      this.loadingSub.next(true);
    } else if (loading === false && this.loadingMap.has(url)) {
      this.loadingMap.delete(url);
    }
    
    if (this.loadingMap.size === 0) {
      this.loadingSub.next(false);
      this.spinner.hide();
    }
  }


  async create(type: SpinnerTypes = 'bubbles') {
        const loader = await this.loadingCtrl.create({
            message: 'Please wait...',
            spinner: type,
            duration: 5000,
            showBackdrop: true,
        });
        return loader;
  }


  /* For loader not to be dissmissed with fixed time */
  async create_v5(message: string, type: SpinnerTypes = 'bubbles') {
    const loader = await this.loadingCtrl.create({
        spinner: type,
        message: message,
        showBackdrop: false,
    });
    return loader;
  }

  /* For loader not to be dissmissed with fixed time with custom css */
  async create_v6(message: string, type: SpinnerTypes = 'bubbles', css: string) {
      const loader = await this.loadingCtrl.create({
          spinner: type,
          message: message,
          showBackdrop: false,
          cssClass: css
      });
      this.storeLoaders(loader);
      return loader;
  }

  async dismissLoader() {
    return this.closeAllLoaders();
  }

  async presentWithOptions() {
        const loader = await this.loadingCtrl.create({
            spinner: 'bubbles',
            duration: 5000,
            message: 'Please wait...',
            translucent: true,
            cssClass: 'custom-class custom-loading'
        });
        return loader;
  }

  storeLoaders(x)
  {
        this.loaderInst[this.loader_num] = x;
        this.loader_num++;
  }

  closeAllLoaders()
  {
    for(var i=0; i< this.loaderInst.length; i++)
    {
      this.loaderInst[i].dismiss();
    }
  }

}
