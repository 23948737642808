import { config } from 'src/config';
import { AuthenticationToken } from '../models/authenticationToken';
import { IMapRestriction } from '../models/mapRestriction';
import { ISoreLocationsMapInformation } from '../models/storeLocationsMapInformation';
import { SwiperOptions } from 'swiper';
import { IUserAccount } from '../models/userAcccount';

export class Constants {
  constructor() {
  }


  public static get ALL_SUPPORTED_LANGUAGES() {
    return [
      {key: 'en', value: 'English (US)'},
      {key: 'es', value: 'Española'},
      {key: 'fr', value: 'Francés'},
    ];
  }

  public static get DEFAULT_MAP_ZOOM(): number  { return 11; }

  public static get DEFAULT_MAP_STYLE(): Array<any> {
    return [
        {
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5',
            },
          ],
        },
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161',
            },
          ],
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#f5f5f5',
            },
          ],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#bdbdbd',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [
            {
              color: '#eeeeee',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              color: '#e5e5e5',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              color: '#ffffff',
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [
            {
              color: '#dadada',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161',
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e',
            },
          ],
        },
        {
          featureType: 'transit.line',
          elementType: 'geometry',
          stylers: [
            {
              color: '#e5e5e5',
            },
          ],
        },
        {
          featureType: 'transit.station',
          elementType: 'geometry',
          stylers: [
            {
              color: '#eeeeee',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#c9c9c9',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e',
            },
          ],
        },
      ];
  }

  public static get DEFAULT_MAP_RESTRICTION(): IMapRestriction {
    // Default Map restricted to USA
    return <IMapRestriction>{
        latLngBounds: {
          north: 49.5904,
          south: 24.9493,
          west: -125.0011,
          east: -66.9326
        },
        strictBounds: true
      };
  }
  public static get DEFAULT_STORE_LOCATIONS_MAP_INFORMATION(): ISoreLocationsMapInformation {
    return <ISoreLocationsMapInformation>{
      storeLocations: [],
      restriction: Constants.DEFAULT_MAP_RESTRICTION,
      mapPinIconUrl: {
        url: '../../../../assets/svg_icon/google-pin.svg',
        scaledSize: {
          height: 40, 
          width: 40
        }
      },
      zoom: Constants.DEFAULT_MAP_ZOOM,
      styles: Constants.DEFAULT_MAP_STYLE,
      latitude: 0,
      longitude: 0,
      distanceUnit: 'K',      
      facebookIconUrl: '../../../assets/svg_icon/facebook-share.svg',
      twitterIconUrl: '../../../assets/svg_icon/twitter-share.svg',
      instagramIconUrl: '../../../assets/svg_icon/instagram-share.svg'
    };
  }
  public static get EMPTY_USER_ACCOUNT(): IUserAccount {
    return {
      id: null,
      fullName: null,
      phone: null,
      email: null,
      password: null,
      dateOfBirth: null,
      formattedDOBFromServerSide: null,
      formattedDateOfBirth: null,
      preferredLocation: null,
      smsConsent: false,
      termsConsent: false,
      enabledPushNotifications: false
    };
  }

  public static get SWIPER_CONFIGURATION(): SwiperOptions {
    return <SwiperOptions>{
      slidesPerView: 1,
      spaceBetween: 15,
      pagination: { clickable: false },
      allowTouchMove: true,
      centeredSlides: false,
      centeredSlidesBounds: false,
      centerInsufficientSlides: false,
      touchReleaseOnEdges: false,
      loop: false,
      width: 300,
      breakpoints: {
        375: {
            width: 300
        },
        390: {
          slidesPerView: 1,
          width: 320
        }
      }
    };
  }

  public static get ONBOARDING_SLIDES_SWIPER_CONFIGURATION(): SwiperOptions {
    return <SwiperOptions>{
      slidesPerView: 'auto',
      spaceBetween: 20,
      pagination: { clickable: false },
      allowTouchMove: true,
      centeredSlides: true,
      centeredSlidesBounds: true,
      centerInsufficientSlides: false,
      touchReleaseOnEdges: false,
      loop: true
    };
  }

  public static get SPOTLIGHT_SWIPER_CONFIGURATION(): SwiperOptions {
    return <SwiperOptions>{
      slidesPerView: 'auto',
      spaceBetween: 20,
      pagination: { clickable: false },
      allowTouchMove: true,
      centeredSlides: true,
      centeredSlidesBounds: true,
      centerInsufficientSlides: true,
      touchReleaseOnEdges: false,
      loop: true
    };
  }

  public static get DEFAULT_PROMOTION_SLIDE_IMAGE_URL(): string {
    return 'assets/images/tm-promo-default-02.jpg';
  }

  public static get DISCOUNT_TYPES(): Array<string> {
    return ['AMOUNT','BOGO','FREE_DELIVERY','PERCENTAGE'];
  }
  public static get DISCOUNT_TYPE_ICON_NAMES(): Array<string> {
    return ['dollar','bogo','delivery truck','percent'];
  }

  public static get GENERIC_REWARD_ICON_NAME(): string {
    return 'coupon ticket.svg';
  }

  public static get MOCK_EMAIL_ADDRESS(): string {
    return 'applereview@tapmango.com';
  }

  public static get NOTIFICATIONS_LIMIT(): number {
    return 20;
  }

  public static get UNVERIFIED_PHONE(): string {
    return 'unverifiedPhone';
  }

  public static get UNVERIFIED_EMAIL(): string {
    return 'unverifiedEmail';
  }

  public static get FORGOT_PASSSWORD(): string {
    return 'forgotPassword';
  }
  public static get ERROR_TYPE_TIMEOUT(): string {
    return 'TIMEOUT';
  }
  public static get ERROR_TYPE_404(): string {
    return '404';
  }
  
  public static get PAYMENT_FORM_URL_MAP(): Map<string, string> {
    return new Map<string, string>([
      ['Square', 'square-card'],
      ['Stripe', 'stripe-card'],
      ['Clover', 'clover-card'],
      ['CloverEcom', 'clover-ecom-card'],
      ['Toast', 'toast-card']
    ]);
  }


  public static get IN_STORE_CHANNELS(): Array<string> {
    return ['MANUAL', 'POS', 'OTHER'];
  }

  public static get MOBILE_ORDERING(): string {
    return 'MOBILEORDERING';
  }

  public static get EMAIL_REGEX(): string {
    return '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';//'^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$';
  }

  /* BEGIN Rewards Slider */
  static SPACER_WIDTH: number = 0.3;
  static SMALL_SPACER_WIDTH: number = 0.15;
  static PADDING_LEFT_AND_RIGHT: number = 6;
  static NUMBER_OF_BARS = 10;
  /* END Rewards Slider */

  /* BEGIN local storage key names */
  public static get AUTHENTICATION_TOKEN_KEY(): string {
    return 'authentication_token';
  }
  public static get CUSTOMER_ID_KEY(): string {
    return 'customerId';
  }
  public static get NOTIFICATIONS_KEY(): string {
    return 'notifications';
  }
  public static get PROMOTIONS_KEY(): string {
    return 'promotions';
  }
  public static get MEMBERSHIPS_KEY(): string {
    return 'memberships';
  }
  public static get PUNCHCARDS_KEY(): string {
    return 'punchcards';
  }
  public static get ACTIVITIES_KEY(): string {
    return 'activities';
  }
  public static get PROFILE_DATA_KEY(): string {
    return 'profile_data';
  }
  public static get REWARDS_KEY(): string {
    return 'rewards';
  }
  public static get SPOTLIGHT_KEY(): string {
    return 'spotlight';
  }
  public static get WALLET_BALANCE_KEY(): string {
    return 'walletBalance';
  }
  public static get WALLET_LOAD_DATA_KEY(): string {
    return 'walletLoadData';
  }
  public static get WAYS_TO_EARN_KEY(): string {
    return 'ways_to_earn';
  }
  public static get FEEDBACK_SETTINGS_KEY(): string {
    return 'feedback_settings';
  }
  public static get STORE_INFO_KEY(): string {
    return `${config.MERCHANT_GUID}_store_info`;
  }
  public static get LAST_FETCHED_STORE_INFO_DATA_KEY(): string {
    return `lastfetchedStoreData`;
  }
  public static get DISTANCE_UNIT_KEY(): string {
    return `distance_unit`;
  }
  public static get VERIICATION_TYPE_KEY(): string {
    return 'verificationType';
  }
  public static get VERIFICATION_CUSTOMER_ID_KEY(): string {
    return 'verification_customerId';
  }
  public static get VERIFICATION_PHONE_KEY(): string {
    return 'verification_phone';
  }
  public static get VERIFICATION_EMAIL_KEY(): string {
    return 'verification_email';
  }
  public static get APP_LANGUAGE_KEY(): string {
    return 'appLanguage';
  }
  public static get DEVICE_ID_KEY(): string {
    return 'device_id';
  }
  public static get MEMBERSHIP_ID_KEY(): string {
    return 'membershipId';
  }
  public static get LAST_FETCHED_MERCHANT_ASSET_DATA_KEY(): string {
    return 'lastfetchedMerchantAssetData';
  }
  public static get CUSTOM_DEFAULT_PROMOTION_IMAGE_URL_KEY(): string {
    return 'customDefaultPromotionImageURL';
  }
  /* END local storage key names */
}