import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Utils } from 'src/app/common/Util';
import { AuthV4Service } from 'src/app/services/auth-v4.service';
import { config } from 'src/config';
import { BaseComponent } from '../../base/base.component';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Constants } from 'src/app/common/Constants';

@Component({
  selector: 'app-on-board1-form',
  templateUrl: './on-board1-form.component.html',
  styleUrls: ['./on-board1-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnBoard1FormComponent extends BaseComponent implements OnInit {

  @Output() verify: EventEmitter<string> = new EventEmitter<string>();

  phoneMinLength: number = Utils.maskAwarePhoneLength;
  phoneMaxLength: number = Utils.maskAwarePhoneLength;
  phonePlaceHolder: string = Utils.getPhoneMask().phonePlaceholder;
  companyEmail: string = null;

  readonly mobileAppAllowsRegistrationByEmail: boolean = Utils.mobileAppAllowsRegistrationByEmail;

  form: FormGroup = null;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authV4Service: AuthV4Service
  ) {
    super();
  }
  
  ngOnInit() {
    console.log( Date.now(), `OnBoard1FormComponent.ngOnInit()`)
    if(this.authV4Service.isLoggedIn) 
    {
      console.log( Date.now(), 'OnBoard1FormComponent.ngOnInit() isLoggedIn=', this.authV4Service.isLoggedIn);
      console.log( Date.now(), `OnBoard1FormComponent.ngOnInit() will navigate to home page`)
      this.router.navigate(['/tabs/home'], { replaceUrl: true });
      console.log( Date.now(), `OnBoard1FormComponent.ngOnInit() has navigated to home page`)
    }

    this.companyEmail = this.authV4Service.getCompanyEmail();
    
    this.createForm();

    if( !Utils.mobileAppAllowsRegistrationByEmail ) {
      // format the phone number as you type
      this.form.get('phone').valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(value => this.form.get('phone').setValue(Utils.formatPhoneNumber(value)));
    }
  }

  createForm() {
    if( Utils.isNullOrUndefined(this.form) ) {
      this.form = Utils.mobileAppAllowsRegistrationByEmail ?
        this.formBuilder.group({
          email: new FormControl('', [Validators.email, Validators.pattern(Constants.EMAIL_REGEX), Validators.required])
        }) :
        this.formBuilder.group({
          phone: new FormControl('', [Validators.minLength(this.phoneMinLength), Validators.maxLength(this.phoneMaxLength), Validators.required])
        });
    }
  }

  onVerify() {
    if( this.form.valid ) {
      const phoneOrEmail: string = Utils.mobileAppAllowsRegistrationByEmail ? this.form.get('email').value : this.form.get('phone').value;
      this.verify.emit(phoneOrEmail);
    }
  }

  goToLoginByEmail() {
    this.router.navigateByUrl('login-email', { replaceUrl: true })
  }

}
