import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ICarouselSlide } from 'src/app/models/carouselSlide';
import { AssetService } from 'src/app/services/asset.service';

@Component({
  selector: 'app-promotion-details-card',
  templateUrl: './promotion-details-card.component.html',
  styleUrls: ['./promotion-details-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromotionDetailsCardComponent implements OnInit {
  @Input() carouselSlide: ICarouselSlide = null;
  constructor(public assetService: AssetService) { }

  ngOnInit() {
    console.log('PromotionDetailsCardComponent.ngOnInit() carouselSlide=', this.carouselSlide);
  }
}
