import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IHowToEarn } from 'src/app/models/response/getWaysToEarnResponse';

@Component({
  selector: 'app-birthday-offer-explanation',
  templateUrl: './birthday-offer-explanation.component.html',
  styleUrls: ['./birthday-offer-explanation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BirthdayOfferExplanationComponent implements OnInit {
  
  @Input() howToEarn: IHowToEarn = null;

  constructor() { }

  ngOnInit() {}

}
