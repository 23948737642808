import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ISoreLocationsMapInformation } from 'src/app/models/storeLocationsMapInformation';

@Component({
  selector: 'app-store-info-card',
  templateUrl: './store-info-card.component.html',
  styleUrls: ['./store-info-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoreInfoCardComponent implements OnInit {

  @Input() storeLocationsMapInfo: ISoreLocationsMapInformation = null;
  
  constructor() { }

  ngOnInit() {
  }

}
