import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Constants } from 'src/app/common/Constants';
import { Utils } from 'src/app/common/Util';
import { IProgressBarStep } from 'src/app/models/progressbarStep';
import { IPoint, IRewardsSliderData } from 'src/app/models/rewardsSliderData';

@Component({
  selector: 'app-rewards-slider',
  templateUrl: './rewards-slider.component.html',
  styleUrls: ['./rewards-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RewardsSliderComponent implements OnInit, OnChanges {
  @Input() rewardsSliderData: IRewardsSliderData;
  @Input() widthPercentage: number;
  
  totalWidth: number = 0;
  numberOfBars: number = 0;
  barWidth: number = 0;
  
  numberOfCompletedBars: number = 0; // can be a rational number i.e. 3.15
  noOfFullyCompletedBars: number = 0; // it is an integer i.e. 3
  partiallyCompletedBarIndex = -1;
  backgroundColorForPartiallyCompletedBar = null;

  pointsToNextRewardsLevel: number = 0;
  styleNextReward: any = null;
  styleNextRewardText: any = null;
  nextRewardsLevelPaddingLeft: number = 0;

  bars: Array<IProgressBarStep> = [];

  numberOfPointsPerBar: number = 0;
  points: Array<IPoint> = [];
  primaryColor: string = getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary'); ;


  availableNextRewardLevels: boolean = true;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    console.log('RewardsSliderComponent.ngOnInit() rewardsSliderData=', Utils.debugGetSafeJSON(this.rewardsSliderData));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if( !Utils.isNullOrUndefined(changes['rewardsSliderData'] ) ) {
      if( !Utils.isNullOrUndefined(this.rewardsSliderData)) {
        console.log('RewardsSliderComponent.ngOnChanges() changes=', changes);
        this.initialize();
        this.calculate();
      }
    }
  }

  private initialize() {
    this.totalWidth = 0;
    this.numberOfBars = 0;
    this.barWidth = 0;
    
    this.numberOfCompletedBars = 0; // can be a rational number i.e. 3.15
    this.noOfFullyCompletedBars = 0; // it is an integer i.e. 3
    this.partiallyCompletedBarIndex = -1;
    this.backgroundColorForPartiallyCompletedBar = null;

    this.pointsToNextRewardsLevel = 0;
    this.styleNextReward = null;
    this.styleNextRewardText = null;
    this.nextRewardsLevelPaddingLeft = 0;

    this.bars = [];

    this.numberOfPointsPerBar = 0;
    this.points = [];
  }
 
  private calculate() {
    this.availableNextRewardLevels = Utils.isFirstArrayElementValid(this.rewardsSliderData?.nextRewardLevels);

    this.calculateTotalWidth();
    this.calculateBarWidth();

    if( !Utils.isNullOrUndefined(this.rewardsSliderData) ) {
      this.calculateNumberOfPointsPerBar();
      this.calculateCompletedSteps();

      this.calculateNextLevelRewards();
      this.calculateNumberOfPoints();

      this.createBars();
      this.cdr.detectChanges();
    }
  }

  get totalNumberOfPoints(): number {
    return this.availableNextRewardLevels ?
           this.rewardsSliderData.nextRewardLevels[this.rewardsSliderData.nextRewardLevels.length - 1] :
           this.rewardsSliderData.currentNumberOfPoints;
  }

  get nextLevelRewards(): number {
    return this.availableNextRewardLevels ?
           this.rewardsSliderData.nextRewardLevels[0] :
           this.rewardsSliderData.currentNumberOfPoints;
  }

  getRewardLevel(index: number): number | null {
    let result: number | null = null;
    if( index <= this.rewardsSliderData.nextRewardLevels.length - 1 ) {
      result = this.rewardsSliderData.nextRewardLevels[index];
    }
    return result;
  }

  getPointsToDisplay(index: number, displayed: boolean): number | null {
    let result: number | null = null;
    if( index <= this.rewardsSliderData.nextRewardLevels.length - 1 ) {
      if( index === 0 || !displayed || index === this.rewardsSliderData.nextRewardLevels.length - 1 ) {
        result = this.rewardsSliderData.nextRewardLevels[index];
      } else {
        result = this.rewardsSliderData.nextRewardLevels[index];
        for( let pointsIndex = this.points.length - 1; pointsIndex >= 0; pointsIndex-- ) {
          if( this.points[pointsIndex].displayed ) {
            break;
          } else {
            result = this.points[pointsIndex].points;
          }
        }
      }
    }
    return result;
  }

  private calculateTotalWidth() {
    this.totalWidth = (window.innerWidth / 10) * this.widthPercentage / 100 - Constants.PADDING_LEFT_AND_RIGHT;
  }

  private calculateBarWidth() {
    this.barWidth = (this.totalWidth / Constants.NUMBER_OF_BARS) - Constants.SPACER_WIDTH;
  }
  
  private calculateNumberOfPointsPerBar() {
    this.numberOfPointsPerBar = this.totalNumberOfPoints / Constants.NUMBER_OF_BARS;
  }
  
  /*
  Decrease the points font size if there are at least 2 rewards level with 4 or more digits based on screen size.
  Decrease the points font size if there are at least 2 rewards level with 3 or more digits and the number of points between them is lower than numberOfPointsPerBar.
  */
  private get needSmallerFontSize(): boolean {
    return !this.fourDigitsRewardsCanFit || !this.threeDigitsRewardsCanFit;
  }

  private get fourDigitsRewardsCanFit(): boolean {
    return this.rewardsSliderData.nextRewardLevels.filter( e => e >= 1000 ).length <= 1;
  }

  private get threeDigitsRewardsCanFit(): boolean {
    var result = true;
    const minBarWidth: number = Utils.minWidthForRewardlevel(100) + 1;
    var threeDigitsRewards = this.rewardsSliderData.nextRewardLevels.filter( e => e >= 100 );
    if( threeDigitsRewards.length >= 2 && this.barWidth <= minBarWidth ) {
      for( var index = 1; index < threeDigitsRewards.length; ++index ) {
        if( threeDigitsRewards[index] - threeDigitsRewards[index-1] < this.numberOfPointsPerBar ) {
          result = false;
          break;
        }
      }
    }
    return result;
  }

  private get calculateSmallFontSize(): string {
    return window.innerWidth < 390 ?
           '.7rem' :
           '.8rem';
  }
  
  private calculateCompletedSteps() {
    this.numberOfCompletedBars = this.rewardsSliderData.currentNumberOfPoints / ( this.numberOfPointsPerBar === 0 ? 1 : this.numberOfPointsPerBar);
    
    this.noOfFullyCompletedBars = Math.trunc(this.numberOfCompletedBars);

    if( this.noOfFullyCompletedBars < this.numberOfCompletedBars ) {
      this.partiallyCompletedBarIndex = this.noOfFullyCompletedBars;

      let bluePercentage = this.numberOfCompletedBars * 100 - this.noOfFullyCompletedBars * 100;
      
      this.backgroundColorForPartiallyCompletedBar = `linear-gradient(to right, ${this.primaryColor} ` + bluePercentage + `%, #D5D9E7 ` + bluePercentage + `%, #D5D9E7 ` + (100 - bluePercentage) + `%)`;
    }
  }

  private createBars() {
    for( let index = 0; index < Constants.NUMBER_OF_BARS; ++index ) {
      this.bars.push({
        isCompleted: false,
        isPartiallyCompleted: false,
        class: this.getProgressBarStepClasses(index),
        style: index === this.partiallyCompletedBarIndex ?
              {'background': this.backgroundColorForPartiallyCompletedBar, 'width': this.barWidth + 'rem'} :
              {'width': this.barWidth + 'rem'}
      });
    }
  }

  private calculateNextLevelRewards() {

    this.pointsToNextRewardsLevel = this.nextLevelRewards - this.rewardsSliderData.currentNumberOfPoints;
    
    const numberOfCompletedBars: number = this.nextLevelRewards / ( this.numberOfPointsPerBar === 0 ? 1 : this.numberOfPointsPerBar );
    const noOfFullyCompletedBars: number = Math.trunc(numberOfCompletedBars);

    let blueWidth: number = 0;
    if( noOfFullyCompletedBars < numberOfCompletedBars ) {
      const bluePercentage: number = numberOfCompletedBars * 100 - noOfFullyCompletedBars * 100;
      // barWidth  ... 100
      // blueWidth ... bluePercentage
      blueWidth = this.barWidth * bluePercentage / 100;      
    }
    
    const paddingLeft: number = Constants.SMALL_SPACER_WIDTH + (this.barWidth + Constants.SPACER_WIDTH) * noOfFullyCompletedBars + blueWidth;
    this.nextRewardsLevelPaddingLeft = Math.min(paddingLeft, this.totalWidth - Constants.SPACER_WIDTH);

    this.styleNextReward = {'padding-left': this.nextRewardsLevelPaddingLeft + 'rem'};

    // the Next Rewards Level text needs to move right if it is too close to the left end; it needs to move left if it is too close to the right end
    this.styleNextRewardText = this.nextLevelRewards < this.numberOfPointsPerBar ?
                                {'margin-left': '75%' } :
                                this.nextLevelRewards + this.numberOfPointsPerBar > this.totalNumberOfPoints ?
                                {'margin-right': '75%' } :
                                {};
  }

  private calculateNumberOfPoints() {
    if( this.rewardsSliderData.currentNumberOfPoints === 0 && this.totalNumberOfPoints === 0 ) {
      return;
    }
    const needLowerFontSize: boolean = this.needSmallerFontSize;
    const smallFontSize: string = this.calculateSmallFontSize;

    let widthPercentage: number = this.nextLevelRewards * 100 / ( this.totalNumberOfPoints === 0 ? 1 : this.totalNumberOfPoints );
    // TOTAL_WIDTH ... 100 %
    // widthStart ... widthStartPercentage %
    let width: number = widthPercentage * this.totalWidth / 100 - Constants.SPACER_WIDTH;
    let style: any = needLowerFontSize ? {'width': `${width}rem`, 'padding-left': `${Constants.SMALL_SPACER_WIDTH}rem`, 'font-size': smallFontSize}: {'width': `${width}rem`, 'padding-left': `${Constants.SMALL_SPACER_WIDTH}rem`};

    const start: IPoint = {
      points: 0,
      width: width,
      style: style,

      pointsToDisplay: 0,
      displayed: true
    };
    this.points.push(start);
    let leftWidth: number = width;

    if( !this.availableNextRewardLevels && this.totalNumberOfPoints > 0 ) {
      // draw only the total number of points. Temporary add the total number of points to the empty array.
      this.rewardsSliderData.nextRewardLevels.push(this.totalNumberOfPoints);
    }
    this.rewardsSliderData.nextRewardLevels.forEach((rewardLevel: number, index: number) => {
      leftWidth = this.calculatePointStyle(index, leftWidth, needLowerFontSize, smallFontSize);      
    });
    if( !this.availableNextRewardLevels ) {
      // remove the total number of points
      this.rewardsSliderData.nextRewardLevels.pop();
    }
    console.log('calculateNumberOfPoints() points=', this.points)
  }

  private calculatePointStyle(index: number, leftWidth: number, needLowerFontSize: boolean, smallFontSize: string): number {
    let style: any;
    let width: number = 0;
    let rewardLevel: number = this.getRewardLevel(index);
    let doesntFit: boolean = false;
    let displayed: boolean = true;

    if( index === this.rewardsSliderData.nextRewardLevels.length - 1 ) {
      style = needLowerFontSize ?
      { 'padding-left': `${Constants.SMALL_SPACER_WIDTH}rem`, 'font-size': smallFontSize } :
      { 'padding-left': `${Constants.SMALL_SPACER_WIDTH}rem` };
    }
    else {
      const previousLevelDidntFit: boolean = index > 0 && Utils.isTrue(this.points[index].doesntFit);
      displayed = !previousLevelDidntFit;

      const next: number = this.getRewardLevel(index + 1);
      const widthPercentage: number = next * 100 / this.totalNumberOfPoints;
      // TOTAL_WIDTH ... 100 %
      // widthStart ... widthStartPercentage %
      width = widthPercentage * this.totalWidth / 100;
      width = width - leftWidth;

      let styleWidth: number = width;
      
      // if there is not enough space to write this rewards level, then push it under the other reward levels so it has enough space
      const minWidth: number = Utils.minWidthForRewardlevel(rewardLevel);

      if (width < minWidth) {
        styleWidth = minWidth;
        doesntFit = true;
      }
      displayed = !doesntFit;
      style = needLowerFontSize ?
        { 'width': `${styleWidth}rem`, 'padding-left': `${Constants.SMALL_SPACER_WIDTH}rem`, 'font-size': smallFontSize } :
        { 'width': `${styleWidth}rem`, 'padding-left': `${Constants.SMALL_SPACER_WIDTH}rem` };
    }

    this.points.push({
      points: rewardLevel,
      width,
      style,
      doesntFit: doesntFit,

      displayed,
      pointsToDisplay: this.getPointsToDisplay(index, displayed)
    });

    leftWidth += width;
    return leftWidth;
  }


  private getProgressBarStepClasses(index: number): string {
    let result: string = `progress-bar-step`;
    if( index === 0 ) {
      result = `${result} left-progress-bar-step margin-right-03`;
    } else if( index === Constants.NUMBER_OF_BARS - 1 ) {
      result = `${result} right-progress-bar-step`;
    } else {
      result = `${result} margin-right-03`;
    }

    if( this.rewardsSliderData.currentNumberOfPoints <= 0 ) {
      result = `${result} not-completed`;
    } else if( index <= this.noOfFullyCompletedBars ) {
      result = `${result} completed`;
    } else if( index <= this.partiallyCompletedBarIndex ) {
      result = `${result} completed`;
    } else {
      result = `${result} not-completed`;
    }

    return result;
  }
}
