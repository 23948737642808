import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dayFormat'
})
export class DayFormatPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    var days = [
        "1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th",  
        "11th", "12th", "13th", "14th", "15th", "16th", "17th", "18th", "19th", "20th", 
        "21st", "22nd", "23rd", "24th", "25th", "26th", "27th", "28th", "29th", "30th", "31st"]
    var newValue = days[value - 1];
    return newValue;
  }
}