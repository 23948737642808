import { ILocation } from "./location";

export interface IUserAccount {
    id: string;
    fullName: string;
    phone: string;
    email: string;
    password: string;
    dateOfBirth: Date;
    preferredLocation: ILocation;
    smsConsent: boolean;
    termsConsent: boolean;

    formattedDOBFromServerSide: string;
    formattedDateOfBirth: string;

    enabledPushNotifications: boolean;

    Data1?: string | Date | boolean,
    Data2?: string | Date | boolean,
    Data3?: string | Date | boolean,
    Data4?: string | Date | boolean,
    Data5?: string | Date | boolean
}

export const EMPTY_USER_ACCOUNT: IUserAccount = {
    id: null,
    fullName: null,
    phone: null,
    email: null,
    password: null,
    dateOfBirth: null,
    preferredLocation: null,
    smsConsent: null,
    termsConsent: null,

    formattedDOBFromServerSide: null,
    formattedDateOfBirth: null,

    enabledPushNotifications: null,

    Data1: null,
    Data2: null,
    Data3: null,
    Data4: null,
    Data5: null
};
