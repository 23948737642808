import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AssetService } from '../../../services/asset.service';
import { GetWalletLoadDataResponse } from '../../../models/wallet';
import { WalletService } from '../../../services/wallet.service';
import { AlertService } from '../../../services/alert.service';
import { UtilService } from '../../../services/util.service';
import { Router } from '@angular/router';
import { LoadingOptions } from '../../popup-model/popup-model.component';
import { PopupService } from '../../../services/popup.service';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { ThemeService } from 'src/app/services/theme.service';
import { Utils } from 'src/app/common/Util';
import { BaseComponent } from '../../base/base.component';
import { takeUntil } from 'rxjs/operators';
import { WalletV4Service } from 'src/app/services/wallet-v4.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-stripe-card-info',
  templateUrl: './stripe-card-info.component.html',
  styleUrls: ['./stripe-card-info.component.scss'],
  providers: [Keyboard]
})
export class StripeCardInfoComponent extends BaseComponent implements OnInit {

  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  @ViewChild('backBtn') backBtn: ElementRef<HTMLElement>;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    },
    hidePostalCode: true,
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'auto'
  };

  walletLoadData: GetWalletLoadDataResponse;

  stripe: FormGroup;
  saveCardDetail: FormControl;
  loadWalletAmount: number = 0.0;
  currencySymbol: string = "$";
  canSaveCreditCard: boolean = true;

  keyboardOpened: boolean = false;

  constructor(private fb: FormBuilder,
    private router: Router,
    private util: UtilService,
    private walletV4Service: WalletV4Service,
    private walletService: WalletService, // COSMIN this was kept from backward compatibility reasons to avaoid changing the payment forms
    private assetService: AssetService,
    private translate: TranslateService,
    private stripeService: StripeService,
    private elementRef: ElementRef,
    public popupService: PopupService,
    public keyboard: Keyboard,
    public themeService: ThemeService
  ) {
    super();

    this.saveCardDetail = new FormControl(false);
    this.stripeService.changeKey(this.walletService.stripePublicKey);
    this.stripe = this.fb.group({
      name: ['', [Validators.required]],
      saveCardDetail: [false],
    });    
  }

  async ngOnInit() {

  }

  ionViewDidEnter() {
  }

  ionViewDidLeave() {
    this.stripe.reset();
    this.elementRef.nativeElement.remove();
  }

  async ionViewWillEnter() {

    this.listenKeyboardevents();

    this.currencySymbol = this.assetService.currencySymbol;
    this.loadWalletAmount = this.walletService.reloadAmount;


    await this.walletService.getWalletLoadData().then(async (result: GetWalletLoadDataResponse) => {
      if (result) {
        this.walletLoadData = result;
        this.canSaveCreditCard = result.CanSaveCreditCard;

      }
    }, async error => {
      Utils.handleError(error, this.popupService, 'There was an error while processing this request. Please try again later.');

      await this.backDynamic();

    });
  }

  listenKeyboardevents() {
    this.keyboard.onKeyboardWillShow()
    .pipe(takeUntil(this.destroyed$))
    .subscribe(() => this.keyboardOpened = true );

    this.keyboard.onKeyboardWillHide()
    .pipe(takeUntil(this.destroyed$))
    .subscribe(() => this.keyboardOpened = false );
  }
   
  async backDynamic() {
    let el: HTMLElement = (<any>this.backBtn).el;
    el.click();
  }

  createToken(): void {
    const name = this.stripe.get('name').value;
    this.stripeService
      .createToken(this.card.element, { name })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        if (result.token) {
          // Use the token
          console.log(result.token.id);
        } else if (result.error) {
          // Error creating the token
          console.log(result.error.message);
        }
      });
  }

  async loadWalletV4() {
    const name = this.stripe.get("name").value;
    const saveCardDetail = this.stripe.get("saveCardDetail").value;
    if (!name.replace(/\s/g, "").length) {
      await this.popupService.notifyV4("Card Holder Name is Required");
      return;
    }

    this.stripeService
      .createToken(this.card.element, { name })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async (result) => {
        if (result.token) {
          console.log(result.token);
          const payloadData = {
            CardHolderName: result.token.card.name,
            Token: result.token.id,
            CustomerId: 0,
            Amount: this.loadWalletAmount,
            CardNumber: result.token.card.last4,
            CardExpirationMonth: result.token.card.exp_month,
            CardExpirationYear: result.token.card.exp_year,
            CardCVV: "",
            SaveCreditCard: saveCardDetail,
            PayWithSavedCreditCard: false
          };

          const confirmMessage: string = `Your card ending in ${payloadData.CardNumber} will be charged ${this.currencySymbol}${Number(payloadData.Amount).toFixed(2)}`;
          this.popupService.confirmV4(null, confirmMessage).then(async (confirmed: boolean) => {
            if (confirmed) {
              let loadingOptions = {
                processingMessage: 'Processing Payment...'
              } as LoadingOptions;

              let loader = await this.popupService.loading(loadingOptions);

              await this.walletService.loadFundsV4(payloadData).then(async (response: any) => {

                await loader.dismiss();

                console.log(response);
                if (response.OK == false) {
                  let errorMessage =
                    this.translate.instant('Error verifying card. Please try again.') ;

                  if (
                    response.ErrorDetails &&
                    response.ErrorDetails.ErrorType == 11
                  ) {
                    errorMessage =
                      this.translate.instant('The minimum amount to reload wallet is $')  +
                      Number(response.ErrorDetails.Amount).toFixed(2);
                  }
                  if (response.ErrorDetails
                    && response.ErrorDetails.ErrorType == 10) {
                    errorMessage =
                      this.translate.instant('Enter lower amount, wallet balance cannot exceed limit of $')  +
                      Number(response.ErrorDetails.Amount).toFixed(2);
                  }

                  await this.popupService.failV4('Payment Failed', errorMessage);

                  console.error(response.Error);
                } else {
                  await this.popupService.successV4('Payment Success', 'Your wallet has been reloaded.')
                  .then(async () => {
                    this.walletV4Service.clearInMemoryCache();
                    this.walletService.clearCache();
                    this.walletService.getWalletBalance({ MerchantLocationId: this.util.merchantLocationId });
                    this.router.navigateByUrl('wallet');
                  });
                }
              });
            }
          });
        } else if (result.error) {
          // Error creating the token
          console.log(result.error.message);
          this.popupService.failV4('Error!',result.error.message);
        }
      });
  }

}
