import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-additional-field-checkbox',
  templateUrl: './additional-field-checkbox.component.html',
  styleUrls: ['./additional-field-checkbox.component.scss'],
})
export class AdditionalFieldCheckboxComponent implements OnInit {

  @Input() _description: any = null;
  @Input() _name: any = null;
  @Input() _modalCtrl: ModalController = null;
  @Input() _modalId: string = null;
  @Input() updateCheckBoxValue: Function;
  @Input() _id: any = null;

  constructor() { }

  ngOnInit() {}

  dismiss(){
    this._modalCtrl.dismiss(null, null, this._modalId );
  }

  setCheckbox(val) {
    let data = {key: this._id, val: val}
    this.updateCheckBoxValue(data).then((result) => {
      console.log(result);
      this.dismiss();
    });
  }

}
