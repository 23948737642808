import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TileData } from 'src/app/models/tileData';

@Component({
  selector: 'app-single-tile',
  templateUrl: './single-tile.component.html',
  styleUrls: ['./single-tile.component.scss'],
})
export class SingleTileComponent implements OnInit {
  
  @Input() tileData: TileData;
  
  constructor(private router: Router) { }

  ngOnInit() {}

  goToQRCode() {
    this.router.navigateByUrl('/qrcode');
  }
  

}
