import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { ValidatorService } from 'src/app/services/validator.service';
import { BaseComponent } from '../../base/base.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordFormComponent extends BaseComponent implements OnInit{

  private changePasswordModel: any;
  @Output() changePassword: EventEmitter<any> = new EventEmitter<any>();
  showNewPassword: boolean = false;
  showExistingPassword: boolean = false;
  showConfirmNewPassword: boolean = false;


  newPassword: FormControl;
  cnewPassword: FormControl;
  currentPassword: FormControl;
  form: FormGroup;
  
  constructor(
    private validatorService: ValidatorService,
    private formBuilder: FormBuilder,
    private router: Router
  )
  {
    super();
  }

  ngOnInit() {
    this.currentPassword = new FormControl('', [Validators.required]);

    this.newPassword = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(8),      
      this.validatorService.LowerCase(),
      this.validatorService.UpperCase(),
      this.validatorService.Digits(), 
      this.validatorService.SpecialChar(),
      this.validatorService.NoSpace()
    ]);

    this.cnewPassword = new FormControl('', [Validators.required]);

    this.form = this.formBuilder.group({
      currentPassword: this.currentPassword,
      newPassword: this.newPassword,
      cnewPassword: this.cnewPassword   
    },
    { validator: this.matchPassword('newPassword', 'cnewPassword') }
    );
  }

  matchPassword(firstControlName: string, secondControlName: string): ValidatorFn {
 
    return (control: AbstractControl): ValidationErrors | null => {
 
      const password: string = control.get(firstControlName).value;
      const confirm: string = control.get(secondControlName).value;

      if (password != confirm) {
        return { mustMatch: true };
      }
 
      return null; 
    }
  }

  save() {
    if(this.form.valid ) {
      if (this.form.controls['newPassword'].value !== this.form.controls['cnewPassword'].value) {
        this.form.controls['cnewPassword'].setErrors({'mustMatch': true}); 
        this.form.controls['cnewPassword'].setValue('');     
        this.form.controls['cnewPassword'].markAsTouched();
        return;
      }
      else {
        console.log(`ChangePasswordFormComponent.save()`)
        this.changePasswordModel = { 
          currentPassword: this.form.controls['currentPassword'].value,  
          newPassword: this.form.controls['newPassword'].value
        };
        this.changePassword.emit(this.changePasswordModel);
      }      
    }
  }

  forgotPassword() {
    this.router.navigateByUrl('forgot-password', { state: { pageToGoBackTo: 'change-password'}, replaceUrl: true });
  }

}
