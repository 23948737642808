import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Utils } from 'src/app/common/Util';
import { IFeedback } from 'src/app/models/feedback';
import { ISettingsFeedbackResponse, ISettingsFeedbackSetup } from 'src/app/models/response/getSettingsFeedbackResponse';
import { IHowToEarn } from 'src/app/models/response/getWaysToEarnResponse';


@Component({
  selector: 'app-leave-feedback-form',
  templateUrl: './leave-feedback-form.component.html',
  styleUrls: ['./leave-feedback-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeaveFeedbackFormComponent implements OnInit {
  
  @Input() howToEarn: IHowToEarn = null;
  @Input() settingsFeedback: ISettingsFeedbackResponse = null;
  @Output() save: EventEmitter<IFeedback> = new EventEmitter<IFeedback>();

  // [0,1,2,3,4] -> indexes for the 5 stars drawn on the screen
  starIndexes: Array<number> = Array.from({ length: 5 }, (value, index) => index);
  feedback: IFeedback = {
    stars: null,
    feedback: null
  };

  form: FormGroup = this.formBuilder.group({
    feedback: new FormControl('', [Validators.required])
  });
  
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.form.patchValue(this.feedback);
  }

  setRating(stars: number) {
    this.feedback.stars = stars;
  }

  get rating(): number {
    return Utils.isNullOrUndefined(this.feedback?.stars) ? 0 : this.feedback.stars;
  }

  get isValid(): boolean {
    return this.rating > 0 && (this.form.valid || !this.askForComment);
  }

  get askForComment(): boolean {
    let result: boolean = false;    
    const match: ISettingsFeedbackSetup = this.settingsSetup;
    if( !Utils.isNullOrUndefined(match) ) {
      result = match.AskForComment;
    }
    return result;
  }

  get commentPlaceholder(): string {
    let result: string = 'Additional Feedback?';    
    const match: ISettingsFeedbackSetup = this.settingsSetup;
    if( !Utils.isNullOrUndefined(match) ) {
      result = match.CommentPlaceHolder;
    }
    return result;
  }
  

  private get settingsSetup(): ISettingsFeedbackSetup {
    const stars: number = Utils.isNullOrUndefined(this.feedback.stars) || this.feedback.stars > 5 ? 0 : this.feedback.stars;
    return this.settingsFeedback?.Setup?.find(s => s.Rating == stars);
  }

  saveFeedback() {
    if( this.isValid ) {      
      if( !this.askForComment ) {
        this.feedback.feedback = null;
      } else {
        this.feedback = {
          ...this.feedback,
          ...this.form.value
        };
      }
      console.log(`LeaveFeedbackFormComponent.saveFeedback(${this.feedback})`);
      this.save.emit(this.feedback);
    }
  }

}
