import { IAddress, EMPTY_ADDRESS } from "./address";

export interface ILocation {
    Id: number;
    Title: string;
    distance: number,
    Address: IAddress;
}

export const EMPTY_LOCATION: ILocation = {
    Id: null,
    Title: null,
    distance: null,
    Address: EMPTY_ADDRESS
}
