import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IEarnable } from 'src/app/models/response/getWaysToEarnResponse';

@Component({
  selector: 'app-refer-afriend-instructions',
  templateUrl: './refer-afriend-instructions.component.html',
  styleUrls: ['./refer-afriend-instructions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReferAFriendInstructionsComponent implements OnInit {

  @Input() earnables: Array<IEarnable> = null;
  constructor() { }

  ngOnInit() {}

}
