import {Component, Inject, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ActionSheetController, Config, IonRouterOutlet, MenuController, ModalController, Platform} from "@ionic/angular";
import {SplashScreen} from "@ionic-native/splash-screen/ngx";
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {ToastService} from "./services/toast.service";
import {Router} from "@angular/router";
import { TranslationService } from './services/translation.service';
import { UtilService } from './services/util.service';
import { AssetService } from './services/asset.service';
import { NetworkService } from './services/network.service';
import { LoadingService } from './services/loading.service';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { config } from 'src/config';
import { BehaviorSubject, timer } from 'rxjs';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { VersionUpdateComponent } from './components/version-update/version-update.component';
import { HelperService } from './services/helper.service';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { TranslateService } from '@ngx-translate/core';
import { ApplePayService } from './services/apple-pay.service';
import { LocationService } from './services/location.service';
import { AuthV4Service } from './services/auth-v4.service';
import { Constants } from './common/Constants';
import { ThemeService } from './services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  rtlSide = "left";
  lastBackPress = 0;
  timePeriodToExit = 2000;
  selectedIndex: any;
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  systemLanguage:any;
  swipeGesture: boolean = true;
  deviceId : any = "0";
  externalLink: string = null;
  loaderColor$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  toggle: any;
  preferredThemeModeDark: boolean = null;
  isWalletEnabled = new BehaviorSubject<boolean>(false);
  isMembershipsEnabled = new BehaviorSubject<boolean>(false);
  hasMobileFriendlyMemberships = new BehaviorSubject<boolean>(false);
  spinnerColor: string = "#FFFFFF";
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private toastService: ToastService,
    private translationService: TranslationService,
    public actionSheetController: ActionSheetController,
    private router: Router,
    private menu: MenuController,
    private authService: AuthV4Service,
    private util: UtilService,
    private assetService: AssetService,
    private networkService: NetworkService,
    private loadingService: LoadingService,
    private appVersion: AppVersion,
    public modalCtrl: ModalController,
    private helperService: HelperService,
    private translate: TranslateService,
    private applePayService: ApplePayService,
    private locationService: LocationService,
    private themeService: ThemeService
    ) {           
    this.initializeApp();    
  }

  initializeApp() {
    this.platform.ready().then(async () => {       
      if (config && config.COLORS &&  config.COLORS.progressbarBackground) {
        this.spinnerColor = config.COLORS.progressbarBackground;
      }       
      console.log("PLATFORM IS READY");    
      this.networkService.startWatchingForConnection();
      this.networkService.startWatchingDisconnection(); 
      this.helperService.clearNotificationPreference();
      this.helperService.setPushPopUpPreference(true);
      this.helperService.setPushPopUpPreferenceOnResume(true);
      this.helperService.registerForNotifications(); 
      this.helperService.setupNotificationHandler();     

        /* Ignored if dark mode is set to default */
        this.preferredThemeModeDark = false;     
        this.themeService.setAppTheme(false);
        await this.themeService.removeThemeKey();
        if (this.assetService.isDarkModeDefault && this.assetService.isDarkModeDefault == 'true') {           
          this.preferredThemeModeDark = true;     
          this.themeService.setAppTheme(true);               
        }        
       /* Force Single Theme */
       if(this.util.forceMode == "light" || this.util.forceMode == "dark") {
        if (this.util.forceMode == "light") {
          await this.themeService.removeThemeKey();
          this.preferredThemeModeDark = false;     
          this.themeService.setAppTheme(false);          
        }
        if (this.util.forceMode == "dark") {
          this.preferredThemeModeDark = true;     
          this.themeService.setAppTheme(true);   
          await this.themeService.addThemeKey(true);  
        }
      }

      //await this.checkToggle();
 
     
      this.platform.resume.subscribe(async () => {
        await this.checkAppVersioning();   
        if (this.authService.isLoggedIn) {
          this.helperService.updateNotificationPreference();   
        }             
      });      

      this.updateAppLanguage();
      this.backButton();        
      localStorage.setItem('platform', this.helperService.determinePlatform());        
      this.locationService.setLocationPopUpPreference(true); 
         
      await this.assetService.getMerchantAssets().then(async () => {  
        this.loaderColor$.next(this.assetService.progressbarBackground); 
        this.isWalletEnabled.next(this.util.walletEnabled);
        this.isMembershipsEnabled.next(this.util.membershipsEnabled);
        this.hasMobileFriendlyMemberships.next(this.util.hasMobileFriendlyMemberships);

        if (this.platform.is("cordova")) {         
          console.log('Fixing Status bar');
          this.statusBar.overlaysWebView(true);
          timer(50).subscribe(x => {            
            this.statusBar.overlaysWebView(false);
          });    
          await this.checkAppVersioning(); 
          console.log("Checked App Vesion");
          await this.applePayService.checkIfApplepayAvailable().then((applePayResult) => {
            console.log(`This device supports Apple Pay: ${applePayResult}`);
            localStorage.setItem('isApplePayAvailable', applePayResult);
          });
        }   

        var permission = null;
        this.locationService._getCurrentPositionFromBrowser().then(coords => {
          console.log("Coordinates:", coords);
          permission = coords;
          console.log("LOCATION PERMISSION VALUE"); 
          if (permission && permission.latitude && permission.longitude) {
            let coOrdinates = {latitude: permission.latitude, longitude: permission.longitude};          
            this.locationService.setLocationCoOrdinates(coOrdinates); 
          }
          console.log(permission);  
        })
        .catch(err => {
          console.error("Failed to get location:", err);
        })      
        
        let popuppref = await this.locationService.setLocationPopUpPreference(false);
        console.log("POPUP PREFERENCE"); 
        console.log(popuppref);     
      }, async error => {          
        console.log('error while fetching merchant assets initial load');
        await this.checkAppVersioning(); 
      }).then(() => {
        console.log('ALL Loaded => Hiding splash screen !!!');  
        if (this.platform.is("cordova")) {
          this.splashScreen.hide();
        }    
      });

      if (this.assetService && this.assetService.isHighRiskMerchant != "" && this.assetService.isHighRiskMerchant == "true" && 
          ((this.assetService.restrictedCountry && this.assetService.restrictedCountry != "") || (this.assetService.restrictedState && this.assetService.restrictedState != ""))) 
      {

        this.goToDisclaimer();
      }
     
    });
  }

  backButton() {
        this.platform.backButton.subscribeWithPriority(1, () => {
            this.routerOutlets.forEach((outlet: IonRouterOutlet) => {
                if (this.router.url === '/home' || this.router.url === '' || this.router.url.toLowerCase().includes('tabs')) {
                    if (new Date().getTime() - this.lastBackPress < this.timePeriodToExit) {
                        navigator['app'].exitApp();
                    } else {
                        this.toastService.show('Press back again to exit App');
                        this.lastBackPress = new Date().getTime();
                    }
                } 
                else if (outlet && outlet.canGoBack()) {
                    if (this.router.url.includes('/online-order')) {                       
                       this.showConfirmation();
                    }
                    else {
                      outlet.pop();
                    }                    
                }
            });
        });
  }

  navigateBackOO() {
    let iframe = document.getElementById('orderFrame') as HTMLIFrameElement;
    var msg = { navigateBackOO : true };  
    iframe.contentWindow.postMessage( msg, environment.ONLINE_ORDER_URL);
  }

  closeMenu() {
    this.menu.close();
  }

  updateAppLanguage(){
    if(!localStorage.getItem(Constants.APP_LANGUAGE_KEY)){
      localStorage.setItem(Constants.APP_LANGUAGE_KEY, config.DEFAULT_LANGUAGE);
      this.systemLanguage = config.DEFAULT_LANGUAGE;
    }
    else {
     this.systemLanguage = localStorage.getItem(Constants.APP_LANGUAGE_KEY);
    }
    this.translationService.setLanguage(this.systemLanguage);
  } 

  async changeLanguage() 
  {
    const actionSheet = await this.actionSheetController.create({
      header: this.translate.instant("Choose your language"),
      buttons: [
        {
          text: "English",
          handler: () => {
            localStorage.setItem(Constants.APP_LANGUAGE_KEY, "en");
            this.updateAppLanguage();
          },
        },
        {
          text: "Spanish",
          handler: () => {
            localStorage.setItem(Constants.APP_LANGUAGE_KEY, "es");
            this.updateAppLanguage();            
          },
        },
        {
          text: "French",
          handler: () => {
            localStorage.setItem(Constants.APP_LANGUAGE_KEY, "fr");
            this.updateAppLanguage();            
          },
        }        
      ],
    });
    await actionSheet.present();
  }

  async logout() 
  {   
    this.closeMenu();
    this.authService.logout(true);
  }

  ngOnInit() {     
    let html = document.querySelector('html');
    /*
    console.log('INJECTING CUSTOM CSS');
    let custom_css_url = `${environment.API_URL}/api/CustomCSS/Get?guid=${config.MERCHANT_GUID}&template=v4&timestamp=${new Date().getTime()}` 
    let existingCustomCSS = this.document.getElementById('customCSS');
    if (existingCustomCSS) {
      existingCustomCSS.parentNode.removeChild(existingCustomCSS);
    }
    // Create a new link element
    const linkElement = this.document.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.type = 'text/css';
    linkElement.id = 'customCSS';
    linkElement.href = custom_css_url;
    this.document.head.appendChild(linkElement);
    */
    
    if (this.assetService.loginBgUrl) {
      html.style.setProperty('--custom-login-bg-img-url', 'url('+this.assetService.loginBgUrl+')');
    }

  }

  visitExternalLink() {

    this.externalLink = this.util.companyWebsite;

    if(this.externalLink) 
    {
      let url = this.util.addhttp(this.externalLink);

      console.log(`Opening external link with system defult browser => ${url}`);
      if (this.platform.is('cordova')) {
        this.util.openWithSystemBrowser(url);
      } else {            
        window.open(url, '_blank');
      }      
    }    
  }

  gotoRewards() {
    this.menu.close();
    this.router.navigateByUrl('/tabs/rewards');
  }

  goToDisclaimer() {
    this.menu.close();
    this.router.navigateByUrl('/disclaimer');
  }

  gotoWallet() {
    this.menu.close();
    this.router.navigateByUrl('/tabs/wallet');
  }  

  async checkAppVersioning() {
    try {
      if (this.platform.is('cordova')) {                     
        await this.getAppInfo().then(async (appInfoResult) => {
          console.log(appInfoResult);                  
          await this.util.checkIfAppNeedsUpdate(appInfoResult).then((checkResult) => {
            console.log(`App Requires Update Result => ${JSON.stringify(checkResult)}`);  
            if (checkResult && checkResult.UpdateRequired) {
               appInfoResult.updateMessage = checkResult.UpdateMessage;
                appInfoResult.updateURL = checkResult.UpdateURL;       
                this.loadVersionUpdateModal(appInfoResult);                    
            }                          
          }, err => {
            console.log(err);
          });
        });           
      }            
    } catch (error) {
      
    } 
  }

  async getAppInfo() : Promise<any> {
    return await new Promise<any>(async (resolve, reject) => {
       let appName = '';  
       await this.appVersion.getAppName().then((result) => {
         appName = result;
       });

       let appPackageName = '';
       await this.appVersion.getPackageName().then((result) => {
         appPackageName = result;
       });

       let appVersionCode = null;  
       await this.appVersion.getVersionCode().then((result) => {
         appVersionCode = result;
       });
       let appVersionNumber = '';    
       await this.appVersion.getVersionNumber().then((result) => {
         appVersionNumber = result;
       }).then(() => {
         let platform  = null;
         if (this.platform.is('ios')) {
           platform = 'ios';
         }
         else if (this.platform.is('android')) {
           platform = 'android';
         }
         console.log(`App Name => ${appName}`);
         console.log(`App Package Name => ${appPackageName}`);
         console.log(`App Version Code => ${appVersionCode}`);
         console.log(`App Version Number => ${appVersionNumber}`);
         let result = 
         {"platform": platform, 
         "appName": appName, 
         "appPackageName": appPackageName, 
         "appVersionCode":  appVersionCode, 
         "appVersionNumber": appVersionNumber,
         "updateMessage": null,
         "updateURL": null,
        }
         resolve(result);
       });
     });
  }

  async loadVersionUpdateModal(appInfo: any) {

    await this.util.closeAllModals();

    const modal = await this.modalCtrl.create({
      component: VersionUpdateComponent,
      animated: true,
      mode: 'ios',
      cssClass: 'tm-custom-version-update-modal-css',
      backdropDismiss: false,
      componentProps: {"appInfo": appInfo, "_util": this.util}
    });

    //this.util.storeModal(modal);

    return await modal.present(); 

  }
  
  async showConfirmation() {

    const modal = await this.modalCtrl.create({
      component: ConfirmationComponent,
    });

    modal.componentProps = {_modal: this.modalCtrl, _util: this.util};

    this.util.storeModal(modal);

    return await modal.present();
   
  }

  async checkToggle(): Promise<any> {        
    if (this.preferredThemeModeDark != null) {   
      this.assetService.isDarkModeDefault =  this.preferredThemeModeDark.toString();
      this.assetService.preferredDarkModeSelected = this.preferredThemeModeDark;
      this.themeService.setAppTheme(this.preferredThemeModeDark)
    }
    else { 
      let storedval =  await this.themeService.getStorageThemeValue();
      console.log("User Has changed Theme Mode => " + this.preferredThemeModeDark);    
      console.log("this.assetService.preferredDarkModeSelected => " + this.assetService.preferredDarkModeSelected);  
      console.log("stored value => " + storedval);  
      if (this.preferredThemeModeDark == null) {

        this.assetService.preferredDarkModeSelected = storedval;
        this.preferredThemeModeDark = this.assetService.preferredDarkModeSelected;
      }
      this.assetService.preferredDarkModeSelected = this.preferredThemeModeDark;
      
      this.themeService.setAppTheme(this.preferredThemeModeDark)
    }     
  }
  
}




