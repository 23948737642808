import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {

  @Input() _modal: HTMLIonModalElement;
  @Input() _util: UtilService;

  constructor() { }

  ngOnInit() {}

  async dismiss() {
    try {
      this._util.closeAllModals();      
    } catch (error) {
      console.log(error);
    }    
  }

  async confirm() 
  {
    try {
      this._util.closeAllModals(); 
      this._util.goToHome();      
    } catch (error) {
      console.log(error);
    }   
  }

}
