import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient)
  {
  }

  getData(url: string) {
    return this.http.get(this.getFullUrl(url));
  }

  postData(url: string, data: any) {
    return this.http.post(this.getFullUrl(url), data);
  }

  private getFullUrl(url: string): string {
    return `${environment.API_URL}${url}`;
  }
}
