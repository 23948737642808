import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Constants } from 'src/app/common/Constants';

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordFormComponent implements OnInit {

  @Output() sendResetLink: EventEmitter<string> = new EventEmitter<string>(); 

  form: FormGroup = this.formBuilder.group({
    email: new FormControl('', [Validators.email, Validators.pattern(Constants.EMAIL_REGEX), Validators.required])
  });
  
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
  }

  send() {
    if( this.form.valid ) {
      this.sendResetLink.emit(this.form.get('email').value);
    }
  }

}
