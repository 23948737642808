import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { AssetService } from 'src/app/services/asset.service';

@Component({
  selector: 'app-on-board1-content',
  templateUrl: './on-board1-content.component.html',
  styleUrls: ['./on-board1-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnBoard1ContentComponent implements OnInit {

  @Output() verify: EventEmitter<string> = new EventEmitter<string>();

  constructor(public assetService: AssetService) {}

  ngOnInit() {}

  onVerify(phoneOrEmail: string) {
    console.log(`OnBoard1ContentComponent.onVerify(${phoneOrEmail})`);
    this.verify.emit(phoneOrEmail);
  }

  async menu() {
    await this.assetService.menu();
  }
}
