import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { Constants } from 'src/app/common/Constants';
import { Utils } from 'src/app/common/Util';
import { HeaderData } from 'src/app/models/headerData';
import { AssetService } from 'src/app/services/asset.service';
import { LocationService } from 'src/app/services/location.service';
import { NotificationV4Service } from 'src/app/services/notification-v4.service';
import { ProfileV4Service } from 'src/app/services/profile-v4.service';
import { BaseComponent } from '../base/base.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent extends BaseComponent implements OnInit {
  @Input() headerData: HeaderData = null;
  merchantLocations: any;  
  notificationsCount: string = this.notificationServiceV4.notificationsCount;
  isLoggedIn$ = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    public notificationServiceV4: NotificationV4Service,
    public assetService: AssetService,    
    private cd: ChangeDetectorRef
  ) 
  {
    super();
  }


  ngOnInit() {
    this.notificationServiceV4.notificationsCount$.pipe(takeUntil(this.destroyed$)).subscribe((e) => {
        if (e) {
          this.notificationsCount = e;
        }
        else {
          this.notificationsCount = null;
        }
       console.log(e);
       this.cd.detectChanges();
    });
  }


  ngOnChanges(changes: SimpleChanges): void {
    if( !Utils.isNullOrUndefined(changes['headerData'] ) ) {
      if( !Utils.isNullOrUndefined(this.headerData)) {
        this.notificationsCount = this.notificationServiceV4.notificationsCount;
        this.isLoggedIn$.next(ProfileV4Service.isProfileLoaded);
        this.cd.detectChanges();
      }
    }
  }

  goToNotifications() {    
    this.router.navigateByUrl('/notifications');
  }

  async menu() {
    await this.assetService.menu();
  }

}
