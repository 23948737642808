import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../common/Util';

@Pipe({
  name: 'phoneFormat'
})
export class PhoneFormatPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return Utils.formatPhoneNumber(value);
  }
}