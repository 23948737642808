import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IEmailLogin } from 'src/app/models/emailLogin';
import { AssetService } from 'src/app/services/asset.service';

@Component({
  selector: 'app-login-email-content',
  templateUrl: './login-email-content.component.html',
  styleUrls: ['./login-email-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginEmailContentComponent implements OnInit {

  @Output() verifyPassword: EventEmitter<IEmailLogin> = new EventEmitter<IEmailLogin>(); 
  @Input() emailLogin: IEmailLogin;

  constructor(public assetService: AssetService) {}

  ngOnInit() {}

  login(emailLogin: IEmailLogin) {
    this.verifyPassword.emit(emailLogin);
  }

  async menu() {
    await this.assetService.menu();
  }
}
