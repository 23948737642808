import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IStoreLocation } from 'src/app/models/storeLocation';

@Component({
  selector: 'app-store-info-hours',
  templateUrl: './store-info-hours.component.html',
  styleUrls: ['./store-info-hours.component.scss'],
})
export class StoreInfoHoursComponent implements OnInit {
  @Input() storeLocation: IStoreLocation = null;
  
  constructor(public modalCtrl: ModalController) { }

  ngOnInit() {}

  dismiss(){
    this.modalCtrl.dismiss();
  }

}
