import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IHowToEarn } from 'src/app/models/response/getWaysToEarnResponse';

@Component({
  selector: 'app-make-apurchase-instructions',
  templateUrl: './make-apurchase-instructions.component.html',
  styleUrls: ['./make-apurchase-instructions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MakeAPurchaseInstructionsComponent implements OnInit {

  @Input() howToEarn: IHowToEarn = null;
  
  constructor(private router: Router) { }

  ngOnInit() {}

  goToOnlineOrder() {
    this.router.navigateByUrl('online-order')
  }

}
