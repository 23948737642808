import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Utils } from 'src/app/common/Util';
import { config } from 'src/config';
import { BaseComponent } from '../../base/base.component';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-provide-phone-number-form',
  templateUrl: './provide-phone-number-form.component.html',
  styleUrls: ['./provide-phone-number-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProvidePhoneNumberFormComponent extends BaseComponent implements OnInit {

  @Input() email: string = null;
  @Output() confirm: EventEmitter<string> = new EventEmitter<string>();

  phoneMinLength: number = Utils.maskAwarePhoneLength;
  phoneMaxLength: number = Utils.maskAwarePhoneLength;
  phonePlaceHolder: string = Utils.getPhoneMask().phonePlaceholder;

  form: FormGroup = this.formBuilder.group({
    phone: new FormControl('', [Validators.minLength(this.phoneMinLength), Validators.maxLength(this.phoneMaxLength), Validators.required])
  });;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    console.log( Date.now(), `ProvidePhoneNumberFormComponent.ngOnInit()`);

    // format the phone number as you type
    this.form.get('phone').valueChanges
    .pipe(
      takeUntil(this.destroyed$),
      debounceTime(500),
      distinctUntilChanged()
    )
    .subscribe(value => this.form.get('phone').setValue(Utils.formatPhoneNumber(value)));
  }

  onConfirm() {
    if( this.form.valid ) {
      const phone: string = this.form.get('phone').value;
      this.confirm.emit(phone);
    }
  }

}
