import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-go-back',
  templateUrl: './header-go-back.component.html',
  styleUrls: ['./header-go-back.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderGoBackComponent implements OnInit {

  @Input() titleText: string;
  @Input() goBackUrl: string;

  constructor(private router: Router) { }

  ngOnInit() {}

  goBack() {
    this.router.navigateByUrl(this.goBackUrl)
  }

}
