export enum OnboardStep {
    ERROR = 0,
    PHONE_VERIFICATION = 1,
    EMAIL_VERIFICATION = 2,
    PHONE_CODE = 3,
    EMAIL_CODE = 4,
    PROFILE = 5,
    DONE = 6,
    PROVIDE_PHONE_FOR_VERIFIED_EMAIL = 7
}


    // ERROR, //onboarding process can not be continued
    // PHONE_VERIFICATION, //redirect customer to phone number form for sending verification code. on submit call "verify/send" serice and redirect to PHONE_CODE
    // EMAIL_VERIFICATION, //redirect customer to email address form for sending verification code. on submit call "verify/send" serice and redirect to EMAIL_CODE
    // PHONE_CODE, //redirect customer to code form. on submit call verify/code, if not verified allow to resend the code, if verified call "onboard/" service with all details you collected 
    // EMAIL_CODE, //redirect customer to code form. on submit call verify/code, if not verified allow to resend the code, if verified call "onboard/" service with all details you collected 
    // PROFILE, //redirect customer to profile (signup/connect/complete) form. on submit "onboard/" service with all details you collected 
    // DONE, //customer onboarded. save authToken and redirect customer to home screen
    // PROVIDE_PHONE_FOR_VERIFIED_EMAIL //for Email onboarding type, if claimed customer/mastr customer has stored phone number we will ask user to provide the phone number and we will compare it to what we have
