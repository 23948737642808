export interface IRequestHeaderData {
    Accept: string;
    MERCHANT_GUID: string;
    MERCHANT_APP_ID: string;
    APP_LANG: string;
    APP_VERSION: string;
    APP_BUNDLE_ID: string;
    DEVICE_ID: string;
}

export const EMPTY_REQUEST_HEADER_DATA: IRequestHeaderData = <IRequestHeaderData>{
    Accept: 'application/json',
    APP_VERSION: '5.0.1',
    APP_BUNDLE_ID: 'com.tapmango.mobile',
    APP_LANG: 'en'
}