import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss'],
})
export class NotificationPopupComponent implements OnInit {  
  @Input() title: string = null;
  @Input() message: string = null;
  @Input() btnYesText: string = null;
  @Input() btnNoText: string = null;

  constructor(public modalCtrl: ModalController) { }

  ngOnInit() {}

  async ok() {
    await this.dismiss(true);
  }

  async cancel() {
    await this.dismiss(false);
  }

  private async dismiss(result: boolean) {
    await this.modalCtrl.dismiss(result);
  }


}
