import { ChangeDetectionStrategy, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from 'src/app/common/Util';
import { INotification } from 'src/app/models/response/notification';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationListComponent implements OnInit {
  @Input() notifications: Array<INotification> = [];

  constructor(private router: Router) {
  }

  ngOnInit() {
    console.log('NotificationListComponent.ngOnInit() notifications=', Utils.debugGetSafeJSON(this.notifications))
  }

  goToNotificationDetail(notification: INotification) {
    if( Utils.isNullOrUndefined( notification ) ) {
      console.error(`NotificationListComponent.goToNotificationDetail(${notification.Id}) could not find any notification with this id!`)
    } else {
      this.router.navigateByUrl('notification-detail', { state: { notification: notification } });
    }
  }
}
