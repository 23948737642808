import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Utils } from '../common/Util';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  private regex: any = {
    digits: '(\\d.*)',
    letters: '([a-zA-Z].*)',
    lowercase: '([a-z].*)',
    uppercase: '([A-Z].*)',
    symbols: '([`~\\!@#\\$%\\^\\&\\*\\(\\)\\-_\\=\\+\\[\\\{\\}\\]\\\\\|;:\\\'",<.>\\/\\?€£¥₹§±].*)',
    spaces: '([\\s].*)',
    specialcharacter: '([#?!@$%^&*-].*)'
  }

  /**
   * Method searches a string for a match against a regular expression, and returns the boolean result
   * @param {string} [string] - string thant needs to be tested
   * @param {RegExp} [regexp] - custom Regex which should be present
   */

  private _process(regexp, string) {
      return new RegExp(regexp).test(string);
  }

  /**
   * Method searches a string for a match against a regular expression, and returns the boolean result
   * @param {string} [string] - string thant needs to be tested
   */

  private _checkFullName(value: string) {
      return !Utils.isNullOrUndefinedOrWhitespace(value) && value.trim().indexOf(' ') != -1
  }

   /**
   * Method to make sure restricted strings are not used
   * @param {string} string - string that needs to be tested
   * @param {array} list - list of values not allowed
   */
  private _oneOf(list, string) {
    return list.indexOf(string) >= 0;
  }

  constructor() { }

/**
 * MustMatch Validator
 * @param {AbstractControl} firstControl - Control whose value needs to be matched
 */
  MustMatch(firstControl: AbstractControl): ValidatorFn {
    return (
        secondControl: AbstractControl
    ): { [key: string]: boolean } | null => {
        // return null if controls haven't initialised yet
        if (!firstControl && !secondControl) {
            return null;
        }

        // return null if another validator has already found an error on the matchingControl
        if (secondControl.hasError && !firstControl.hasError) {
            return null;
        }
        // set error on matchingControl if validation fails
        if (firstControl.value !== secondControl.value) {
            return { mustMatch: true };
        } else {
            return null;
        }
    };
  }

  /**
   * Method to make sure at least one special character #?!@$%^&*- is used
  */
  SpecialChar() : ValidatorFn {
    return (control: AbstractControl) : { [key: string]: boolean } | null => {      
      if (!this._process(this.regex.specialcharacter, control.value)) {
        return { specialChar: true };
      } else {
          return null;
      }
    };
  }

  /**
   * Method to make sure at least one lowercase character is used
  */
  LowerCase() : ValidatorFn {
    return (control: AbstractControl) : { [key: string]: boolean } | null => {      
      if (!this._process(this.regex.lowercase, control.value)) {
        return { lowerChar: true };
      } else {
          return null;
      }
    };
  }


  /**
   * Method to make sure at least one uppercase character is used
  */
  UpperCase() : ValidatorFn {
    return (control: AbstractControl) : { [key: string]: boolean } | null => {      
      if (!this._process(this.regex.uppercase, control.value)) {
        return { upperChar: true };
      } else {
          return null;
      }
    };
  }

  /**
   * Method to make sure at least one digit is used
  */
  Digits() : ValidatorFn {
    return (control: AbstractControl) : { [key: string]: boolean } | null => {      
      if (!this._process(this.regex.digits, control.value)) {
        return { digitChar: true };
      } else {
          return null;
      }
    };
  }

  /**
   * Method to make sure no spaces are used 
  */
  NoSpace() : ValidatorFn {
    return (control: AbstractControl) : { [key: string]: boolean } | null => {      
      if (this._process(this.regex.spaces, control.value)) {
        return { nospaceChar: true };
      } else {
          return null;
      }
    };
  }

   /**
   * Method to make sure full name is provided
  */
  FullName() : ValidatorFn {
      return (control: AbstractControl) : { [key: string]: boolean } | null => {      
        if (!this._checkFullName(control.value)) {          
          return { fullName: true };
        } else {         
          return null;
        }
      };
  }
  

   /**
   * Method to make sure restricted strings are not used
   * @param {array} list -  list of values not allowed
   */
  RestrictedString(list: any) : ValidatorFn {
    return (control: AbstractControl) : { [key: string]: boolean } | null => {      
      if (!this._oneOf(list, control.value)) {
        return { restricted: true };
      } else {
          return null;
      }
    };
  }  
}
