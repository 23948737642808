import { Pipe, PipeTransform } from '@angular/core';
import { config } from 'src/config';
import { Utils } from '../common/Util';


@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    var newValue = value;
    if (!Utils.isNullOrUndefined(value)) {
      if (config.POINTS_FORMAT == 'local') {
        newValue = value.toLocaleString();
      }
      else if (config.POINTS_FORMAT == 'intl')  {
        newValue = this.nFormatter(value, value.toString().length);
      }   
      else if (config.POINTS_FORMAT != 'intl' && config.POINTS_FORMAT != 'local') {
         var str = newValue.toLocaleString();
         str = str.replace(/\./, config.POINTS_FORMAT);
         str = str.replace(/,/g, config.POINTS_FORMAT);
         newValue = str;
      }
      else {
        newValue = value;
      }  
      return newValue;
    }    
  }

  nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }
}
