import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { config } from 'src/config';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { ModalController } from '@ionic/angular';
import { AssetService } from './asset.service';
import { Constants } from '../common/Constants';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  
  browser_options: InAppBrowserOptions = {
    location : 'yes',
    hidden : 'no',
    clearcache : 'yes',
    clearsessioncache : 'yes',
    zoom : 'yes',
    hardwareback : 'yes',
    mediaPlaybackRequiresUserAction : 'no',
    shouldPauseOnSuspend : 'no', 
    closebuttoncaption : 'Home', 
    disallowoverscroll : 'no', 
    toolbar : 'yes',
    enableViewportScale : 'no',
    allowInlineMediaPlayback : 'no', 
    presentationstyle : 'pagesheet',
    fullscreen : 'yes', 
  };
  isLoading: boolean = false;
  timeout: number = 10000;
  modalInst = [];
  modal_num = 0;
  feedbackBGUrl$ = new BehaviorSubject<string>(null);

  constructor(
    private router: Router, 
    private inAppBrowser: InAppBrowser, 
    public modalCtrl: ModalController,
    private assetService: AssetService
    ) { 
    }

  /* Getters */
  get merchantId() { return  this.assetService.merchantId } 
  get merchantGuid() { return  this.assetService.merchantGuid } 
  get currencyCode() { return  this.assetService.currencyCode } 
  get currencySymbol() { return  this.assetService.currencySymbol }
  get darkLogoUrl() : Observable<string> {    
    let logoUrl = "../../../assets/images/logoDark.png";
    if (!this.assetService.logoDarkUrl) {       
      return of(logoUrl)
    }
    else {                
      return of(this.assetService.logoDarkUrl)
    }     
  }

  get logoUrl() : Observable<string> { 
    let selectedTheme = localStorage.getItem('slected-app-theme');
    let logoUrl = "../../../assets/images/logo.png";
    let logoDarkUrl = "../../../assets/images/logoDark.png";
    if (!this.assetService.logoUrl) {   
      if(selectedTheme == 'true') {
        return of(logoDarkUrl)
      }                
      return of(logoUrl)
    }
    else {
      if(selectedTheme == 'true') {
        return of(this.assetService.logoDarkUrl)
      }               
      return of(this.assetService.logoUrl)
    }     
  } 
  get loginBgUrl() : Observable<string> { 
    if (!this.assetService.loginBgUrl) {
      return of("../../../assets/images/splash.png")
    }
    else {
      return of(this.assetService.loginBgUrl)
    }     
  } 
  
  get companyWebsite() { return  this.assetService.companyWebsite } 
  get companyEmail() { return  this.assetService.companyEmail } 
  get walletEnabled() { 
    if (this.assetService.walletEnabled == 'true') {
        return true;
    } else {
      return false;
    }
  } 

  get membershipsEnabled() { 
    if (this.assetService.membershipsEnabled == 'true') {
        return true;
    } else {
      return false;
    }
  } 

  get hasMobileFriendlyMemberships() {
    if (this.assetService.hasMobileFriendlyMemberships == 'true') {
      return true;
    } else {
      return false;
    }
  }

  get mobileOrderingEnabled() { 
    if (this.assetService.mobileOrderingEnabled == 'true') {
        return true;
    } else {
      return false;
    }
  }
  get customTabs() : Observable<any> { return  localStorage.getItem('tabs') ? of(localStorage.getItem('tabs')) : null } 
  
  get primaryColor(): Observable<string>  {
    return of(this.assetService.primaryColor);
  }
  get primaryColorValue(): string {
    return this.assetService.primaryColor;
  }
  get qRCodeIconUrl(): Observable<string>  {
    let url = "../../../assets/custom-icons/qr-icon.svg";   
    if (!this.assetService.qRCodeIconUrl || this.assetService.qRCodeIconUrl != "null") {
      return of(url);
    }
    return of(null);
  }
  get pointsIconUrl(): Observable<string>  {
    let url = "../../../assets/custom-icons/points-icon.svg";
    if (!this.assetService.pointsIconUrl || this.assetService.pointsIconUrl != "null") {
      return of(url);
    }
    return of(null);
  }
  get walletIconUrl(): Observable<string>  {
    let url = "../../../assets/custom-icons/wallet-icon.svg";
    if (!this.assetService.walletIconUrl || this.assetService.walletIconUrl != "null") {
      return of(url);
    }
    return of(null);
  }
  get earnTabIconUrl(): Observable<string>  {
    let url = "../../../assets/custom-icons/earn-tab-icon.svg";
    if (!this.assetService.earnTabIconUrl || this.assetService.earnTabIconUrl != "null") {
      return of(url);
    }
    return of(null);
  }
  get facebookIconUrl(): Observable<string>  {
    let url = "../../../assets/custom-icons/facebook-icon.svg";
    if (!this.assetService.facebookIconUrl || this.assetService.facebookIconUrl != "null") {
      return of(url);
    }
    return of(null);
  }
  get feedbackIconUrl(): Observable<string>  {
    let url = "../../../assets/custom-icons/feedback-icon.svg";
    if (!this.assetService.leaveUsFeedbackIconUrl || this.assetService.leaveUsFeedbackIconUrl != "null") {
      return of(url);
    }
    return of(null);
  }
  get spendIconUrl(): Observable<string>  {
    let url = "../../../assets/custom-icons/spend-icon.svg";
    if (!this.assetService.spendIconUrl || this.assetService.spendIconUrl != "null") {
      return of(url);
    }
    return of(null);
  }
  get birthdayIconUrl(): Observable<string>  {
    let url = "../../../assets/custom-icons/birthday-icon.svg";
    if (!this.assetService.birthdayIconUrl || this.assetService.birthdayIconUrl != "null") {
      return of(url);
    }
    return of(null);
  }
  get homeTabIconUrl(): Observable<string>  {
    let url = "../../../assets/custom-icons/home-tab-icon.svg";
    if (!this.assetService.homeTabIconUrl || this.assetService.homeTabIconUrl != "null") {
      return of(url);
    }
    return of(null);
  }
  get instagramIconUrl(): Observable<string>  {
    let url = "../../../assets/custom-icons/instagram-icon.svg";
    if (!this.assetService.instagramIconUrl || this.assetService.instagramIconUrl != "null") {
      return of(url);
    }
    return of(null);
  }
  get mapPinIconUrl(): Observable<string>  {
    let url = "../../../assets/custom-icons/map-pin-icon.svg";
    if (!this.assetService.mapPinIconUrl || this.assetService.mapPinIconUrl != "null") {
      return of(url);
    }
    return of(null);
  }
  get notificationIconUrl(): Observable<string>  {
    let url = "../../../assets/custom-icons/notification-icon.svg";
    if (!this.assetService.notificationIconUrl || this.assetService.notificationIconUrl != "null") {
      return of(url);
    }
    return of(null);
  }
  get ordertabIconUrl(): Observable<string>  {
    let url = "../../../assets/custom-icons/order-tab-icon.svg";
    if (!this.assetService.orderTabIconUrl || this.assetService.orderTabIconUrl != "null") {
      return of(url);
    }
    return of(null);
  }
  get pointsEarnedUrl(): Observable<string>  {
    let url = "../../../assets/custom-icons/points-earned-icon.svg";
    if (!this.assetService.pointsEarnedIconUrl || this.assetService.pointsEarnedIconUrl != "null") {
      return of(url);
    }
    return of(null);
  }
  get referIconUrl(): Observable<string>  {
    let url = "../../../assets/custom-icons/refer-icon.svg";
    if (!this.assetService.referFriendIconUrl || this.assetService.referFriendIconUrl != "null") {
      return of(url);
    }
    return of(null);
  }
  get rewardTabIconUrl(): Observable<string>  {
    let url = "../../../assets/custom-icons/reward-tab-icon.svg";
    if (!this.assetService.rewardTabIconUrl || this.assetService.rewardTabIconUrl != "null") {
      return of(url);
    }
    return of(null);
  }
  get storeTabIconUrl(): Observable<string>  {
    let url = "../../../assets/custom-icons/store-tab-icon.svg";
    if (!this.assetService.storeTabIconUrl || this.assetService.storeTabIconUrl != "null") {
      return of(url);
    }
    return of(null);
  }
  get twitterIconUrl(): Observable<string>  {
    let url = "../../../assets/custom-icons/twitter-icon.svg";
    if (!this.assetService.twitterIconUrl || this.assetService.twitterIconUrl != "null") {
      return of(url);
    }
    return of(null);
  }
  get yelpIconUrl(): Observable<string>  {
    let url = "../../../assets/custom-icons/yelp-icon.svg";
    if (!this.assetService.yelpIconUrl || this.assetService.yelpIconUrl != "null") {
      return of(url);
    }
    return of(null);
  }
  get customTabIconUrl(): Observable<string>  {
    let url = "../../../assets/custom-icons/custom-tab-icon.svg";
    if (!this.assetService.customTabIconUrl || this.assetService.customTabIconUrl != "null") {
      return of(url);
    }
    return of(null);
  }
  
  get forceMode(): string  {
    let mode = config.FORCE_MODE;
    if (mode == "" || mode == null || mode == undefined) {
      return null;
    }
    return mode;
  }
  get mobileAppAllowRegistrtaionByEmail() : Observable<boolean> {   
    return of(this.assetService.mobileAppAllowRegistrtaionByEmail)
  } 
  get mobileAppRewardsRedeemMessage() : Observable<string> {   
    return of(this.assetService.mobileAppRewardsRedeemMessage)
  } 
  get mobileAppWalletHomeMessage() : Observable<string> {   
    return of(this.assetService.mobileAppWalletHomeMessage)
  } 
  get replyTo() : Observable<string> {   
    return of(this.assetService.replyTo)
  } 

  get onboardingSlides() : Observable<string[]> { 
    let selectedTheme = localStorage.getItem('slected-app-theme');

    let slide1 = this.assetService.onboardingSlide_1 && this.assetService.onboardingSlide_1 != 'null' ? this.assetService.onboardingSlide_1 : null;
    let slide2 = this.assetService.onboardingSlide_2 && this.assetService.onboardingSlide_2 != 'null' ? this.assetService.onboardingSlide_2 : null;
    let slide3 = this.assetService.onboardingSlide_3 && this.assetService.onboardingSlide_3 != 'null' ? this.assetService.onboardingSlide_3 : null;
    let slide4 = this.assetService.onboardingSlide_4 && this.assetService.onboardingSlide_4 != 'null' ? this.assetService.onboardingSlide_4 : null;

    let slide5 = this.assetService.onboardingSlide_5 && this.assetService.onboardingSlide_5 != 'null' ? this.assetService.onboardingSlide_5 : null;
    let slide6 = this.assetService.onboardingSlide_6 && this.assetService.onboardingSlide_6 != 'null' ? this.assetService.onboardingSlide_6 : null;
    let slide7 = this.assetService.onboardingSlide_7 && this.assetService.onboardingSlide_7 != 'null' ? this.assetService.onboardingSlide_7 : null;
    let slide8 = this.assetService.onboardingSlide_8 && this.assetService.onboardingSlide_8 != 'null' ? this.assetService.onboardingSlide_8 : null;

    let slides_array_light = [slide1, slide2, slide3, slide4];
    let slides_array_dark = [slide5, slide6, slide7, slide8];

    if(selectedTheme == 'true') {
      return of(slides_array_dark)
    }       
    else {
      return of(slides_array_light)
    }    
  }

  /* Setters */
  set merchantLocationId(locationId: string) { localStorage.setItem('merchantLocationId', locationId)}
  set customerId(id: number) { localStorage.setItem('customerId', id.toString())}
  set customerPhone(phone: string) { localStorage.setItem('phone', phone.toString())}
  
  async checkIfAppNeedsUpdate(appInfo: any) : Promise<any> {
    return await new Promise<any>(async (resolve, reject) => {
      await this.assetService.checkIfAppNeedsUpdate(appInfo).then((result) => {
         if (result) {
           resolve(result);
         }
      }, error => {
        reject(error);
      });
    });    
  }


 

  viewNotifications() 
  {
    this.router.navigate(['notifications'])
  }


  /* Navigation Helpers */
  goToProfileEdit() 
  {
    this.router.navigate(['/tabs/profile-edit']);
  }

  goToProfile() 
  {
    this.router.navigate(['/tabs/profile']);
  }

  goToOnlineOrder() {
    let origin =  window.location.origin;
    console.log(origin);
    this.router.navigateByUrl(`${origin}/online-order`)
  }

  reloadPage() {
    location.reload();
  }

  goToHome() {
    this.router.navigate(['/tabs/home']);
  }

  goToChangePass() {
    this.router.navigate(['/tabs/change-password']);
  }

  goToRewards() {
    this.router.navigate(['/tabs/rewards']);
  }

  /* In-App Browser Modes */
  public openWithSystemBrowser(url : string){
    let target = "_system";
    this.inAppBrowser.create(url,target,this.browser_options);
  }

  public openWithInAppBrowser(url : string){
    let target = "_blank";
    this.inAppBrowser.create(url,target,this.browser_options);
  }

  public openWithCordovaBrowser(url : string){
    let target = "_self";
    this.inAppBrowser.create(url,target,this.browser_options);
  } 

  public getV3LogoUrl() {
    if (!this.assetService.logoUrl) {                     
      return null
    }
    else {
      return this.assetService.logoUrl;
    }
  }

  /* Helper function */
  addhttp($url) {
    if (!/^https?:\/\//i.test($url)) {
      $url = 'http://' + $url;
    }
    return $url;
  }

  storeModal(x)
  {
        this.modalInst[this.modal_num] = x;
        this.modal_num++;
  }

  closeAllModals()
  {
    for(var i=0; i< this.modalInst.length; i++)
    {
      this.modalInst[i].dismiss();
    }
  }

  getMinutesBetweenDates(startDate, endDate) {
    var diff = endDate.getTime() - startDate.getTime();
    return (diff / 60000);
  }

  // clearOnboardingRequest() {
  //   this.onboardingRequest.IdentityClaim = new IOnboardIdentityClaim();
  //   this.onboardingRequest.Profile = new OnboardProfile();
  // }

  // clearProfileUpdateRequest() {
  //   this.profileUpdateRequest = new ProfileUpdateRequest();
  // }

  async clearAndReloadAssets() {
    localStorage.removeItem(Constants.LAST_FETCHED_MERCHANT_ASSET_DATA_KEY);
   await this.assetService.getMerchantAssets();
  }

  setfeedbackBGUrl(url: string) {
    this.feedbackBGUrl$.next(url);
  }
 
}
