import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from 'src/app/common/Util';
import { IRewardUIModel } from 'src/app/models/yourReward';

@Component({
  selector: 'app-your-reward',
  templateUrl: './your-reward.component.html',
  styleUrls: ['./your-reward.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YourRewardComponent implements OnInit {

  @Input() yourReward: IRewardUIModel = null;
  constructor(private router: Router) { }

  ngOnInit() {   
  }

  seeDetails() {
    if( !Utils.isNullOrUndefined( this.yourReward ) ) {
      this.router.navigateByUrl('reward-details', { state: { reward: this.yourReward } });
    }
  }

  calculatePointsPercentage(currentPoints, requiredPoints) {
    const totalPoints = currentPoints + requiredPoints;
    const percentage = (currentPoints / totalPoints) * 100;
    let pString = percentage.toFixed(2).toString() + "%";
    return pString;
  }
}
