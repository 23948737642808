import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AssetService } from '../../../services/asset.service';
import { UtilService } from '../../../services/util.service';
import { WalletService } from '../../../services/wallet.service';
import { Router } from '@angular/router';
import { GetWalletLoadDataResponse, SysCodeResponse } from '../../../models/wallet';
import { ErrorsOptions, LoadingOptions } from '../../popup-model/popup-model.component';
import { PopupService } from '../../../services/popup.service';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Utils } from 'src/app/common/Util';
import { BaseComponent } from '../../base/base.component';
import { takeUntil } from 'rxjs/operators';
import { WalletV4Service } from 'src/app/services/wallet-v4.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-toast-card-info',
  templateUrl: './toast-card-info.component.html',
  styleUrls: ['./toast-card-info.component.scss'],
  providers: [Keyboard]
})
export class ToastCardInfoComponent extends BaseComponent implements OnInit {

  @ViewChild('backBtn') backBtn: ElementRef<HTMLElement>;

  walletLoadData: GetWalletLoadDataResponse;
  secureBy: string;
  cardCaptureForm: FormGroup;
  cardNumber: FormControl;
  expiry: FormControl;
  cvv: FormControl;
  postal: FormControl;
  cardHolderName: FormControl;
  saveCardDetail: FormControl;
  countryCode: FormControl;
  errors: string[] = [];
  isStoredCardEnabled: boolean = false;
  cardNumberMask: Array<any> = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/,];
  expiryMask: Array<any> = [/[0-1]/, /\d/, '/', /\d/, /\d/];
  cvvMask: Array<any> = [/\d/, /\d/, /\d/, /\d/, /\d/];
  //customPatterns = { '0': { pattern: new RegExp('^[0-9]*$') } };
  loadWalletAmount: any = 0.00;
  currencySymbol: string = "$";
  canSaveCreditCard: boolean = false;
  countries: any;
  defaultCountryCode: string = 'USA';

  keyboardOpened: boolean = false;

  constructor(private fb: FormBuilder,
    private router: Router,
    private util: UtilService,
    private walletV4Service: WalletV4Service,
    private walletService: WalletService, // COSMIN this was kept from backward compatibility reasons to avaoid changing the payment forms
    private assetService: AssetService,
    public popupService: PopupService,
    public keyboard: Keyboard,
    private translate: TranslateService
  ) {
      super();
  }

  ngOnInit() {

    this.loadWalletAmount = (Number(sessionStorage.getItem('RELOAD_AMOUNT'))).toFixed(2);
    this.currencySymbol = sessionStorage.getItem('currency_symbol');
    this.cardNumber = new FormControl('', [Validators.required]);
    this.expiry = new FormControl('', [Validators.required]);
    this.cvv = new FormControl('', [Validators.required]);
    this.postal = new FormControl('', [Validators.required]);
    this.cardHolderName = new FormControl('', [Validators.required]);
    this.saveCardDetail = new FormControl(false);
    this.secureBy = sessionStorage.getItem('PAYMENT_TYPE');
    this.countryCode = new FormControl('', [Validators.required]);

    this.cardCaptureForm = this.fb.group({
      'cardNumber': this.cardNumber,
      'expiry': this.expiry,
      'cvv': this.cvv,
      'postal': this.postal,
      'cardHolderName': this.cardHolderName,
      'saveCardDetail': this.saveCardDetail,
      'countryCode': this.countryCode
    });
  }

  goToNextElement(evnt, nextElement) {
    if (nextElement.el.name == "expiry" && evnt.target.value.length >= 19) {
      nextElement.setFocus();
    }
    else if (nextElement.el.name == "cvv" && evnt.target.value.length >= 5) {
      nextElement.setFocus();
    }
    else if (nextElement.el.name == "postal" && evnt.target.value.length >= 5) {
      nextElement.setFocus();
    }
    else if (nextElement.el.name == "cardHolderName" && evnt.target.value.length >= 7) {
      nextElement.setFocus();
    }
  }

  async ionViewWillEnter() {

    this.listenKeyboardevents();

    this.currencySymbol = this.assetService.currencySymbol;
    this.loadWalletAmount = this.walletService.reloadAmount;

    await this.walletService.getWalletLoadData().then(async (result: GetWalletLoadDataResponse) => {
      if (result) {
        this.walletLoadData = result;
        this.canSaveCreditCard = result.CanSaveCreditCard;
      }
    }, async error => {
      Utils.handleError(error, this.popupService, 'There was an error while processing this request. Please try again later.')

      await this.backDynamic();

    }).then(async () => {
      await this.walletService.GetCountriesAlpha3().then(async (result: SysCodeResponse) => {
        if (result.OK == true) {
          this.countries = result.Result;
          this.setDefaultMerchantContry(result.MerchantCountryCode);
          console.log(this.countries);
        }
      }, async error => {
        Utils.handleError(error, this.popupService, 'There was an error while processing this request. Please try again later.');

        await this.backDynamic();

      })
    });    
  }

  listenKeyboardevents() {
    this.keyboard.onKeyboardWillShow()
    .pipe(takeUntil(this.destroyed$))
    .subscribe(() => this.keyboardOpened = true );

    this.keyboard.onKeyboardWillHide().subscribe(() => this.keyboardOpened = false );
  }

  async backDynamic() {
    let el: HTMLElement = (<any>this.backBtn).el;
    el.click();
  }

  ionViewDidLeave() {
    this.cardCaptureForm.reset();
  }

  setDefaultMerchantContry(merchantCountryCode: string) {
    if (merchantCountryCode != "" && merchantCountryCode != null && merchantCountryCode != undefined) {
      this.defaultCountryCode = merchantCountryCode;
    }
  }

  async onSubmit() {
    let cardDetails = this.cardCaptureForm.value;
    let validatedCardDetails = this.ValidateCardDetails();
    if (!validatedCardDetails) {
      let errorsOptions = {
        errMessage: 'Card Submission Failed!',
        errorList: this.errors
      } as ErrorsOptions;

      await this.popupService.erros(errorsOptions);
      return;
    }

    const payloadData = {
      CardHolderName: cardDetails.cardHolderName,
      Token: null,
      CustomerId: 0,
      Amount: this.loadWalletAmount,
      CardNumber: validatedCardDetails.cardNumber.replace(/\s/g, ''),
      CardExpirationMonth: validatedCardDetails.expiryMonth,
      CardExpirationYear: validatedCardDetails.expiryYear,
      CardCVV: validatedCardDetails.cvv,
      SaveCreditCard: validatedCardDetails.saveCardDetail,
      PayWithSavedCreditCard: false,
      PostalCode: cardDetails.postal,
      CountryCode: cardDetails.countryCode
    };

    const cardLast4Digit = payloadData.CardNumber.substr(-4);
    const confirmMessage: string = `Your card ending in ${cardLast4Digit} will be charged ${this.currencySymbol}${Number(payloadData.Amount).toFixed(2)}`;
    this.popupService.confirmV4(null, confirmMessage).then(async (confirmed: boolean) => {
      if (confirmed) {

        let loadingOptions = {
          processingMessage: 'Processing Payment...'
        } as LoadingOptions;

        let loader = await this.popupService.loading(loadingOptions);
        
        await this.walletService.loadFundsV4(payloadData).then(async (response: any) => {

          await loader.dismiss();

          console.log(response);
          if (response.OK == false) {
            let errorMessage = this.translate.instant('Error verifying card. Please try again.') ;

            if (response.ErrorDetails && response.ErrorDetails.ErrorType == 11) {
              errorMessage = this.translate.instant('The minimum amount to reload wallet is $')  + Number(response.ErrorDetails.Amount).toFixed(2);
            }
            if (response.ErrorDetails && response.ErrorDetails.ErrorType == 10) {
              errorMessage = this.translate.instant('Enter lower amount, wallet balance cannot exceed limit of $')  + Number(response.ErrorDetails.Amount).toFixed(2);
            }
                           
            await this.popupService.failV4('Payment Failed', errorMessage);

            console.error(response.Error)
          } else {
            await this.popupService.successV4('Payment Success', 'Your wallet has been reloaded.').then(async () => {
              this.walletV4Service.clearInMemoryCache();
              this.walletService.clearCache();
              this.walletService.getWalletBalance({ MerchantLocationId: this.util.merchantLocationId });
              this.router.navigateByUrl('wallet');
            });
          }
        });
      }
    });
    
  }


  ValidateCardDetails(): cardCaptureDetails {
    let cardDetails = new cardCaptureDetails();
    this.errors = [];
    this.validateCardNumber(cardDetails);
    this.validateExpiry(cardDetails);
    this.validateCVV(cardDetails);
    //validate postal
    let postalCode: string = this.cardCaptureForm.value.postal;
    if (postalCode.length > 0) {
      cardDetails.postal = postalCode;
    }
    else {
      cardDetails.valid = false;
      this.errors.push("Zip/Postal code required.");
    }
    cardDetails.countryCode = this.cardCaptureForm.value.countryCode;
    cardDetails.cardHolderName = this.cardCaptureForm.value.cardHolderName;
    cardDetails.saveCardDetail = this.cardCaptureForm.value.saveCardDetail;
    if (!cardDetails.valid) {
      cardDetails = null;
    }
    return cardDetails;
  }

  validateCardNumber(cardDetails = new cardCaptureDetails()) {
    //Validate card number
    let crdNumber = this.cardCaptureForm.value.cardNumber.replace(/\s/g, "");
    if (crdNumber.length > 0) {
      if (crdNumber.length >= 12) {
        cardDetails.cardNumber = crdNumber;
        return true;
      }
      else {
        cardDetails.valid = false;
        this.errors.push("Card number is invalid.");
        return false;
      }
    }
    else {
      cardDetails.valid = false;
      this.errors.push("Card number required.");
      return false;
    }
  }

  validateExpiry(cardDetails = new cardCaptureDetails()) {
    //Validate Expiry
    let exp: string = this.cardCaptureForm.value.expiry;
    if (exp.length > 0) {
      if (exp.length == 5 && exp.indexOf('/') > 0) {
        let month: number = +(exp.split('/')[0]);
        let year: number = +("20" + exp.split('/')[1]);
        let datetime = new Date();
        if (month > 0 && month < 13) {
          if (year > datetime.getFullYear()) {
            cardDetails.expiryMonth = month.toString();
            cardDetails.expiryYear = year.toString().slice(2, 4);
            return true;
          }
          else if (year == datetime.getFullYear()) {
            if (month >= datetime.getMonth()) {
              cardDetails.expiryMonth = month.toString();
              cardDetails.expiryYear = year.toString().slice(2, 4);
              return true;
            }
            else {
              cardDetails.valid = false;
              this.errors.push("Expiry date is invalid.");
              return false;
            }
          }
          else {
            cardDetails.valid = false;
            this.errors.push("Expiry date is invalid.");
            return false;
          }
        }
        else {
          cardDetails.valid = false;
          this.errors.push("Expiry date is invalid.");
          return false;
        }
      }
      else {
        cardDetails.valid = false;
        this.errors.push("Expiry date is invalid.");
        return false;
      }
    }
    else {
      cardDetails.valid = false;
      this.errors.push("Expiry date required.");
      return false;
    }
  }

  validateCVV(cardDetails = new cardCaptureDetails()) {
    //validate CVV
    let cvvNumber: string = this.cardCaptureForm.value.cvv;
    if (cvvNumber.length > 0) {
      if (cvvNumber.length >= 3) {
        cardDetails.cvv = cvvNumber;
        return true;
      }
      else {
        cardDetails.valid = false;
        this.errors.push("CVV is invalid.");
        return false;
      }
    }
    else {
      cardDetails.valid = false;
      this.errors.push("CVV number required.");
      return false;
    }
  }

}

export class cardCaptureDetails {
  cardNumber = "";
  expiryMonth = "";
  expiryYear = "";
  cvv = "";
  postal = "";
  countryCode = "";
  cardHolderName = "";
  saveCardDetail = false;
  valid = true;
}
