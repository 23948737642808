import { Injectable } from '@angular/core';
import { Constants } from '../common/Constants';
import { IGetProfileRequest } from '../models/request/getProfileRequest';
import { Subject, zip } from 'rxjs';
import { AssetService } from './asset.service';
import { AuthV4Service } from './auth-v4.service';
import { Utils } from '../common/Util';
import { ApiService } from './api.service';
import { EndPoint } from '../enums/endpoints';
import { take } from 'rxjs/operators';
import { IGetProfileResponse } from '../models/response/getProfileResponse';
import { AlertController, Platform } from '@ionic/angular';
import { HelperService } from './helper.service';
import { NotificationV4Service } from './notification-v4.service';
import { NotificationService } from './notification.service';
import { IUserAccount } from '../models/userAcccount';
import { NextProfileUpdateStepType } from '../enums/next-profile-update-step-type';
import { AlertService } from './alert.service';
import { Router } from '@angular/router';
import { IClearCacheNotificationClient } from '../models/ClearCacheNotificationClient';
import { CacheCleaningNotificationService } from './cache-cleaning-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { DeleteCustomerProfileRequest, IProfileUpdateRequest } from '../models/profile-update-request';
import { MenuData } from '../models/menuData';
import { TileData } from '../models/tileData';
import { ITileAndMenuData } from '../models/tileDataAndMenuData';
import { PopupService } from './popup.service';
import { ISMSSendRequest } from '../models/request/sendSMSRequest';
import { IEmailSendRequest } from '../models/request/sendEmailRequest';
import { ISendSMSResponse } from '../models/response/sendSMSReponse';

@Injectable({
  providedIn: 'root'
})
export class ProfileV4Service implements IClearCacheNotificationClient {
  private profile: IGetProfileResponse = null;
  public profile$: Subject<IGetProfileResponse> = new Subject<IGetProfileResponse>();

  constructor(
    private assetService: AssetService,
    private apiService: ApiService,
    private authV4Service: AuthV4Service,
    private helperService: HelperService,
    private platform: Platform,
    private notificationService: NotificationService,
    private notificationV4Service: NotificationV4Service,
    private alertService:AlertService,
    private popupService: PopupService,
    private router: Router,
    private clearCacheNotificationService: CacheCleaningNotificationService,
    private translate: TranslateService,
    private alertCtrl: AlertController
  ) {
    this.handleClearCacheNotification();
    this.handleClearMemoryCacheNotification();
  }

  forceGetProfile()
  {
    console.log('ProfileV4Service.forceGetProfile()');
    // clear the cached values
    this.profile = null;
    const request: IGetProfileRequest = <IGetProfileRequest>{ MerchantId: this.assetService.merchantId, LocationId: null, Channel: null};
    this.getProfile(request, true, true );
  }

  private checkPushPermission(response: IGetProfileResponse) {
    response.Profile.IsPushEnabled = false;
    if (this.platform.is('cordova')) {
      this.notificationService.deviceHasPermission().then((permission) => {
        response.Profile.IsPushEnabled = permission;
      });
    }
  }
  public calculateHomeLocationDistance(response: IGetProfileResponse) {
    if(response.HomeLocation && response.HomeLocation.MerchantLocationName && response.HomeLocation.MerchantLocationId) {
      response.Profile.HomeLocation = response.HomeLocation;
    }

    if(response.HomeLocation && response.HomeLocation.Lat && response.HomeLocation.Lng) {
      this.helperService.getDistanceForHomeLocation(response.HomeLocation.Lat, response.HomeLocation.Lng).then((distance) => {
        response.HomeLocation.Distance = distance;
        response.Profile.HomeLocation = response.HomeLocation;
      });
    }
  }

  getProfile(request: IGetProfileRequest, loadFromLocalStorageInCaseOfError = true, logoutInCaseOfError: boolean = false ) {
    console.log(`ProfileV4Service.getProfile() request=${Utils.debugGetSafeJSON(request)}`);
    if( !Utils.isNullOrUndefined(this.profile)) {
      this.setProfile(this.profile);
      return;
    }

    // the purpose of the 2nd and 3rd call is to cache their responses. These 2 calls are perfromed in parallel with the profile call
    zip(
      /* STEP  1: GET Profile */
      this.apiService.postData(EndPoint.PROFILE, request),
      /* STEP  2: get notifications. The notification count will appear in the header. */
      this.notificationV4Service.getNotifications()
    )
    .pipe(take(1))
    .subscribe( (response: any) => {
        console.log(`ProfileV4Service.getProfile() response=${Utils.debugGetSafeJSON(response)}`);

        const profileResponse: IGetProfileResponse = response[0];
        if( Utils.isNullOrUndefined(profileResponse) || Utils.isTrue(profileResponse.IsError) ) 
        {
          console.error(profileResponse?.Error);
          if( loadFromLocalStorageInCaseOfError ) {
            this.setProfile(<IGetProfileResponse>Utils.readFromLocalStorage(Constants.PROFILE_DATA_KEY));
          }
        } else {          
          /* STEP  3: Check Push Permissions */
         //this.checkPushPermission(profileResponse);

          this.storeProfileToLocalStorage(profileResponse);
          this.setProfile(profileResponse);
        }
      },
      (error) => {
        console.error(`ProfileV4Service.getProfile() error=${Utils.debugGetSafeJSON(error)}`);
        if( logoutInCaseOfError ) {
          this.authV4Service.logout();
        } else if( loadFromLocalStorageInCaseOfError ) {
          this.setProfile(<IGetProfileResponse>Utils.readFromLocalStorage(Constants.PROFILE_DATA_KEY));
        }
    });
  }

  private setProfile(profile: IGetProfileResponse, publish: boolean = true) {
    this.profile = profile;
    if( publish ) {
      this.profile$.next(this.profile);
    }
  }

  storeProfileToLocalStorage(profile: IGetProfileResponse) {
    Utils.storeToLocalStorage(Constants.PROFILE_DATA_KEY, profile);
    if(Utils.isNullOrUndefined(profile)) {
      localStorage.removeItem(Constants.CUSTOMER_ID_KEY);
    } else {  
      localStorage.setItem(Constants.CUSTOMER_ID_KEY, profile.CustomerId.toString());
    }
  }

  getMerchantEmail() { return this.assetService.companyEmail }

  getMerchantWebsite() { return this.assetService.companyWebsite }

  getMerchantName() { return this.assetService.companyName }

  get MerchantId(): number { return this.assetService.merchantId } 

  getMerchantGuid() { return this.assetService.merchantGuid }   

  getCustomerDeviceId() { return localStorage.getItem(Constants.DEVICE_ID_KEY) ?  Number(localStorage.getItem(Constants.DEVICE_ID_KEY)) : 0  } 

  getCurrencySymbol() { return this.assetService.currencySymbol }

  getCurrencycode() { return  this.assetService.currencyCode } 

  getMerchantlocationId() { 
    if (this.profile && this.profile.HomeLocation && this.profile.HomeLocation.MerchantLocationId) {
      return this.profile.HomeLocation.MerchantLocationId;
    }
    else {
      let customerData = <IGetProfileResponse>Utils.readFromLocalStorage(Constants.PROFILE_DATA_KEY);
      console.log(customerData);
      if (customerData && customerData.HomeLocation && customerData.HomeLocation.MerchantLocationId) {
        return customerData.HomeLocation.MerchantLocationId;
      }
      return null;      
    }
  }

  getMerchantLogoUrl() {
    if (!this.assetService.logoUrl) {                     
      return null
    }
    else {
      return this.assetService.logoUrl;
    }
  }

  getCustomeId() { 
    if (this.profile && this.profile.Profile && this.profile.CustomerId) {
      return this.profile.CustomerId;
    }
    else {
      let customerData = <IGetProfileResponse>Utils.readFromLocalStorage(Constants.PROFILE_DATA_KEY);
      return customerData.Profile.CustomerId;
    }
  }

  getCustomerFullName() { 
    if (this.profile && this.profile.Profile && this.profile.Profile.Name) {
      return this.profile.Profile.Name;
    }
    else {
      let customerData = <IGetProfileResponse>Utils.readFromLocalStorage(Constants.PROFILE_DATA_KEY);
      return customerData.Profile.Name;
    }
  }

  get isWalletEnabled() {
    if (this.assetService.walletEnabled == 'true') {
      return true;
    } else {
      return false;
    }
  }

  goToProfile() {
    this.router.navigateByUrl('/profile');
  }

  async deleteProfile(userAcccount: IUserAccount) {
    const alert = await this.alertCtrl.create({
      message: this.translate.instant("Are you sure, you want to delete this account?"),
      buttons: [
        {
          text: this.translate.instant("CANCEL"),
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        },
        {
          text: this.translate.instant("PROCEED"),
          handler: async () => {
            let deleteCustomerProfileRequest: DeleteCustomerProfileRequest = {
              CustomerId: Number(userAcccount.id),
              Source: "MOBILE_APP"
            };            
            console.log(`Account Deletion Request Data => ${JSON.stringify(deleteCustomerProfileRequest)}`);
            
            await this.authV4Service.deleteProfile(deleteCustomerProfileRequest).then(async (result) => {
              if (result && result.success) {          
                console.log(result);
                this.deleteSuccessOk(this.translate.instant('Your account has now been deleted. All personal information will be removed within 24 hours'), this.translate.instant("Success"));
              }
              else if (result && !result.success) {
                this.alertService.present(this.translate.instant("There was an error while processing this request, please try again or contact support"));
              }
            }, async error => {
              this.alertService.present(this.translate.instant("There was an error while processing this request, please try again or contact support"));
            });   
          }
        }
      ],
      cssClass: "custom-alert"
    });
    return await alert.present();   
  }

  deleteSuccessOk(msg: string, title = "", backDropDismiss: boolean = true): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertCtrl.create({
        header: title,
        message: msg,
        backdropDismiss: backDropDismiss,
        buttons: [{
          text: this.translate.instant('OK'),
          handler: () => {
            alert.dismiss().then(() => {
              ProfileV4Service.clearProfileCache();
              this.authV4Service.logout(true);
            });
            return false;
          }
        }]
      });
      await alert.present();
    });
  }

  async updateProfile(request: IProfileUpdateRequest) {
    return await new Promise<any>((resolve, reject) => {
      this.apiService.postData(EndPoint.EDIT_PROFILE, request).subscribe(async (result: any) => {
        if(result && !result.IsError) {
          resolve(result); 
        }
        else if(result && result.IsError)  
        {      
            reject(result.Error)  
        }
      },
      (err) => {
        reject(err)   
      });
    });
  }

  async updatePassword(data: any) {
    return await new Promise<any>((resolve, reject) => {
      this.apiService.postData(EndPoint.UPDATE_PASSWORD, data).subscribe(async (result: any) => {
        if(result && !result.IsError) {
          resolve(result); 
        }
        else if(result && result.IsError)  
        {      
            reject(result.Error)  
        }
      },
      (err) => {
        reject(err)   
      });
    });
  }
 
  handleUpdateProfileResult(profileUpdateRequest: IProfileUpdateRequest, response: any) {
    console.log(`ProfileV4Service.handleUpdateProfileResult() response=${Utils.debugGetSafeJSON(response)}`);
    if (response.NextStep == NextProfileUpdateStepType.ERROR) {
      console.error(`ProfileV4Service.handleUpdateProfileResult() nextStep == ERROR`);
      Utils.handleError(response, this.popupService); 
    }
    else if (response.NextStep == NextProfileUpdateStepType.PHONE_VERIFICATION) {
      console.log(`ProfileV4Service.handleUpdateProfileResult() nextStep == PHONE_VERIFICATION`);
      this.handleUpdateProfilePhoneVerification(profileUpdateRequest);
    }
    else if (response.NextStep == NextProfileUpdateStepType.EMAIL_VERIFICATION) {
      console.log(`ProfileV4Service.handleUpdateProfileResult() nextStep == EMAIL_VERIFICATION`);
      this.handleUpdateProfileEmailVerification(profileUpdateRequest);
    }
    else if (response.NextStep == NextProfileUpdateStepType.DONE) {
      console.log(`ProfileV4Service.handleUpdateProfileResult() nextStep == DONE`);
      ProfileV4Service.clearProfileCache();
      this.setProfile(null);
      this.popupService.successV4('Success', 'Profile Updated Successfully.')
      .then(() => {
        if( Utils.isTrue(profileUpdateRequest.needToLogout) ) {
          this.authV4Service.logout(true);
        } else {
          this.goToProfile();
        }
      });
    }
  }

  handleUpdateProfilePhoneVerification(profileUpdateRequest: IProfileUpdateRequest) {
    console.log(`ProfileV4Service.handleNextStepPhoneVerification()`);

    const smsSendRequest: ISMSSendRequest = <ISMSSendRequest>{
      MerchantId: profileUpdateRequest.MerchantId,
      MerchantLocationId: profileUpdateRequest.LocationId,
      Phone: profileUpdateRequest.Phone,
      CustomerId: profileUpdateRequest.CustomerId,
      SourceDevice: null
    };
    console.log(`ProfileV4Service.handleNextStepPhoneVerification(), smsSendRequest=`, smsSendRequest);

    this.authV4Service.sendSMSVerificationCode(smsSendRequest)
    .pipe(take(1))
    .subscribe((response: ISendSMSResponse) => {
      console.log(`ProfileV4Service.handleNextStepPhoneVerification() sendSMSVerificationCode() subscribe response=${Utils.debugGetSafeJSON(response)})`);

      if( Utils.isNullOrUndefined(response) || response.IsError ) {
        if( !Utils.isNullOrUndefinedOrWhitespace(response?.LimitErrorMessage ) ) {
          Utils.handleError(response, this.popupService, response.LimitErrorMessage);
        } else {
          Utils.handleError(response, this.popupService, 'We could not verify your phone number. Please provide a valid phone number.');
        }
      } else {
        this.router.navigateByUrl('phone-verification', { state: { 
          phone: smsSendRequest.Phone, 
          verificationType: Constants.UNVERIFIED_PHONE, 
          customerId: smsSendRequest.CustomerId,
          onboardingRequest: null,
          profileUpdateRequest: profileUpdateRequest,
        }, replaceUrl: true });
      }
    },
    (error) => Utils.handleError(error, this.popupService, 'We could not verify your phone number. Please provide a valid phone number.')
    );
  }

  handleUpdateProfileEmailVerification(profileUpdateRequest: IProfileUpdateRequest) {
    console.log(`ProfileV4Service.handleUpdateProfileEmailVerification()`);

    const sendEmailRequest: IEmailSendRequest = <IEmailSendRequest>{
      MerchantId: profileUpdateRequest.MerchantId,
      MerchantLocationId: profileUpdateRequest.LocationId,
      Email: profileUpdateRequest.Email,
      CustomerId: profileUpdateRequest.CustomerId
    };
    
    console.log(`ProfileV4Service.handleUpdateProfileEmailVerification(), sendEmailRequest=`, sendEmailRequest);
    this.authV4Service.sendEmailVerificationCode(sendEmailRequest)
    .pipe(take(1))
    .subscribe((response) => {
      console.log(`ProfileV4Service.handleNextStepPhoneVerification() sendEmailVerificationCode() subscribe response=${Utils.debugGetSafeJSON(response)})`);
      this.router.navigateByUrl('phone-verification', { state: { 
        email: sendEmailRequest.Email, 
        verificationType: Constants.UNVERIFIED_EMAIL, 
        customerId: sendEmailRequest.CustomerId,
        onboardingRequest: null,
        profileUpdateRequest: profileUpdateRequest,
      }, replaceUrl: true });
    });
  }

  handleUpdatePasswordResult(result: any) {
    if (result == "SUCCESS") {
      this.popupService.successV4('Success', 'Profile Updated Successfully.')
      .then(() => {
        ProfileV4Service.clearProfileCache();
        this.setProfile(null);
        this.goToProfile();        
      });  
    } else {
      this.popupService.failV4('Error', 'Password could not be changed. Please review details and try again.');
    }
  }

  static clearProfileCache() {
    localStorage.removeItem(Constants.PROFILE_DATA_KEY);
    localStorage.removeItem(Constants.PUNCHCARDS_KEY);
    localStorage.removeItem(Constants.REWARDS_KEY);
    localStorage.removeItem(Constants.SPOTLIGHT_KEY);
  }

  handleClearCacheNotification(): void {
    this.clearCacheNotificationService.cacheCleaningNotification$
    .subscribe( () => {
      this.setProfile(null);
      ProfileV4Service.clearProfileCache();
    });
  }

  handleClearMemoryCacheNotification(): void {
    this.clearCacheNotificationService.memoryCacheCleaningNotification$
    .subscribe( () => this.setProfile(null) );
  }

  public static get isProfileLoaded(): boolean {
    return !Utils.isNullOrUndefinedOrWhitespace(localStorage.getItem(Constants.PROFILE_DATA_KEY));
  }

  public get profileName(): string {
    return Utils.isNullOrUndefined(this.profile) ? '' : this.profile.Profile.Name;
  }

  getTileAndMenuData(): ITileAndMenuData {
    console.log('ProfileV4Service.getTileAndMenuData()')
    let menuData: MenuData = new MenuData();
    let tileData: TileData = new TileData(0, this.getCurrencySymbol(), 0);

    if (!Utils.isNullOrUndefined(this.profile)) {
      menuData = new MenuData(this.profile.Profile.Name);
      tileData = new TileData(this.profile.Wallet.Balance, this.getCurrencySymbol(), this.profile.Points.Balance);
    }
    return <ITileAndMenuData>{menuData: menuData, tileData: tileData};
  }
}
