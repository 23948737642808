import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from "@angular/router";
import {IonicModule} from "@ionic/angular";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TranslateModule } from '@ngx-translate/core';
import { HowToRedeemComponent } from './how-to-redeem/how-to-redeem.component';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { QRCodeModule } from 'angularx-qrcode';
import { PreferredLocationListComponent } from './preferred-location-list/preferred-location-list.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { StripeCardInfoComponent } from './wallet/stripe-card-info/stripe-card-info.component';
import { CloverCardInfoComponent } from './wallet/clover-card-info/clover-card-info.component';
import { NgxStripeModule } from 'ngx-stripe';
import { NgxMaskIonicModule } from 'ngx-mask-ionic';
import { ToastCardInfoComponent } from './wallet/toast-card-info/toast-card-info.component';
import { SquareCardInfoComponent } from './wallet/square-card-info/square-card-info.component';
import { PopupModelComponent } from './popup-model/popup-model.component';
import { VersionUpdateComponent } from './version-update/version-update.component';
import { AdditionalFieldCheckboxComponent } from './additional-field-checkbox/additional-field-checkbox.component';
import { CloverEcomCardInfoComponent } from './wallet/clover-ecom-card-info/clover-ecom-card-info.component';
import { MonthFormatPipe } from '../pipes/month-format.pipe';
import { DayFormatPipe } from '../pipes/day-format.pipe';
import { PunchCardComponent } from './punch-card/punch-card.component';
import { PunchCardsComponent } from './punch-cards/punch-cards.component';
import { TwinTileComponent } from './twin-tile/twin-tile.component';
import { SingleTileComponent } from './single-tile/single-tile.component';
import { HeaderComponent } from './header/header.component';
import { RewardsSliderComponent } from './rewards-slider/rewards-slider.component';
import { RewardsPointsComponent } from './rewards-points/rewards-points.component';
import { SlideMenuComponent } from './slide-menu/slide-menu.component';
import { CarouselLabelComponent } from './carousel-label/carousel-label.component';
import { SwipeModule } from '../directives/swipe/swipe.module';
import { YourRewardsComponent } from './yourRewards/your-rewards/your-rewards.component';
import { YourRewardComponent } from './yourRewards/your-reward/your-reward.component';
import { RewardDetailsCardComponent } from './rewardDetails/reward-details-card/reward-details-card.component';
import { RewardDetailsExplanationComponent } from './rewardDetails/reward-details-explanation/reward-details-explanation.component';
import { RewardDetailsInstructionsComponent } from './rewardDetails/reward-details-instructions/reward-details-instructions.component';
import { ComponentHeaderComponent } from './component-header/component-header.component';
import { YourRewardsTabComponent } from './yourRewards/your-rewards-tab/your-rewards-tab.component';
import { PunchCardsCardComponent } from './yourPunchCards/punch-cards-card/punch-cards-card.component';
import { PunchCardsCarouselComponent } from './yourPunchCards/punch-cards-carousel/punch-cards-carousel.component';
import { YourPunchCardsTabComponent } from './yourPunchCards/your-punch-cards-tab/your-punch-cards-tab.component';
import { DecisionPopupComponent } from './decision-popup/decision-popup.component';
import { ReloadAmountComponent } from './reload-amount/reload-amount.component';
import { SuccessPopupComponent } from './success-popup/success-popup.component';
import { InfoPopupComponent } from './yourPunchCards/info-popup/info-popup.component';
import { BaseComponent } from './base/base.component';
import { WaysToEarnComponent } from './earn/ways-to-earn/ways-to-earn.component';
import { ReferAFriendCardComponent } from './ReferAFriend/refer-afriend-card/refer-afriend-card.component';
import { ReferAFriendExplanationComponent } from './ReferAFriend/refer-afriend-explanation/refer-afriend-explanation.component';
import { ReferAFriendInstructionsComponent } from './ReferAFriend/refer-afriend-instructions/refer-afriend-instructions.component';
import { ReferAFriendPopupComponent } from './ReferAFriend/refer-afriend-popup/refer-afriend-popup.component';
import { HeaderGoBackComponent } from './header-go-back/header-go-back.component';
import { LeaveFeedbackCardComponent } from './LeaveFeedback/leave-feedback-card/leave-feedback-card.component';
import { LeaveFeedbackFormComponent } from './LeaveFeedback/leave-feedback-form/leave-feedback-form.component';
import { StoreInfoListItemComponent } from './StoreInfoV4/store-info-list-item/store-info-list-item.component';
import { StoreInfoMapComponent } from './StoreInfoV4/store-info-map/store-info-map.component';
import { StoreInfoCardComponent } from './StoreInfoV4/store-info-card/store-info-card.component';
import { MakeAPurchaseCardComponent } from './MakeAPurchase/make-apurchase-card/make-apurchase-card.component';
import { MakeAPurchaseExplanationComponent } from './MakeAPurchase/make-apurchase-explanation/make-apurchase-explanation.component';
import { MakeAPurchaseInstructionsComponent } from './MakeAPurchase/make-apurchase-instructions/make-apurchase-instructions.component';
import { BirthdayOfferCardComponent } from './BirthdayOffer/birthday-offer-card/birthday-offer-card.component';
import { BirthdayOfferExplanationComponent } from './BirthdayOffer/birthday-offer-explanation/birthday-offer-explanation.component';
import { QRCodeContentComponent } from './QRCode/qrcode-content/qrcode-content.component';
import { LoginEmailContentComponent } from './LoginEmail/login-email-content/login-email-content.component';
import { LoginEmailFormComponent } from './LoginEmail/login-email-form/login-email-form.component';
import { ForgotPasswordFormComponent } from './ForgotPasswordV4/forgot-password-form/forgot-password-form.component';
import { OnBoard1ContentComponent } from './OnBoard1/on-board1-content/on-board1-content.component';
import { OnBoard1FormComponent } from './OnBoard1/on-board1-form/on-board1-form.component';
import { PhoneVerificationFormComponent } from './PhoneVerification/phone-verification-form/phone-verification-form.component';
import { HelpContentComponent } from './Help/help-content/help-content.component';
import { OnBoard2FormComponent } from './OnBoard2/on-board2-form/on-board2-form.component';
import { OnBoardingCarouselComponent } from './OnBoardingSlides/on-boarding-carousel/on-boarding-carousel.component';
import { OnBoardingCarouselBottomCardComponent } from './OnBoardingSlides/on-boarding-carousel-bottom-card/on-boarding-carousel-bottom-card.component';
import { NotificationListComponent } from './Notifications/notification-list/notification-list.component';
import { NotificationDetailComponent } from './NotificationDetails/notification-detail/notification-detail.component';
import { ActivityComponent } from './Activity/activity/activity.component';
import { ChangePasswordFormComponent } from './ChangePassword/change-password-form/change-password-form.component';
import { WalletComponent } from './wallet/wallet/wallet.component';
import { LoadWalletComponent } from './LoadWallet/load-wallet/load-wallet.component';
import { ProfileComponent } from './Profile/profile/profile.component';
import { AdditionalProfileDataComponent } from './OnBoard2/additional-profile-data/additional-profile-data.component';
import { ThankYouForFeedbackCardComponent } from './ThankYouForFeedback/thank-you-for-feedback-card/thank-you-for-feedback-card.component';
import { DigitOnlyDirective } from '../directives/digits-only.directive';
import { SwiperModule } from 'swiper/angular';
import { NumberFormatPipe } from '../pipes/number-format.pipe';
import { PhoneFormatPipe } from '../pipes/phone-format.pipe.';
import { PromotionDetailsCardComponent } from './PromotionDetails/promotion-details-card/promotion-details-card.component';
import { PromotionDetailsExplanationComponent } from './PromotionDetails/promotion-details-explanation/promotion-details-explanation.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { InputPopupComponent } from './input-popup/input-popup.component';
import { ConfirmPopupComponent } from './confirm-popup/confirm-popup.component';
import { ProvidePhoneNumberContentComponent } from './ProvidePhoneNumber/provide-phone-number-content/provide-phone-number-content.component';
import { ProvidePhoneNumberFormComponent } from './ProvidePhoneNumber/provide-phone-number-form/provide-phone-number-form.component';
import { NotificationPopupComponent } from './notification-popup/notification-popup.component';
import { ForgotPasswordProvideNewPasswordFormComponent } from './ForgotPasswordProvideNewPassword/forgot-password-provide-new-password-form/forgot-password-provide-new-password-form.component';
import { PureTextPipe } from '../pipes/pure-text.pipe';
import { NetworkErrorComponent } from './network-error/network-error.component';
import { StoreInfoHoursComponent } from './StoreInfoV4/store-info-hours/store-info-hours.component';

@NgModule({
  declarations: [
    TermsComponent,
    PrivacyComponent,
    HowToRedeemComponent,
    QrCodeComponent,
    WalletComponent,
    LoadWalletComponent,
    StripeCardInfoComponent,
    CloverCardInfoComponent,
    CloverEcomCardInfoComponent,
    ToastCardInfoComponent,
    SquareCardInfoComponent,
    PreferredLocationListComponent,
    ConfirmationComponent,
    NotificationPopupComponent,
    PopupModelComponent,
    VersionUpdateComponent,
    AdditionalFieldCheckboxComponent,
    MonthFormatPipe,
    DayFormatPipe,
    PunchCardComponent,
    PunchCardsComponent,
    CarouselLabelComponent,
    TwinTileComponent,
    SingleTileComponent,
    HeaderComponent,
    RewardsSliderComponent,
    RewardsPointsComponent,
    SlideMenuComponent,
    YourRewardsComponent,
    YourRewardComponent,
    RewardDetailsCardComponent,
    RewardDetailsInstructionsComponent,
    RewardDetailsExplanationComponent,
    ComponentHeaderComponent,
    YourRewardsTabComponent,
    PunchCardsCardComponent,
    YourPunchCardsTabComponent,
    PunchCardsCarouselComponent,
    DecisionPopupComponent,
    InputPopupComponent,
    ReloadAmountComponent,
    SuccessPopupComponent,
    InfoPopupComponent,
    BaseComponent,
    WaysToEarnComponent,
    ReferAFriendPopupComponent,
    ReferAFriendInstructionsComponent,
    ReferAFriendExplanationComponent,
    ReferAFriendCardComponent,
    HeaderGoBackComponent,
    LeaveFeedbackCardComponent,
    LeaveFeedbackFormComponent,
    StoreInfoListItemComponent,
    StoreInfoMapComponent,
    StoreInfoCardComponent,
    ReferAFriendCardComponent,
    MakeAPurchaseCardComponent,
    MakeAPurchaseExplanationComponent,
    MakeAPurchaseInstructionsComponent,
    BirthdayOfferCardComponent,
    BirthdayOfferExplanationComponent,
    QRCodeContentComponent,
    LoginEmailContentComponent,
    LoginEmailFormComponent,
    ForgotPasswordFormComponent,
    OnBoard1ContentComponent,
    OnBoard1FormComponent,
    PhoneVerificationFormComponent,
    HelpContentComponent,
    OnBoard2FormComponent,
    OnBoardingCarouselComponent,
    OnBoardingCarouselBottomCardComponent,
    NotificationListComponent,
    NotificationDetailComponent,
    ActivityComponent,
    ChangePasswordFormComponent,
    ProfileComponent,
    AdditionalProfileDataComponent,
    ThankYouForFeedbackCardComponent,
    DigitOnlyDirective,
    NumberFormatPipe,
    PhoneFormatPipe,
    PromotionDetailsCardComponent,
    PromotionDetailsExplanationComponent,
    ConfirmPopupComponent,
    ProvidePhoneNumberContentComponent,
    ProvidePhoneNumberFormComponent,
    ForgotPasswordProvideNewPasswordFormComponent,
    PureTextPipe,
    NetworkErrorComponent,
    StoreInfoHoursComponent
  ],
  exports: [
    TermsComponent,
    PrivacyComponent,
    HowToRedeemComponent,
    QrCodeComponent,
    WalletComponent,
    LoadWalletComponent,
    StripeCardInfoComponent,
    CloverCardInfoComponent,
    CloverEcomCardInfoComponent,
    ToastCardInfoComponent,
    SquareCardInfoComponent,
    PreferredLocationListComponent,
    ConfirmationComponent,
    NotificationPopupComponent,
    PopupModelComponent,
    VersionUpdateComponent,
    AdditionalFieldCheckboxComponent,
    PunchCardComponent,
    PunchCardsComponent,
    CarouselLabelComponent,
    TwinTileComponent,
    SingleTileComponent,
    HeaderComponent,
    RewardsSliderComponent,
    RewardsPointsComponent,
    SlideMenuComponent,
    YourRewardsComponent,
    YourRewardComponent,
    RewardDetailsCardComponent,
    RewardDetailsInstructionsComponent,
    RewardDetailsExplanationComponent,
    ComponentHeaderComponent,
    YourRewardsTabComponent,
    PunchCardsCardComponent,
    YourPunchCardsTabComponent,
    PunchCardsCarouselComponent,
    DecisionPopupComponent,
    InputPopupComponent,
    ReloadAmountComponent,
    SuccessPopupComponent,
    InfoPopupComponent,
    BaseComponent,
    WaysToEarnComponent,
    ReferAFriendPopupComponent,
    ReferAFriendInstructionsComponent,
    ReferAFriendExplanationComponent,
    ReferAFriendCardComponent,
    HeaderGoBackComponent,
    LeaveFeedbackCardComponent,
    LeaveFeedbackFormComponent,
    StoreInfoListItemComponent,
    StoreInfoMapComponent,
    StoreInfoCardComponent,
    MakeAPurchaseCardComponent,
    MakeAPurchaseExplanationComponent,
    MakeAPurchaseInstructionsComponent,
    BirthdayOfferCardComponent,
    BirthdayOfferExplanationComponent,
    QRCodeContentComponent,
    LoginEmailContentComponent,
    LoginEmailFormComponent,
    ForgotPasswordFormComponent,
    OnBoard1ContentComponent,
    OnBoard1FormComponent,
    PhoneVerificationFormComponent,
    HelpContentComponent,
    OnBoard2FormComponent,
    OnBoardingCarouselComponent,
    NotificationListComponent,
    NotificationDetailComponent,
    ActivityComponent,
    ChangePasswordFormComponent,
    ProfileComponent,
    ThankYouForFeedbackCardComponent,
    DigitOnlyDirective,
    NumberFormatPipe,
    PhoneFormatPipe,
    PromotionDetailsCardComponent,
    PromotionDetailsExplanationComponent,
    ConfirmPopupComponent,
    ProvidePhoneNumberContentComponent,
    ProvidePhoneNumberFormComponent,
    ForgotPasswordProvideNewPasswordFormComponent,
    NetworkErrorComponent,
    StoreInfoHoursComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule,
    QRCodeModule,
    SwipeModule,
    AgmCoreModule.forRoot({
      apiKey: environment.MAP_KEY,
    }),
    NgxStripeModule.forRoot('pk_test_SJll8oij4pUjhmk1jqpEGzbU'),
    NgxMaskIonicModule.forRoot(),
    SwiperModule,
    InlineSVGModule.forRoot(),
  ],
  entryComponents: [],
})
export class ComponentsModule { }
