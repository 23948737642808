import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EndPoint } from '../enums/endpoints';
import { GetBalanceResponse, GetWalletLoadDataResponse } from '../models/wallet';
import { ApiService } from './api.service';
import { LoadingService } from './loading.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  walletBalance$: Observable<GetBalanceResponse>;
  walletLoadData$: Observable<GetWalletLoadDataResponse>;

  // COSMIN This code is used for backward compatibility for the payment componets which are passing data using this service
  private _reloadAmount: number = 0;
  get reloadAmount() {
    return this._reloadAmount;
  }
  set reloadAmount(value: number) {
      this._reloadAmount = value;
  }

  private _stripePublicKey: string = "";
  get stripePublicKey() {
    return this._stripePublicKey;
  }
  set stripePublicKey(value: string) {
      this._stripePublicKey = value;
  }
  

  constructor(private apiService: ApiService, private util: UtilService) { }

  async getWalletBalance(data: any) {

    return new Promise<any>((resolve, reject) => {
      if (!this.walletBalance$) {
        this.apiService.postData(EndPoint.USER_WALLET_BALANCE, data)
          .subscribe(
            async (result: any) => {
              if (result && result.IsError) {
                reject(result.Error)
              }
              else if (result) {
                this.walletBalance$ = result;
                resolve(result)
              }
            },
            async (err) => {
              reject(err)
            });
      }
      else {
        resolve(this.walletBalance$);
      }
    });
  }


  async getWalletLoadData() {

    return new Promise<any>((resolve, reject) => {
      if (!this.walletLoadData$) {
        this.apiService.getData(EndPoint.USER_WALLET_DATA)
          .subscribe(
            async (result: any) => {
              if (result && result.IsError) {
                reject(result.Error)
              }
              else if (result) {
                this.walletLoadData$ = result;
                resolve(result)
              }
            },
            async (err) => {
              reject(err)
            });
      }
      else {
        resolve(this.walletLoadData$);
      }
    });
  }

  async setCustomerPhone(data: any) {

    return new Promise<any>((resolve, reject) => {
      this.apiService.postData(EndPoint.USER_SET_PHONE, data)
        .subscribe(
          async (result: any) => {
            if (result && result.IsError) {
              reject(result.Error)
            }
            else if (result) {
              resolve(result)
            }
          },
          async (err) => {
            reject(err)
          });
    });
  }

  async loadFundsV4(data: any) {

    return new Promise<any>((resolve, reject) => {
      this.apiService.postData(EndPoint.USER_LOAD_WALLET, data)
        .subscribe(
          async (result: any) => {
            if (result && result.IsError) {
              reject(result.Error)
            }
            else if (result) {
              resolve(result)
            }
          },
          async (err) => {
            reject(err)
          });
    });
  }

  async saveCustomerCard(data: any) {

    return new Promise<any>((resolve, reject) => {
      this.apiService.postData(EndPoint.USER_SAVE_CUSTOMER_CARD, data)
        .subscribe(
          async (result: any) => {
            if (result && result.IsError) {
              reject(result.Error)
            }
            else if (result) {
              resolve(result)
            }
          },
          async (err) => {
            reject(err)
          });
    });
  }

  async deleteCard(data: any) {

    return new Promise<any>((resolve, reject) => {
      this.apiService.postData(EndPoint.USER_DELETE_CARD, data)
        .subscribe(
          async (result: any) => {
            if (result && result.IsError) {
              reject(result.Error)
            }
            else if (result) {
              resolve(result)
            }
          },
          async (err) => {
            reject(err)
          });
    });
  }

  async GetCountriesAlpha3() {

    return new Promise<any>((resolve, reject) => {
      this.apiService.getData(EndPoint.GET_COUNTRIES_ALPHA3)
        .subscribe(
          async (result: any) => {
            if (result && result.IsError) {
              reject(result.Error)
            }
            else if (result) {
              resolve(result)
            }
          },
          async (err) => {
            reject(err)
          });
    });
  }

  clearCache() {
    this.walletBalance$ = null;
    this.walletLoadData$ = null;
  }
}
