import { Injectable } from '@angular/core';
import { AlertController } from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private alertCtrl: AlertController) { }

  async presentCustom(msg: string, subHeader: string, buttonText: string, backDropDismiss: boolean = true): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertCtrl.create({
        subHeader: subHeader,
        message: msg,
        buttons: [{
          text: buttonText,
          handler: () => {
            alert.dismiss().then(() => {
              resolve(true);
            });
            return false;
          }
        }],
        cssClass: "custom-alert",
        backdropDismiss: backDropDismiss
      });
      await alert.present();
    });
  }

  async present(msg: string) {
    const alert = await this.alertCtrl.create({
      subHeader: 'Info',
      message: msg,
      buttons: ['OK'],
      cssClass: "custom-alert"
    });
    await alert.present();
  }

  presentOk(msg: string, title = "", backDropDismiss: boolean = true): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertCtrl.create({
        header: title,
        message: msg,
        backdropDismiss: backDropDismiss,
        buttons: [{
          text: 'OK',
          handler: () => {
            alert.dismiss().then(() => {
              resolve(true);
            });
            return false;
          }
        }],
        cssClass: "custom-alert"
      });

      await alert.present();
    });
  }

  async confirm(msg: string, okText = 'ok', cancelText = 'cancel') {
    const alert = await this.alertCtrl.create({
      message: msg,
      buttons: [
        {
          text: cancelText,
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        },
        {
          text: okText,
          handler: () => {
            console.log('ok clicked');
          }
        }
      ],
      cssClass: "custom-alert"
    });
    return await alert.present();
  }
}
