import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-response',
  templateUrl: './response.page.html',
  styleUrls: ['./response.page.scss'],
})
export class ResponsePage implements OnInit {
  @Input() modalCtrl: ModalController;
  @Input() title: string;
  @Input() message: string;

  constructor() { }

  ngOnInit() {
  }

  close() {
    this.modalCtrl.dismiss();
  }

}
