import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-on-boarding-carousel-bottom-card',
  templateUrl: './on-boarding-carousel-bottom-card.component.html',
  styleUrls: ['./on-boarding-carousel-bottom-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnBoardingCarouselBottomCardComponent implements OnInit {
  @Input() pageIndex: number = 0;
  @Output() goToPageIndex: EventEmitter<number> = new EventEmitter<number>(); 


  constructor(private router: Router) { }

  ngOnInit() {}

  next() {
    if( this.pageIndex === 3 ) {
      this.goToHomePage();
    } else {
      this.changePageIndex(this.pageIndex + 1);
    }
  }

  skipTutorial() {
    this.goToHomePage();
  }

  private goToHomePage() {
    this.router.navigateByUrl('tabs/home');
  }

  changePageIndex(newIndex: number) {
    this.goToPageIndex.emit(newIndex);
  }

}
