import { Injectable } from '@angular/core';
import {
  NativeGeocoder,
  NativeGeocoderResult,
  NativeGeocoderOptions,
} from "@ionic-native/native-geocoder/ngx";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { ApiService } from 'src/app/services/api.service';
import { LocationAccuracy } from '@ionic-native/location-accuracy';
import { AndroidPermissions } from '@ionic-native/android-permissions';
import { ToastService } from 'src/app/services/toast.service';
import { EndPoint } from 'src/app/enums/endpoints';
import { Subject } from 'rxjs';
import { UtilService } from './util.service';
import { Platform } from '@ionic/angular';
import { ThemeService } from './theme.service';
import { MapsAPILoader } from '@agm/core';
import { IStoreLocation } from '../models/storeLocation';
import { Constants } from '../common/Constants';
import { Utils } from '../common/Util';
import { IMapRestriction } from '../models/mapRestriction';
import { AssetService } from './asset.service';
import { IMapIconUrl } from '../models/storeLocationsMapInformation';
import { ISocialInfo } from '../models/socialInfo';
import { ProfileV4Service } from './profile-v4.service';
import { Storage } from '@ionic/storage';
declare let window: any;
declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  storeList: Array<IStoreLocation> = [];
  storeList$: Subject<Array<IStoreLocation>> = new Subject<Array<IStoreLocation>>();

  err: any;
  latitude: number = 0;
  longitude: number = 0;
  facebookIconUrl = "../../../assets/svg_icon/facebook-share.svg";
  twitterIconUrl = "../../../assets/svg_icon/twitter-share.svg";
  instagramIconUrl = "../../../assets/svg_icon/instagram-share.svg";
  yelpIconUrl = "../../../assets/svg_icon/yelp-share.svg";
  mapPinIconUrl: IMapIconUrl = Constants.DEFAULT_STORE_LOCATIONS_MAP_INFORMATION.mapPinIconUrl;
  currentCenter = { lat: null, lng: null};
  popupPreferenceResult = false;


  /* Inject Custom Styles - For MAP */
  public styles: Array<any> = Constants.DEFAULT_MAP_STYLE;
  public endpoint = EndPoint.STORE_INFO;


  constructor(
    public mapsApiLoader: MapsAPILoader,
    private platForm: Platform,
    private util: UtilService, 
    private toastService: ToastService,  
    private api: ApiService, 
    private nativeGeocoder: NativeGeocoder,  
    private geolocation: Geolocation,
    private themeService: ThemeService,
    private assetService: AssetService,
    private storage: Storage
    ) 
    {
      // this.themeService.mapStyles$.subscribe(x => {
      //   this.styles = x;
      // });
      this.util.facebookIconUrl.subscribe(x => {        
        if (x) {
          this.facebookIconUrl = x;
        } 
      }); 
      this.util.twitterIconUrl.subscribe(x => {        
        if (x) {
          this.twitterIconUrl = x;
        }
      });
      this.util.instagramIconUrl.subscribe(x => {        
        if (x) {
          this.instagramIconUrl = x;
        }
      });
      this.util.yelpIconUrl.subscribe(x => {        
        if (x) {
          this.yelpIconUrl = x;
        }
      });   
      this.util.mapPinIconUrl.subscribe(x => {
        if (x) {
          this.mapPinIconUrl.url = x;
        }        
      });
      LocationService.setDistanceUnit('K');   
  }

  async populateStores() : Promise<any>  {
    return await new Promise<any>(async (resolve, reject) => {
        const stored_list = localStorage.getItem(Constants.STORE_INFO_KEY);
        const stored_list_time = localStorage.getItem(Constants.LAST_FETCHED_STORE_INFO_DATA_KEY);
        const data = {};

        if (!ProfileV4Service.isProfileLoaded) {
          this.endpoint = EndPoint.STORE_LOCATIONS;
        }
        else
        {
          this.endpoint = EndPoint.STORE_INFO;
        }

        if( !Utils.isNullOrUndefined(stored_list) && !Utils.isNullOrUndefined(stored_list_time) ) {
          let startDate = new Date(stored_list_time);
          let endDate = new Date();
          let store_list_Data = JSON.parse(stored_list);

          let timeDiff = Utils.getMinutesBetweenDates(startDate, endDate);
            if (timeDiff > 5) {
              this.api.postData(this.endpoint, data).subscribe(async (res: any) => {
                if (res) {
                  if (res.Locations && res.Locations.length > 0) {
                    LocationService.setDistanceUnit( Utils.getUnit(res.Locations) );
                    if (res.Locations[0].Lat && res.Locations[0].Lng) {
                      this.currentCenter.lat = res.Locations[0].Lat;
                      this.currentCenter.lng = res.Locations[0].Lng;                    
                      console.log(`The Current Center Latitude is  => ${this.currentCenter.lat}`);
                      console.log(`The Current Center Longitude is  => ${this.currentCenter.lng}`);
                    }    
                                   
                    let lastFetchedData = new Date();              
                    localStorage.setItem(Constants.STORE_INFO_KEY, JSON.stringify(res.Locations));
                    localStorage.setItem(Constants.LAST_FETCHED_STORE_INFO_DATA_KEY, lastFetchedData.toString());
                  }
                  if (!res.Locations) {
                    res.Locations = [];
                  }
                  await this.setStoreList(res.Locations);
                  resolve(this.storeList);
                }
              });              
            }
            else {
              if (store_list_Data.length > 0 && store_list_Data[0].Lat && store_list_Data[0].Lng) {
                this.currentCenter.lat = store_list_Data[0].Lat;
                this.currentCenter.lng = store_list_Data[0].Lng;                    
                console.log(`The Current Center Latitude is  => ${this.currentCenter.lat}`);
                console.log(`The Current Center Longitude is  => ${this.currentCenter.lng}`);
              }    
              await this.setStoreList(store_list_Data);
              resolve(this.storeList);
            }
        }
        else {
            this.api.postData(this.endpoint, data).subscribe(async (res: any) => {
              if (res) {
                if (res.Locations && res.Locations.length > 0) {
                  LocationService.setDistanceUnit( Utils.getUnit(res.Locations) );
                  if (res.Locations[0].Lat && res.Locations[0].Lng) {
                    this.currentCenter.lat = res.Locations[0].Lat;
                    this.currentCenter.lng = res.Locations[0].Lng;                    
                    console.log(`The Current Center Latitude is  => ${this.currentCenter.lat}`);
                    console.log(`The Current Center Longitude is  => ${this.currentCenter.lng}`);
                  }    
                  let lastFetchedData = new Date();                       
                  localStorage.setItem(Constants.STORE_INFO_KEY, JSON.stringify(res.Locations));
                  localStorage.setItem(Constants.LAST_FETCHED_STORE_INFO_DATA_KEY, lastFetchedData.toString());
                }
                if (!res.Locations) {
                  res.Locations = [];
                }
                await this.setStoreList(res.Locations);
                resolve(this.storeList);
              }             
            });      
        }
    });   
  }
  

  clearStoreListCache()
  {
    localStorage.removeItem(Constants.STORE_INFO_KEY);
    localStorage.removeItem(Constants.LAST_FETCHED_STORE_INFO_DATA_KEY);
  }

  async getDistanceForHomeLocation(Lat: number, Lng: number) {

    return await new Promise<number>(async (resolve, reject) => {

      var isCordovaApp = !!window.cordova;
      
      let homeLocationDistance: number = null;      
    

      if (isCordovaApp) {
        await this.getLocationPopUpPreference().then(async (preferenceResult) => {        
          if (preferenceResult) {
            this.popupPreferenceResult = preferenceResult;
          }
        }); 
        if (this.popupPreferenceResult) {
          console.log('Requesting Co-Ordinates for getDistanceForHomeLocation');
          await this.geolocation.getCurrentPosition({enableHighAccuracy: true, timeout: 5000, maximumAge: 0}).then((resp) => {

            console.log(resp); 
  
            this.latitude = resp.coords.latitude;
            this.longitude = resp.coords.longitude;
  
            homeLocationDistance = Number(Utils.distance(resp.coords.latitude, resp.coords.longitude, Lat, Lng, LocationService.distanceUnit).toFixed(2));
  
            resolve(homeLocationDistance);
          }, error => {
  
            resolve(homeLocationDistance);
  
          });
        }
        else {
          resolve(homeLocationDistance);
        }        
      }
      else {
        await navigator.permissions.query({name:'geolocation'}).then(async (result) => {
          if (result.state == 'granted') {
            await this.getCurrentPositionFromBrowser().then((result) => {
              if(result !== null) {
                homeLocationDistance = Number(Utils.distance(result.coords.latitude, result.coords.longitude, Lat, Lng, LocationService.distanceUnit).toFixed(2));
              }
            });
          }
          else if (result.state == 'prompt') {
            await this.getCurrentPositionFromBrowser().then((result) => {
              if(result !== null) {
                homeLocationDistance = Number(Utils.distance(result.coords.latitude, result.coords.longitude, Lat, Lng, LocationService.distanceUnit).toFixed(2));
              }
            });      
          }
          else if (result.state == 'denied') {
            resolve(homeLocationDistance);
          }        
        })
      }
    });
    
  }

  async initializeStoreInfo(msg: string): Promise<any> {
    return await new Promise<any>(async (resolve, reject) => {
      console.log("Loading Locations....");
       
      if (!ProfileV4Service.isProfileLoaded) {
        this.endpoint = EndPoint.STORE_LOCATIONS;
      }
      
      var isCordovaApp = !Utils.isNullOrUndefined(window.cordova);
  
      let options: NativeGeocoderOptions = {
        useLocale: true,
        maxResults: 5,
      };  
     
      /* Check if the request was made from mobile app */
      if (isCordovaApp) {
        await this.getLocationPopUpPreference().then(async (preferenceResult) => {        
          if (preferenceResult) {
            this.popupPreferenceResult = preferenceResult;
          }
        });
        if (this.popupPreferenceResult) {
          console.log('Requesting Co-Ordinates for initializeStoreInfo');
          this.geolocation.getCurrentPosition({enableHighAccuracy: true, timeout: 5000, maximumAge: 0})
          .then((resp) => {
            console.log(resp);            
            let data = {};
            this.populateStores().then(async (response: Array<IStoreLocation>) => {
              console.log(`LocationService.initializeStoreInfo() populateStores().then() response=${Utils.debugGetSafeJSON(response)}`);
              
              const storeList: Array<IStoreLocation> = Utils.isNullOrUndefined(response) ? [] : response;
              
              if (!Utils.isNullOrUndefined(response))
              {
                this.latitude = resp.coords.latitude;
                this.longitude = resp.coords.longitude;
                storeList.forEach(async (element: IStoreLocation) => {
                  // If api has already returned lat and lng - no need to calculate with native functionality
                  if (element.Lat && element.Lng) {
                    Utils.calculateStoreDistance(this.latitude, this.longitude, element, LocationService.distanceUnit);
                  } else {
                    // COSMIN there is no adress: string member! Ask Lyoid what is the code from below doing.
                    await this.nativeGeocoder.forwardGeocode((<any>element).address, options)
                      .then((result: NativeGeocoderResult[]) => {
                        element.Lat = Number(result[0].latitude);
                        element.Lng = Number(result[0].longitude);
                        Utils.calculateStoreDistance(this.latitude, this.longitude, element, LocationService.distanceUnit);                      
                      })
                      .catch((error: any) => 
                      {
                        element.Distance = 0;
                        console.log("error", error)
                      });         
                  }
                });
                await this.setStoreList(storeList);
                resolve(this.storeList);
              } else {
                await this.setStoreList([]);
              }
            })
          })
          .catch(async (error) => {
            if (error && error.message) 
            {
                // Request GPS Permission
                if ((error.message == "User denied Geolocation" || error.message == "Timeout expired")) {
                    const permission = await this.requestGPSPermission();
                    if (permission === 'CAN_REQUEST' || permission === 'GOT_PERMISSION') {
                      const canUseGPS = await this.askToTurnOnGPS();
                      this.postGPSPermission(canUseGPS);
                    }
                    else 
                    {
                      /* Denied by client */                   
                      await this.postGPSPermission(false).then(async () => {
                        resolve(this.storeList);
                      });        
                    } 
                } 
                await this.postGPSPermission(false).then(async () => {
                  resolve(this.storeList);
                });               
            }
            else {
               // this.postGPSPermission(false);      
               this.populateStores().then((res: any) => {
                this.storeList = res;
                if (!this.storeList) {
                  this.storeList = [];
                }
                this.storeList.forEach(async (element) => {
                  if (!element.Lat || !element.Lng) {
                    element.Lat = 0;
                    element.Lng = 0;
                  }                  
                  element.Distance = 0;
                });             
              }).then(() => {
                if(this.storeList.length > 1) 
                {
                  this.sortbyDistance();              
                }   
                resolve(this.storeList);
              });
              await this.postGPSPermission(false).then(async () => {
                resolve(this.storeList);
              });              
            }  
          });
        }
        else {
          this.populateStores().then((res: any) => {
            this.storeList = res;
            if (!this.storeList) {
              this.storeList = [];
            }
            this.storeList.forEach(async (element) => {
              if (!element.Lat || !element.Lng) {
                element.Lat = 0;
                element.Lng = 0;
              }  
              element.Distance = 0;
            });             
          }).then(() => {
            if(this.storeList.length > 1) 
            {
              this.sortbyDistance();              
            }   
            resolve(this.storeList);
          });
          await this.postGPSPermission(false).then(async () => {
            resolve(this.storeList);
          });        
        }
       
      } else {
         // If browser app get current position from browser
         await navigator.permissions.query({name:'geolocation'})
         .then(async (result) => {
           console.log(result)
           if (result.state == 'granted') {
            await this.getLocationForBrowser().then(() => {
              resolve(this.storeList);
            });            
           }
           else if (result.state == 'prompt') {
            await this.getLocationForBrowser().then(() => {
              resolve(this.storeList);
            });                            
           }
           else if (result.state == 'denied') {
            let data = {};
            await this.populateStores().then((response: Array<IStoreLocation>) => {
              this.storeList = Utils.isNullOrUndefined(response) ? [] : response;
              this.storeList.forEach(async (element: IStoreLocation) => {
                if (!element.Lat || !element.Lng) {
                  element.Lat = 0;
                  element.Lng = 0;
                }  
                element.Distance = 0;
              });             
            }).then(async () => {
              if(this.storeList.length > 1) 
              {
                this.sortbyDistance();              
              }
              await this.setStoreList(this.storeList);
              resolve(this.storeList);
            });
            await this.postGPSPermission(false).then(async () => {
            });            
           }
         })
      }      
    });
  }

  async revealPosition(resp) 
  {
    if(
      resp.coords.latitude && Number(resp.coords.latitude) 
      &&
      resp.coords.longitude && Number(resp.coords.longitude)
      ) 
    {
      this.latitude = Number(resp.coords.latitude);
      this.longitude =  Number(resp.coords.longitude);
    }
   
    console.log(resp);
  }

  async requestGPSPermission(): Promise<any> {
    return await new Promise<any>(async (resolve, reject) => {
      var isCordovaApp = !!window.cordova;
      if (isCordovaApp) {
        LocationAccuracy.canRequest().then((canRequest: boolean) => {
          if (canRequest) {
            resolve('CAN_REQUEST');
          } 
          else if(this.platForm.is('android')) 
          {
              // Show 'GPS Permission Request' dialogue
              AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION)
                .then(result => {
                      if (result.hasPermission) {
                        // If having permission show 'Turn On GPS' dialogue
                        resolve('GOT_PERMISSION');
                      } else {
                          // If not having permission ask for permission
                          resolve('DENIED_PERMISSION');
                      }
                  }, error => {
                    // Show alert if user click on 'No Thanks'
                    this.toastService.show('requestPermission Error requesting location permissions ' + error);
              });
          }
          else if(this.platForm.is('ios') && !canRequest)  {
            resolve('DENIED_PERMISSION');
          }
        });        
      } else {
        // this.toastService.show('In order to sort by nearest location, please allow app to access location in device settings.');
        await this.getLocationForBrowser();
      }      
    });
  }

  async askToTurnOnGPS(): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
       LocationAccuracy.canRequest().then((canRequest: boolean) => {
          if (canRequest) {
            // the accuracy option will be ignored by iOS
            LocationAccuracy.request(LocationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY)
            .then(() => {
              resolve(true);
            },
              error => 
              { 
                resolve(false); 
              }
            );
          } else {
             resolve(false); 
          }
       });
    });
  }

  async postGPSPermission(canUseGPS: boolean) 
  {
    if (canUseGPS) 
    { 
      this.initializeStoreInfo('Loading....');
    }
    else {
      let hasCoOrdinates = false;
      await this.populateStores().then(async (response: Array<IStoreLocation>) => {        
          this.storeList = Utils.isNullOrUndefined(response) ? [] : response;
          if (!this.storeList) {
            this.storeList = [];
          }
          this.latitude = 0;
          this.longitude = 0;
          let coOrdinates = await this.getLocationCoOrdinates();

          if (coOrdinates && coOrdinates.latitude && coOrdinates.longitude) {
            this.latitude = coOrdinates.latitude;
            this.longitude = coOrdinates.longitude;
            hasCoOrdinates = true;
          }
          this.storeList.forEach(async (element: IStoreLocation) => {
            if (this.latitude == 0 || this.longitude == 0) {
              if (!element.Lat || !element.Lng) {
                element.Lat = 0;
                element.Lng = 0;
              }  
              element.Distance = 0;
            }  
            else {
              element.Distance = Number(Utils.distance(this.latitude, this.longitude, element.Lat, element.Lng, LocationService.distanceUnit).toFixed(2));
            }                  
          });
          if( this.storeList.length > 0) 
          {
            this.sortbyHomeLocation();
          }
          await this.setStoreList(this.storeList);
      });   
      if (!hasCoOrdinates) {
        await this.toastService.show("Permission to access location was denied. For better results allow location permission");
      }        
    }
  }

  async getLocationForBrowser() : Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(async (resp) => {
        if(resp.coords.latitude &&  Number(resp.coords.latitude) && resp.coords.longitude &&  Number(resp.coords.longitude)) 
        {
          this.latitude = Number(resp.coords.latitude);
          this.longitude =  Number(resp.coords.longitude);
          let data = {};
          await this.populateStores().then((response: Array<IStoreLocation>) => {   
            this.storeList = Utils.isNullOrUndefined(response) ? [] : response;

            this.storeList.forEach((element: IStoreLocation) => {

              if (!this.currentCenter || !this.currentCenter.lat || !this.currentCenter.lng ) {
                console.log('Current Center Not defined');
                this.currentCenter.lat = this.latitude;
                this.currentCenter.lng = this.longitude;
              }
              
              Utils.calculateStoreDistance(this.latitude, this.longitude, element, LocationService.distanceUnit);
            
            });             
          }).then(async () => {
            if(this.storeList.length > 1) 
            {
              this.sortbyDistance();              
            }
            await this.setStoreList(this.storeList);  
            resolve(this.storeList);
          });
        }         
        console.log(resp);
      },
      (error) => {
        this.positionDenied(error);
      } ,{enableHighAccuracy: true, timeout: 3000, maximumAge: 0});
    });
   
  }

  async getCurrentPositionFromBrowser() {
    return await new Promise<any>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(async (resp) => {
        if(resp.coords.latitude &&  Number(resp.coords.latitude) && resp.coords.longitude &&  Number(resp.coords.longitude)) {
          resolve(resp.coords);
        }
      }, error => {
        resolve(null);
      });
    });    
  }

  async _getCurrentPositionFromBrowser() {
    return await new Promise<any>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          if (resp.coords.latitude && Number(resp.coords.latitude) && resp.coords.longitude && Number(resp.coords.longitude)) {
            resolve(resp.coords);
          } else {
            reject(new Error("Invalid coordinates"));
          }
        },
        (error) => {
          console.error("Geolocation error:", error);
          reject(error);  // Reject the promise with the error
        }
      );
    });
  }

  async getCountryInfoFromBrowser() {    
    return await new Promise<any>(async (resolve, reject) => {      
      var isCordovaApp = !!window.cordova;

      if (isCordovaApp) {
        await this.geolocation.getCurrentPosition({enableHighAccuracy: true, timeout: 5000, maximumAge: 0})
        .then((resp) => {
          this.latitude = resp.coords.latitude;
          this.longitude = resp.coords.longitude;
          this.mapsApiLoader.load().then(() => {
            var geocoder = new google.maps.Geocoder(); 
            
            var latlng = new google.maps.LatLng(Number(this.latitude), Number(this.longitude));
            geocoder.geocode({ 'location' : latlng}, (results, status) => {
              if (status == 'OK') {
                resolve(this.getLongAddressObject(results));
              } else {
                console.log('Geocode was not successful for the following reason: ' + status);
                resolve(null);                
              }           
            });   
          });    
        })
        .catch(async (error) => {
          if (error && error.message) 
          {
              // Request GPS Permission
              if ((error.message == "User denied Geolocation" || error.message == "Timeout expired")) {
                  const permission = await this.requestGPSPermission();
                  if (permission === 'CAN_REQUEST' || permission === 'GOT_PERMISSION') {
                    const canUseGPS = await this.askToTurnOnGPS();
                    this.postGPSPermission(canUseGPS);
                  }
                  else 
                  {
                    /* Denied by client */                   
                    await this.postGPSPermission(false).then(async () => {
                      resolve(null);
                    });        
                  } 
              } 
              resolve(null);           
          }          
        });
      }
      else {
        navigator.geolocation.getCurrentPosition(async (resp) => {
          if(resp.coords.latitude &&  Number(resp.coords.latitude) && resp.coords.longitude &&  Number(resp.coords.longitude)) {         
            this.mapsApiLoader.load().then(() => {
              var geocoder = new google.maps.Geocoder(); 
              
              var latlng = new google.maps.LatLng(Number(resp.coords.latitude), Number(resp.coords.longitude));
              geocoder.geocode({ 'location' : latlng}, (results, status) => {
                if (status == 'OK') {
                  resolve(this.getLongAddressObject(results));
                } else {
                  console.log('Geocode was not successful for the following reason: ' + status);
                  resolve(null);                
                }           
              });   
            });     
          }
          else {
            resolve(null);
          }
        }, error => {
          resolve(null);
        });
      }     
    });    
  }

  getLongAddressObject(object) {
    let address = {};
    const address_components = object[0].address_components;
    address_components.forEach(element => {
        address[element.types[0]] = element.long_name;
    });
    return address;
  }

  async positionDenied(error) 
  {
    console.log(error);
    await this.postGPSPermission(false);
  }

  sortbyDistance() 
  {
    this.storeList = Utils.sortbyDistance(this.latitude, this.longitude, this.storeList);
  }

  sortbyHomeLocation() 
  {
    this.storeList = Utils.sortbyHomeLocation(this.storeList);
  }

  get requestGPSPermissionFunc() {
    return this.requestGPSPermission.bind(this);
  }
  
  get initializeStoreInfoFunc() {
    return this.initializeStoreInfo.bind(this);
  }

  get postGPSPermissionFunc() {
    return this.postGPSPermission.bind(this);
  }

  get askToTurnOnGPSFunc() {
    return this.askToTurnOnGPS.bind(this);
  }


  private async setStoreList(storeList: Array<IStoreLocation>) {   
    this.fixPictureUrl(storeList);
    await this.calculateDistanceIfNeeded(storeList);
    this.setSocialMediaUrls(storeList);
    this.storeList = storeList;
    this.storeList$.next(this.storeList);
  }

  private fixPictureUrl(storeList: Array<IStoreLocation>) {
    if( !Utils.isNullOrUndefined(storeList) ) {
      storeList.forEach( (e: IStoreLocation) => {
        if( Utils.isNullOrUndefinedOrWhitespace(e.PictureUrl) ) {
          e.PictureUrl = 'locationPicture.svg';
        }
      });
    }
  }
  
  private async calculateDistanceIfNeeded(storeList: Array<IStoreLocation>) {
    let coOrdinates = await this.getLocationCoOrdinates();
    if (coOrdinates && coOrdinates.latitude && coOrdinates.longitude) {
      this.latitude = coOrdinates.latitude;
      this.longitude = coOrdinates.longitude;
    }
    if( !Utils.isNullOrUndefined(storeList) ) {
      storeList.forEach( (e: IStoreLocation) => {
        if (this.latitude > 0 && this.longitude > 0) {
          e.Distance = 0;
        }       
        if( Utils.isNullOrUndefined(e.Distance) || e.Distance === 0 ) {
          Utils.calculateStoreDistance(this.latitude, this.longitude, e, LocationService.distanceUnit);
        }
      });
    }
  }

  private setSocialMediaUrls(storeList: Array<IStoreLocation>) {
    if( Utils.isNullOrUndefined(storeList) ) {
      return;
    }
    storeList.forEach( (e: IStoreLocation) => {
      if( !Utils.isNullOrUndefined(e.Socials) ) {
        e.Socials.forEach( (socialInfo: ISocialInfo) => {
          if( !Utils.isNullOrUndefined(socialInfo) && !Utils.isNullOrUndefinedOrWhitespace(socialInfo.Type) ) {
            if( socialInfo.Type === 'Facebook' ) {
              e.fblink = socialInfo.Url;
            } else if( socialInfo.Type === 'Twitter' ) {
              e.twlink = socialInfo.Url;
            } else if( socialInfo.Type === 'Instagram' ) {
              e.inlink = socialInfo.Url;
            } else if( socialInfo.Type === 'Yelp' ) {
              e.yelink = socialInfo.Url;
            }
          }
        });
      }
    });
  }

  getIcon(name: string) : string {
    let result: string = null;
    
    return result;
  }

  public get restriction(): IMapRestriction {
    // Default Map restricted to USA
    let result: IMapRestriction = Constants.DEFAULT_MAP_RESTRICTION;

    const mapBoundry = this.assetService.mapBoundry;
        
    if (!Utils.isNullOrUndefinedOrWhitespace(mapBoundry)) {
      var boundry = JSON.parse(mapBoundry);
      console.log(boundry);
      let updatedRestrictions = {
        latLngBounds: {
          north: boundry.north,
          south: boundry.south,
          west: boundry.west,
          east: boundry.east,
        },
        strictBounds: true
      }
      result = updatedRestrictions;
    }

    return result;
  }


  private static setDistanceUnit(unit: string) {
    localStorage.setItem(Constants.DISTANCE_UNIT_KEY, unit);
  }

  public static get distanceUnit(): string {
    let result: string = 'K';
    const savedUnit: string = localStorage.getItem(Constants.DISTANCE_UNIT_KEY);
    if (!Utils.isNullOrUndefinedOrWhitespace(savedUnit)) {
      result = savedUnit;
    }
    return result;
  }

  public async getStoreList() {
    console.log('FETCHING STORE_LIST FROM STORAGE');
    let stores = JSON.parse(localStorage.getItem(Constants.STORE_INFO_KEY));
    
    if(stores && stores.length > 0) {   
      let distanceUnit = Utils.getUnit(stores); 
      localStorage.setItem(Constants.DISTANCE_UNIT_KEY, distanceUnit);
      console.log(`DISTANCE UNIT IS: ${distanceUnit}`);
      if (this.latitude == 0 && this.longitude == 0) {
        await this.geolocation.getCurrentPosition({enableHighAccuracy: true, timeout: 5000, maximumAge: 0})
        .then((resp) => {
          this.latitude = resp.coords.latitude; 
          this.longitude = resp.coords.longitude;
          stores.forEach(async (element: IStoreLocation)  => {
           element.Distance = Number(Utils.distance(this.latitude, this.longitude, element.Lat, element.Lng, distanceUnit).toFixed(2));
          });  
          localStorage.setItem(Constants.STORE_INFO_KEY, JSON.stringify(stores))
        }, (error) => {
          console.log('FETCHING USER LOCATION FAILED')
        });
      }       
    }    
    return stores;
  }

  async setLocationPopUpPreference(val) {
    let result = await this.storage.set("ASK_FOR_LOCATION", val);
    return result;
  }

  async getLocationPopUpPreference() {
    return await this.storage.get("ASK_FOR_LOCATION");
  }

  async removeLocationPopUpPreference() {
    await this.storage.remove("ASK_FOR_LOCATION");
  }

  async setLocationCoOrdinates(val) {
    this.latitude = val.latitude;
    this.longitude = val.longitude;
    let result = await this.storage.set("LOCATION_CORDINATES", val);
    return result;
  }

  async getLocationCoOrdinates() {
    return await this.storage.get("LOCATION_CORDINATES");
  }
}
