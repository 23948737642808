import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Utils } from 'src/app/common/Util';
import { IRewardUIModel } from 'src/app/models/yourReward';

@Component({
  selector: 'app-your-rewards',
  templateUrl: './your-rewards.component.html',
  styleUrls: ['./your-rewards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YourRewardsComponent implements OnInit, OnChanges {

  @Input() yourRewards: Array<IRewardUIModel> = [];
  yourUnlockedRewards: Array<IRewardUIModel> = [];
  yourLockedRewards: Array<IRewardUIModel> = [];
  
  
  constructor() { }

  ngOnInit() {
   
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.yourRewards = [
      {
          "id": "42392",
          "dealType": "Reward",
          "points": 150,
          "pointsAsString": "150pts",
          "iconUrl": "https://s3.amazonaws.com/img.tapmango.com/corev4.1/ff6cd7f5ffaa17fff5b8c3ae44a7f2af.png",
          "text": "FREE House Coffee, Cold Brew, Loose Leaf Tea, or Fountain Drink",
          "locationText": "Select Locations Only",
          "locationsShortText": "Just Love Coffee - Alcoa, Just Love Coffee - Ashland City",
          "locationsLongText": "Just Love Coffee - Alcoa, Just Love Coffee - Ashland City, Just Love Coffee - Battle Crossing, Just Love Coffee - Berea, Just Love Coffee - Brentwood, Just Love Coffee - Champlin, Just Love Coffee - Chattanooga, Just Love Coffee - Cleveland, Just Love Coffee - Crossroads, Just Love Coffee - Fairmont, Just Love Coffee - Fayetteville, Just Love Coffee - Ft. Collins, Just Love Coffee - Grand Prairie, Just Love Coffee - Huntsville Times Plaza, Just Love Coffee - Knoxville, Just Love Coffee - Littleton, Just Love Coffee - Madison, AL, Just Love Coffee - Madison, WI, Just Love Coffee - Melrose, Just Love Coffee - Mesa, Just Love Coffee - Mt. Juliet, Just Love Coffee - Neillsville, Just Love Coffee - New Jersey , Just Love Coffee - Nolensville, Just Love Coffee - Paris, TX, Just Love Coffee - Plainview, Just Love Coffee - Sandy Springs, Just Love Coffee - Tributary, Just Love Coffee - Tuscaloosa",
          "availableText": "App, Instore & Online",
          "formattedExpiryDate": null,
          "locked": false,
          "coreV4Header": "Reward",
          "coreV4SubHeader": "FREE House Coffee, Cold Brew, Loose Leaf Tea, or Fountain Drink",
          "showOrderInStore": true,
          "showOrderOnline": true
      },
      {
          "id": "42393",
          "dealType": "Reward",
          "points": 450,
          "pointsAsString": "450pts",
          "iconUrl": "https://s3.amazonaws.com/img.tapmango.com/corev4.1/4eb28122c538e9cbe0042c4c65547eec.png",
          "text": "FREE Hand Crafted Beverage",
          "locationText": "Select Locations Only",
          "locationsShortText": null,
          "locationsLongText": null,
          "availableText": "App, Instore & Online",
          "formattedExpiryDate": null,
          "locked": true,
          "coreV4Header": "Reward",
          "coreV4SubHeader": "FREE Hand Crafted Beverage",
          "showOrderInStore": true,
          "showOrderOnline": true
      },
      {
          "id": "42394",
          "dealType": "Reward",
          "points": 600,
          "pointsAsString": "600pts",
          "iconUrl": "https://s3.amazonaws.com/img.tapmango.com/corev4.1/8f1941ba7dd1343caf0e6f7dda9b62ab.png",
          "text": "FREE Sweet Treat or Waffle",
          "locationText": "Select Locations Only",
          "locationsShortText": null,
          "locationsLongText": null,
          "availableText": "App, Instore & Online",
          "formattedExpiryDate": null,
          "locked": true,
          "coreV4Header": "Reward",
          "coreV4SubHeader": "FREE Sweet Treat or Waffle",
          "showOrderInStore": true,
          "showOrderOnline": true
      },
      {
          "id": "42395",
          "dealType": "Reward",
          "points": 750,
          "pointsAsString": "750pts",
          "iconUrl": "https://img.tapmango.com/corev4/659ad077-1e84-4903-aca7-9083bef57323/fa123784-13d2-4207-a329-ea7e480c565a.png",
          "text": "FREE Lunch or Breakfast Item",
          "locationText": "Select Locations Only",
          "locationsShortText": null,
          "locationsLongText": null,
          "availableText": "App, Instore & Online",
          "formattedExpiryDate": null,
          "locked": true,
          "coreV4Header": "Reward",
          "coreV4SubHeader": "FREE Lunch or Breakfast Item",
          "showOrderInStore": true,
          "showOrderOnline": true
      },
      {
          "id": "42400",
          "dealType": "Reward",
          "points": 1200,
          "pointsAsString": "1200pts",
          "iconUrl": "https://s3.amazonaws.com/img.tapmango.com/corev4.1/8f1941ba7dd1343caf0e6f7dda9b62ab.png",
          "text": "FREE 12oz bag of coffee beans or Just Love Coffee Mug",
          "locationText": "Select Locations Only",
          "locationsShortText": null,
          "locationsLongText": null,
          "availableText": "App, Instore & Online",
          "formattedExpiryDate": null,
          "locked": true,
          "coreV4Header": "Reward",
          "coreV4SubHeader": "FREE 12oz bag of coffee beans or Just Love Coffee Mug",
          "showOrderInStore": true,
          "showOrderOnline": true
      }
    ];
    console.log(this.yourRewards);
    if(!Utils.isNullOrUndefined(changes['yourRewards'])) {
      this.yourUnlockedRewards = this.yourRewards.filter( e => !e.locked );
      this.yourLockedRewards = this.yourRewards.filter( e => e.locked );
    }
  }

}
