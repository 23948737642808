import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Utils } from '../common/Util';
import { EndPoint } from '../enums/endpoints';
import { catchError, map, take } from 'rxjs/operators';
import { Constants } from '../common/Constants';
import { IGetNotificationsRequest } from '../models/request/getNotificationsRequest';
import { IUpdateNotificationRequest } from '../models/request/updateNotificationRequest';
import { IGetNotificationsResponse } from '../models/response/getNotificationsResponse';
import { INotification } from '../models/response/notification';
import { IUpdateNotificationResponse } from '../models/response/updateNotificationResponse';
import { IClearCacheNotificationClient } from '../models/ClearCacheNotificationClient';
import { CacheCleaningNotificationService } from './cache-cleaning-notification.service';
import { PopupService } from './popup.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationV4Service implements IClearCacheNotificationClient {

  private notifications: Array<INotification> = null;
  public notificationsCount: string = null;
  public notificationsCount$ = new BehaviorSubject<string>(null);

  constructor(
    private apiService: ApiService,
    private popupService: PopupService,
    private clearCacheNotificationService: CacheCleaningNotificationService
  ) {
    this.handleClearCacheNotification();
    this.handleClearMemoryCacheNotification();
  }


  private clearCache() 
  {
    localStorage.removeItem(Constants.NOTIFICATIONS_KEY);
    this.setNotifications(null);
  }
  
  handleClearCacheNotification(): void {
    this.clearCacheNotificationService.cacheCleaningNotification$
    .subscribe( () => this.clearCache());
  }

  handleClearMemoryCacheNotification(): void {
    this.clearCacheNotificationService.memoryCacheCleaningNotification$
    .subscribe( () => this.setNotifications(null) );
  }

  forceGetNotifications(limit: number = 20, offset: number = 0): Observable<Array<INotification>> {
    console.log(`NotificationV4Service.forceGetNotifications()`);
    this.notifications = null;
    return this.getNotifications(limit, offset);
  }

  getNotifications(limit: number = 200, offset: number = 0): Observable<Array<INotification>> {
    console.log(`NotificationV4Service.getNotifications()`);
    if( !Utils.isNullOrUndefined(this.notifications)) {
      this.setNotifications(this.notifications);
      return of(this.notifications);
    }
    else {
      const request: IGetNotificationsRequest = <IGetNotificationsRequest>{
        limit : limit,
        offset: offset
      };
      return this.apiService.postData(EndPoint.USER_NOTIFICATION, request)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(error);
          // could not load the most recent notifications. We need to display the last loaded ones from the local storage.
          this.setNotifications(<Array<INotification>>Utils.readFromLocalStorage(Constants.NOTIFICATIONS_KEY));
  
          Utils.handleError(error, this.popupService, 'There was an error while processing this request. Please try again later.');
          return of(this.notifications);
        }),
        map( (response: IGetNotificationsResponse) => {
          console.log(`NotificationV4Service.getNotifications() response=${Utils.debugGetSafeJSON(response)}`);
          if(!Utils.isNullOrUndefined(response)) 
          {
            if(response.IsError) {
              console.error(response.Error);
              // could not load the most recent notifications. We need to display the last loaded ones from the local storage.
              this.setNotifications(<Array<INotification>>Utils.readFromLocalStorage(Constants.NOTIFICATIONS_KEY));
            } else {
              this.setNotificationsCount(response.Notifications.filter( e => !e.Read).length);
              this.setNotifications(response.Notifications);
              Utils.storeToLocalStorage(Constants.NOTIFICATIONS_KEY, response.Notifications);
            }
          }
          return this.notifications;
        })
      )      
    }
  }

  markNotificationAsRead(notificationId: string, read: boolean = true) {
    const request: IUpdateNotificationRequest = <IUpdateNotificationRequest>{
      NotificationId: notificationId,
      Read: read
    };
    console.log(`NotificationV4Service.markNotificationAsRead() request=${Utils.debugGetSafeJSON(request)}`);
    this.apiService.postData(EndPoint.USER_NOTIFICATION_UPDATE, request)
    .pipe(take(1))
    .subscribe( {
      next: (response: IUpdateNotificationResponse) => {
        console.log(`NotificationV4Service.markNotificationAsRead() response=${Utils.debugGetSafeJSON(response)}`);
        if( Utils.isNullOrUndefined(response) || Utils.isTrue(response.IsError) ) 
        {
          console.error(response?.Error);
        }
      },
      error: (error) => console.error(error),
      complete: () => this.notifications = null // clear the memory cache; in this way the Notifications page will have to read fresh data and this notification will appear as read
    });
  }

  private setNotificationsCount(notificationsCount: number | null) {
    const value: string = Utils.isNullOrUndefined(notificationsCount) || notificationsCount <= 0 ? null : 
                          notificationsCount > 9 ? '9+' : notificationsCount.toString();
    this.notificationsCount = value;
    this.notificationsCount$.next(this.notificationsCount);
  }

  private setNotifications(notifications: Array<INotification>) {
    this.notifications = notifications;
  }

}
