import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-help-content',
  templateUrl: './help-content.component.html',
  styleUrls: ['./help-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpContentComponent implements OnInit, OnDestroy {

  @Input() locationEmailAddress: string = null;

  constructor(private router: Router) { }

  ngOnInit() {
    console.log(`HelpContentComponent.ngOnInit() locationEmailAddress = ${this.locationEmailAddress}`);
  }
  
  ngOnDestroy() {
    console.log(`HelpContentComponent.ngOnDestroy()`);
  }

  loadPrivacy() {
    console.log(`HelpContentComponent.loadPrivacy()`);
    this.router.navigateByUrl('privacy-policy-v4');
  }

  loadTerms() {
    console.log(`HelpContentComponent.loadTerms()`);
    this.router.navigateByUrl('terms-v4');
  }
}
