import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Utils } from 'src/app/common/Util';
import { IStoreLocation } from 'src/app/models/storeLocation';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-store-info-list-item',
  templateUrl: './store-info-list-item.component.html',
  styleUrls: ['./store-info-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoreInfoListItemComponent implements OnInit {

  @Input() storeLocation: IStoreLocation = null;
  @Input() distanceUnit: string = 'K';
  @Input() latitude: number = 0;
  @Input() longitude: number = 0;

  constructor(
    private platform: Platform,
    private util: UtilService
  ) { }

  ngOnInit() {
  }

  openMap()
  {
    Utils.openMap( this.storeLocation, this.util, this.platform.is('ios'), this.platform.is('android'));
  }

  openExternalLink(link: string) {
    if (!Utils.isNullOrUndefinedOrWhitespace(link)) {
      this.util.openWithSystemBrowser(link);
    }
  }
}
