import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ValidatorService } from 'src/app/services/validator.service';

@Component({
  selector: 'app-forgot-password-provide-new-password-form',
  templateUrl: './forgot-password-provide-new-password-form.component.html',
  styleUrls: ['./forgot-password-provide-new-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordProvideNewPasswordFormComponent implements OnInit {
  
  @Output() resetPasswordSetNewPassword: EventEmitter<string> = new EventEmitter<string>();
  showNewPassword: boolean = false;

  form: FormGroup = this.formBuilder.group({
    newPassword: new FormControl('', 
    [
      Validators.required,
      Validators.maxLength(50),
      Validators.minLength(8),      
      this.validatorService.LowerCase(),
      this.validatorService.UpperCase(),
      this.validatorService.Digits(), 
      this.validatorService.SpecialChar(),
      this.validatorService.NoSpace()
    ])
  });
  
  constructor(
    private formBuilder: FormBuilder,
    private validatorService: ValidatorService
  ) { }

  ngOnInit() {
    this.form.reset();
  }

  save() {
    if( this.form.valid ) {
      this.resetPasswordSetNewPassword.emit(this.form.get('newPassword').value);
      this.form.reset();
    }
  }
}
