import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Utils } from 'src/app/common/Util';
import { IStoreLocation } from 'src/app/models/storeLocation';
import { ISoreLocationsMapInformation } from 'src/app/models/storeLocationsMapInformation';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-store-info-map',
  templateUrl: './store-info-map.component.html',
  styleUrls: ['./store-info-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoreInfoMapComponent implements OnInit {
  
  @Input() storeLocationsMapInfo: ISoreLocationsMapInformation = null;
  private previousInfoWindow = null;

  constructor(
    private platform: Platform,
    private util: UtilService
  ) { }

  ngOnInit() {
    console.log(`StoreInfoMapComponent.ngOnInit() storeLocationsMapInfo=`, Utils.debugGetSafeJSON(this.storeLocationsMapInfo));
  }

  onMapReady(event) {
    console.log(`StoreInfoMapComponent.onMapReady() event=`, event);
    event.setOptions({
      zoomControl: false,  
      streetViewControl: false
    });
  }

  async clickedMarker(label: string, index: number, infoWindow: any) {
    if (!Utils.isNullOrUndefined(this.previousInfoWindow)) {
      this.previousInfoWindow.close();
    }
    this.previousInfoWindow = infoWindow;
    console.log(`clicked the marker: ${label || index}`);
  }

  openMap(storeLocation: IStoreLocation)
  {
    Utils.openMap(storeLocation, this.util, this.platform.is('ios'), this.platform.is('android'));
  }
}
