import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AuthV4Service } from '../services/auth-v4.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor( private navCtrl: NavController, private authV4Service: AuthV4Service ) {}

  public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authV4Service.isLoggedIn) {
      return true;
    } else {
      this.navCtrl.navigateForward('on-board1');
      return false;
    }
  }
  
}
