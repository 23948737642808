import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-info-popup',
  templateUrl: './info-popup.component.html',
  styleUrls: ['./info-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoPopupComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() btnCloseText: string;
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

}
