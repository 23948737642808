import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-refer-afriend-popup',
  templateUrl: './refer-afriend-popup.component.html',
  styleUrls: ['./refer-afriend-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReferAFriendPopupComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() btnCloseText: string;
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  dismiss() {
    this.modalCtrl.dismiss();
  }

}
