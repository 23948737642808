import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from 'src/app/common/Util';


@Component({
  selector: 'app-component-header',
  templateUrl: './component-header.component.html',
  styleUrls: ['./component-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComponentHeaderComponent implements OnInit {

  @Input() title: string;
  @Input() urlToNavigateWhenClosed: string;
  @Input() urlToNavigateWhenBacked: string;
  @Input() showBackBtn: boolean = false;
  @Input() showCloseBtn: boolean = true;  
  @Input() transformTitleToTitleCase: boolean = false;

  constructor(private router: Router) { }

  ngOnInit() {}

  goToWhenBacked() {
    if( !Utils.isNullOrUndefinedOrWhitespace(this.urlToNavigateWhenBacked)) {
      this.router.navigateByUrl(this.urlToNavigateWhenBacked);
    }
  }

  goToWhenClosed() {
    if( !Utils.isNullOrUndefinedOrWhitespace(this.urlToNavigateWhenClosed)) {
      this.router.navigateByUrl(this.urlToNavigateWhenClosed);
    }
  }
}
