import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-popup-model',
  templateUrl: './popup-model.component.html',
  styleUrls: ['./popup-model.component.scss'],
})
export class PopupModelComponent implements OnInit {

  @Input() popupOptions: any;
  @Input() type: string;  

  confirmButtonText: string = "Confirm";
  cancelButtonText: string = "Cancel";
  

  confirmMessage: string = "Are you sure you want to proceed?";
  processingMessage: string = "Processing Payment...";

  successTitle: string;
  successMessage: string;

  failTitle: string;
  failMessage: string;


  inputMessage: string;
  inputValue: any;
  inputType: string;
  inputPlaceholder: string;
  okButtonText: string;

  errMessage: string;
  errorList: string[];

  constructor(
    private modalCtr: ModalController,
  ) { }

  async ngOnInit() {

    if (this.type == 'confirm') await this.confirm(this.popupOptions);
    else if (this.type == 'input') await this.input(this.popupOptions);
    else if (this.type == 'success') await this.success(this.popupOptions);
    else if (this.type == 'fail') await this.fail(this.popupOptions);
    else if (this.type == 'processing') await this.loading(this.popupOptions);
    else if (this.type == 'errors') await this.errors(this.popupOptions);
    
  }

  async close(response: PopupResponse) {
    await this.modalCtr.dismiss(response);
  }

  async inputResponse() {
    await this.close({ isConfirmed: true, inputValue: this.inputValue });
  }

  async ok() {
    await this.close({ isConfirmed: true, inputValue: undefined });
  }

  async cancel() {
    await this.close({ isConfirmed: false, inputValue: undefined });
  }

  async ionViewWillEnter() {
    console.log(1);
  }

  async confirm(opt: ConfirmOptions) {
    console.log(2);

    this.confirmMessage = "Are you sure you want to proceed?";
    if (opt.confirmMessage) {
      this.confirmMessage = opt.confirmMessage;
    }

    this.confirmButtonText = "Confirm";
    if (opt.confirmButtonText) {
      this.confirmButtonText = opt.confirmButtonText;
    }

    //this.type = "confirm";
  }


  async loading(opt: LoadingOptions) {
    console.log(3);

    this.processingMessage = "Loading...";
    if (opt.processingMessage) {
      this.processingMessage = opt.processingMessage;
    }

    //this.type = "processing";
  }

  async input(opt: InputOptions) {
    console.log(4);

    this.inputMessage = "Please enter the value";
    if (opt.inputMessage) {
      this.inputMessage = opt.inputMessage;
    }

    this.inputPlaceholder = "";
    if (opt.inputPlaceholder) {
      this.inputPlaceholder = opt.inputPlaceholder;
    }

    this.inputType = "text";
    if (opt.inputType) {
      this.inputType = opt.inputType;
    }

    if (opt.inputValue != null || opt.inputValue != undefined) {
      this.inputValue = opt.inputValue;
    }

    //this.type = "input";
  }

  async success(opt: SuccessOptions) {
    console.log(3);

    this.successTitle = "Succeeded";
    if (opt.successTitle) {
      this.successTitle = opt.successTitle;
    }

    this.successMessage = "";
    if (opt.successMessage) {
      this.successMessage = opt.successMessage;
    }

    this.okButtonText = "Ok";
    if (opt.okButtonText) {
      this.okButtonText = opt.okButtonText;
    }

    //this.type = "success";
  }


  async fail(opt: FailOptions) {
    console.log(3);

    this.failTitle = "Failed";
    if (opt.failTitle) {
      this.failTitle = opt.failTitle;
    }

    this.failMessage = "";
    if (opt.failMessage) {
      this.failMessage = opt.failMessage;
    }

    this.okButtonText = "Ok";
    if (opt.okButtonText) {
      this.okButtonText = opt.okButtonText;
    }

    //this.type = "success";
  }

  async errors(opt: ErrorsOptions) {
    console.log(3);

    this.errMessage = "Ooops.. something is not right!";
    if (opt.errMessage) {
      this.errMessage = opt.errMessage;
    }

    this.errorList = [];
    if (opt.errorList) {
      this.errorList = opt.errorList;
    }

    this.okButtonText = "Retry";
    if (opt.okButtonText) {
      this.okButtonText = opt.okButtonText;
    }

    //this.type = "errors";
  }

}



export class InputOptions {    
  inputType: string;
  inputPlaceholder: string;
  inputMessage: string;
  inputValue: any;
  confirmButtonText: string;
  cancelButtonText: string;
}

export class LoadingOptions {
  processingMessage: string;
}

export class ConfirmOptions {
  confirmMessage: string;
  confirmButtonText: string;
  cancelButtonText: string;
}

export class SuccessOptions {
  successTitle: string;
  successMessage: string;
  okButtonText: string;
}

export class FailOptions {
  failTitle: string;
  failMessage: string;
  okButtonText: string;
}

export class ErrorsOptions {
  errMessage: string;
  errorList: string[];
  okButtonText: string;
}

export class PopupResponse {
  isConfirmed: boolean;
  inputValue: any;
}
