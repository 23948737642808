import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConfirmOptions, ErrorsOptions, FailOptions, InputOptions, LoadingOptions, PopupModelComponent, PopupResponse, SuccessOptions } from '../components/popup-model/popup-model.component';
import { SuccessPopupComponent } from '../components/success-popup/success-popup.component';
import { DecisionPopupComponent } from '../components/decision-popup/decision-popup.component';
import { ConfirmPopupComponent } from '../components/confirm-popup/confirm-popup.component';
import { NotificationPopupComponent } from '../components/notification-popup/notification-popup.component';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  constructor(public modalCtrl: ModalController) { }
  
  
  notifyV4(message: string = null, buttonYesText: string = 'OK', btnNoText: string = null): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const modal = await this.modalCtrl.create({
        component: NotificationPopupComponent,
        cssClass: 'tm-decision-modal',
        backdropDismiss: false,
        componentProps: {
          btnYesText: buttonYesText,
          btnNoText: btnNoText,
          message: message
      }});

      modal.onDidDismiss().then((response) => resolve(<boolean>response.data) );

      await modal.present();
    });
  }

  confirm(opt: ConfirmOptions): Promise<PopupResponse> {
    return new Promise(async (resolve, reject) => {
      const modal = await this.modalCtrl.create({
        component: PopupModelComponent,
        componentProps: {
          'type': 'confirm',
          'popupOptions': opt
        },
        cssClass: 'tm-popup-model',
        backdropDismiss: false
      });

      modal.onDidDismiss().then((response) => {
        resolve(<PopupResponse>response.data);
      });

      await modal.present();
    });
  }

  confirmV4(title: string = null, message: string = 'Are you sure you want to proceed?', buttonYesText: string = 'Confirm', btnNoText: string = 'Cancel'): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const modal = await this.modalCtrl.create({
        component: ConfirmPopupComponent,
        cssClass: 'tm-decision-modal',
        backdropDismiss: false,
        componentProps: {
          title: title,
          btnYesText: buttonYesText,
          btnNoText: btnNoText,
          message: message
      }});

      modal.onDidDismiss().then((response) => resolve(<boolean>response.data) );

      await modal.present();
    });
  }

  async loading(opt: LoadingOptions): Promise<HTMLIonModalElement> {
    
    const modal = await this.modalCtrl.create({
      component: PopupModelComponent,
      componentProps: {
        'type': 'processing',
        'popupOptions': opt
      },
      cssClass: 'tm-popup-model',
      backdropDismiss: false
    });      

    await modal.present();

    return modal;
  }

  input(opt: InputOptions): Promise<PopupResponse> {
    return new Promise(async (resolve, reject) => {
      const modal = await this.modalCtrl.create({
        component: PopupModelComponent,
        componentProps: {
          'type': 'input',
          'popupOptions': opt
        },
        cssClass: 'tm-popup-model',
        backdropDismiss: false
      });

      modal.onDidDismiss().then((response) => {
        resolve(<PopupResponse>response.data);
      });

      await modal.present();
    });
  }

  success(opt: SuccessOptions): Promise<PopupResponse> {
    return new Promise(async (resolve, reject) => {
      const modal = await this.modalCtrl.create({
        component: PopupModelComponent,
        componentProps: {
          'type': 'success',
          'popupOptions': opt
        },
        cssClass: 'tm-popup-model',
        backdropDismiss: false
      });

      modal.onDidDismiss().then((response) => {
        resolve(<PopupResponse>response.data);
      });

      await modal.present();
    });
  }

  successV4(title: string = 'Success', message: string = '', buttonText: string =  'OK' ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const modal = await this.modalCtrl.create({
        component: SuccessPopupComponent,
        cssClass: 'tm-decision-modal',
        backdropDismiss: false,
        componentProps: {
          btnCloseText: buttonText,
          title: title,
          message: message
      }});

      modal.onDidDismiss().then((response) => resolve(true) );

      await modal.present();
    });
  }
  
  warnV4(title: string = null, message: string = 'Are you sure you want to proceed?', buttonYesText: string = 'Confirm', btnNoText: string = 'Cancel'): Promise<boolean> {

    return new Promise(async (resolve, reject) => {
      const modal = await this.modalCtrl.create({
        component: ConfirmPopupComponent,
        cssClass: 'tm-decision-modal',
        backdropDismiss: false,
        componentProps: {
          title: title,
          btnYesText: buttonYesText,
          btnNoText: btnNoText,
          message: message,
          isWarning: true
      }});

      modal.onDidDismiss().then((response) => resolve(<boolean>response.data) );

      await modal.present();
    });
  }

  fail(opt: FailOptions): Promise<PopupResponse> {
    return new Promise(async (resolve, reject) => {
      const modal = await this.modalCtrl.create({
        component: PopupModelComponent,
        componentProps: {
          'type': 'fail',
          'popupOptions': opt
        },
        cssClass: 'tm-popup-model',
        backdropDismiss: false
      });

      modal.onDidDismiss().then((response) => {
        resolve(<PopupResponse>response.data);
      });

      await modal.present();
    });
  }

  failV4(title: string = 'Failed', message: string = '', buttonText: string =  'OK' ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const modal = await this.modalCtrl.create({
        component: DecisionPopupComponent,
        cssClass: 'tm-decision-modal',
        backdropDismiss: false,
        componentProps: {
          btnYesText: buttonText,
          title: title,
          message: message
      }});

      modal.onDidDismiss().then((response) => resolve(true) );

      await modal.present();
    });
  }

  erros(opt: ErrorsOptions): Promise<PopupResponse> {
    return new Promise(async (resolve, reject) => {
      const modal = await this.modalCtrl.create({
        component: PopupModelComponent,
        componentProps: {
          'type': 'errors',
          'popupOptions': opt
        },
        cssClass: 'tm-popup-model',
        backdropDismiss: false
      });

      modal.onDidDismiss().then((response) => {
        resolve(<PopupResponse>response.data);
      });

      await modal.present();
    });
  }
}
