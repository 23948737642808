import { IBaseResponse } from "./baseResponse";


export interface ISpotLightResponse extends IBaseResponse {
  CarouselSlides: Array<ICarouselSlideResponse>;
}

export interface ICarouselSlideResponse {
  Id: number;
  Url: string;
  CoreV4Url: string,
  CoreV4DetailUrl: string,
  Title: string;
  Description: string;
  ButtonText: string;
  SlideType: CarouselSlideTypeEnum;
  CoreV4Header: string;
  FormattedExpiryDate: string;
  DisplayOrderOnline: boolean;
  DisplayOrderInStore: boolean;
}

export enum CarouselSlideTypeEnum
{
    Membership = 0,
    PromotionTile = 1
}