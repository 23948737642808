import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IThankYouForFeedback } from 'src/app/models/thankYouForFeedback';
import { AssetService } from 'src/app/services/asset.service';

@Component({
  selector: 'app-thank-you-for-feedback-card',
  templateUrl: './thank-you-for-feedback-card.component.html',
  styleUrls: ['./thank-you-for-feedback-card.component.scss'],
})
export class ThankYouForFeedbackCardComponent implements OnInit {

  @Input() thankYouForFeedback: IThankYouForFeedback = null;

  constructor(private router:Router, public assetService: AssetService) { }

  ngOnInit() {}

  done() {
    this.router.navigateByUrl('tabs/earn');
  }
}
