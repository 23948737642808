import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IPunchCardsUIModel, PunchCard } from 'src/app/models/punchCard';
import { InfoPopupComponent } from '../info-popup/info-popup.component';
import { Utils } from 'src/app/common/Util';


@Component({
  selector: 'app-punch-cards-card',
  templateUrl: './punch-cards-card.component.html',
  styleUrls: ['./punch-cards-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PunchCardsCardComponent implements OnInit, OnChanges {

  @Input() punchCardsUIModel: IPunchCardsUIModel = null;
  punchCards: Array<PunchCard> = [];
  completedPunchCards: Array<PunchCard> = [];
  inProgressTitle: string = 'In Progress';
  thereAreNoPunchcardsAvailableAtThisTime: boolean = false;

  constructor(private modalCtrl: ModalController) {
  }

  ngOnInit() {} 

  ngOnChanges(changes: SimpleChanges): void {
    if( !Utils.isNullOrUndefined(changes['punchCardsUIModel'] ) ) {
      this.thereAreNoPunchcardsAvailableAtThisTime = false;
      this.inProgressTitle = 'In Progress';
      if( !Utils.isNullOrUndefined(this.punchCardsUIModel) ) {
        this.punchCards = this.punchCardsUIModel.punchCards;
        this.completedPunchCards = this.punchCards.filter(e => e.availableToRedeem > 0);        

        if( !Utils.isNullOrUndefinedOrWhitespace(this.punchCardsUIModel.noPunchCardsTitle) ) {
          this.inProgressTitle = this.punchCardsUIModel.noPunchCardsTitle;
          this.thereAreNoPunchcardsAvailableAtThisTime = true;
        }
      } else {
        this.punchCards = [];
        this.completedPunchCards = [];
      }
    }
  }
  
  async showInfo() {
    const modal = await this.modalCtrl.create({
      component: InfoPopupComponent,
      cssClass: 'tm-decision-modal',
      componentProps: {
        btnCloseText: 'Got it, Thanks',
        title: 'How Punch Cards Work',
        message: `Once a punch card is completed, a voucher is automatically added to your account, and a new punch card is started.`
    }});

    modal.present(); 
  }
}
