import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monthFormat'
})
export class MonthFormatPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    var newValue = months[value - 1];
    return newValue;
  }
}