export const config = {
	MERCHANT_GUID: "659ad077-1e84-4903-aca7-9083bef57323",
	MERCHANT_APP_ID: "1453",
    COLORS : {
		primary: '#e53d30',
		progressbarBackground: '#e53d30',
		androidPushNotificationColor: '#e53d30',
		customerHomeBackground: '#e53d30',
		splashScreenBackground: '#ffffff'
    },
	DEFAULT_LANGUAGE: 'en',
	FORCE_MODE: '',
	REGISTER_WITH_EMAIL: '0',
	PHONE_MIN_LENGTH: '10',
	PHONE_MAX_LENGTH: '15',
	APPLE_PAY_MERCHANT_ID: 'merchant.com.tapmango',
	PHONE_MASK: '(xxx) xxx-xxxx',
	POINTS_FORMAT: 'local',
	CACHE_CLEANUP_INTERVAL_IN_SECONDS: '120',
};
