import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TileData } from 'src/app/models/tileData';
import { UtilService } from 'src/app/services/util.service';
import { WalletV4Service } from 'src/app/services/wallet-v4.service';

@Component({
  selector: 'app-twin-tile',
  templateUrl: './twin-tile.component.html',
  styleUrls: ['./twin-tile.component.scss'],
})
export class TwinTileComponent implements OnInit {

  @Input() tileData: TileData;
  
  constructor(private router: Router, public util: UtilService, private walletV4Service: WalletV4Service) 
  {
    
  }

  ngOnInit() {}

  goToWallet() {
    this.walletV4Service.clearWalletLoadBalanceResponse();
    this.router.navigateByUrl('/wallet');
  }
  
  goToQRCode() {
    this.router.navigateByUrl('/qrcode');
  }

}
