import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
import { ICarouselSlide } from 'src/app/models/carouselSlide';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-promotion-details-explanation',
  templateUrl: './promotion-details-explanation.component.html',
  styleUrls: ['./promotion-details-explanation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromotionDetailsExplanationComponent implements OnInit, AfterViewInit {

  @Input() carouselSlide: ICarouselSlide = null;
  constructor(
    private router: Router,
    private util: UtilService, 
  ) {
  }

  ngOnInit() {
    console.log('PromotionDetailsExplanationComponent.ngOnInit() carouselSlide=', this.carouselSlide);
  }

  ngAfterViewInit() {
    // <a href="playabowls.com/locations" rel="noopener noreferrer"

    // add click handlers for links
    timer(500).subscribe(x => {
      var elements = document.getElementsByClassName("externalLink");
      if (elements.length > 0) {
        for (var i = 0; i < elements.length; i++) {
          elements[i].addEventListener('click', (e) => this.openLink(e), false);
        }
      }
      console.log(elements)
    });
  }

  openLink(e) {
    e.preventDefault();
    let href = e.target.href;
    if (!href && e.target.parentElement && e.target.parentElement.href) {
      href = e.target.parentElement.href;
    }
    console.log(href);
    if (e && e.target && href) {
      this.util.openWithSystemBrowser(href);
    }
  }

  goToOnlineOrder() {
    this.router.navigateByUrl('online-order');
  }
}
