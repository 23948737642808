import { IOnBoardingProfile } from "../response/onboardingProfile";

export interface IOnboardingRequest {
    MerchantLocationId: number;
    DeviceId: number;
    Source: OnboardSourceTypeEnum;
    IdentityClaimType: OnboardIdentityClaimTypeEnum;
    IdentityClaim: IOnboardIdentityClaim;
    Profile: IOnBoardingProfile;
    SourceDevice: string;
}

export enum OnboardSourceTypeEnum  {
    MOBILE_APP = "MOBILE_APP",
    WEBORDERING = "WEBORDERING",
    CUSTOMER_PORTAL= "CUSTOMER_PORTAL",
}


export enum OnboardIdentityClaimTypeEnum  {
    EMAIL = "EMAIL",
    PHONE = "PHONE",
    USERNAME_AND_PASSWORD = "USERNAME_AND_PASSWORD",
    ONBOARDING_TOKEN = "ONBOARDING_TOKEN"
}

export interface IOnboardIdentityClaim  {
    Email: string;
    Phone: string;
    Username: string;
    Password: string;
    OnboardingToken: string
}


export const EMPTY_ON_BOARDING_REQUEST: IOnboardingRequest = {
    MerchantLocationId: null,
    DeviceId: null,
    Source: OnboardSourceTypeEnum.MOBILE_APP,
    IdentityClaimType: null,
    IdentityClaim: <IOnboardIdentityClaim>{
    Email: null,
    Phone: null,
    Username: null,
    Password: null,
    OnboardingToken: null
    },
    Profile: <IOnBoardingProfile>{},
    SourceDevice: null
};