import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {ComponentsModule} from "./components/components.module";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslationService } from './services/translation.service';
import {
  NativeGeocoder,
  NativeGeocoderResult,
  NativeGeocoderOptions,
} from "@ionic-native/native-geocoder/ngx";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { Push } from '@ionic-native/push/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { Facebook } from '@ionic-native/facebook/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Network } from '@ionic-native/network/ngx';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Device } from '@ionic-native/device/ngx';
import { FormsModule } from '@angular/forms';
import { InlineSVGModule } from 'ng-inline-svg';
import { ApplePay } from '@ionic-native/apple-pay/ngx';
import { DecimalPipe } from '@angular/common';
import { HeadersService } from './services/headers.service';
import { AssetService } from './services/asset.service';
import { Constants } from './common/Constants';
import { DEFAULT_TIMEOUT, HttpInterceptorService } from './services/http-interceptor.service';
import { ResponsePageModule } from './pages/response/response.module';

export function LanguageLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
export function initializeHeadersService(headersService: HeadersService) {
  return (): Promise<any> => { 
    return headersService.initialize();
  }
}
export function initializeApplicationLoadAssets(assetService: AssetService) {
  return (): Promise<any> => {
    localStorage.removeItem(Constants.LAST_FETCHED_MERCHANT_ASSET_DATA_KEY);
    return assetService.getMerchantAssets();
  }
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot({
      mode: 'md'
    }), 
    BrowserAnimationsModule,
    NgxSpinnerModule,
    AppRoutingModule, ComponentsModule, HttpClientModule, 
    FormsModule,
    IonicStorageModule.forRoot(),
    InlineSVGModule.forRoot(),
    ResponsePageModule,
    TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: (LanguageLoader),
      deps: [HttpClient]
    }
  })],
  providers: [
    StatusBar,
    SplashScreen,
    Geolocation,
    NativeGeocoder,
    Push,
    Facebook,
    TranslationService,
    InAppBrowser,
    AppVersion,
    Network,
    Device,
    { 
      provide: RouteReuseStrategy, 
      useClass: IonicRouteStrategy 
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ApplePay,
    DecimalPipe,
    HeadersService,
    { provide: APP_INITIALIZER, useFactory: initializeHeadersService, deps: [HeadersService], multi: true },
    { provide: APP_INITIALIZER, useFactory: initializeApplicationLoadAssets, deps: [AssetService], multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: 60000 },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true }
    
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
