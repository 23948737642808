import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Constants } from 'src/app/common/Constants';
import { Utils } from 'src/app/common/Util';
import { PunchCard } from 'src/app/models/punchCard';
import SwiperCore, { SwiperOptions, Pagination } from 'swiper';
SwiperCore.use([Pagination]);
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'app-punch-cards-carousel',
  templateUrl: './punch-cards-carousel.component.html',
  styleUrls: ['./punch-cards-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PunchCardsCarouselComponent implements OnInit, AfterViewInit {

  @Input() punchCards: Array<PunchCard>;
  selectedIndex: number = 0;
  @ViewChild('swiper') swiper_punchcards: SwiperComponent;

  swiper_punchcards_config: SwiperOptions = null;
  
  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.swiper_punchcards_config = {
      ...Constants.SWIPER_CONFIGURATION,
      allowTouchMove: Utils.isFirstArrayElementValid(this.punchCards)
    };
  }

  public ngAfterViewInit(): void {    
  }

  selectSlide(index: number) {
    this.swiper_punchcards?.swiperRef.slideTo(index);
    this.selectedIndex = this.swiper_punchcards?.swiperRef.activeIndex;
    this.fixMargin();
  }

  fixMargin() {
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;    
    if (this.swiper_punchcards?.swiperRef.isBeginning) {
      const nativeElement = (<any>this.swiper_punchcards)?.elementRef?.nativeElement;
      if (nativeElement) {
        if (width >= 360 && width < 390) {
          console.log('width >= 360 && width < 390 :' + width );
          nativeElement.setAttribute('style', 'margin-left: -0.3rem');
        } 
        else {
          console.log('DEVICE WIDTH: ' + width );
          nativeElement.setAttribute('style', 'margin-left: 0');
        }       
      }
    }
    if (this.swiper_punchcards?.swiperRef.isEnd) {
      const nativeElement = (<any>this.swiper_punchcards)?.elementRef?.nativeElement;
      if (nativeElement) {      
        if (width >= 412 && width < 430) {
          console.log('DEVICE WIDTH: width >= 430 && width < 430 :' + width );
          nativeElement.setAttribute('style', 'margin-left: 5rem');
        }   
        if (width >= 430) {
          console.log('DEVICE WIDTH: width >= 430 :' + width );
          nativeElement.setAttribute('style', 'margin-left: 8rem');
        }    
      }
    }
  }

  moveRight() {
    this.swiper_punchcards?.swiperRef.slideNext();
  }

  moveLeft() {
    this.swiper_punchcards?.swiperRef.slidePrev();
  }

  onSlideChange() {
    this.selectedIndex = this.swiper_punchcards?.swiperRef.activeIndex;
    console.log(this.selectedIndex);
    this.fixMargin();
    this.cd.detectChanges();
  }

  get availableToRedeem(): number {
    if (Utils.isNullOrUndefined(this.swiper_punchcards?.swiperRef)) {
      return 0;
    }
    const selectedPunchCard =this.punchCards[this.swiper_punchcards?.swiperRef.activeIndex];  
    return !Utils.isNullOrUndefined(selectedPunchCard) ? selectedPunchCard.availableToRedeem : 0;
  }

}


