import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { EMPTY, Observable, throwError} from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { HeadersService } from './headers.service';
import { LoadingService } from './loading.service';
import { Constants } from '../common/Constants';
import { AuthV4Service } from './auth-v4.service';
import { IRequestHeaderData } from '../models/requestHeaderData';
import { Utils } from '../common/Util';
import { environment } from 'src/environments/environment';
import { IExplicitError } from '../models/response/baseResponse';
import { PopupService } from './popup.service';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
    private authService: AuthV4Service,
    private loadingService: LoadingService,
    private headerService: HeadersService,
    private popupService: PopupService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.loadingService.setLoading(true, request.url);

    
    const timeoutValue = request.headers.get('timeout') || this.defaultTimeout;
    const timeoutValueNumeric: number = Number(timeoutValue);

    const authRequest: HttpRequest<any> = this.getAuthRequest(request);

    return next.handle(authRequest)
        .pipe(
          map<HttpEvent<any>, any>((event: HttpEvent<any>) => this.handleSuccesfulResponses(authRequest.url, event)),
          timeout(timeoutValueNumeric),
          catchError(err => this.handleError(err, authRequest, next))
        );
  }

  private handleSuccesfulResponses(url: string, event: HttpEvent<any>): HttpEvent<any> {
    if (event instanceof HttpResponse) {
      this.loadingService.setLoading(false, url);
    }
    return event;
  }

  private handleError(error: any, request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (error.status === 401) {
      this.authService.logout();
      location.reload();
    }
    let errorMessage: string = 'We encountered an error';

    const isTimeOutError: boolean = !Utils.isNullOrUndefined(error) &&
                                    error.hasOwnProperty('name') &&
                                    !Utils.isNullOrUndefinedOrWhitespace(error['name']) &&
                                    error['name'] === 'TimeoutError';

    const serviceNotAvailable: boolean = error.status === 404 && request.url.startsWith(`${environment.API_URL}/api/`);
    
    if( isTimeOutError ) {
      errorMessage = 'It took longer than expected to load data. Please pull down the page to reload.';
    } else if ( serviceNotAvailable ) {
      errorMessage = 'The service is not available. Please try again later or reach out to support.';
    } else {

      if(!Utils.isNullOrUndefinedOrWhitespace(error?.error?.message)) {
        errorMessage =  error.error.message;
      } else if(!Utils.isNullOrUndefinedOrWhitespace(error?.statusText)) {
        errorMessage =  error.statusText;
      }

    }

    this.loadingService.setLoading(false, request.url);
    if( serviceNotAvailable || isTimeOutError ) {
      const explicitError: IExplicitError = {
        Type: isTimeOutError ? Constants.ERROR_TYPE_TIMEOUT : Constants.ERROR_TYPE_404,
        ExplicitErrorMessage: errorMessage,
      };
      return throwError( explicitError);
    }

    return throwError( errorMessage );
  }


  private getAuthRequest( request: HttpRequest<any> ): HttpRequest<any> {
    const requestHeaderData: IRequestHeaderData = this.headerService.RequestHeaderData;
    const userToken: string = localStorage.getItem(Constants.AUTHENTICATION_TOKEN_KEY);
    const headers: HttpHeaders = request.headers
                  .set('Accept',  requestHeaderData.Accept)
                  .set('MERCHANT_GUID', requestHeaderData.MERCHANT_GUID)
                  .set('MERCHANT_APP_ID', requestHeaderData.MERCHANT_APP_ID)
                  .set('APP_LANG', requestHeaderData.APP_LANG)
                  .set('APP_VERSION', requestHeaderData.APP_VERSION)
                  .set('APP_BUNDLE_ID', requestHeaderData.APP_BUNDLE_ID)
                  .set('DEVICE_ID', requestHeaderData.DEVICE_ID)
                  .set('Authorization', 'Bearer ' + userToken)

    const authRequest: HttpRequest<any> = request.clone({ headers });
    return authRequest;
  }

}
