import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Utils } from 'src/app/common/Util';

@Component({
  selector: 'app-phone-verification-form',
  templateUrl: './phone-verification-form.component.html',
  styleUrls: ['./phone-verification-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneVerificationFormComponent implements OnInit {

  @Output() verifyCode: EventEmitter<string> = new EventEmitter<string>();
  @Output() resendVerificationCode: EventEmitter<void> = new EventEmitter<void>();
  @Input() phone: string;
  @Input() email: string;
  @Input() isOnBoarding: boolean = true;

  @Input() destination: string = '';
  @Input() last4Digits: string = '';

  form: FormGroup = this.formBuilder.group({
    code: new FormControl('', [Validators.minLength(4), Validators.maxLength(6), Validators.required])
  });
  
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    console.log(`PhoneVerificationFormComponent.ngOnInit() email=${Utils.debugGetSafeString(this.email)}, phone=${Utils.debugGetSafeString(this.phone)}, 
    isOnBoarding=${this.isOnBoarding}, destination=${Utils.debugGetSafeString(this.destination)}, last4Digits=${Utils.debugGetSafeString(this.last4Digits)}`);
  }

  ionViewWillEnter() {
    this.form.reset();
    console.log(`PhoneVerificationFormComponent.ionViewWillEnter() email=${Utils.debugGetSafeString(this.email)}, phone=${Utils.debugGetSafeString(this.phone)}, 
    isOnBoarding=${this.isOnBoarding}, destination=${Utils.debugGetSafeString(this.destination)}, last4Digits=${Utils.debugGetSafeString(this.last4Digits)}`);
  }


  confirm() {
    if( this.form.valid ) {
      const code: string = this.form.get('code').value;

      console.log(`PhoneVerificationFormComponent.confirm() code=${code}), email=${Utils.debugGetSafeString(this.email)}, phone=${Utils.debugGetSafeString(this.phone)}`);
    
      this.verifyCode.emit(code);
      this.form.reset();
    }
  }
  
  resendCode() {
    console.log(`PhoneVerificationFormComponent.resendCode() email=${Utils.debugGetSafeString(this.email)}, phone=${Utils.debugGetSafeString(this.phone)}`);
    this.resendVerificationCode.emit();
    this.form.reset();
  }

}
